import {createSlice} from '@reduxjs/toolkit';
import {logout} from 'src/api/auth';
import {createInvitationRequest, getInvitationDetail} from 'src/api/invitation';

const initialState = {
  isLoading: false,
  error: false,
  data: {},

  isLoadingCreateInvite: false,
  errorCreateInvite: false,
};

export const invitationSlice = createSlice({
  name: 'invitation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getInvitationDetail.pending, state => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(getInvitationDetail.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
    builder.addCase(getInvitationDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.data = action.payload.data;
    });

    builder.addCase(createInvitationRequest.pending, state => {
      state.isLoadingCreateInvite = true;
      state.errorCreateInvite = false;
    });
    builder.addCase(createInvitationRequest.rejected, state => {
      state.isLoadingCreateInvite = false;
      state.errorCreateInvite = true;
    });
    builder.addCase(createInvitationRequest.fulfilled, state => {
      state.isLoadingCreateInvite = false;
      state.errorCreateInvite = false;
    });

    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

const {reducer: invitationReducer} = invitationSlice;

export default invitationReducer;
