import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {getNotificationUrl} from '../../endpoints';

export const getNotificationRequest = createAsyncThunk(
  'notification/getNotificationRequest',
  async ({successCallback, failureCallback}, {rejectWithValue}) => {
    try {
      const response = await axios.request({
        method: 'GET',
        url: getNotificationUrl,
      });
      successCallback?.(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback?.(err);
        return rejectWithValue(err.response.data);
      }
    }
  },
);
