import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {loginUrl} from '../../endpoints';

export const loginRequest = createAsyncThunk(
  'auth/login',
  async ({successCallback, failureCallback, params}, {rejectWithValue}) => {
    try {
      const response = await axios.request({
        method: 'POST',
        url: loginUrl,
        data: params,
      });
      successCallback?.(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback?.(err);
        return rejectWithValue(err.response.data);
      }
    }
  },
);
