import {StyleSheet, Platform} from 'react-native';
import {colors} from 'src/constants';
import {calculateHeight, calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
  },
  imageBackground: {
    width: '100%',
    height: '100%',
  },
  bottomButtonMoreChooseCity: {
    marginHorizontal: calculateWidth(32),
    marginBottom: Platform.OS === 'ios' ? 0 : calculateWidth(32),
    position: 'absolute',
    width: calculateWidth(310),
    bottom: calculateHeight(10),
  },
  backIcon: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
  backButton: {
    position: 'absolute',
    left: calculateWidth(22),
    width: calculateWidth(40),
    height: calculateWidth(40),
    justifyContent: 'center',
    alignItems: 'center',
  },
  shareButton: {
    marginTop: calculateWidth(16),
  },
  findButton: {
    backgroundColor: '#fff',
  },
  locationButton: {
    backgroundColor: '#fff',
    marginTop: calculateWidth(16),
  },
  iconLocation: {
    width: calculateWidth(20),
    height: calculateWidth(20),
    marginRight: calculateWidth(4),
  },
  location: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: calculateWidth(8),
    marginBottom: calculateWidth(32),
  },
  centeredView: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  modalView: {
    width: calculateWidth(375),
    height: calculateHeight(300),
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTextCancel: {
    ...textFont(20, '400'),
  },
  modalTextOk: {
    ...textFont(20, '400'),
    color: '#60a445',
  },
  modalControl: {
    justifyContent: 'space-between',
    width: calculateWidth(375),
    flexDirection: 'row',
    padding: calculateWidth(5),
    paddingLeft: calculateWidth(15),
    paddingRight: calculateWidth(15),
    borderBottomWidth: 1,
    borderBottomColor: '#dbdada',
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  item: {
    backgroundColor: colors.neutral9,
    margin: 0,
    borderRadius: 5,
    textAlign: 'center',
  },
  itemSelected: {
    backgroundColor: colors.neutral7,
    margin: 0,
    borderRadius: 5,
    textAlign: 'center',
  },
  title: {
    ...textFont(20, '400'),
  },
  container: {
    flex: 1,
    marginTop: 0,
  },
  modal: {
    paddingTop: calculateHeight(15),
  },
});

export default styles;
