import {View, Text, Modal, TouchableOpacity, TextInput} from 'react-native';
import React from 'react';
import styles from './styles';
import {Button} from 'src/components';
import {useTranslations} from 'src/hooks';
import {useFormik} from 'formik';
import * as yup from 'yup';
const phoneRegExp = /1\d{10}/;

const ModalAddUser = ({show, onClose, onNext}) => {
  const {i18n} = useTranslations();

  const {values, errors, touched, handleSubmit, setFieldValue, resetForm} =
    useFormik({
      initialValues: {
        name: '',
        phone: '',
      },
      validationSchema: yup.object({
        name: yup.string().required(i18n.t('this_is_required')),
        phone: yup
          .string()
          .required(i18n.t('this_is_required'))
          .length(11, i18n.t('phone_not_valid'))
          .matches(phoneRegExp, i18n.t('phone_not_valid')),
      }),
      onSubmit: () => {
        onClose();
        onNext({name: values.name, phone: values.phone});
        resetForm({name: '', phone: ''});
      },
    });

  const handleClose = () => {
    resetForm({name: '', phone: ''});
    onClose();
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={show}
      onRequestClose={handleClose}>
      <View style={styles.container}>
        <View style={styles.popup}>
          <Text style={styles.headerAlign}>{i18n.t('add_people')}</Text>
          <View>
            <TextInput
              value={values.name}
              onChangeText={value => {
                setFieldValue('name', value);
              }}
              style={[
                styles.nameInput,
                touched?.name && errors?.name && styles.error,
              ]}
            />
            <Text style={styles.label}>{i18n.t('nickname')}</Text>
          </View>
          <View>
            <TextInput
              value={values.phone}
              onChangeText={value => {
                setFieldValue('phone', value);
              }}
              style={[
                styles.nameInput,
                touched?.phone && errors?.phone && styles.error,
              ]}
              keyboardType="phone-pad"
            />
            <Text style={styles.errorText}>
              {touched?.phone && errors?.phone ? i18n.t('phone_invalid') : ''}
            </Text>
            <Text style={styles.label}>{i18n.t('phone')}</Text>
          </View>

          <Button
            title={i18n.t('add')}
            buttonStyle={styles.bottomButton}
            onClick={handleSubmit}
          />
          <TouchableOpacity style={styles.buttonClose} onPress={handleClose}>
            <Text style={styles.textClose}>+</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ModalAddUser;
