import {
  View,
  Text,
  Modal,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from 'react-native';
import React from 'react';
import styles from './styles';
import {useTranslations} from 'src/hooks';
import {useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import Member from '../Member';
import dayjs from 'dayjs';
import {useSelector} from 'react-redux';

const ModalInvite = ({modalVisible, onClose, tripId, data, creatorId}) => {
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const userData = useSelector(state => state.user.data);

  const members = data?.members;
  const invitedList = data?.invited_users;
  const maximumMember = data?.maximum_member;

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={onClose}>
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={styles.container}>
          <View style={styles.content}>
            <Text style={styles.text15400}>
              {i18n.t('invitation.invite_friends')}
            </Text>
            <View style={styles.wrapper}>
              <View>
                <Member
                  creatorId={creatorId}
                  members={members}
                  invitedList={invitedList}
                  maximumMember={maximumMember}
                  containerStyle={styles.profile}
                  shouldNavigateUserDetail={false}
                />
                <TouchableOpacity
                  style={styles.overlap}
                  onPress={() => {
                    onClose();
                    navigation.navigate(routeNames.MainFlow.FindFriend, {
                      tripId,
                      inviteLimit: 1000000,
                    });
                  }}
                />
              </View>
              <View>
                <View style={styles.border} />
                <View style={styles.moveOrText}>
                  <Text style={styles.textOr}>{i18n.t('or')}</Text>
                </View>
              </View>
              <TouchableOpacity
                style={styles.buttonFind}
                onPress={() => {
                  onClose();
                  navigation.navigate(routeNames.MainFlow.ShareActivity, {
                    data: JSON.stringify({
                      title: userData.name,
                      dateFromTo: `${dayjs(data?.from_date).format(
                        'DD/MM',
                      )} - ${dayjs(data?.to_date).format('DD/MM')}`,
                      year: dayjs(data?.from_date).format('YYYY'),
                      note: data?.heading || '',
                      backgroundUrl: data?.background || '',
                    }),
                    tripId,
                  });
                }}>
                <Text style={styles.text16600}>
                  {i18n.t('invitation.find_on_we_chat')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default ModalInvite;
