import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';

import {useTranslations} from 'src/hooks';
import {Button} from 'src/components';

import styles from '../styles';
import {useNavigation} from '@react-navigation/native';
import images from 'src/assets/images';

const Header = ({
  title,
  onPreview,
  disabled,
  onGoBack,
  showBackButton = true,
}) => {
  const {i18n} = useTranslations();
  const navigation = useNavigation();

  return (
    <>
      <View style={styles.headerContainer}>
        {showBackButton ? (
          <TouchableOpacity
            style={styles.leftIconWrapper}
            onPress={() => {
              if (navigation.canGoBack()) {
                onGoBack?.();
                navigation.goBack();
              }
            }}>
            <Image
              source={images.ArrowLeft}
              style={styles.leftIcon}
              resizeMode="contain"
            />
          </TouchableOpacity>
        ) : (
          <View style={styles.leftIconWrapper} />
        )}
        <Text style={styles.header}>{title || i18n.t('addStory.create')}</Text>
        <Button
          disabled={disabled}
          onClick={onPreview}
          title={i18n.t('addStory.preview')}
          buttonStyle={styles.preview}
        />
      </View>
      <View style={styles.divider} />
    </>
  );
};

export default Header;
