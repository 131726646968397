import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: calculateWidth(8),
  },
  containerUser: {
    flexDirection: 'row-reverse',
    paddingVertical: calculateWidth(8),
  },
  avatar: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    borderRadius: 100,
    backgroundColor: 'lightgray',
    marginHorizontal: calculateWidth(16),
    marginTop: 2,
  },
  content: {flex: 1, marginRight: calculateWidth(16)},
  contentUser: {
    flex: 1,
    marginLeft: calculateWidth(16),
    alignItems: 'flex-end',
  },
  textName: {
    ...textFont(14, '600'),
  },
  messageWrapper: {
    marginTop: calculateWidth(6),
    alignSelf: 'baseline',
    borderTopLeftRadius: 0,
    borderTopRightRadius: calculateWidth(16),
    borderBottomLeftRadius: calculateWidth(16),
    borderBottomRightRadius: calculateWidth(16),
    backgroundColor: colors.neutral8,
    padding: calculateWidth(8),
  },
  messageWrapperUser: {
    marginTop: calculateWidth(6),
    borderTopRightRadius: 0,
    borderTopLeftRadius: calculateWidth(16),
    borderBottomLeftRadius: calculateWidth(16),
    borderBottomRightRadius: calculateWidth(16),
    backgroundColor: colors.neutral8,
    padding: calculateWidth(8),
  },
  message: {
    ...textFont(16, '400'),
    alignSelf: 'baseline',
  },
});

export default styles;
