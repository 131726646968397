import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.neutral9,
    borderRadius: 12,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.05,
    shadowRadius: 16,
    elevation: 5,
    alignItems: 'center',
    padding: calculateWidth(17),
    flexDirection: 'row',
  },
  flexRow: {
    flexDirection: 'row',
  },
  image: {
    width: calculateWidth(56),
    height: calculateWidth(56),
    borderRadius: 200,
    marginRight: calculateWidth(13),
    backgroundColor: colors.neutral6,
  },
  userData: {
    flex: 1,
  },
  userWrapper: {
    flexDirection: 'row',
    marginBottom: calculateWidth(8),
    alignItems: 'center',
    marginRight: calculateWidth(8),
  },
  userName: {
    ...textFont(22, '600'),
  },
  userRole: {
    ...textFont(13, '600'),
    color: colors.neutral9,
  },
  userRoleBackground: {
    borderRadius: 100,
    backgroundColor: colors.secondary1,
    marginLeft: calculateWidth(8),
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: calculateWidth(6),
    height: calculateWidth(20),
  },
  follow: {
    ...textFont(14, '600'),
    color: colors.neutral4,
  },
  code: {
    width: calculateWidth(27),
    height: calculateWidth(27),
  },
  circle: {
    width: 8,
    height: 8,
  },
  circleWrapper: {
    justifyContent: 'center',
    marginHorizontal: calculateWidth(12),
  },
});
