import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';

const styles = StyleSheet.create({
  container: {},
  hideView: {display: 'none'},
  thumbnail: {
    width: calculateWidth(25),
    height: calculateWidth(25),
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(6),
    justifyContent: 'center',
    alignItems: 'center',
    margin: calculateWidth(8),
  },
  thumbnailWrapper: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
  },
  listImage: {
    flexDirection: 'row',
  },
});

export default styles;
