import {StyleSheet} from 'react-native';

import {calculateWidth} from 'src/utils/dimensions';
import {colors} from 'src/constants';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: calculateWidth(15),
    marginHorizontal: calculateWidth(32),
    borderBottomWidth: 1,
    borderBottomColor: colors.neutral7,
  },
  leftIconWrapper: {
    minWidth: calculateWidth(40),
    height: calculateWidth(40),
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  leftIcon: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
  textTitle: {
    ...textFont(16, '400'),
    color: colors.neutral1,
  },
  burger: {
    width: calculateWidth(20),
    height: calculateWidth(20),
    tintColor: colors.neutral1,
  },
  menuItem: {
    ...textFont(16, '400'),
    color: colors.neutral1,
  },
});
