import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {flex: 1, width: '100%', height: '100%', backgroundColor: '#fff'},
  content: {
    paddingHorizontal: calculateWidth(20),
    paddingBottom: calculateWidth(20),
    flex: 1,
  },

  textTitle: {
    ...textFont(32, '600'),
    color: colors.neutral1,
  },
  recommendWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: calculateWidth(10),
  },
  tagWrapper: {
    paddingHorizontal: calculateWidth(15),
    paddingVertical: calculateWidth(7),
    marginRight: calculateWidth(10),
    marginTop: calculateWidth(10),
    borderRadius: calculateWidth(8),
    backgroundColor: colors.neutral8,
  },
});
export default styles;
