import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.neutral9,
  },
  title: {
    ...textFont(32, '600'),
    color: colors.neutral0,
    alignSelf: 'baseline',
    backgroundColor: colors.neutral9,
  },
  titleWrapper: {
    marginTop: calculateWidth(20),
    alignSelf: 'center',
  },
  image: {
    width: calculateWidth(375),
    height: calculateWidth(65),
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    paddingBottom: calculateWidth(16),
  },
  calendar: {
    backgroundColor: colors.neutral9,
  },

  detail: {
    backgroundColor: colors.neutral8,
  },
  tripList: {
    padding: calculateWidth(16),
  },

  // Event
  event: {
    padding: calculateWidth(16),
    borderRadius: calculateWidth(12),
    marginBottom: calculateWidth(16),
    backgroundColor: colors.neutral9,
  },
  eventHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: calculateWidth(16),
    alignItems: 'center',
  },
  eventTitle: {
    ...textFont(18, '600'),
    color: colors.neutral1,
    flex: 1,
  },
  members: {
    padding: 0,
  },
  avatar: {
    width: calculateWidth(48),
    height: calculateWidth(48),
  },
  buttonAdd: {
    width: calculateWidth(48),
    height: calculateWidth(48),
    backgroundColor: colors.neutral7,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: calculateWidth(24),
    height: calculateWidth(24),
  },

  borderWrapper: {
    marginBottom: calculateWidth(16),
    height: calculateWidth(20),
    justifyContent: 'center',
  },
  border: {
    borderWidth: 0.5,
    borderStyle: 'dashed',
    marginVertical: calculateWidth(1),
    borderColor: colors.neutral6,
  },
  textOr: {
    backgroundColor: colors.neutral8,
    paddingHorizontal: calculateWidth(10),
    textAlign: 'center',
    ...textFont(15, '400'),
    color: colors.neutral4,
    lineHeight: calculateWidth(20),
    textTransform: 'uppercase',
  },
  moveOrText: {
    position: 'absolute',
    top: 0,
    alignSelf: 'center',
  },
  chatIcon: {
    width: calculateWidth(21),
    height: calculateWidth(22),
  },
});

export default styles;
