import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getOrderDetailRequest} from '../requests';

export const useGetOrderDetail = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(
    state => state.activities.isLoadingGetOrderDetail,
  );

  const [responseData, setResponseData] = useState(null);

  const dispatch = useDispatch();

  const runRequest = useCallback(
    orderId => {
      dispatch(
        getOrderDetailRequest({
          orderId,
          failureCallback: err => {
            failureCallback?.(err);
          },
          successCallback: data => {
            setResponseData(data);
            successCallback?.(data);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  return {
    isLoading,
    runRequest,
    responseData,
  };
};
