import React, {useState} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';

import images from 'src/assets/images';

import styles from './styles';
import {useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import ImageView from '../ImageView';
import {isEmpty} from 'lodash';
import ShareCode from '../ShareCode';
import {API_URL} from 'src/utils/axiosConfig';
import {useTranslations} from 'src/hooks';

const UserCard = ({
  source,
  userName,
  follower,
  following,
  userId,
  isAccompany,
  isOrganizer,
}) => {
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const [showImageList, setShowImageList] = useState([]);
  const [visibleShare, setVisibleShare] = useState(false);

  const navigateToFollowStatus = () => {
    navigation.navigate(routeNames.NonAuthFlow.FollowStatus, {
      userId,
    });
  };

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity
          onPress={() => {
            source.uri && setShowImageList([source.uri]);
          }}>
          <Image
            source={isAccompany ? images.MockAvatar : source}
            style={styles.image}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={navigateToFollowStatus}
          style={styles.userData}>
          <View style={styles.userWrapper}>
            <Text style={styles.userName}>
              {isAccompany ? i18n.t('this_user_not_sign_up') : userName}
            </Text>
            {isOrganizer && (
              <View style={styles.userRoleBackground}>
                <Text style={styles.userRole}>{i18n.t('organizer')}</Text>
              </View>
            )}
          </View>
          {!isAccompany && (
            <View style={styles.flexRow}>
              <Text style={styles.follow}>{follower}</Text>
              <View style={styles.circleWrapper}>
                <Image
                  source={images.Circle}
                  resizeMode="center"
                  style={styles.circle}
                />
              </View>
              <Text style={styles.follow}>{following}</Text>
            </View>
          )}
        </TouchableOpacity>
        {!isAccompany && (
          <TouchableOpacity
            onPress={() => {
              setVisibleShare(true);
            }}>
            <Image source={images.Code} style={styles.code} />
          </TouchableOpacity>
        )}
      </View>
      <ShareCode
        value={`${API_URL}/user/${userId}`}
        visible={visibleShare}
        onClose={() => {
          setVisibleShare(false);
        }}
      />
      <ImageView
        visible={!isEmpty(showImageList)}
        setVisible={() => {
          setShowImageList([]);
        }}
        imageList={showImageList}
      />
    </>
  );
};

export default UserCard;
