import {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {deleteStoryRequest} from '../requests';

export const useDeleteStory = ({failureCallback, successCallback}) => {
  const [responseData, setResponseData] = useState(null);
  const dispatch = useDispatch();

  const runRequest = useCallback(
    storyId => {
      dispatch(
        deleteStoryRequest({
          storyId,
          failureCallback: err => {
            failureCallback?.(err);
          },
          successCallback: response => {
            setResponseData(response);
            successCallback?.(response);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  return {
    runRequest,
    responseData,
  };
};
