import {StyleSheet} from 'react-native';

import {calculateWidth} from 'src/utils/dimensions';

import {colors} from 'src/constants';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.neutral9,
  },
  header: {
    width: calculateWidth(375),
    height: calculateWidth(248),
    overflow: 'hidden',
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  headerNavigation: {
    borderBottomWidth: 0,
  },
  image: {
    width: calculateWidth(375),
    height: calculateWidth(248),
    backgroundColor: colors.neutral4,
  },
  profileIcon: {
    width: calculateWidth(20),
    height: calculateWidth(20),
    marginRight: calculateWidth(20),
  },
  userWrapper: {
    width: '90%',
    marginLeft: '5%',
    marginTop: '-12%',
    marginBottom: calculateWidth(24),
  },
  bio: {
    ...textFont(16, '400'),
    marginHorizontal: calculateWidth(32),
    color: colors.neutral1,
    marginBottom: calculateWidth(22),
  },
  pillWrapper: {
    flexDirection: 'row',
    marginHorizontal: calculateWidth(32),
    flexWrap: 'wrap',
    marginBottom: calculateWidth(22),
  },
  divider: {
    height: 4,
    backgroundColor: colors.neutral8,
  },
  edit: {
    height: calculateWidth(40),
    marginHorizontal: calculateWidth(32),
    marginVertical: calculateWidth(20),
  },
  following: {
    backgroundColor: colors.neutral7,
    marginHorizontal: calculateWidth(32),
    marginVertical: calculateWidth(20),
  },
  bottomWrapper: {
    backgroundColor: colors.neutral8,
  },
  achievements: {
    ...textFont(18, '600'),
    color: colors.neutral1,
  },
  achievement: {
    paddingLeft: calculateWidth(32),
  },
  achievementWrapper: {
    marginHorizontal: calculateWidth(32),
    marginBottom: calculateWidth(20),
    marginTop: calculateWidth(32),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
  },
  dividerImg: {
    height: 2,
    marginHorizontal: calculateWidth(32),
    marginBottom: calculateWidth(33),
  },
  journey: {
    height: 40,
    marginHorizontal: calculateWidth(32),
    marginBottom: calculateWidth(32),
  },
  journeyBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  story: {
    ...textFont(32, '600'),
  },
  ss: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
});
