import {
  Platform,
  View,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import React from 'react';

import styles from './styles';
import {isEmpty} from 'lodash';
import {
  IMAGE_COMPRESS_QUALITY,
  MAX_IMAGE_HEIGHT,
  MAX_IMAGE_WIDTH,
} from 'src/constants';

/**
 *
 * @param {base64String/url} image Current image
 * @param {boolean} isLoading Loading state
 * @param {ReactNode} thumbnail Show when no image
 * @param {ReactNode} thumbnailIcon Show on the top of image
 * @param {Object} thumbnailStyle Style for thumbnailIcon
 * @param {Function} onSelectImage (image: base64String) => void
 * @param {boolean} showImageAfterUpload Enable show image
 * @param {boolean} resizeImage Enable resize image
 * @param {boolean} compressImage Enable compress image
 */
const ImageSelector = ({
  thumbnail,
  thumbnailIcon,
  containerStyle,
  onSelectImage,
  image,
  isLoading,
  thumbnailStyle = {},
  showImageAfterUpload = true,
  resizeImage = true,
  compressImage = true,
  multiple = false,
}) => {
  const inputFileRef = React.useRef();
  const handleFileSelection = event => {
    if (!isEmpty(event?.target?.files)) {
      const files = event.target.files;
      const reader = new FileReader();
      const readFile = (index, listImageString) => {
        if (index >= files.length) {
          if (listImageString.length === 1) {
            onSelectImage(listImageString[0]);
          } else {
            onSelectImage(listImageString);
          }
          return;
        }
        reader.onload = e => {
          const img = document.createElement('img');
          img.onload = () => {
            let width = img.width;
            let height = img.height;
            if (resizeImage) {
              if (width > height) {
                if (width > MAX_IMAGE_WIDTH) {
                  height *= MAX_IMAGE_WIDTH / width;
                  width = MAX_IMAGE_WIDTH;
                }
              } else {
                if (height > MAX_IMAGE_HEIGHT) {
                  width *= MAX_IMAGE_HEIGHT / height;
                  height = MAX_IMAGE_HEIGHT;
                }
              }
            }
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(img, 0, 0, width, height);

            const dataUrl = compressImage
              ? canvas.toDataURL('image/jpeg', IMAGE_COMPRESS_QUALITY)
              : canvas.toDataURL();
            readFile(index + 1, [...listImageString, dataUrl]);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[index]);
      };
      readFile(0, []);
    }
  };
  const handleOpenPicker = () => {
    if (Platform.OS === 'web') {
      inputFileRef.current.click();
    } else {
      require('react-native-image-crop-picker')
        ?.openPicker({
          includeBase64: true,
          mediaType: 'photo',
          multiple: multiple,
          ...(compressImage && {compressImageQuality: IMAGE_COMPRESS_QUALITY}),
          ...(resizeImage && {
            compressImageMaxWidth: MAX_IMAGE_WIDTH,
            compressImageMaxHeight: MAX_IMAGE_HEIGHT,
          }),
        })
        .then(images => {
          if (!isEmpty(images)) {
            onSelectImage(`data:${images.mime};base64,${images.data}`);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <TouchableOpacity
      disabled={isLoading}
      style={[styles.container, containerStyle]}
      onPress={handleOpenPicker}>
      {showImageAfterUpload && !isEmpty(image) ? (
        <View style={styles.listImage}>
          {(typeof image === 'string' ? [image] : image).map((data, index) => {
            return (
              <ImageBackground
                key={index}
                source={{uri: data}}
                style={[containerStyle, styles.thumbnailWrapper]}>
                {thumbnailIcon && (
                  <View
                    style={StyleSheet.flatten([
                      styles.thumbnail,
                      thumbnailStyle,
                    ])}>
                    {thumbnailIcon}
                  </View>
                )}
              </ImageBackground>
            );
          })}
        </View>
      ) : (
        thumbnail
      )}
      {Platform.OS === 'web' && (
        <View style={styles.hideView}>
          <input
            ref={inputFileRef}
            type="file"
            onChange={handleFileSelection}
            accept="image/*"
            multiple={multiple}
          />
        </View>
      )}
    </TouchableOpacity>
  );
};

export default ImageSelector;
