import {View, Text, Image} from 'react-native';
import React from 'react';
import styles from './styles';
import {useSelector} from 'react-redux';
import {find} from 'lodash';
import {getUserPhoto} from 'src/utils/common';

const ChatItem = ({item}) => {
  const userData = useSelector(state => state.user.data);
  const chatUser = useSelector(state => state.conversation.data)?.members || [];

  const isMyMessage = item.sender === userData.id;
  const messageUser = isMyMessage
    ? {
        username: userData.name || userData.username,
        avatar: getUserPhoto(userData),
      }
    : find(chatUser, i => i.id === item.sender);
  const username = `${messageUser?.name || messageUser?.username}@` || '';

  return (
    <View style={isMyMessage ? styles.containerUser : styles.container}>
      {messageUser?.avatar ? (
        <Image source={{uri: messageUser.avatar}} style={styles.avatar} />
      ) : (
        <View style={styles.avatar} />
      )}
      <View style={isMyMessage ? styles.contentUser : styles.content}>
        <Text style={styles.textName}>
          {username.slice(0, username.indexOf('@'))}
        </Text>
        <View
          style={
            isMyMessage ? styles.messageWrapperUser : styles.messageWrapper
          }>
          <Text style={styles.message}>{item.content}</Text>
        </View>
      </View>
    </View>
  );
};

export default ChatItem;
