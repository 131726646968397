import React, {useEffect} from 'react';
import {View, Text, ScrollView, ImageBackground, Image} from 'react-native';
import {StackActions, useNavigation, useRoute} from '@react-navigation/native';
import {useSelector} from 'react-redux';

import images from 'src/assets/images';
import {getUserAge, getUserPhoto} from 'src/utils/common';
import {useAlert, useTranslations} from 'src/hooks';
import {
  useFollowUser,
  useGetUserDetail,
  useGetUserStory,
  useUnFollowUser,
} from 'src/api/users';

import {
  UserCard,
  Pill,
  Achievement,
  Story,
  HomeHeader,
  Loading,
  Button,
} from 'src/components';

import styles from './styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {includes, isEmpty, map} from 'lodash';
import {USER_DATING} from 'src/constants';
import routeNames from 'src/navigation/routeNames';

const UserDetail = () => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const navigation = useNavigation();
  const route = useRoute();
  const userId = route.params?.userId;
  const isAccompany = !userId;
  const {i18n} = useTranslations();
  const {alert, confirm} = useAlert();
  const insets = useSafeAreaInsets();

  const {runRequest: getUserStory, isLoading: loadingGetUserStory} =
    useGetUserStory({});
  const {runRequest: getUserDetail, isLoading: loadingGetUserDetail} =
    useGetUserDetail({
      failureCallback: () => {
        alert(i18n.t('something_went_wrong'), () => {
          if (navigation.canGoBack()) {
            navigation.goBack();
          } else {
            navigation.dispatch(
              StackActions.replace(
                isLoggedIn
                  ? routeNames.BottomTab
                  : routeNames.AuthFlow.WelcomeScreen,
              ),
            );
          }
        });
      },
    });
  const {runRequest: followUser, isLoading: loadingFollowUser} = useFollowUser({
    successCallback: () => {
      getUserDetail(userId);
    },
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'));
    },
  });
  const {runRequest: unFollowUser, isLoading: loadingUnFollowUser} =
    useUnFollowUser({
      successCallback: () => {
        getUserDetail(userId);
      },
      failureCallback: () => {
        alert(i18n.t('something_went_wrong'));
      },
    });
  const userStory = useSelector(state => state.metadata.story);
  const userAchievement = useSelector(
    state => state.metadata.userDetail?.achievements || [],
  );
  const userDetail = useSelector(state => state.metadata.userDetail);
  const userMetadata = isAccompany ? {} : userDetail;

  const userData = useSelector(state => state.user.data);

  useEffect(() => {
    if (!isAccompany) {
      getUserDetail(userId);
      getUserStory(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const isLoading =
    loadingFollowUser ||
    loadingUnFollowUser ||
    loadingGetUserDetail ||
    loadingGetUserStory;

  const isFollowing = userMetadata?.followers?.includes(userData.id);

  const hobby = [
    `${userMetadata.gender === 'male' ? '♂' : '♀'} ${getUserAge(
      userMetadata,
    )} ${i18n.t('invitation.age')}`,
    ...(userMetadata?.job ? [userMetadata?.job] : []),
    ...(userMetadata?.industry ? [userMetadata?.industry] : []),
    ...(userMetadata?.lifestyle || []),
    ...(isEmpty(
      USER_DATING.filter(i => includes(userMetadata?.dating_standard, i)),
    )
      ? []
      : [
          map(
            USER_DATING.filter(i => includes(userMetadata?.dating_standard, i)),
            i18n.t,
          ).join(' / '),
        ]),
  ];

  return (
    <>
      <View style={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={styles.header}>
            <ImageBackground
              source={images.Background}
              style={styles.image}
              resizeMode="cover">
              <HomeHeader
                withBackButton
                containerStyle={[
                  styles.headerNavigation,
                  {marginTop: insets.top},
                ]}
                isDarkHeader={true}
              />
            </ImageBackground>
          </View>
          <View style={styles.userWrapper}>
            <UserCard
              userId={userId}
              source={
                getUserPhoto(userMetadata)
                  ? {uri: getUserPhoto(userMetadata)}
                  : undefined
              }
              isAccompany={isAccompany}
              userName={userMetadata.name}
              follower={`${userMetadata?.followers?.length || 0} ${i18n.t(
                'follower',
              )}`}
              following={`${userMetadata?.following?.length || 0} ${i18n.t(
                'following',
              )}`}
            />
          </View>
          {isAccompany ? null : (
            <>
              <Text style={styles.bio}>{userMetadata.bio}</Text>
              <View style={styles.pillWrapper}>
                {map(hobby, (data, index) => {
                  return <Pill content={data} key={index} />;
                })}
              </View>
              {isLoggedIn && userData.id !== userId && (
                <>
                  <View style={styles.divider} />
                  {isFollowing ? (
                    <Button
                      onClick={() => {
                        confirm(
                          i18n.t('un_follow_confirm'),
                          () => {
                            unFollowUser(userId);
                          },
                          () => {},
                        );
                      }}
                      title={i18n.t('unFollow')}
                      buttonStyle={styles.following}
                    />
                  ) : (
                    <Button
                      title={`+   ${i18n.t('follow')}`}
                      onClick={() => {
                        followUser(userId);
                      }}
                      buttonStyle={styles.edit}
                    />
                  )}
                </>
              )}
              <View style={styles.bottomWrapper}>
                <View style={styles.achievementWrapper}>
                  <Text style={styles.achievements}>
                    {i18n.t('achievements')}
                  </Text>
                </View>
                <ScrollView
                  horizontal
                  style={styles.achievement}
                  showsHorizontalScrollIndicator={false}>
                  {map(userAchievement, (achievement, index) => {
                    return (
                      <Achievement key={index} achievement={achievement} />
                    );
                  })}
                </ScrollView>
                <Image
                  source={images.Divider}
                  resizeMode="contain"
                  style={styles.dividerImg}
                />
                <View style={styles.journey}>
                  <ImageBackground
                    style={styles.journeyBackground}
                    source={images.Journey}
                    resizeMode="contain">
                    <Text style={styles.story}>{i18n.t('my_story')}</Text>
                  </ImageBackground>
                </View>
                <Story userStory={userStory} />
              </View>
            </>
          )}
        </ScrollView>
      </View>
      {isLoading && <Loading />}
    </>
  );
};

export default UserDetail;
