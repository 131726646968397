import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateHeight, calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(18, 24, 16, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    width: calculateWidth(375),
    alignSelf: 'center',
  },
  popup: {
    width: calculateWidth(335),
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(24),
    padding: calculateWidth(36),
    maxHeight: calculateHeight(700),
  },
  buttonClose: {
    width: calculateWidth(36),
    height: calculateWidth(36),
    backgroundColor: colors.neutral8,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 12,
    top: calculateWidth(-18),
  },
  textClose: {
    fontSize: calculateWidth(25),
    marginBottom: calculateWidth(2),
    marginLeft: calculateWidth(3),
    color: colors.neutral1,
    transform: [{rotate: '45deg'}],
  },

  headerAlign: {
    ...textFont(32, '600'),
    color: colors.neutral1,
    textAlign: 'center',
    marginBottom: calculateWidth(12),
  },
  disclaimerItem: {
    flexDirection: 'row',
    marginTop: calculateWidth(16),
  },
  disclaimerTitle: {
    ...textFont(13, '700'),
    color: colors.neutral5,
    borderWidth: 1,
    borderColor: colors.neutral5,
    paddingVertical: calculateWidth(4),
    paddingHorizontal: calculateWidth(12),
    borderRadius: calculateWidth(14),
  },
  disclaimerDetail: {
    ...textFont(16, '400'),
    color: colors.neutral1,
    marginLeft: calculateWidth(16),
    marginTop: calculateWidth(2),
    flex: 1,
  },

  bottomButton: {
    marginTop: calculateWidth(40),
  },
});

export default styles;
