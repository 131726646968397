import {StyleSheet} from 'react-native';
import {opacityColor, textFont} from 'src/utils/styles';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';

export default StyleSheet.create({
  container: {flex: 1, backgroundColor: colors.neutral9},
  thumb: {
    width: '100%',
    height: 581,
    backgroundColor: colors.neutral6,
  },
  dotActiveStyle: {
    backgroundColor: colors.secondary1,
    width: calculateWidth(24),
    height: calculateWidth(4),
    borderRadius: calculateWidth(4),
    marginHorizontal: calculateWidth(4),
  },

  dotsNormalStyle: {
    backgroundColor: colors.neutral9,
    width: calculateWidth(12),
    height: calculateWidth(4),
    borderRadius: calculateWidth(4),
    marginHorizontal: calculateWidth(4),
  },
  nameWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: calculateWidth(32),
  },
  code: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
  location: {
    width: 20,
    height: 20,
  },
  title: {
    ...textFont(32, '600'),
    marginVertical: calculateWidth(12),
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: calculateWidth(32),
    marginBottom: calculateWidth(9),
  },
  publish: {
    ...textFont(16, '400'),
    color: colors.neutral4,
    marginRight: 8,
  },
  author: {
    ...textFont(16, '400'),
    marginLeft: 8,
  },
  locationText: {
    ...textFont(16, '400'),
    marginLeft: 8,
  },
  content: {
    margin: calculateWidth(32),
  },
  divider: {
    height: 1,
    backgroundColor: colors.neutral7,
    marginVertical: calculateWidth(20),
  },
  image: {width: '100%', height: 311},
  interactive: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    ...textFont(15, '600'),
    color: colors.neutral4,
    marginLeft: 7,
  },
  textLiked: {
    color: colors.primary,
  },
  icon: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
  bigDivider: {
    height: 4,
    backgroundColor: colors.neutral7,
  },
  details: {
    ...textFont(22, '600'),
    marginBottom: calculateWidth(12),
  },
  detailsWrapper: {
    margin: calculateWidth(32),
  },
  sectionText: {
    ...textFont(18, '400'),
    color: colors.neutral4,
    marginBottom: calculateWidth(16),
  },
  sectionImage: {
    marginBottom: calculateWidth(16),
    borderRadius: calculateWidth(6),
    width: calculateWidth(311),
    height: calculateWidth(311),
  },
  avatar: {
    width: calculateWidth(24),
    height: calculateWidth(24),
    borderRadius: 100,
    backgroundColor: colors.neutral6,
  },
  userImage: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    borderRadius: 100,
    backgroundColor: colors.neutral6,
  },
  input: {
    height: calculateWidth(40),
    borderWidth: 1,
    borderColor: colors.neutral6,
    borderRadius: calculateWidth(8),
    backgroundColor: colors.neutral9,
    color: colors.neutral1,
    paddingHorizontal: calculateWidth(10),
    marginLeft: calculateWidth(16),
    flex: 1,
    ...textFont(16, '400'),
  },
  userComment: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: calculateWidth(16),
  },
  sendWrapper: {
    marginLeft: calculateWidth(10),
  },
  sendIcon: {
    width: calculateWidth(30),
    height: calculateWidth(30),
  },

  replyText: {
    marginLeft: calculateWidth(56),
    marginBottom: calculateWidth(4),
    ...textFont(12, '400'),
    color: colors.neutral4,
  },
  textReplyName: {
    ...textFont(12, '600'),
    color: colors.neutral4,
  },
  textCancel: {
    marginLeft: calculateWidth(2),
    ...textFont(12, '400'),
    color: colors.neutral4,
  },
  more: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: calculateWidth(6),
    backgroundColor: opacityColor(colors.neutral8, 0.8),
  },
  moreText: {
    ...textFont(16, '600'),
    color: colors.neutral1,
  },
});
