import {View, Text, Modal, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import styles from './styles';
import {Button} from 'src/components';
import {useTranslations} from 'src/hooks';
import images from 'src/assets/images';

const ModalFailure = ({title, show, onClose, onNext}) => {
  const {i18n} = useTranslations();

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={show}
      onRequestClose={onClose}>
      <View style={styles.container}>
        <View style={styles.popup}>
          <View>
            <Text style={styles.headerAlign}>{i18n.t('payment')}</Text>
            <Text style={styles.textDetail}>{title}</Text>
          </View>
          <Image source={images.Failure} style={styles.success} />
          <Button
            title={i18n.t('payment_again')}
            buttonStyle={styles.bottomButton}
            onClick={onNext}
          />
          <TouchableOpacity style={styles.buttonClose} onPress={onClose}>
            <Text style={styles.textClose}>+</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ModalFailure;
