import {StyleSheet} from 'react-native';
import {HEADER_HEIGHT} from 'src/constants';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: calculateWidth(10),
    borderBottomWidth: 1,
    borderBottomColor: colors.neutral7,
    height: calculateWidth(HEADER_HEIGHT),
  },
  leftIconWrapper: {
    minWidth: calculateWidth(40),
    height: calculateWidth(40),
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  leftIcon: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
  textTitle: {
    ...textFont(16, '400'),
    color: colors.neutral1,
  },
  textTitleDark: {
    ...textFont(16, '400'),
    color: colors.neutral9,
  },
  rightIconWrapper: {
    height: calculateWidth(40),
    flexDirection: 'row',
  },
  notification: {
    width: calculateWidth(15),
    height: calculateWidth(15),
    borderRadius: 100,
    backgroundColor: colors.error,
    position: 'absolute',
    top: calculateWidth(4),
    right: calculateWidth(6),
    justifyContent: 'center',
    alignItems: 'center',
  },
  textNotification: {
    ...textFont(9, '400'),
    color: colors.neutral9,
  },
  museum: {
    borderColor: colors.neutral5,
    borderWidth: 1,
    paddingVertical: calculateWidth(3),
    paddingHorizontal: calculateWidth(8),
    borderRadius: calculateWidth(100),
    marginLeft: calculateWidth(6),
  },
});
export default styles;
