import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {userDislikeRequest} from '../requests';

export const useUserDislike = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(state => state.metadata.isLoadingUserDislike);
  const [responseData, setResponseData] = useState(null);
  const dispatch = useDispatch();

  const runRequest = useCallback(
    userId => {
      dispatch(
        userDislikeRequest({
          userId,
          failureCallback: err => {
            failureCallback && failureCallback(err);
          },
          successCallback: data => {
            setResponseData(data);
            successCallback && successCallback(data);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  const returnObject = {
    isLoading,
    runRequest,
    responseData,
  };

  return returnObject;
};
