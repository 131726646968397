import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {createStoryRequest} from '../requests';

export const useCreateStory = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(state => state.story.isLoadingCreateStory);
  const [responseData, setResponseData] = useState(null);
  const dispatch = useDispatch();

  const runRequest = useCallback(
    data => {
      dispatch(
        createStoryRequest({
          data,
          failureCallback: err => {
            failureCallback?.(err);
          },
          successCallback: response => {
            setResponseData(response);
            successCallback?.(response);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  return {
    isLoading,
    runRequest,
    responseData,
  };
};
