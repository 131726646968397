import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    width: calculateWidth(164),
    height: calculateWidth(260),
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: calculateWidth(8),
    marginBottom: calculateWidth(16),
  },
  invitation: {
    flex: 1,
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(8),
    padding: calculateWidth(12),
    shadowColor: colors.neutral5,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },

  userWrapper: {
    flexDirection: 'row',
    marginTop: calculateWidth(2),
    alignItems: 'center',
    padding: calculateWidth(12),
  },
  avatar: {
    width: calculateWidth(15),
    height: calculateWidth(15),
  },
  textName: {
    marginLeft: calculateWidth(6),
    ...textFont(14, '600'),
    color: colors.neutral4,
    lineHeight: calculateWidth(20),
  },

  image: {
    width: calculateWidth(137),
    flex: 1,
    borderRadius: calculateWidth(9),
    backgroundColor: 'gray',
  },
  textTitle: {
    ...textFont(12, '400'),
    color: colors.black,
    marginTop: calculateWidth(4),
    marginRight: calculateWidth(6),
  },
  textVillage: {
    ...textFont(12, '400'),
    color: colors.neutral4,
    marginLeft: calculateWidth(4),
    flex: 1,
  },
});

export default styles;
