import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import ImageRatio from '../ImageRatio';

import styles from './styles';

const Content = ({data = {}, handleShowImage}) => {
  return (
    <View>
      {!!data.content && <Text style={styles.textDetail}>{data.content}</Text>}
      {!!data?.photos?.[0] && (
        <TouchableOpacity onPress={handleShowImage(data?.photos)}>
          <ImageRatio
            imageWidth={311}
            source={{
              uri: data?.photos?.[0],
            }}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default Content;
