import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  Image,
  Platform,
  TouchableOpacity,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import {useAlert, useTranslations} from 'src/hooks';
import styles from './styles';
import {Button, Loading, ModalFailure, ModalSuccess} from 'src/components';
import images from 'src/assets/images';
import {useIsFocused, useNavigation, useRoute} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import {formatPrice, getUserPhoto, isWeChat} from 'src/utils/common';
import {useGetMarketActivity} from 'src/api/activities';
import {colors} from 'src/constants';
import ModalAddUser from './components/ModalAddUser';
import {textFont} from 'src/utils/styles';
import {isEmpty, map} from 'lodash';
import {API_URL} from 'src/utils/axiosConfig';
import {useTrackEvent} from 'src/api/event';
import {removeInfluencerPromotion} from 'src/store/slices/auth/authSlice';

const SPLIT_USER = '+';
const SPLIT_LIST = '_';

const Participant = ({people, onPress}) => {
  return (
    <View style={styles.userRow}>
      <TouchableOpacity style={styles.buttonMinus} onPress={onPress}>
        <Text style={styles.textMinus}>-</Text>
      </TouchableOpacity>
      <Text style={styles.userName} numberOfLines={1}>
        {people?.name}
      </Text>
      <Text style={textFont(16, '100')}>{people?.phone}</Text>
    </View>
  );
};

const OrderConfirm = () => {
  const navigation = useNavigation();
  const {i18n} = useTranslations();
  const {alert} = useAlert();
  const route = useRoute();
  const activityId = route.params?.activityId;
  const paymentStatus = route.params?.paymentStatus;
  const participants = route.params?.participants;
  const dispatch = useDispatch();

  const userData = useSelector(state => state.user.data);
  const influencerPromotion = useSelector(
    state => state.auth.influencerPromotion,
  );

  const token = useSelector(state => state.auth.token);
  const [showAddUser, setShowAddUser] = useState(false);
  const [participant, setParticipant] = useState(
    participants
      ? decodeURI(participants)
          .split(SPLIT_LIST)
          .map(i => {
            const [name, phone] = i.split(SPLIT_USER);
            return {name, phone};
          })
      : [],
  );

  const isFocused = useIsFocused();

  const [showSuccessPayment, setShowSuccessPayment] = useState(
    paymentStatus === 'pass',
  );
  const [pendingPayment, setPendingPayment] = useState(
    paymentStatus === 'pass',
  );
  const [showFailurePayment, setShowFailurePayment] = useState(
    paymentStatus === 'fail',
  );

  const handleGoBack = () => {
    setParticipant([]);
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      dispatch(removeInfluencerPromotion());
      navigation.navigate(routeNames.BottomTab);
    }
  };

  const {
    runRequest: getMarketActivity,
    isLoading: isLoadingGetDetail,
    responseData,
  } = useGetMarketActivity({
    successCallback: () => {
      setTimeout(() => {
        setPendingPayment(false);
      }, 3000);
    },
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'), handleGoBack);
    },
  });

  const {runRequest: trackEvent} = useTrackEvent({});

  const marketActivityData = responseData?.data;

  const handlePayment = () => {
    if (Platform.OS === 'web' && isWeChat()) {
      const params = new URLSearchParams({
        data: JSON.stringify({
          type: 'trip',
          bazaarId: activityId,
          price:
            (1 + participant.length) * (marketActivityData?.prices?.price || 1),
          processor: ['wechatpay', 'jsapi'],
          ...(!isEmpty(participant) && {
            person: map(participant, i => `${i.name}/${i.phone}`),
          }),
        }),
        redirects: JSON.stringify({
          success: `${API_URL}/order/${activityId}/pass`,
          error: `${API_URL}/order/${activityId}/fail/${encodeURI(
            map(participant, i => `${i.name}${SPLIT_USER}${i.phone}`).join(
              SPLIT_LIST,
            ),
          )}`,
        }),
        auth: token,
      });
      const payLink = '/api/payment/new-order?' + params;
      window.location.href = payLink;
    } else {
      alert(i18n.t('please_open_wechat_payment'));
    }
  };

  useEffect(() => {
    if (isFocused && activityId) {
      getMarketActivity(activityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  useEffect(() => {
    if (paymentStatus === 'pass' && influencerPromotion) {
      trackEvent({
        event_type: 'payment',
        event_name: 'bazaar-trip',
        influencer: influencerPromotion,
        order_id: marketActivityData?.order?.id,
        user_id: userData?.id,
        product_id: marketActivityData?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus]);

  const isLoading = isLoadingGetDetail;

  return (
    <>
      <SafeAreaView style={styles.container}>
        <ScrollView
          contentContainerStyle={styles.scrollView}
          showsVerticalScrollIndicator={false}>
          <TouchableOpacity style={styles.buttonClose} onPress={handleGoBack}>
            <Text style={styles.textClose}>+</Text>
          </TouchableOpacity>
          <Text style={styles.text32600}>{marketActivityData?.title}</Text>
          <Text style={[styles.text32600, styles.dotColor]}>{'...'}</Text>
          <View style={styles.invitationWrapper}>
            <View style={styles.invitation}>
              <View style={styles.flexRowAlign}>
                <Image
                  source={images.Calendar}
                  style={styles.icon20}
                  resizeMode="contain"
                />
                <Text style={[styles.text16400, styles.marginLeft8]}>
                  {dayjs(marketActivityData?.from_date).format('YYYY-MM-DD')}
                </Text>
                <Text style={[styles.text16400]}>{' ~ '}</Text>
                <Text style={[styles.text16400]}>
                  {dayjs(marketActivityData?.to_date).format('YYYY-MM-DD')}
                </Text>
              </View>
              <View style={[styles.flexRowAlign, styles.marginTop8]}>
                <Image
                  source={images.Map}
                  style={styles.icon20}
                  resizeMode="contain"
                />
                <Text style={[styles.text16400, styles.marginLeft8]}>
                  {marketActivityData?.location}
                </Text>
              </View>

              <View style={styles.border} />
              <View style={styles.accommodation}>
                <Text style={styles.text16600}>{i18n.t('introduction')}</Text>
              </View>
              <Text style={[styles.text16400, styles.marginTop8]}>
                {marketActivityData?.introduction}
              </Text>
              <View style={styles.border} />
              <View style={styles.userRow}>
                <Image
                  style={styles.avatar}
                  source={{uri: getUserPhoto(userData)}}
                />
                <Text style={styles.userName}>{userData?.name}</Text>
                <Text style={textFont(16, '100')}>{userData?.phone}</Text>
              </View>
              <View style={styles.userRow}>
                <Text style={styles.textPeople}>
                  {i18n.t('people_going_with_me')}
                </Text>
                <TouchableOpacity
                  style={styles.buttonAdd}
                  onPress={() => {
                    setShowAddUser(true);
                  }}>
                  <Text style={styles.textPlus}>+</Text>
                </TouchableOpacity>
              </View>
              {participant.map((people, index) => (
                <Participant
                  key={index}
                  onPress={() => {
                    const newPeople = [...participant];
                    newPeople.splice(index, 1);
                    setParticipant(newPeople);
                  }}
                  people={people}
                />
              ))}
              <View style={styles.border} />
              <View style={styles.accommodation}>
                <Text style={styles.text16400}>{i18n.t('total_price')}</Text>
                <View style={styles.money}>
                  <Text style={[textFont(16, '100'), {color: colors.primary}]}>
                    {`${1 + participant.length} × ¥${formatPrice(
                      marketActivityData?.prices?.price,
                    )} = `}
                    <Text style={styles.text16700}>{`¥${formatPrice(
                      (1 + participant.length) *
                        marketActivityData?.prices?.price,
                    )}`}</Text>
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <View style={styles.bottomButton}>
          <Button title={i18n.t('buy_now')} onClick={handlePayment} />
        </View>
        <ModalSuccess
          title={marketActivityData?.title}
          show={showSuccessPayment}
          onClose={() => {
            setShowSuccessPayment(false);
            handleGoBack();
            navigation.navigate(routeNames.MainFlow.OrderDetail, {
              orderId: marketActivityData?.order?.id,
              isFromAppNavigate: true,
            });
          }}
          onNext={() => {
            setShowSuccessPayment(false);
            handleGoBack();
            navigation.navigate(routeNames.MainFlow.OrderDetail, {
              orderId: marketActivityData?.order?.id,
              isFromAppNavigate: true,
            });
          }}
          nextButtonTitle={'order_detail'}
          disabled={!marketActivityData?.order?.id}
          isLoading={isLoading || pendingPayment}
        />
        <ModalFailure
          title={marketActivityData?.title}
          show={showFailurePayment}
          onClose={() => {
            setShowFailurePayment(false);
          }}
          onNext={() => {
            setShowFailurePayment(false);
            handlePayment();
          }}
        />
        <ModalAddUser
          show={showAddUser}
          onClose={() => setShowAddUser(false)}
          onNext={data => {
            const newData = {
              name: data.name.replace(/[+_]/gi, ''),
              phone: data.phone.replace(/[+_]/gi, ''),
            };
            setParticipant(people => [...people, newData]);
          }}
        />
      </SafeAreaView>
      {isLoading && <Loading />}
    </>
  );
};

export default OrderConfirm;
