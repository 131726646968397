import {View, Text, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import styles from './styles';
import {useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import {getUserPhoto} from 'src/utils/common';

const StoryItem = ({item}) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        navigation.navigate(routeNames.NonAuthFlow.StoryDetail, {
          storyId: item.cuid,
        });
      }}>
      <View style={styles.content}>
        <Image source={{uri: item.background}} style={styles.image} />
        <Text numberOfLines={2} style={styles.title}>
          {item.title}
        </Text>
      </View>
      <View style={styles.userWrapper}>
        {getUserPhoto(item.publisher) ? (
          <Image
            source={{uri: getUserPhoto(item.publisher)}}
            style={styles.avatar}
          />
        ) : (
          <View style={styles.avatar} />
        )}
        <Text numberOfLines={2} style={styles.textName}>
          {item.publisher?.name}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default StoryItem;
