import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {replyCommentUrl} from '../../endpoints';

export const replyCommentRequest = createAsyncThunk(
  'story/replyComment',
  async (
    {successCallback, failureCallback, storyId, commentId, data},
    {rejectWithValue},
  ) => {
    try {
      const response = await axios.request({
        method: 'POST',
        url: replyCommentUrl(storyId, commentId),
        data,
      });
      successCallback?.(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback?.(err);
        return rejectWithValue(err.response.data);
      }
    }
  },
);
