import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.neutral9,
  },
  nameWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  code: {
    width: calculateWidth(27),
    height: calculateWidth(27),
  },
  name: {
    ...textFont(32, '600'),
    marginVertical: calculateWidth(32),
  },
  firstImage: {
    height: calculateWidth(311),
    width: calculateWidth(311),
    borderRadius: 4,
  },
  secondRow: {
    flexDirection: 'row',
    marginTop: calculateWidth(8),
    marginBottom: calculateWidth(32),
    justifyContent: 'space-between',
  },
  secondImages: {
    width: calculateWidth(98),
    height: calculateWidth(98),
    borderRadius: 4,
  },

  overview: {
    ...textFont(22, '600'),
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: calculateWidth(24),
  },
  geo: {
    ...textFont(18, '600'),
  },
  location: {
    width: calculateWidth(20),
    height: calculateWidth(20),
    marginRight: calculateWidth(12),
  },
  map: {
    height: 198,
    width: '100%',
  },
  season: {
    ...textFont(22, '600'),
    marginVertical: calculateWidth(26),
  },
  rec: {
    width: 12,
    height: 20,
    marginRight: 12,
  },
  scroll: {
    marginHorizontal: calculateWidth(32),
    paddingBottom: calculateWidth(32),
  },
  monthCard: {
    marginTop: calculateWidth(32),
    backgroundColor: colors.neutral8,
    padding: calculateWidth(20),
    paddingBottom: calculateWidth(4),
    borderRadius: 8,
  },
  title: {
    ...textFont(16, '600'),
  },
  content: {
    ...textFont(16, '400'),
    marginBottom: calculateWidth(16),
  },
  introduction: {
    ...textFont(18, '400'),
    color: colors.neutral4,
    marginTop: calculateWidth(16),
  },
  textSeeMore: {
    ...textFont(14, '600'),
    color: colors.neutral1,
  },
  seeMore: {
    width: calculateWidth(90),
    height: calculateWidth(32),
    borderRadius: calculateWidth(20),
    borderWidth: 1,
    borderColor: colors.neutral6,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: calculateWidth(16),
    marginBottom: calculateWidth(32),
    alignSelf: 'center',
  },

  divider: {
    height: calculateWidth(4),
    backgroundColor: colors.neutral7,
    marginHorizontal: calculateWidth(-32),
  },

  sections: {
    paddingVertical: calculateWidth(32),
  },

  storyWrapper: {
    marginHorizontal: calculateWidth(-32),
    marginTop: calculateWidth(20),
  },

  storyContainer: {
    marginLeft: calculateWidth(32),
  },

  story: {
    width: calculateWidth(140),
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.neutral6,
    marginRight: calculateWidth(16),
    overflow: 'hidden',
    backgroundColor: colors.neutral9,
  },
  imageStory: {
    width: calculateWidth(140),
    height: calculateWidth(135),
    marginBottom: calculateWidth(16),
  },
  des: {
    marginHorizontal: calculateWidth(16),
    ...textFont(16, '400'),
    flex: 1,
  },
  rowStory: {
    alignItems: 'center',
    flexDirection: 'row',
    margin: calculateWidth(16),
    marginTop: 0,
  },
  nameStory: {
    marginLeft: 8,
    ...textFont(14, '600'),
    color: colors.neutral4,
  },
  avatar: {
    width: calculateWidth(24),
    height: calculateWidth(24),
    borderRadius: 100,
    backgroundColor: colors.neutral6,
  },

  activity: {
    width: calculateWidth(140),
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.neutral6,
    marginRight: calculateWidth(16),
    overflow: 'hidden',
    backgroundColor: colors.neutral9,
  },
  activityImage: {
    width: calculateWidth(139),
    height: calculateWidth(139),
    backgroundColor: colors.neutral6,
  },
  activityName: {
    margin: calculateWidth(12),
    ...textFont(16, '400'),
  },
});
