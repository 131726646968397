import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {updateStoryUrl} from '../../endpoints';

export const updateStoryRequest = createAsyncThunk(
  'story/updateStory',
  async (
    {successCallback, failureCallback, data, storyId},
    {rejectWithValue},
  ) => {
    try {
      const response = await axios.request({
        method: 'PATCH',
        url: updateStoryUrl(storyId),
        data,
      });
      successCallback?.(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback?.(err);
        return rejectWithValue(err.response.data);
      }
    }
  },
);
