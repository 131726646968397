const routeNames = {
  BottomTab: 'BottomTab',
  MainFlow: {
    MainHome: 'MainHome',
    ChatScreen: 'ChatScreen',
    MyTrip: 'MyTrip',
    SearchScreen: 'SearchScreen',
    AddStory: 'AddStory',
    MainProfile: 'MainProfile',
    FindFriend: 'FindFriend',
    MainAdmin: 'MainAdmin',
    Notification: 'Notification',
    EditProfile: 'EditProfile',
    OrderConfirm: 'OrderConfirm',
    ShareActivity: 'ShareActivity',
    OrderDetail: 'OrderDetail',
  },
  AuthFlow: {
    WelcomeScreen: 'WelcomeScreen',
    VerifyPhone: 'VerifyPhone',
    EditProfile: 'EditProfile',
    // MyWorkAndIndustryScreen: 'MyWorkAndIndustryScreen',
    // MyCollegeScreen: 'MyCollegeScreen',
    // MyLifestyleScreen: 'MyLifestyleScreen',
    // MyWishScreen: 'MyWishScreen',
    // MyProfilePhoto: 'MyProfilePhoto',
    // PeopleLifestyle: 'PeopleLifestyle',
    // PeopleWish: 'PeopleWish',
    // Bio: 'Bio',
    // RecommendFriend: 'RecommendFriend',
  },
  NonAuthFlow: {
    TripDetail: 'TripDetail',
    StoryDetail: 'StoryDetail',
    VillageDetail: 'VillageDetail',
    UserDetail: 'UserDetail',
    FollowStatus: 'FollowStatus',
    MarketActivity: 'MarketActivity',
    PlanDetail: 'PlanDetail',
    InvitationDetail: 'InvitationDetail',
    ChooseCity: 'ChooseCity',
  },
};

export default routeNames;
