import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.neutral8,
  },
  wrapper: {
    // paddingVertical: calculateWidth(32),
  },
  divider: {
    marginTop: calculateWidth(24),
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderTopWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: colors.neutral9,
    backgroundColor: colors.neutral8,
    height: 40,
  },
  select: {
    marginHorizontal: calculateWidth(32),
  },
  scroll: {
    marginHorizontal: calculateWidth(32),
  },
  addBuutton: {
    width: 48,
    height: 48,
  },
  addWrapper: {
    position: 'absolute',
    bottom: 32,
    right: 32,
  },
});
