import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  flex1: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: calculateWidth(375),
    backgroundColor: 'rgba(18, 24, 16, 0.4)',
  },
  content: {
    zIndex: 100,
    position: 'absolute',
    bottom: 0,
    height: calculateWidth(320),
    width: calculateWidth(375),
    backgroundColor: colors.neutral9,
    padding: calculateWidth(24),
    alignSelf: 'center',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  label: {
    textAlign: 'center',
    ...textFont(16, '400'),
  },
  button: {
    alignSelf: 'center',
    width: calculateWidth(80),
    height: calculateWidth(35),
    marginTop: calculateWidth(16),
  },
});
