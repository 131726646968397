import {View, Text, TouchableOpacity, Image} from 'react-native';
import React, {useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import styles from './styles';
import images from 'src/assets/images';
import {useGetUserStory} from 'src/api/users';

const Swiper = ({
  userList,
  renderCard,
  handleSwipedLeft,
  handleSwipedRight,
  onSwiped,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const {runRequest: getUserStory} = useGetUserStory({});

  useEffect(() => {
    getUserStory(userList[currentIndex]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList, currentIndex]);

  if (isEmpty(userList) || currentIndex >= userList.length) {
    return null;
  }
  const onSwipeLeft = () => {
    onSwiped?.(currentIndex);
    handleSwipedLeft(currentIndex);
    setCurrentIndex(index => index + 1);
  };
  const onSwipeRight = () => {
    onSwiped?.(currentIndex);
    handleSwipedRight(currentIndex);
    setCurrentIndex(index => index + 1);
  };
  return (
    <View style={styles.container}>
      {renderCard(userList[currentIndex])}
      <TouchableOpacity style={styles.buttonClose} onPress={onSwipeLeft}>
        <Text style={styles.textClose}>+</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.buttonChoose} onPress={onSwipeRight}>
        <Image source={images.Tick} style={styles.chooseIcon} />
      </TouchableOpacity>
    </View>
  );
};

export default Swiper;
