import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getInvitationDetail} from '../requests';

export const useGetInvitationDetail = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(state => state.invitation.isLoading);
  const [responseData, setResponseData] = useState(null);
  const dispatch = useDispatch();

  const runRequest = useCallback(
    invitationId => {
      dispatch(
        getInvitationDetail({
          invitationId,
          failureCallback: err => {
            failureCallback?.(err);
          },
          successCallback: data => {
            setResponseData(data);
            successCallback?.(data);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  return {
    isLoading,
    runRequest,
    responseData,
  };
};
