import {View, Text, Modal, TouchableOpacity} from 'react-native';
import React from 'react';
import styles from './styles';
import {Button, Checkbox} from 'src/components';
import {useTranslations} from 'src/hooks';
import {useState} from 'react';
import {colors} from 'src/constants';
import {textFont} from 'src/utils/styles';
import {get, max, min} from 'lodash';
import {getTripMemberCount} from 'src/utils/common';

const ModalJoinTrip = ({
  show,
  onClose,
  onNext,
  data,
  nextButtonTitle,
  handleAgreement,
  accompanyPeople,
  setAccompanyPeople,
}) => {
  const {i18n} = useTranslations();
  const [checkedAgreement, setCheckedAgreement] = useState('unchecked');
  const maximumPerson =
    get(data, `prices.details[${data?.trip_tier || 0}].personsCount`, 0) -
    getTripMemberCount(data?.members);
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={show}
      onRequestClose={onClose}>
      <View style={styles.container}>
        <View style={styles.popup}>
          <Text style={styles.headerAlign}>{i18n.t('join_event')}</Text>
          <Text style={styles.textDetail}>{data?.heading}</Text>
          {maximumPerson > 1 && (
            <View>
              <View style={styles.publicRadio}>
                <View style={styles.people}>
                  <Text style={[textFont(15, '900'), {color: colors.neutral1}]}>
                    {i18n.t('pay_for_your_friend')}
                  </Text>
                  <Text style={[textFont(12, '900'), {color: colors.neutral1}]}>
                    {i18n.t('share_to_invite')}
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    setAccompanyPeople(value => max([0, value - 1]));
                  }}
                  disabled={accompanyPeople === 0}
                  style={[
                    styles.buttonCount,
                    accompanyPeople === 0 && {borderColor: colors.neutral6},
                  ]}>
                  <Text
                    style={[
                      accompanyPeople === 0 && {color: colors.neutral6},
                      styles.plusText,
                    ]}>
                    −
                  </Text>
                </TouchableOpacity>
                <Text style={styles.accompany}>{accompanyPeople}</Text>
                <TouchableOpacity
                  onPress={() => {
                    setAccompanyPeople(value =>
                      min([value + 1, maximumPerson - 1]),
                    );
                  }}
                  disabled={maximumPerson - 1 === accompanyPeople}
                  style={[
                    styles.buttonCount,
                    maximumPerson - 1 === accompanyPeople && {
                      borderColor: colors.neutral6,
                    },
                  ]}>
                  <Text
                    style={[
                      accompanyPeople === maximumPerson - 1 && {
                        color: colors.neutral6,
                      },
                      styles.plusText,
                    ]}>
                    +
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
          <View style={styles.confirm}>
            <Checkbox
              status={checkedAgreement}
              onPress={() => {
                setCheckedAgreement(
                  checkedAgreement === 'checked' ? 'unchecked' : 'checked',
                );
              }}
              color={colors.primary}
              uncheckedColor={colors.neutral7}
            />
            <Text style={styles.agreement}>
              {`${i18n.t('agree_with')}`}
              <Text
                style={[textFont(14, '900'), {color: colors.neutral1}]}
                onPress={handleAgreement}>
                {`《${i18n.t('payment_agreement')}》`}
              </Text>
            </Text>
          </View>
          <Button
            title={nextButtonTitle}
            buttonStyle={styles.bottomButton}
            onClick={onNext}
            disabled={checkedAgreement === 'unchecked'}
          />
          <TouchableOpacity style={styles.buttonClose} onPress={onClose}>
            <Text style={styles.textClose}>+</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ModalJoinTrip;
