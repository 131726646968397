import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  imagePicker: {
    width: '100%',
    height: 448,
    backgroundColor: colors.neutral8,
    marginBottom: calculateWidth(32),
  },
  upload: {
    ...textFont(14, '400'),
    color: colors.neutral4,
  },
  thumbIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  thumbGallery: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  thumbnailStyle: {
    width: null,
    paddingHorizontal: 16,
    marginVertical: 8,
    borderRadius: 48,
  },
  name: {
    ...textFont(32, '600'),
  },
  wrapper: {
    marginHorizontal: calculateWidth(24),
  },
  bottom: {
    marginBottom: calculateWidth(14),
  },
  location: {
    ...textFont(16, '400'),
  },
  intro: {
    ...textFont(18, '600'),
  },
  scroll: {
    marginBottom: calculateWidth(32),
  },
  heading: {
    ...textFont(32, '600'),
  },
  headingView: {
    marginHorizontal: calculateWidth(16),
    marginVertical: calculateWidth(32),
    paddingHorizontal: calculateWidth(16),
  },
  locationEditImage: {
    tintColor: colors.primary,
    width: 20,
    height: 20,
    marginRight: 8,
  },
  locationContainer: {
    flexDirection: 'row',
    marginVertical: calculateWidth(32),
    marginHorizontal: calculateWidth(16),
  },
  locationInput: {
    ...textFont(16, '400'),
    color: colors.primary,
  },
  introductionText: {
    ...textFont(18, '400'),
  },
  vContainer: {
    marginVertical: 32,
    marginHorizontal: 16,
  },
  vLabel: {
    ...textFont(16, '600'),
    marginBottom: 12,
  },
  vInput: {
    ...textFont(16, '400'),
  },
  vInputContainer: {
    padding: 8,
  },
  priceContainer: {
    marginVertical: calculateWidth(32),
    marginHorizontal: calculateWidth(16),
  },
  personRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: calculateWidth(16),
  },
  personColumn: {
    width: '49%',
  },
  personLabel: {
    ...textFont(14, '400'),
    color: colors.neutral4,
    marginBottom: calculateWidth(8),
  },
  priceInput: {
    ...textFont(16, '400'),
    paddingVertical: calculateWidth(12),
    paddingHorizontal: calculateWidth(20),
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.neutral6,
    color: colors.neutral1,
  },
  divider: {
    height: 1,
    width: '100%',
    borderRadius: 1,
    borderWidth: 1,
    borderStyle: 'dotted',
    borderColor: colors.neutral7,
    marginBottom: 20,
  },
  priceColumn: {
    width: '74%',
  },
  noPersonColumn: {
    width: '25%',
  },
  priceRow: {
    flexDirection: 'row',
    paddingVertical: calculateWidth(3),
    paddingLeft: calculateWidth(20),
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.neutral6,
  },
  priceUnit: {
    borderRightWidth: 1,
    marginVertical: calculateWidth(10),
    flex: 1,
    color: colors.neutral1,
  },
  select: {
    width: 140,
  },
  currency: {
    width: 20,
    height: 20,
    tintColor: colors.black,
  },
  addMoreWrapper: {
    borderWidth: 1,
    borderColor: colors.neutral6,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 16,
  },
  addMore: {
    ...textFont(14, '600'),
  },
  editContainer: {
    marginVertical: 32,
    marginHorizontal: 16,
  },
  prepareViewStyle: {
    paddingVertical: 8,
    paddingHorizontal: 8,
    marginBottom: 12,
  },
  prepareTexstyle: {
    ...textFont(16, '400'),
  },
});
