import {View, Text, SafeAreaView, Image} from 'react-native';
import React from 'react';
import styles from './styles';
import {HomeHeader, Loading} from 'src/components';
import {useAlert, useTranslations} from 'src/hooks';
import {useGetSharePoster} from 'src/api/activities/hooks/useGetSharePoster';
import {useNavigation, useRoute} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import {useEffect} from 'react';
import {useGetSharePosterTrip} from 'src/api/activities';
import {useCreateInvite} from 'src/api/invitation';
import {API_URL} from 'src/utils/axiosConfig';

const ShareActivity = () => {
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const {alert} = useAlert();
  const route = useRoute();
  const data = route.params?.data;
  const tripId = route.params?.tripId;

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate(routeNames.BottomTab);
    }
  };

  const {
    runRequest: getSharePoster,
    isLoading: loadingGetPoster,
    responseData: poster,
  } = useGetSharePoster({
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'), handleGoBack);
    },
  });

  const {
    runRequest: getSharePosterTrip,
    isLoading: loadingGetPosterTrip,
    responseData: posterTrip,
  } = useGetSharePosterTrip({
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'), handleGoBack);
    },
  });

  const {runRequest: inviteUser} = useCreateInvite({
    successCallback: (_, response) => {
      const posterData = JSON.parse(data || '{}');
      getSharePosterTrip({
        ...posterData,
        url: `${API_URL}/invite/${response.data.id}`,
      });
    },
    failureCallback: user => {
      alert(i18n.t('something_went_wrong'), handleGoBack);
    },
  });

  useEffect(() => {
    if (tripId) {
      inviteUser({data: {tripId, userId: ''}, user: {}});
    } else {
      getSharePoster(JSON.parse(data || '{}'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  const response = tripId ? posterTrip : poster;

  return (
    <>
      <SafeAreaView style={styles.container}>
        <HomeHeader withBackButton withRightButton={false} />
        <View style={styles.content}>
          <Text style={styles.title}>{i18n.t('invite_friends_play')}</Text>
          <Image
            style={styles.poster}
            source={{uri: response?.data?.image || ''}}
          />
          <View style={styles.button}>
            <Text style={styles.hint}>{i18n.t('hint_download_poster')}</Text>
          </View>
          {/* <Button title={'test'} buttonStyle={styles.button} /> */}
        </View>
      </SafeAreaView>
      {(loadingGetPoster || loadingGetPosterTrip) && <Loading />}
    </>
  );
};

export default ShareActivity;
