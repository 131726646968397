import {
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Image,
  TextInput,
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform,
  Keyboard,
} from 'react-native';
import React, {useMemo} from 'react';
import Swiper from 'react-native-web-swiper';
import {useFormik} from 'formik';
import * as yup from 'yup';

import styles from './styles';
import images from 'src/assets/images';

import {useLogin, useRegister} from 'src/api/auth';
import {useNavigation, useRoute} from '@react-navigation/native';
import {useAlert, useTranslations} from 'src/hooks';
import colors from 'src/constants/colors';
import {
  getNextNavigateOnboarding,
  isFillUserPhoneData,
  isWeChat,
} from 'src/utils/common';
import {useEffect} from 'react';
import {useGetUserDetail} from 'src/api/users';
import {axiosSetAuthToken, IS_STAGING} from 'src/utils/axiosConfig';
import {useDispatch, useSelector} from 'react-redux';
import {registerLogin, storeToken} from 'src/store/slices/auth/authSlice';
import {Loading} from 'src/components';
const STATUS = {
  SIGN_UP: 1,
  SIGN_IN: 2,
};

const Welcome = () => {
  const {alert} = useAlert();
  const {i18n} = useTranslations();
  const route = useRoute();
  const token = route.params?.token;
  const userId = route.params?.userId;
  const loginStatus = route.params?.loginStatus;
  const influencerPromotion = useSelector(
    state => state.auth.influencerPromotion,
  );
  const dispatch = useDispatch();
  const {runRequest: getUserDetail, isLoading} = useGetUserDetail({
    successCallback: response => {
      setTimeout(() => {
        navigation.navigate(getNextNavigateOnboarding(response.data));
      }, 100);
      if (isFillUserPhoneData(response.data)) {
        dispatch(registerLogin());
      }
    },
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'));
    },
  });
  const {runRequest: signUp, isLoading: registerLoading} = useRegister({
    successCallback: response => {
      navigation.navigate(getNextNavigateOnboarding(response.data));
    },
    failureCallback: error => {
      alert(
        i18n.t(error?.response?.data?.message) ||
          i18n.t('something_went_wrong'),
      );
    },
  });
  const {runRequest: login, isLoading: loginLoading} = useLogin({
    failureCallback: error => {
      alert(
        i18n.t(error?.response?.data?.message) ||
          i18n.t('something_went_wrong'),
      );
    },
    successCallback: response => {
      navigation.navigate(getNextNavigateOnboarding(response.data));
    },
  });

  useEffect(() => {
    switch (loginStatus) {
      case 'success':
        if (
          token &&
          token !== 'undefined' &&
          userId &&
          userId !== 'undefined'
        ) {
          axiosSetAuthToken(token);
          dispatch(storeToken({token}));
          getUserDetail(userId);
        }
        break;
      case 'failure':
        alert('something_went_wrong');
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userId, loginStatus]);

  const navigation = useNavigation();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setTouched,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      openInputStatus: 0,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(i18n.t('profile.welcome.invalid_email'))
        .required(i18n.t('profile.welcome.required')),
      password: yup.string().required(i18n.t('profile.welcome.required')),
      confirmPassword: yup.string().when('openInputStatus', {
        is: STATUS.SIGN_UP,
        then: schema =>
          schema
            .required(i18n.t('profile.welcome.required'))
            .oneOf(
              [yup.ref('password'), null],
              i18n.t('profile.welcome.pass_match'),
            ),
      }),
    }),
    onSubmit: () => {
      if (values.openInputStatus === STATUS.SIGN_UP) {
        signUp({
          email: values.email,
          password: values.password,
          username: values.email,
          influencer: influencerPromotion,
        });
      }
      if (values.openInputStatus === STATUS.SIGN_IN) {
        login({
          email: values.email,
          password: values.password,
          username: values.email,
        });
      }
    },
  });

  const handleSignIn = () => {
    if (values.openInputStatus === STATUS.SIGN_IN) {
      handleSubmit();
    } else {
      setFieldValue('openInputStatus', STATUS.SIGN_IN);
      setTouched({});
    }
  };
  const handleSignUp = () => {
    if (values.openInputStatus === STATUS.SIGN_UP) {
      handleSubmit();
    } else {
      setFieldValue('openInputStatus', STATUS.SIGN_UP);
      setTouched({});
    }
  };

  const isShowErrorField = React.useCallback(
    fieldName => {
      return errors?.[fieldName] && touched?.[fieldName];
    },
    [errors, touched],
  );

  const inputSection = useMemo(() => {
    if (!values.openInputStatus) {
      return null;
    }
    return (
      <View>
        <TextInput
          style={styles.input}
          onChangeText={handleChange('email')}
          onBlur={handleBlur('email')}
          value={values.email}
          placeholder={i18n.t('profile.welcome.email')}
          autoCapitalize={'none'}
          keyboardType="email-address"
          autoComplete="email"
          textContentType={'emailAddress'}
        />
        <Text style={styles.textError}>
          {isShowErrorField('email') ? errors.email : ''}
        </Text>
        <TextInput
          style={styles.input}
          onChangeText={handleChange('password')}
          onBlur={handleBlur('password')}
          value={values.password}
          placeholder={i18n.t('profile.welcome.password')}
          secureTextEntry={true}
          autoComplete={'password'}
          textContentType={
            values.openInputStatus === STATUS.SIGN_IN
              ? 'password'
              : 'newPassword'
          }
        />
        <Text style={styles.textError}>
          {isShowErrorField('password') ? errors.password : ''}
        </Text>
        {values.openInputStatus === STATUS.SIGN_UP && (
          <>
            <TextInput
              style={styles.input}
              onChangeText={handleChange('confirmPassword')}
              onBlur={handleBlur('confirmPassword')}
              value={values.confirmPassword}
              placeholder={i18n.t('profile.welcome.confirm_pass')}
              secureTextEntry={true}
              autoComplete={'password'}
              textContentType={'newPassword'}
            />
            <Text style={styles.textError}>
              {isShowErrorField('confirmPassword')
                ? errors.confirmPassword
                : ''}
            </Text>
          </>
        )}
      </View>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleBlur, handleChange, values, errors, isShowErrorField]);

  return (
    <KeyboardAvoidingView
      behavior={'padding'}
      enabled={Platform.OS === 'ios'}
      style={styles.container}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <ImageBackground
          source={images.WelcomeBackground}
          style={styles.imageBackground}
          resizeMode="cover">
          <View style={styles.flexContainer}>
            <Swiper
              timeout={3}
              loop
              controlsProps={{
                dotsPos: 'top-left',
                DotComponent: ({isActive}) => (
                  <View
                    style={
                      isActive ? styles.dotActiveStyle : styles.dotsNormalStyle
                    }
                  />
                ),
                dotsWrapperStyle: {marginTop: 40},
                prevPos: false,
                nextPos: false,
              }}>
              <View style={styles.slide1}>
                <Text style={styles.textSlide1Title}>
                  {i18n.t('profile.welcome.starting')}
                </Text>
                <Text style={styles.textSlide1Description}>
                  {i18n.t('profile.welcome.the_travel')}
                </Text>
                <Image source={images.Slider1} style={styles.imageSlide1} />
              </View>
              <View style={styles.slide2}>
                <View style={styles.slide2Wrapper}>
                  <Image source={images.Slider2} style={styles.imageSlide2} />
                  <View style={styles.flexContainer}>
                    <Text style={styles.textSlide2Title}>
                      {i18n.t('profile.welcome.explore')}
                    </Text>
                  </View>
                </View>
                <Text style={styles.textSlide2Description}>
                  {i18n.t('profile.welcome.explore_description')}
                </Text>
              </View>
              <View style={styles.slide3}>
                <View style={styles.slide2Wrapper}>
                  <Image source={images.Slider3} style={styles.imageSlide2} />
                  <View style={styles.flexContainer}>
                    <Text style={styles.textSlide2Title}>
                      {i18n.t('profile.welcome.travel')}
                    </Text>
                  </View>
                </View>
                <Text style={styles.textSlide2Description}>
                  {i18n.t('profile.welcome.travel_description')}
                </Text>
              </View>
            </Swiper>

            <View style={styles.buttonWrapperBorder}>
              <View style={styles.buttonWrapper}>
                {inputSection}
                {IS_STAGING && (
                  <>
                    <TouchableOpacity
                      style={styles.buttonSignIn}
                      onPress={handleSignIn}>
                      {loginLoading ? (
                        <ActivityIndicator color={colors.primary} />
                      ) : (
                        <Text style={styles.textSignIn}>
                          {i18n.t('profile.welcome.sign_in')}
                        </Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={styles.buttonSignUp}
                      onPress={handleSignUp}>
                      {registerLoading ? (
                        <ActivityIndicator color={'#fff'} />
                      ) : (
                        <Text style={styles.textSignUp}>
                          {i18n.t('profile.welcome.sign_up')}
                        </Text>
                      )}
                    </TouchableOpacity>
                  </>
                )}
                {Platform.OS === 'web' && isWeChat() && (
                  <TouchableOpacity
                    style={styles.buttonSignUp}
                    onPress={() => {
                      const params = new URLSearchParams({
                        redirects: JSON.stringify({
                          success: '/loginScreen/success',
                          error: '/loginScreen/failure',
                        }),
                      });
                      const loginLink = '/api/wechat/login?' + params;
                      window.location.href = loginLink;
                    }}>
                    {registerLoading ? (
                      <ActivityIndicator color={'#fff'} />
                    ) : (
                      <View style={styles.wechatButton}>
                        <Image style={styles.wechat} source={images.Wechat} />
                        <Text style={styles.textSignUp}>
                          {i18n.t('wechat')}
                        </Text>
                      </View>
                    )}
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
        </ImageBackground>
      </TouchableWithoutFeedback>
      {isLoading && <Loading />}
    </KeyboardAvoidingView>
  );
};

export default Welcome;
