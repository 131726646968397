import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {opacityColor, textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.neutral9,
    flex: 1,
  },
  flex1: {
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: calculateWidth(30),
    paddingBottom: calculateWidth(90),
  },
  invitationWrapper: {
    borderWidth: 1,
    borderColor: colors.neutral7,
    borderRadius: calculateWidth(8),
    marginBottom: calculateWidth(16),
  },
  invitation: {
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(8),
    padding: calculateWidth(16),
    shadowColor: colors.neutral1,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },

  userWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colors.neutral6,
  },
  text16600: {
    ...textFont(16, '600'),
    color: colors.neutral1,
    lineHeight: calculateWidth(22),
  },

  text32600: {
    ...textFont(32, '600'),
    color: colors.neutral1,
    textAlign: 'center',
  },

  text16700: {
    ...textFont(16, '700'),
    color: colors.primary,
  },

  marginLeft8: {
    marginLeft: calculateWidth(8),
  },

  border: {
    borderWidth: 0.5,
    borderStyle: 'dashed',
    marginVertical: calculateWidth(14),
    borderColor: colors.neutral7,
  },
  text16400: {
    ...textFont(16, '400'),
    color: colors.neutral1,
    lineHeight: calculateWidth(22),
  },
  icon20: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
  textInvite: {
    ...textFont(16, '600'),
    color: colors.neutral1,
    lineHeight: calculateWidth(22),
  },

  flexRow: {
    flexDirection: 'row',
  },

  flexRowAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  marginTop8: {
    marginTop: calculateWidth(8),
  },

  accommodation: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accommodationDot: {
    width: calculateWidth(8),
    height: calculateWidth(8),
    borderRadius: 20,
    borderWidth: 1,
    borderColor: colors.neutral4,
  },

  textShowMore: {
    ...textFont(13, '600'),
    color: colors.neutral5,
    lineHeight: calculateWidth(16),
    textTransform: 'uppercase',
    textDecorationLine: 'underline',
  },

  bottomButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    paddingHorizontal: calculateWidth(30),
    paddingVertical: calculateWidth(15),
    backgroundColor: colors.neutral9,
  },

  money: {
    height: calculateWidth(40),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: calculateWidth(8),
    backgroundColor: opacityColor(colors.primary, 0.3),
    paddingHorizontal: calculateWidth(10),
  },

  commentWrapper: {
    padding: calculateWidth(16),
    paddingTop: 0,
  },

  buttonComment: {
    paddingVertical: calculateWidth(12),
    paddingHorizontal: calculateWidth(24),
    backgroundColor: colors.neutral8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: calculateWidth(8),
  },

  buttonClose: {
    width: calculateWidth(36),
    height: calculateWidth(36),
    backgroundColor: colors.neutral8,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: calculateWidth(25),
    marginBottom: calculateWidth(16),
  },
  textClose: {
    fontSize: calculateWidth(25),
    marginBottom: calculateWidth(2),
    marginLeft: calculateWidth(3),
    color: colors.neutral1,
    transform: [{rotate: '45deg'}],
  },

  dotColor: {
    color: colors.secondary1,
    marginBottom: calculateWidth(16),
  },
  activityDot: {
    width: calculateWidth(8),
    height: calculateWidth(8),
    borderRadius: 10,
    borderWidth: 1,
    marginTop: calculateWidth(8),
  },

  inviteWrapper: {
    alignItems: 'center',
  },

  userRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: calculateWidth(16),
  },
  userName: {
    ...textFont(16, '900'),
    marginLeft: calculateWidth(10),
    flex: 1,
  },

  textPeople: {
    ...textFont(16, '900'),
    flex: 1,
  },
  buttonAdd: {
    width: calculateWidth(36),
    height: calculateWidth(36),
    backgroundColor: colors.neutral8,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textPlus: {
    fontSize: calculateWidth(25),
    marginBottom: calculateWidth(3),
    color: colors.neutral1,
  },

  buttonMinus: {
    width: calculateWidth(20),
    height: calculateWidth(20),
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.error,
  },
  textMinus: {
    fontSize: calculateWidth(30),
    color: colors.neutral9,
    marginBottom: calculateWidth(5),
  },
});

export default styles;
