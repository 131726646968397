import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: colors.neutral9,
  },
  textHeaderSelected: {
    ...textFont(18, '600'),
    textTransform: 'capitalize',
    color: colors.neutral1,
    borderBottomColor: colors.primary,
    borderBottomWidth: 1,
  },
  textHeader: {
    ...textFont(18, '600'),
    textTransform: 'capitalize',
    color: colors.neutral5,
  },
  tabWrapper: {
    flexDirection: 'row',
    width: calculateWidth(343),
    alignSelf: 'center',
    backgroundColor: colors.neutral8,
    borderRadius: calculateWidth(4),
    padding: calculateWidth(16),
    margin: calculateWidth(16),
  },
  tabItem: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  borderRight: {
    borderRightWidth: 1,
    borderRightColor: colors.neutral6,
  },

  listUser: {
    backgroundColor: colors.neutral8,
    paddingTop: calculateWidth(32),
  },
  // user
  user: {
    width: calculateWidth(343),
    alignSelf: 'center',
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(12),
    padding: calculateWidth(24),
    marginBottom: calculateWidth(16),
  },
  username: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textName: {
    ...textFont(16, '600'),
    color: colors.neutral1,
    marginLeft: calculateWidth(12),
    flex: 1,
  },

  avatar: {
    width: calculateWidth(32),
    height: calculateWidth(32),
    borderRadius: 100,
    backgroundColor: colors.neutral8,
  },
  pillWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: calculateWidth(28),
    marginBottom: calculateWidth(-10),
  },
  morePill: {
    borderWidth: 1,
    borderColor: colors.neutral7,
    borderRadius: 38,
    paddingVertical: calculateWidth(6),
    paddingHorizontal: calculateWidth(12),
    marginRight: calculateWidth(6),
    marginBottom: calculateWidth(10),
    justifyContent: 'center',
    alignItems: 'center',
  },
  textMore: {
    ...textFont(14, '400'),
    color: colors.neutral4,
  },
});

export default styles;
