import {StyleSheet} from 'react-native';

import {opacityColor, textFont} from 'src/utils/styles';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';

export default StyleSheet.create({
  modal: {
    backgroundColor: 'rgba(18, 24, 16, 0.4)',
    flex: 1,
    width: calculateWidth(375),
    alignSelf: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: colors.neutral9,
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
  },
  closeButton: {
    backgroundColor: colors.neutral9,
    borderRadius: 18,
    width: 36,
    height: 36,
    top: -18,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginRight: 16,
  },
  closeIcon: {
    width: 12,
    height: 12,
  },
  closeContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  titleWrapper: {
    alignItems: 'center',
    marginBottom: calculateWidth(32),
  },
  title: {
    ...textFont(22, '600'),
  },

  row: {
    flexDirection: 'row',
    marginHorizontal: calculateWidth(32),
    marginBottom: calculateWidth(9),
  },
  location: {
    width: 20,
    height: 20,
  },
  locationText: {
    ...textFont(16, '600'),
    marginLeft: 8,
  },
  titlePreview: {
    ...textFont(32, '600'),
    marginHorizontal: calculateWidth(32),
    marginVertical: calculateWidth(12),
  },
  publish: {
    ...textFont(16, '400'),
    color: colors.neutral4,
    marginRight: 8,
  },
  author: {
    ...textFont(16, '400'),
    marginLeft: 8,
  },
  contentWrapper: {
    marginHorizontal: calculateWidth(32),
    marginVertical: calculateWidth(32),
  },
  adWapper: {
    borderStyle: 'dashed',
    borderColor: colors.orange,
    borderWidth: 1,
    borderRadius: 80,
    marginHorizontal: calculateWidth(32),
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
  },
  award: {
    width: 20,
    height: 20,
    marginBottom: 6,
  },
  ad: {
    ...textFont(14, '400'),
    color: colors.neutral4,
  },
  add: {
    marginHorizontal: calculateWidth(32),
    marginVertical: calculateWidth(32),
  },

  avatar: {
    width: calculateWidth(24),
    height: calculateWidth(24),
    borderRadius: 50,
  },
  sectionText: {
    ...textFont(18, '400'),
    color: colors.neutral4,
    marginBottom: calculateWidth(16),
  },
  sectionImage: {
    marginBottom: calculateWidth(16),
    borderRadius: calculateWidth(6),
    width: calculateWidth(311),
    height: calculateWidth(311),
  },
  backgroundImage: {
    width: calculateWidth(375),
    height: calculateWidth(375),
  },
  imageWrapper: {
    marginBottom: calculateWidth(16),
    borderRadius: calculateWidth(6),
  },
  more: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: calculateWidth(6),
    backgroundColor: opacityColor(colors.neutral8, 0.8),
  },
  moreText: {
    ...textFont(16, '600'),
    color: colors.neutral1,
  },
});
