import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  SafeAreaView,
  Image,
  ScrollView,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
} from 'react-native';

import {
  HomeHeader,
  ImageRatio,
  Loading,
  MapCard,
  ImageView,
  ShareCode,
  Menu,
} from 'src/components';

import images from 'src/assets/images';

import styles from './styles';
import {
  useCreateComment,
  useDislikeStory,
  useGetStoryComments,
  useGetStoryDetail,
  useLikeStory,
  useReplyComment,
  useDeleteStory,
} from 'src/api/story';
import {getUserPhoto} from 'src/utils/common';
import {
  StackActions,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {useAlert, useTranslations} from 'src/hooks';
import routeNames from 'src/navigation/routeNames';
import {useSelector} from 'react-redux';
import {get, isEmpty, map} from 'lodash';
import Comment from './Comment';
import {colors} from 'src/constants';
import {API_URL} from 'src/utils/axiosConfig';
import {useGetUserStory} from 'src/api/users';
import Swiper from 'react-native-web-swiper';
import {calculateWidth} from 'src/utils/dimensions';
import {useLayoutEffect} from 'react';
import {useMemo} from 'react';

const StoryDetail = () => {
  const route = useRoute();
  const {alert, confirm} = useAlert();
  const navigation = useNavigation();
  const {i18n} = useTranslations();
  const storyId = route.params?.storyId;
  const commentId = route.params?.commentId;
  const userData = useSelector(state => state.user.data);
  const [isStoryLiked, setIsStoryLiked] = useState(false);
  const [storyLikesCount, setStoryLikesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const ref = React.useRef(null);
  const inputRef = React.useRef(null);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const [showImageList, setShowImageList] = useState([]);
  const [commentContent, setCommentContent] = useState('');
  const [replyTo, setReplyTo] = useState({});
  const [visibleShare, setVisibleShare] = useState(false);
  const isFocused = useIsFocused();
  const {runRequest: getUserStory} = useGetUserStory({});

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.dispatch(
        StackActions.replace(
          isLoggedIn ? routeNames.BottomTab : routeNames.AuthFlow.WelcomeScreen,
        ),
      );
    }
  };

  const {runRequest: getStoryDetail, responseData} = useGetStoryDetail({
    failureCallback: () => {
      setIsLoading(false);
      alert(i18n.t('storyDetail.storyNotFound'), () => {
        handleGoBack();
      });
    },
    successCallback: response => {
      setIsLoading(false);
      setIsStoryLiked(
        (response?.data?.likes || []).find(i => i.id === userData.id),
      );
      setStoryLikesCount(response?.data?.likes?.length || 0);
    },
  });

  const {runRequest: getStoryComments, responseData: storyComments} =
    useGetStoryComments({});

  const {runRequest: createComment, isLoading: createCommentLoading} =
    useCreateComment({
      successCallback: () => {
        setCommentContent('');
        getStoryComments(storyId, false);
      },
    });

  const {runRequest: replyComment, isLoading: replyCommentLoading} =
    useReplyComment({
      successCallback: () => {
        setCommentContent('');
        getStoryComments(storyId, false);
      },
    });

  const storyDetail = useMemo(() => responseData?.data || {}, [responseData]);

  const [imageCurrentHeight, setImageCurrentHeight] = useState(375);

  const {runRequest: likeStory} = useLikeStory({
    successCallback: () => {
      getStoryDetail(storyId, false);
      getStoryComments(storyId, false);
      setIsStoryLiked(true);
      setStoryLikesCount(count => count + 1);
    },
  });
  const {runRequest: dislikeStory} = useDislikeStory({
    successCallback: () => {
      getStoryDetail(storyId, false);
      getStoryComments(storyId, false);
      setIsStoryLiked(false);
      setStoryLikesCount(count => count - 1);
    },
  });

  useEffect(() => {
    setIsLoading(true);
    getStoryDetail(storyId, true);
    getStoryComments(storyId, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId]);

  useLayoutEffect(() => {
    if (storyDetail.background) {
      const origin = 375;
      Image.getSize(storyDetail.background, (loadedWidth, loadedHeight) => {
        const newImageCurrentHeight =
          (origin * (loadedHeight || 0)) / (loadedWidth || 0);
        if (origin && newImageCurrentHeight > origin) {
          setImageCurrentHeight(origin);
        } else {
          setImageCurrentHeight(newImageCurrentHeight);
        }
      });
    }
  }, [storyDetail]);

  useEffect(() => {
    if (isFocused) {
      ref.current?.scrollTo?.({x: 0, y: 0, animated: false});
    }
  }, [isFocused]);

  const handleShowImage = imageList => () => {
    setShowImageList(imageList);
  };

  const renderContent = () => {
    return storyDetail?.sections?.map((section, index) => {
      return (
        <View key={index}>
          {isEmpty(section.photo) ||
          storyDetail?.sections?.length === 1 ? null : (
            <TouchableOpacity onPress={handleShowImage(section.photo)}>
              {map(section.photo, (image, imageIndex) => (
                <ImageRatio
                  style={styles.sectionImage}
                  source={{uri: image}}
                  imageWidth={311}
                  key={imageIndex}
                />
              ))}
            </TouchableOpacity>
          )}
          {!!section.content && (
            <Text style={styles.sectionText}>{section.content}</Text>
          )}
          {typeof section.photo === 'string' && (
            <TouchableOpacity onPress={handleShowImage([section.photo])}>
              <ImageRatio
                style={styles.sectionImage}
                source={{uri: section.photo}}
                imageWidth={311}
              />
            </TouchableOpacity>
          )}
        </View>
      );
    });
  };

  const handleLikeButton = () => {
    if (isStoryLiked) {
      dislikeStory(storyId);
    } else {
      likeStory(storyId);
    }
  };

  const handleGotoVillageDetail = () => {
    navigation.navigate(routeNames.NonAuthFlow.VillageDetail, {
      villageId: storyDetail.village.id,
    });
  };

  const handleCreateStoryComment = () => {
    if (commentContent) {
      if (isEmpty(replyTo)) {
        createComment(storyId, {content: commentContent});
      } else {
        replyComment(
          storyId,
          replyTo.reply_to ? replyTo.reply_to : replyTo.id,
          {content: commentContent},
        );
      }
    }
  };

  const handleReply = comment => {
    if (comment.reply_to) {
      setCommentContent(`@${comment.created_by.name} `);
    } else {
      setCommentContent('');
    }
    setReplyTo(comment);
    inputRef.current?.focus();
  };

  const handleScrollFocus = y => {
    ref?.current?.scrollTo({x: 0, y: y, animated: true});
  };

  const {runRequest: deleteStory} = useDeleteStory({
    successCallback: () => {
      handleGoBack();
      getUserStory(userData?.id);
    },
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'), () => {});
    },
  });

  const handleDelete = () => {
    deleteStory(storyId);
  };
  return (
    <>
      <SafeAreaView style={styles.container}>
        <HomeHeader withBackButton />
        <ScrollView showsVerticalScrollIndicator={false} ref={ref}>
          {storyDetail?.sections?.length > 1 && (
            <TouchableOpacity
              onPress={handleShowImage([storyDetail.background])}>
              <ImageRatio
                source={{uri: storyDetail.background}}
                style={styles.thumb}
                imageWidth={375}
              />
            </TouchableOpacity>
          )}
          {storyDetail?.sections?.length === 1 && (
            <Swiper
              timeout={0}
              loop={true}
              // eslint-disable-next-line react-native/no-inline-styles
              innerContainerStyle={{
                height: calculateWidth(imageCurrentHeight),
                position: 'relative',
              }}
              // eslint-disable-next-line react-native/no-inline-styles
              slideWrapperStyle={{justifyContent: 'center'}}
              controlsProps={{
                dotsPos: 'bottom',
                DotComponent: ({isActive}) => (
                  <View
                    style={
                      isActive ? styles.dotActiveStyle : styles.dotsNormalStyle
                    }
                  />
                ),
                dotsWrapperStyle: {marginBottom: calculateWidth(20)},
                prevPos: false,
                nextPos: false,
              }}>
              {[
                storyDetail.background,
                ...get(storyDetail, 'sections[0]?.photo', []),
              ].map((el, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={handleShowImage([
                    storyDetail.background,
                    ...get(storyDetail, 'sections[0]?.photo', []),
                  ])}>
                  <ImageRatio
                    source={{uri: el}}
                    style={styles.thumb}
                    imageWidth={375}
                  />
                </TouchableOpacity>
              ))}
            </Swiper>
          )}
          <View style={styles.nameWrapper}>
            <Text style={styles.title}>{storyDetail.title}</Text>
          </View>
          {storyDetail.publisher && (
            <TouchableOpacity
              style={styles.row}
              onPress={() => {
                navigation.navigate(routeNames.NonAuthFlow.UserDetail, {
                  userId: storyDetail.publisher.id,
                });
              }}>
              <Text style={styles.publish}>{i18n.t('publish_by')}</Text>
              <Image
                source={{uri: getUserPhoto(storyDetail.publisher)}}
                style={styles.avatar}
              />
              <Text style={styles.author}>{storyDetail.publisher?.name}</Text>
            </TouchableOpacity>
          )}
          {storyDetail.village_id && (
            <TouchableOpacity
              style={styles.row}
              onPress={handleGotoVillageDetail}>
              <Image
                source={images.Location}
                style={styles.location}
                resizeMode="cover"
              />
              <Text style={styles.locationText}>
                {storyDetail.village?.name}
              </Text>
            </TouchableOpacity>
          )}

          <View style={styles.content}>
            {renderContent()}
            {storyDetail.story_type !== 'accommodation' && (
              <>
                <View style={styles.divider} />
                <View style={styles.interactive}>
                  <TouchableOpacity
                    onPress={() => {
                      setVisibleShare(true);
                    }}>
                    <Image source={images.Code} style={styles.code} />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.flexRow}
                    onPress={handleLikeButton}>
                    <Image
                      style={styles.icon}
                      source={isStoryLiked ? images.LikeGreen : images.Like}
                    />
                    <Text
                      style={[styles.text, isStoryLiked && styles.textLiked]}>
                      {`${storyLikesCount} ${i18n.t('like')}`}
                    </Text>
                  </TouchableOpacity>
                  {storyDetail?.publisher?.id === userData?.id && (
                    <Menu
                      anchor={
                        <View style={styles.flexRow}>
                          <Image style={styles.icon} source={images.More} />
                        </View>
                      }
                      items={[
                        {
                          title: i18n.t('delete'),
                          onPress: () => {
                            confirm(
                              i18n.t('are_you_sure'),
                              () => {
                                handleDelete();
                              },
                              () => {},
                            );
                          },
                          titleStyle: styles.menuItem,
                        },
                      ]}
                    />
                  )}
                </View>
              </>
            )}
          </View>
          {storyDetail.village_id && (
            <>
              <View style={styles.bigDivider} />
              <View style={styles.detailsWrapper}>
                <Text style={styles.details}>{i18n.t('village_detail')}</Text>
                <MapCard
                  villageData={storyDetail.village}
                  onPress={handleGotoVillageDetail}
                />
              </View>
            </>
          )}
          {storyDetail.story_type !== 'accommodation' && (
            <>
              <View style={styles.bigDivider} />
              <View style={styles.detailsWrapper}>
                {isLoggedIn && (
                  <>
                    {!isEmpty(replyTo) && (
                      <Text style={styles.replyText}>
                        {i18n.t('reply_to')}
                        <Text style={styles.textReplyName}>
                          {` ${replyTo?.created_by?.name} -`}
                        </Text>
                        <Text
                          style={styles.textCancel}
                          onPress={() => {
                            setReplyTo({});
                          }}>
                          {i18n.t('cancel')}
                        </Text>
                      </Text>
                    )}
                    <View style={styles.userComment}>
                      <Image
                        source={{uri: getUserPhoto(userData)}}
                        style={styles.userImage}
                      />
                      <TextInput
                        placeholder={i18n.t('put_your_comment')}
                        value={commentContent}
                        onChangeText={setCommentContent}
                        style={styles.input}
                        underlineColor="transparent"
                        ref={inputRef}
                      />
                      <TouchableOpacity
                        onPress={handleCreateStoryComment}
                        style={styles.sendWrapper}>
                        {createCommentLoading || replyCommentLoading ? (
                          <ActivityIndicator color={colors.primary} size={30} />
                        ) : (
                          <Image source={images.Send} style={styles.sendIcon} />
                        )}
                      </TouchableOpacity>
                    </View>
                  </>
                )}
                {map(storyComments?.data, (item, index) => {
                  return (
                    <Comment
                      commentData={item}
                      key={index}
                      handleReply={isLoggedIn ? handleReply : undefined}
                      focusComment={commentId}
                      handleScrollFocus={handleScrollFocus}
                    />
                  );
                })}
              </View>
            </>
          )}
        </ScrollView>
      </SafeAreaView>
      {isLoading && <Loading />}
      <ImageView
        visible={!isEmpty(showImageList)}
        setVisible={() => {
          setShowImageList([]);
        }}
        imageList={showImageList}
      />
      <ShareCode
        value={`${API_URL}/story/${storyId}`}
        visible={visibleShare}
        onClose={() => {
          setVisibleShare(false);
        }}
      />
    </>
  );
};

export default StoryDetail;
