import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import React, {useEffect} from 'react';
import styles from './styles';
import {AdminHeader, Member} from 'src/components';
import images from 'src/assets/images';
import {useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {useGetAllTrip} from 'src/api/users';
import dayjs from 'dayjs';

const TripAdmin = () => {
  const navigation = useNavigation();
  const {runRequest: getAllTrip} = useGetAllTrip({});
  const tripList = useSelector(state => state.metadata.allTrip);

  useEffect(() => {
    getAllTrip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <AdminHeader />
      <ScrollView showsVerticalScrollIndicator={false} style={styles.detail}>
        <View style={styles.tripList}>
          {!isEmpty(tripList) && (
            <>
              {tripList.map(trip => {
                return (
                  <TouchableOpacity
                    key={trip.id}
                    style={styles.event}
                    onPress={() => {
                      navigation.navigate(routeNames.NonAuthFlow.TripDetail, {
                        tripId: trip.id,
                      });
                    }}>
                    <View style={styles.eventHeader}>
                      <View>
                        <Text
                          numberOfLines={1}
                          style={styles.eventTitle}>{`Trip ${trip.id}`}</Text>
                        <Text
                          numberOfLines={1}
                          style={styles.eventTitle}>{`${dayjs(
                          trip.fromDate,
                        ).format('DD/MM/YY')} - ${dayjs(trip.fromDate).format(
                          'DD/MM/YY',
                        )}`}</Text>
                      </View>
                      <TouchableOpacity
                        onPress={() => {
                          navigation.navigate(routeNames.MainFlow.ChatScreen, {
                            conversationId: trip?.conversation_id,
                          });
                        }}
                        hitSlop={{bottom: 12, left: 12, right: 12, top: 12}}>
                        <Image source={images.Chat} style={styles.chatIcon} />
                      </TouchableOpacity>
                    </View>
                    <Member
                      creatorId={trip?.created_by}
                      maximumMember={trip?.maximum_member}
                      members={trip?.members}
                      invitedList={trip?.invited_users}
                      emptyComponent={
                        <View style={styles.buttonAdd}>
                          <Image
                            style={styles.icon}
                            source={images.People}
                            resizeMode="cover"
                          />
                        </View>
                      }
                      containerStyle={styles.members}
                      itemStyle={styles.avatar}
                    />
                  </TouchableOpacity>
                );
              })}
            </>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default TripAdmin;
