import React from 'react';
import {View, Image, Text, TouchableOpacity} from 'react-native';

import images from 'src/assets/images';

import styles from './styles';

const MapCard = ({onPress, villageData}) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Image
        source={
          villageData?.location_image
            ? {uri: villageData?.location_image}
            : images.MockMap
        }
        resizeMode={'contain'}
        style={styles.image}
      />
      <View style={styles.contentWrapper}>
        <View style={styles.flex1}>
          <Text style={styles.province} numberOfLines={1}>
            {villageData?.name}
          </Text>
          <Text style={styles.city} numberOfLines={1}>
            {villageData?.location}
          </Text>
        </View>
        <View style={styles.rightWrapper}>
          <Image
            style={styles.arrow}
            source={images.MoreArrow}
            resizeMode={'contain'}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default MapCard;
