import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getListActivityRequest} from '../requests';

export const useGetListActivity = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(state => state.activities.isLoadingGetList);

  const [responseData, setResponseData] = useState([]);

  const dispatch = useDispatch();

  const runRequest = useCallback(
    params => {
      dispatch(
        getListActivityRequest({
          params,
          failureCallback: err => {
            failureCallback?.(err);
          },
          successCallback: data => {
            setResponseData(data);
            successCallback?.(data);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  return {
    isLoading,
    runRequest,
    responseData,
  };
};
