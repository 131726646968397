import {View, Text, Image, TouchableOpacity} from 'react-native';
import React from 'react';
import styles from './styles';
import dayjs from 'dayjs';
import {useTranslations} from 'src/hooks';
import {isEmpty, map} from 'lodash';
import {useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';

const Comment = ({
  commentData,
  handleReply,
  focusComment,
  handleScrollFocus,
}) => {
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  return (
    <View
      style={styles.container}
      onLayout={({nativeEvent: {layout}}) => {
        if (commentData.id === focusComment) {
          handleScrollFocus(layout.top - layout.height);
        }
      }}>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate(routeNames.NonAuthFlow.UserDetail, {
            userId: commentData.created_by?.id,
          });
        }}>
        <Image
          source={{uri: commentData.created_by?.avatar}}
          style={styles.avatar}
        />
      </TouchableOpacity>
      <View style={styles.flex1}>
        <Text style={styles.textName}>{commentData.created_by?.name}</Text>
        <Text style={styles.textContent}>{commentData.content}</Text>
        <View style={styles.replyWrapper}>
          <Text style={styles.textTime}>
            {dayjs(commentData.created_at).toNow(true)}
          </Text>
          <Text
            onPress={() => {
              handleReply(commentData);
            }}
            style={styles.reply}>
            {i18n.t('reply')}
          </Text>
        </View>
        {!isEmpty(commentData.replys) && (
          <View style={styles.replyComment}>
            {map(commentData.replys, (comment, index) => (
              <Comment
                commentData={comment}
                key={index}
                handleReply={handleReply}
                focusComment={focusComment}
                handleScrollFocus={handleScrollFocus}
              />
            ))}
          </View>
        )}
      </View>
    </View>
  );
};

export default Comment;
