import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  flex: {flex: 1},
  container: {backgroundColor: colors.neutral9, flex: 1},
  scrollView: {padding: calculateWidth(32)},
  input: {
    marginBottom: calculateWidth(12),
    height: calculateWidth(48),
    borderWidth: 1,
    borderColor: colors.neutral6,
    backgroundColor: colors.neutral9,
    paddingHorizontal: calculateWidth(20),
    borderRadius: calculateWidth(8),
    color: colors.neutral1,
    ...textFont(16, '400'),
  },
  bioInput: {
    marginBottom: calculateWidth(12),
    height: calculateWidth(100),
    borderWidth: 1,
    borderColor: colors.neutral6,
    backgroundColor: colors.neutral9,
    paddingHorizontal: calculateWidth(20),
    paddingVertical: calculateWidth(10),
    borderRadius: calculateWidth(8),
    color: colors.neutral1,
    ...textFont(16, '400'),
  },
  label: {
    marginTop: calculateWidth(10),
    marginBottom: calculateWidth(12),
    ...textFont(16, '600'),
    color: colors.neutral1,
  },

  asterisk: {color: colors.error},

  select: {
    marginBottom: calculateWidth(12),
    paddingHorizontal: calculateWidth(20),
    height: calculateWidth(48),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: calculateWidth(8),
    borderWidth: 1,
    borderColor: colors.neutral6,
  },
  selectTitle: {
    ...textFont(16, '400'),
    color: colors.neutral1,
  },

  gender: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: calculateWidth(12),
  },
  selectButton: {
    width: '47%',
    borderRadius: 8,
    color: 'red',
    height: calculateWidth(48),
  },

  search: {
    marginBottom: calculateWidth(12),
  },

  item: {
    flexDirection: 'row',
    marginBottom: calculateWidth(12),
  },
  itemTitle: {
    ...textFont(16, '400'),
    color: colors.neutral1,
  },
  itemDescription: {
    ...textFont(14, '400'),
    color: colors.neutral5,
  },

  imagePickerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: calculateWidth(24),
  },
  imagePicker: {
    width: calculateWidth(140),
    height: calculateWidth(140),
    backgroundColor: colors.neutral8,
    borderRadius: calculateWidth(12),
    overflow: 'hidden',
  },
  imagePickerRound: {
    width: calculateWidth(140),
    height: calculateWidth(140),
    borderRadius: calculateWidth(100),
    backgroundColor: colors.neutral8,
    overflow: 'hidden',
    // borderWidth: 1,
  },
  avatar: {
    borderRadius: calculateWidth(100),
    alignSelf: 'center',
    marginBottom: calculateWidth(16),
    shadowColor: colors.neutral4,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  avatarThumbnail: {
    position: 'absolute',
    bottom: calculateWidth(6),
    right: calculateWidth(6),
    backgroundColor: colors.neutral7,
    padding: calculateWidth(4),
    borderRadius: 20,
  },
  imageThumbnail: {
    width: calculateWidth(140),
    height: calculateWidth(140),
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: calculateWidth(20),
  },
  imagePickerIcon: {
    width: calculateWidth(23),
    height: calculateWidth(23),
  },
  textPicker: {
    ...textFont(15, '400'),
    color: colors.neutral4,
    marginTop: calculateWidth(5),
    textAlign: 'center',
  },
  textAddMore: {
    fontSize: calculateWidth(25),
    color: colors.neutral4,
  },
});
