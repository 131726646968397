import React from 'react';
import {Text, TouchableOpacity} from 'react-native';

import {useTranslations} from 'src/hooks';

import styles from './styles';
import {colors} from 'src/constants';

const MonthCard = ({month, active, onPress}) => {
  const {i18n} = useTranslations();
  return (
    <TouchableOpacity
      style={[
        styles.monthCard,
        {
          backgroundColor: active ? colors.neutral7 : colors.neutral9,
        },
      ]}
      onPress={() => onPress(month)}>
      <Text
        style={[
          styles.text,
          {
            color: active ? colors.primary : colors.neutral1,
          },
        ]}>
        {`${month}${i18n.t('villageDetails.month')}`}
      </Text>
    </TouchableOpacity>
  );
};

export default MonthCard;
