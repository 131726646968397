import {StyleSheet} from 'react-native';
import {HEADER_HEIGHT} from 'src/constants';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: calculateWidth(10),
    height: calculateWidth(HEADER_HEIGHT),
  },
  leftIconWrapper: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftIcon: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
  textTitle: {
    ...textFont(18, '600'),
    color: colors.neutral1,
  },

  titleDark: {
    color: colors.neutral9,
  },

  rightIconWrapper: {
    width: calculateWidth(40),
    height: calculateWidth(40),
  },
  skip: {
    ...textFont(15, '400'),
    color: colors.neutral1,
  },
});
export default styles;
