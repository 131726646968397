import {
  View,
  Text,
  Image,
  TextInput,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import React, {useState, useEffect} from 'react';
import Header from '../AddStory/components/Header';
import {useAlert, useTranslations} from 'src/hooks';
import SelectButton from 'src/components/SelectButton';
import SearchDropdown from 'src/components/SearchDropdown';
import ImageSelector from 'src/components/ImageSelector';
import {getUserPhoto} from 'src/utils/common';

import styles from './styles';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import images from 'src/assets/images';
import {useGetMetadata} from 'src/api/users';
import {useUpdateUserProfile} from 'src/api/user';
import {cloneDeep, filter, get, isEmpty, set} from 'lodash';
import {colors, USER_DATING, USER_PHOTO_KEY} from 'src/constants';
import ModalPreview from './components/ModalPreview';
import {useUpload} from 'src/api/upload/hooks/useUpload';
import {Loading, Checkbox, DatePickerModal} from 'src/components';
import {useNavigation, useRoute} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import {registerLogin} from 'src/store/slices/auth/authSlice';

const useInputState = initialValue => {
  const [value, setValue] = useState(initialValue);
  return {value, onChangeText: setValue};
};

const EditProfile = () => {
  const {i18n} = useTranslations();
  const {alert} = useAlert();
  const navigation = useNavigation();
  const userData = useSelector(state => state.user.data);
  const dispatch = useDispatch();
  const route = useRoute();
  const isFromMyProfile = route.params?.isFromMyProfile;
  const influencerPromotion = useSelector(
    state => state.auth.influencerPromotion,
  );

  const nameInputState = useInputState(userData.name);
  const birthdayInputState = useInputState(
    userData?.birthday && dayjs(userData?.birthday, 'YYYY-MM-DD').isValid()
      ? dayjs(userData?.birthday, 'YYYY-MM-DD').toDate()
      : undefined,
  );
  const bioInputState = useInputState(userData.bio);
  const industryInputState = useInputState(userData.industry);
  const jobInputState = useInputState(userData.job);
  const collegeInputState = useInputState(userData.college);
  const [gender, setGender] = useState(userData.gender || 'male');
  const [lifestyle, setLifestyle] = useState(userData.lifestyle || []);
  const [dating, setDating] = useState(userData.dating_standard || []);
  const [userImage, setUserImage] = useState({
    ...userData?.photos,
    [USER_PHOTO_KEY.PORTRAIT]: getUserPhoto(userData),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(!!isFromMyProfile);
  const [openSelectDate, setOpenSelectDate] = useState(false);

  const {runRequest: metadataRequest} = useGetMetadata({});

  const lifestyles = useSelector(state => state.metadata.metadata.lifestyles);
  const college = useSelector(state => state.metadata.metadata.college);

  const {runRequest: uploadImageRequest} = useUpload();

  const {runRequest: updateProfile} = useUpdateUserProfile({
    successCallback: () => {
      setIsLoading(false);
      if (isFromMyProfile) {
        alert(i18n.t('update_successful'), () => {
          navigation.goBack();
        });
      } else {
        navigation.navigate(routeNames.AuthFlow.WelcomeScreen);
        dispatch(registerLogin());
      }
    },
    failureCallback: () => {
      setIsLoading(false);
      alert(i18n.t('something_went_wrong'));
    },
  });

  useEffect(() => {
    nameInputState.onChangeText(userData.name);
    birthdayInputState.onChangeText(
      userData?.birthday && dayjs(userData?.birthday, 'YYYY-MM-DD').isValid()
        ? dayjs(userData?.birthday, 'YYYY-MM-DD').toDate()
        : undefined,
    );
    bioInputState.onChangeText(userData.bio);
    industryInputState.onChangeText(userData.industry);
    jobInputState.onChangeText(userData.job);
    collegeInputState.onChangeText(userData.college);
    setGender(userData.gender || 'male');
    setLifestyle(userData.lifestyle || []);
    setDating(userData.dating_standard || []);
    setUserImage({
      ...userData?.photos,
      [USER_PHOTO_KEY.PORTRAIT]: getUserPhoto(userData),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.id]);

  useEffect(() => {
    metadataRequest({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditProfile = async () => {
    setShowPreviewModal(false);
    setIsLoading(true);

    const promiseUploadImage = [];
    Object.values(USER_PHOTO_KEY).forEach(value => {
      const imageData = get(userImage, value, '');
      if (imageData) {
        if (imageData.includes('https://')) {
          return promiseUploadImage.push(imageData);
        } else {
          promiseUploadImage.push(
            new Promise((resolve, reject) => {
              uploadImageRequest({
                failureCallback: error => {
                  setIsLoading(false);
                  reject(error);
                },
                successCallback: response => {
                  resolve(response?.data?.Location || '');
                },
                params: {file: imageData, tags: ['users', value]},
              });
            }),
          );
        }
      } else {
        promiseUploadImage.push('');
      }
    });

    const imageUrlList = await Promise.all(promiseUploadImage);

    const mappingUserImage = {};
    Object.values(USER_PHOTO_KEY).forEach((key, index) => {
      if (imageUrlList[index]) {
        mappingUserImage[key] = imageUrlList[index];
      }
    });
    updateProfile({
      name: nameInputState.value,
      birthday: dayjs(birthdayInputState.value).format('YYYY-MM-DD'),
      gender,
      bio: bioInputState.value,
      college: collegeInputState.value,
      industry: industryInputState.value,
      job: jobInputState.value,
      lifestyle: lifestyle.filter(i => !!i),
      datingStandard: USER_DATING.filter(i => dating.includes(i)),
      photos: mappingUserImage,
      ...(!userData.influencer &&
        !!influencerPromotion && {influencer: influencerPromotion}),
    });
  };

  return (
    <>
      <View style={styles.container}>
        <Header
          onPreview={() => setShowPreviewModal(true)}
          disabled={
            !nameInputState.value ||
            !birthdayInputState.value ||
            isEmpty(USER_DATING.filter(i => dating.includes(i))) ||
            !userImage.portrait
          }
          title={i18n.t('edit_profile')}
          showBackButton={!!isFromMyProfile}
        />
        <ScrollView contentContainerStyle={styles.scrollView}>
          <View style={styles.avatar}>
            <ImageSelector
              isLoading={isLoading}
              containerStyle={styles.imagePickerRound}
              thumbnail={
                <View style={styles.imageThumbnail}>
                  <Image
                    source={images.People}
                    style={styles.imagePickerIcon}
                  />
                  <Text style={styles.textPicker}>
                    {i18n.t('profile.photo.portrait')}
                  </Text>
                </View>
              }
              image={userImage.portrait}
              onSelectImage={image => {
                setUserImage(imageData => ({
                  ...imageData,
                  portrait: image,
                }));
              }}
            />
            {userImage.portrait && (
              <View style={styles.avatarThumbnail}>
                <Image source={images.People} style={styles.imagePickerIcon} />
              </View>
            )}
          </View>
          <Text style={styles.label}>
            {i18n.t('nickname')}{' '}
            <Text style={styles.asterisk}>{`(${i18n.t('required')})`}</Text>
          </Text>
          <TextInput {...nameInputState} style={styles.input} />
          <Text style={styles.label}>
            {i18n.t('profile.bio.date_of_birth')}{' '}
            <Text style={styles.asterisk}>{`(${i18n.t('required')})`}</Text>
          </Text>
          <TouchableOpacity
            style={styles.select}
            onPress={() => {
              setOpenSelectDate(true);
            }}>
            <Text style={styles.selectTitle}>
              {birthdayInputState.value
                ? dayjs(birthdayInputState.value).format('YYYY-MM-DD')
                : i18n.t('profile.bio.date_of_birth')}
            </Text>
            <Image source={images.ArrowDown} style={styles.arrowDown} />
          </TouchableOpacity>
          <Text style={styles.label}>
            {i18n.t('gender')}{' '}
            <Text style={styles.asterisk}>{`(${i18n.t('required')})`}</Text>
          </Text>
          <View style={styles.gender}>
            <SelectButton
              title={`♂  ${i18n.t('profile.bio.male')}`}
              style={styles.selectButton}
              onClick={() => setGender('male')}
              active={gender === 'male'}
            />
            <SelectButton
              title={`♀  ${i18n.t('profile.bio.female')}`}
              style={styles.selectButton}
              onClick={() => setGender('female')}
              active={gender === 'female'}
            />
          </View>
          <Text style={styles.label}>
            {i18n.t('wish')}{' '}
            <Text style={styles.asterisk}>{`(${i18n.t('required')})`}</Text>
          </Text>
          <TouchableOpacity
            style={styles.item}
            onPress={() => {
              if (dating.includes('wishDate')) {
                const newDate = cloneDeep(dating);
                newDate.splice(newDate.indexOf('wishDate'), 1);
                setDating(newDate);
              } else {
                setDating(val => [...val, 'wishDate']);
              }
            }}>
            <Checkbox
              status={dating.includes('wishDate') ? 'checked' : 'unchecked'}
              onPress={() => {
                if (dating.includes('wishDate')) {
                  const newDate = cloneDeep(dating);
                  newDate.splice(newDate.indexOf('wishDate'), 1);
                  setDating(newDate);
                } else {
                  setDating(val => [...val, 'wishDate']);
                }
              }}
              color={colors.primary}
              uncheckedColor={colors.neutral7}
            />
            <View style={styles.flex}>
              <Text numberOfLines={1} style={styles.itemTitle}>
                {i18n.t('wishDate')}
              </Text>
              <Text numberOfLines={2} style={styles.itemDescription}>
                {i18n.t('dateDescription')}
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.item}
            onPress={() => {
              if (dating.includes('wishFriend')) {
                const newDate = cloneDeep(dating);
                newDate.splice(newDate.indexOf('wishFriend'), 1);
                setDating(newDate);
              } else {
                setDating(val => [...val, 'wishFriend']);
              }
            }}>
            <Checkbox
              status={dating.includes('wishFriend') ? 'checked' : 'unchecked'}
              onPress={() => {
                if (dating.includes('wishFriend')) {
                  const newDate = cloneDeep(dating);
                  newDate.splice(newDate.indexOf('wishFriend'), 1);
                  setDating(newDate);
                } else {
                  setDating(val => [...val, 'wishFriend']);
                }
              }}
              color={colors.primary}
              uncheckedColor={colors.neutral7}
            />
            <View style={styles.flex}>
              <Text numberOfLines={1} style={styles.itemTitle}>
                {i18n.t('wishFriend')}
              </Text>
              <Text numberOfLines={2} style={styles.itemDescription}>
                {i18n.t('friendDescription')}
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.item}
            onPress={() => {
              if (dating.includes('wishNetwork')) {
                const newDate = cloneDeep(dating);
                newDate.splice(newDate.indexOf('wishNetwork'), 1);
                setDating(newDate);
              } else {
                setDating(val => [...val, 'wishNetwork']);
              }
            }}>
            <Checkbox
              status={dating.includes('wishNetwork') ? 'checked' : 'unchecked'}
              onPress={() => {
                if (dating.includes('wishNetwork')) {
                  const newDate = cloneDeep(dating);
                  newDate.splice(newDate.indexOf('wishNetwork'), 1);
                  setDating(newDate);
                } else {
                  setDating(val => [...val, 'wishNetwork']);
                }
              }}
              color={colors.primary}
              uncheckedColor={colors.neutral7}
            />
            <View style={styles.flex}>
              <Text numberOfLines={1} style={styles.itemTitle}>
                {i18n.t('wishNetwork')}
              </Text>
              <Text numberOfLines={2} style={styles.itemDescription}>
                {i18n.t('networkDescription')}
              </Text>
            </View>
          </TouchableOpacity>
          <Text style={styles.label}>{i18n.t('profile.bio.bio')}</Text>
          <TextInput
            style={styles.bioInput}
            placeholder={i18n.t('addStory.paragraph')}
            multiline={true}
            {...bioInputState}
          />
          <Text style={styles.label}>
            {i18n.t('profile.college.my_college')}
          </Text>
          <SearchDropdown
            value={collegeInputState.value}
            onChangeValue={collegeInputState.onChangeText}
            data={college}
            placeholder={i18n.t('profile.college.placeholder')}
          />
          <Text style={styles.label}>{i18n.t('industry')}</Text>
          <TextInput
            style={styles.input}
            placeholder={i18n.t('addStory.paragraph')}
            {...industryInputState}
          />

          <Text style={styles.label}>{i18n.t('job')}</Text>
          <TextInput
            style={styles.input}
            placeholder={i18n.t('addStory.paragraph')}
            {...jobInputState}
          />
          <Text style={styles.label}>{i18n.t('lifestyle')}</Text>
          <SearchDropdown
            value={lifestyle[0]}
            onChangeValue={val => {
              setLifestyle(data => {
                const newData = cloneDeep(data);
                set(newData, '[0]', val);
                return newData;
              });
            }}
            data={filter(lifestyles, l => !lifestyle.includes(l))}
            placeholder={i18n.t('profile.lifeStyle.placeholder')}
            disableAdd
            containerStyle={styles.search}
          />
          <SearchDropdown
            value={lifestyle[1]}
            onChangeValue={val => {
              setLifestyle(data => {
                const newData = cloneDeep(data);
                set(newData, '[1]', val);
                return newData;
              });
            }}
            data={filter(lifestyles, l => !lifestyle.includes(l))}
            placeholder={i18n.t('profile.lifeStyle.placeholder')}
            disableAdd
            containerStyle={styles.search}
          />
          <SearchDropdown
            value={lifestyle[2]}
            onChangeValue={val => {
              setLifestyle(data => {
                const newData = cloneDeep(data);
                set(newData, '[2]', val);
                return newData;
              });
            }}
            data={filter(lifestyles, l => !lifestyle.includes(l))}
            placeholder={i18n.t('profile.lifeStyle.placeholder')}
            disableAdd
            containerStyle={styles.search}
          />
          <Text style={styles.label}>
            {i18n.t('photo')}{' '}
            <Text style={styles.asterisk}>{`(${i18n.t('required')})`}</Text>
          </Text>
          <View style={styles.imagePickerWrapper}>
            <ImageSelector
              isLoading={isLoading}
              containerStyle={styles.imagePicker}
              thumbnail={
                <View style={styles.imageThumbnail}>
                  <Image
                    source={images.People}
                    style={styles.imagePickerIcon}
                  />
                  <Text style={styles.textPicker}>
                    {i18n.t('profile.photo.portrait')}
                  </Text>
                </View>
              }
              thumbnailIcon={
                <Image source={images.People} style={styles.imagePickerIcon} />
              }
              image={userImage.portrait}
              onSelectImage={image => {
                setUserImage(imageData => ({
                  ...imageData,
                  portrait: image,
                }));
              }}
            />
            <ImageSelector
              isLoading={isLoading}
              containerStyle={styles.imagePicker}
              thumbnail={
                <View style={styles.imageThumbnail}>
                  <Image
                    source={images.Gallery}
                    style={styles.imagePickerIcon}
                  />
                  <Text style={styles.textPicker}>
                    {i18n.t('profile.photo.travel')}
                  </Text>
                </View>
              }
              thumbnailIcon={
                <Image source={images.Gallery} style={styles.imagePickerIcon} />
              }
              image={userImage.travel}
              onSelectImage={image => {
                setUserImage(imageData => ({
                  ...imageData,
                  travel: image,
                }));
              }}
            />
          </View>
          <View style={styles.imagePickerWrapper}>
            <ImageSelector
              isLoading={isLoading}
              containerStyle={styles.imagePicker}
              thumbnail={
                <View style={styles.imageThumbnail}>
                  <Image
                    source={images.Coffee}
                    style={styles.imagePickerIcon}
                  />
                  <Text style={styles.textPicker}>
                    {i18n.t('profile.photo.hobby')}
                  </Text>
                </View>
              }
              thumbnailIcon={
                <Image source={images.Coffee} style={styles.imagePickerIcon} />
              }
              image={userImage.hobby}
              onSelectImage={image => {
                setUserImage(imageData => ({
                  ...imageData,
                  hobby: image,
                }));
              }}
            />
            <ImageSelector
              isLoading={isLoading}
              containerStyle={styles.imagePicker}
              thumbnail={
                <View style={styles.imageThumbnail}>
                  <Text style={styles.textAddMore}>+</Text>
                </View>
              }
              image={userImage.more1}
              onSelectImage={image => {
                setUserImage(imageData => ({
                  ...imageData,
                  more1: image,
                }));
              }}
            />
          </View>
          <View style={styles.imagePickerWrapper}>
            <ImageSelector
              isLoading={isLoading}
              containerStyle={styles.imagePicker}
              thumbnail={
                <View style={styles.imageThumbnail}>
                  <Text style={styles.textAddMore}>+</Text>
                </View>
              }
              image={userImage.more2}
              onSelectImage={image => {
                setUserImage(imageData => ({
                  ...imageData,
                  more2: image,
                }));
              }}
            />
            <ImageSelector
              isLoading={isLoading}
              containerStyle={styles.imagePicker}
              thumbnail={
                <View style={styles.imageThumbnail}>
                  <Text style={styles.textAddMore}>+</Text>
                </View>
              }
              image={userImage.more3}
              onSelectImage={image => {
                setUserImage(imageData => ({
                  ...imageData,
                  more3: image,
                }));
              }}
            />
          </View>
        </ScrollView>
        <DatePickerModal
          visible={openSelectDate}
          onDismiss={() => {
            setOpenSelectDate(false);
          }}
          date={birthdayInputState.value}
          onConfirm={params => {
            setOpenSelectDate(false);
            birthdayInputState.onChangeText(params.date);
          }}
          validRange={{
            endDate: new Date(),
          }}
          saveLabel={i18n.t('profile.bio.select')}
          label={i18n.t('profile.bio.date_of_birth')}
        />
        <ModalPreview
          showPreviewModal={showPreviewModal}
          setShowPreviewModal={setShowPreviewModal}
          user={{
            ...userData,
            name: nameInputState.value,
            birthday: dayjs(birthdayInputState.value).format('YYYY-MM-DD'),
            gender,
            bio: bioInputState.value,
            college: collegeInputState.value,
            industry: industryInputState.value,
            job: jobInputState.value,
            lifestyle: lifestyle.filter(i => !!i),
            dating_standard: USER_DATING.filter(i => dating.includes(i)),
            photos: userImage,
          }}
          onBottomPress={handleEditProfile}
        />
      </View>
      {isLoading && <Loading />}
    </>
  );
};

export default EditProfile;
