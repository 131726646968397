import {createAsyncThunk} from '@reduxjs/toolkit';
import {axiosSetAuthToken} from 'src/utils/axiosConfig';

export const logout = createAsyncThunk(
  'auth/logout',
  async ({successCallback, failureCallback}, {rejectWithValue}) => {
    try {
      axiosSetAuthToken();
      successCallback?.({});
      return {};
    } catch (err) {
      failureCallback?.(err);
      return rejectWithValue(err);
    }
  },
);
