import {createSlice} from '@reduxjs/toolkit';
import {logout} from 'src/api/auth';
import {upload} from 'src/api/upload/requests';

const initialState = {
  isLoading: false,
  error: false,
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(upload.pending, state => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(upload.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
    builder.addCase(upload.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
    });

    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

const {reducer: uploadReducer} = uploadSlice;

export default uploadReducer;
