import {StyleSheet} from 'react-native';

import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';
import {colors, HEADER_HEIGHT} from 'src/constants';

export default StyleSheet.create({
  header: {
    ...textFont(22, '600'),
    color: colors.neutral1,
    flex: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: calculateWidth(HEADER_HEIGHT),
  },
  leftIconWrapper: {
    minWidth: calculateWidth(40),
    height: calculateWidth(40),
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  leftIcon: {
    width: calculateWidth(24),
    height: calculateWidth(24),
  },
  container: {
    flex: 1,
    backgroundColor: colors.neutral9,
  },
  preview: {
    paddingHorizontal: calculateWidth(16),
    height: calculateWidth(40),
    marginRight: calculateWidth(16),
  },
  divider: {
    height: 1,
    backgroundColor: colors.neutral7,
  },
  bg: {
    ...textFont(16, '600'),
    marginTop: calculateWidth(32),
    marginBottom: calculateWidth(12),
  },
  pickerWrapper: {
    borderRadius: 12,
    overflow: 'hidden',
  },
  picker: {
    width: '100%',
    height: 311,
    backgroundColor: colors.neutral8,
    borderRadius: 12,
  },
  gallery: {
    width: 20,
    height: 20,
    marginBottom: 6,
  },
  thumb: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  upload: {
    ...textFont(14, '400'),
    color: colors.neutral4,
  },
  thumbIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  thumbnailStyle: {
    width: null,
    paddingHorizontal: 16,
    marginVertical: 8,
    borderRadius: 48,
  },
  thumbGallery: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  title: {
    ...textFont(16, '600'),
    marginVertical: calculateWidth(24),
  },
  scroll: {
    marginHorizontal: calculateWidth(32),
    paddingBottom: calculateWidth(100),
  },
  inputStyle: {
    borderColor: colors.neutral6,
    backgroundColor: colors.neutral9,
    height: calculateWidth(48),
    paddingHorizontal: calculateWidth(20),
    borderWidth: 1,
    borderRadius: calculateWidth(8),
    color: colors.neutral1,
    ...textFont(16, '400'),
  },

  flexRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textAddSection: {
    ...textFont(14, '600'),
    color: colors.primary,
    paddingVertical: calculateWidth(24),
  },

  storyItem: {
    width: calculateWidth(281),
    alignSelf: 'center',
    borderWidth: 1,
    flexDirection: 'row',
    borderColor: colors.neutral6,
    borderRadius: calculateWidth(4),
  },
  storyText: {
    ...textFont(14, '400'),
    color: colors.neutral1,
    flex: 1,
    padding: calculateWidth(7),
  },
  storyImage: {
    width: calculateWidth(120),
    height: calculateWidth(120),
    backgroundColor: colors.neutral8,
    borderRadius: calculateWidth(4),
  },
  imageWrapper: {
    flexDirection: 'row',
    width: calculateWidth(120),
  },
  imageItem: {
    height: calculateWidth(50),
    flex: 1,
  },
  clear: {
    width: calculateWidth(20),
    height: calculateWidth(20),
    marginLeft: calculateWidth(10),
    tintColor: colors.error,
  },
  storyLine: {
    width: calculateWidth(311),
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: calculateWidth(16),
  },

  inputImageThump: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputImage: {
    width: calculateWidth(93),
    height: calculateWidth(93),
    borderRadius: calculateWidth(8),
    overflow: 'hidden',
    marginBottom: calculateWidth(16),
    marginRight: calculateWidth(16),
    backgroundColor: colors.neutral8,
  },
  inputImageWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: calculateWidth(-16),
    paddingTop: calculateWidth(16),
  },
  inputSection: {
    borderColor: colors.neutral6,
    backgroundColor: colors.neutral9,
    height: calculateWidth(200),
    padding: calculateWidth(20),
    borderWidth: 1,
    borderRadius: calculateWidth(8),
    color: colors.neutral1,
    ...textFont(16, '400'),
  },

  inputClear: {
    width: calculateWidth(10),
    height: calculateWidth(10),
    tintColor: colors.neutral9,
  },
  inputClearButton: {
    width: calculateWidth(15),
    height: calculateWidth(15),
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: colors.error,
    borderRadius: 100,
    right: calculateWidth(10),
    top: calculateWidth(-6),
  },
});
