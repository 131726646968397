import {StyleSheet} from 'react-native';
import {calculateWidth} from 'src/utils/dimensions';

export default StyleSheet.create({
  container: {
    width: calculateWidth(16),
    height: calculateWidth(16),
    borderWidth: calculateWidth(2),
    borderRadius: calculateWidth(4),
    margin: calculateWidth(4),
    justifyContent: 'center',
    alignItems: 'center',
  },
});
