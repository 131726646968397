import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  SafeAreaView,
  Image,
  ScrollView,
  TouchableOpacity,
  FlatList,
} from 'react-native';
import {isEmpty} from 'lodash';
import {StackActions, useNavigation, useRoute} from '@react-navigation/native';

import {useAlert, useTranslations} from 'src/hooks';
import routeNames from 'src/navigation/routeNames';
import images from 'src/assets/images';
import {useGetVillageDetail} from 'src/api/village';

import {
  HomeHeader,
  Content,
  MonthPicker,
  Loading,
  ImageView,
  ShareCode,
} from 'src/components';

import styles from './styles';
import {useSelector} from 'react-redux';
import {API_URL} from 'src/utils/axiosConfig';
import {getUserPhoto} from 'src/utils/common';
import {useGetListActivity} from 'src/api/activities';
import {useGetAllStory} from 'src/api/story';

const VillageDetail = () => {
  const route = useRoute();
  const {alert} = useAlert();
  const navigation = useNavigation();

  const villageId = route.params?.villageId;

  const [currentMonth, setCurrentMonth] = useState(1);
  const [showImageList, setShowImageList] = useState([]);
  const [visibleShare, setVisibleShare] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const {i18n} = useTranslations();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.dispatch(
        StackActions.replace(
          isLoggedIn ? routeNames.BottomTab : routeNames.AuthFlow.WelcomeScreen,
        ),
      );
    }
  };

  const {
    runRequest: getVillageDetail,
    isLoading,
    responseData,
  } = useGetVillageDetail({
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'), handleGoBack);
    },
    successCallback: response => {
      const dataIndex = Object.values(
        response.data?.seasons?.details || [],
      ).findIndex(item => !isEmpty(item));
      if (dataIndex > -1) {
        setCurrentMonth(dataIndex + 1);
      }
    },
  });

  const {runRequest: getAllActivity, responseData: planResponse} =
    useGetListActivity({});

  const {runRequest: getAllStory, responseData: storyResponse} = useGetAllStory(
    {},
  );

  const planData = planResponse?.data || [];
  const storyData = storyResponse?.data || [];

  useEffect(() => {
    getVillageDetail(villageId);
    getAllActivity({village_id: villageId});
    getAllStory({village_id: villageId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [villageId]);

  const villageData = responseData?.data || {};

  const renderMonthSeason = () => {
    let monthSeason = [];
    switch (currentMonth) {
      case 1:
        monthSeason = villageData?.seasons?.details?.jan || [];
        break;
      case 2:
        monthSeason = villageData?.seasons?.details?.feb || [];
        break;
      case 3:
        monthSeason = villageData?.seasons?.details?.mar || [];
        break;
      case 4:
        monthSeason = villageData?.seasons?.details?.apr || [];
        break;
      case 5:
        monthSeason = villageData?.seasons?.details?.may || [];
        break;
      case 6:
        monthSeason = villageData?.seasons?.details?.jun || [];
        break;
      case 7:
        monthSeason = villageData?.seasons?.details?.jul || [];
        break;
      case 8:
        monthSeason = villageData?.seasons?.details?.aug || [];
        break;
      case 9:
        monthSeason = villageData?.seasons?.details?.sep || [];
        break;
      case 10:
        monthSeason = villageData?.seasons?.details?.oct || [];
        break;
      case 11:
        monthSeason = villageData?.seasons?.details?.nov || [];
        break;
      case 12:
        monthSeason = villageData?.seasons?.details?.dec || [];
        break;
      default:
        break;
    }
    return (
      <View style={styles.monthCard}>
        {monthSeason.map?.((data, index) => {
          return (
            <View key={index}>
              <Text style={styles.title}>{data.objective}</Text>
              <Text style={styles.content}>{data.description}</Text>
            </View>
          );
        })}
      </View>
    );
  };
  const handleShowImage = imageList => () => {
    setShowImageList(imageList);
  };

  const renderStory = ({item}) => {
    return (
      <TouchableOpacity
        style={styles.story}
        onPress={() => {
          navigation.navigate(routeNames.NonAuthFlow.StoryDetail, {
            storyId: item.cuid,
          });
        }}>
        <Image source={{uri: item.background}} style={styles.imageStory} />
        <Text style={styles.des} numberOfLines={2}>
          {item.title}
        </Text>
        <View style={styles.rowStory}>
          <Image
            source={{uri: getUserPhoto(item.publisher)}}
            style={styles.avatar}
          />
          <Text style={styles.nameStory} numberOfLines={2}>
            {item.publisher.name}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const renderPlan = ({item}) => {
    return (
      <TouchableOpacity
        style={styles.activity}
        onPress={() => {
          navigation.navigate(routeNames.NonAuthFlow.PlanDetail, {
            activityId: item.id,
          });
        }}>
        <Image style={styles.activityImage} source={{uri: item.background}} />
        <Text style={styles.activityName} numberOfLines={2}>
          {item.heading}
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <HomeHeader withBackButton />
        <ScrollView
          contentContainerStyle={styles.scroll}
          showsVerticalScrollIndicator={false}>
          <View style={styles.nameWrapper}>
            <Text style={styles.name}>{villageData?.name}</Text>
            <TouchableOpacity
              onPress={() => {
                setVisibleShare(true);
              }}>
              <Image source={images.Code} style={styles.code} />
            </TouchableOpacity>
          </View>
          {!isEmpty(villageData?.photos) && (
            <TouchableOpacity onPress={handleShowImage(villageData?.photos)}>
              <Image
                source={{uri: villageData?.photos[0]}}
                resizeMode="cover"
                style={styles.firstImage}
              />
              <View style={styles.secondRow}>
                <Image
                  source={{uri: villageData?.photos[1]}}
                  resizeMode="cover"
                  style={styles.secondImages}
                />
                <Image
                  source={{uri: villageData?.photos[2]}}
                  resizeMode="cover"
                  style={styles.secondImages}
                />
                <Image
                  source={{uri: villageData?.photos[3]}}
                  resizeMode="cover"
                  style={styles.secondImages}
                />
              </View>
            </TouchableOpacity>
          )}
          <Text style={styles.overview}>{i18n.t('introduction')}</Text>
          <Text style={styles.introduction}>{villageData.introduction}</Text>
          {seeMore && (
            <>
              <Text style={styles.overview}>
                {i18n.t('villageDetails.overview')}
              </Text>
              {!isEmpty(villageData?.geo_location) && (
                <>
                  <View style={styles.row}>
                    <Image style={styles.location} source={images.Location} />
                    <Text style={styles.geo}>
                      {i18n.t('villageDetails.geo')}
                    </Text>
                  </View>
                  {villageData?.geo_location.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
              {!isEmpty(villageData?.population) && (
                <>
                  <View style={styles.row}>
                    <Image
                      style={styles.location}
                      source={images.GroupPeople}
                    />
                    <Text style={styles.geo}>
                      {i18n.t('villageDetails.population')}
                    </Text>
                  </View>
                  {villageData?.population.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
              {!isEmpty(villageData?.topography) && (
                <>
                  <View style={styles.row}>
                    <Image style={styles.location} source={images.Clound} />
                    <Text style={styles.geo}>
                      {i18n.t('villageDetails.topo')}
                    </Text>
                  </View>
                  {villageData?.topography.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
              <Text style={styles.season}>
                {i18n.t('villageDetails.season')}
              </Text>
              {!isEmpty(villageData?.seasons) && (
                <>
                  <View style={styles.row}>
                    <Image style={styles.rec} source={images.Rec} />
                    <Text style={styles.geo}>
                      {i18n.t('villageDetails.overviewSeason')}
                    </Text>
                  </View>
                  <Content
                    data={{
                      content: villageData?.seasons?.overview || null,
                      photos: villageData?.seasons?.photos || null,
                    }}
                    handleShowImage={handleShowImage}
                  />
                </>
              )}
              {!isEmpty(villageData?.seasons?.details) &&
                Object.values(villageData?.seasons?.details || {}).some(
                  i => !isEmpty(i),
                ) && (
                  <>
                    <View style={styles.row}>
                      <Image
                        style={styles.location}
                        source={images.CalendarAnnouce}
                      />
                      <Text style={styles.geo}>
                        {i18n.t('villageDetails.details')}
                      </Text>
                    </View>
                    <MonthPicker
                      currentMonth={currentMonth}
                      onChange={m => setCurrentMonth(m)}
                    />
                    {renderMonthSeason()}
                  </>
                )}
              <Text style={styles.season}>
                {i18n.t('villageDetails.history')}
              </Text>
              {!isEmpty(villageData?.folklore) && (
                <>
                  <View style={styles.row}>
                    <Image style={styles.location} source={images.Book} />
                    <Text style={styles.geo}>
                      {i18n.t('villageDetails.folklore')}
                    </Text>
                  </View>
                  {villageData?.folklore.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
              {!isEmpty(villageData?.history) && (
                <>
                  <View style={styles.row}>
                    <Image style={styles.location} source={images.Timer} />
                    <Text style={styles.geo}>
                      {i18n.t('villageDetails.his')}
                    </Text>
                  </View>
                  {villageData?.history.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
              {!isEmpty(villageData?.celebrarity) && (
                <>
                  <View style={styles.row}>
                    <Image style={styles.location} source={images.MagicStar} />
                    <Text style={styles.geo}>
                      {i18n.t('villageDetails.celebrate')}
                    </Text>
                  </View>
                  {villageData?.celebrarity.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
              {!isEmpty(villageData?.traditional_craftsmen) && (
                <>
                  <View style={styles.row}>
                    <Image style={styles.location} source={images.Scissor} />
                    <Text style={styles.geo}>
                      {i18n.t('villageDetails.traditional')}
                    </Text>
                  </View>
                  {villageData?.traditional_craftsmen.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
              <Text style={styles.season}>
                {i18n.t('villageDetails.featured')}
              </Text>
              {!isEmpty(villageData?.building) && (
                <>
                  <View style={styles.row}>
                    <Image style={styles.location} source={images.Building} />
                    <Text style={styles.geo}>
                      {i18n.t('villageDetails.featured')}
                    </Text>
                  </View>
                  {villageData?.building.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
              {!isEmpty(villageData?.natural) && (
                <>
                  <View style={styles.row}>
                    <Image style={styles.location} source={images.Mountain} />
                    <Text style={styles.geo}>
                      {i18n.t('villageDetails.scenery')}
                    </Text>
                  </View>
                  {villageData?.natural.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
              {!isEmpty(villageData?.foodset) && (
                <>
                  <View style={styles.row}>
                    <Image style={styles.location} source={images.Bell} />
                    <Text style={styles.geo}>
                      {i18n.t('villageDetails.foodSingle')}
                    </Text>
                  </View>
                  {villageData?.foodset.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
              {!isEmpty(villageData?.problems) && (
                <>
                  <Text style={styles.season}>
                    {i18n.t('villageDetails.facing')}
                  </Text>
                  {villageData?.problems?.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
              {!isEmpty(villageData?.traffic) && (
                <>
                  <Text style={styles.season}>
                    {i18n.t('villageDetails.traffic')}
                  </Text>
                  {villageData?.traffic?.map?.((item, index) => (
                    <Content
                      data={item}
                      key={index}
                      handleShowImage={handleShowImage}
                    />
                  ))}
                </>
              )}
            </>
          )}
          <TouchableOpacity
            style={styles.seeMore}
            onPress={() => {
              setSeeMore(data => !data);
            }}>
            <Text style={styles.textSeeMore}>
              {i18n.t(seeMore ? 'see_less' : 'see_more')}
            </Text>
          </TouchableOpacity>
          {!isEmpty(storyData) && (
            <>
              <View>
                <View style={styles.divider} />
                <View style={styles.sections}>
                  <Text style={styles.overview}>
                    {i18n.t('story_recommended')}
                  </Text>
                  <FlatList
                    data={storyData}
                    renderItem={renderStory}
                    keyExtractor={item => item.id}
                    horizontal
                    style={styles.storyWrapper}
                    contentContainerStyle={styles.storyContainer}
                  />
                </View>
              </View>
            </>
          )}
          {!isEmpty(planData) && (
            <>
              <View>
                <View style={styles.divider} />
                <View style={styles.sections}>
                  <Text style={styles.overview}>
                    {i18n.t('home.search.activity')}
                  </Text>
                  <FlatList
                    data={planData}
                    renderItem={renderPlan}
                    keyExtractor={item => item.id}
                    horizontal
                    style={styles.storyWrapper}
                    contentContainerStyle={styles.storyContainer}
                  />
                </View>
              </View>
            </>
          )}
        </ScrollView>
      </SafeAreaView>
      {isLoading && <Loading />}
      <ImageView
        visible={!isEmpty(showImageList)}
        setVisible={() => {
          setShowImageList([]);
        }}
        imageList={showImageList}
      />
      <ShareCode
        value={`${API_URL}/village/${villageId}`}
        visible={visibleShare}
        onClose={() => {
          setVisibleShare(false);
        }}
      />
    </>
  );
};

export default VillageDetail;
