import {
  View,
  Text,
  Modal,
  TouchableOpacity,
  Image,
  ScrollView,
} from 'react-native';
import React from 'react';
import styles from './styles';
import images from 'src/assets/images';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useTranslations} from 'src/hooks';
import UserPreview from 'src/components/UserPreview';
import Button from 'src/components/Button';
import {textFont} from 'src/utils/styles';
import {colors} from 'src/constants';

const ModalPreview = ({
  showPreviewModal,
  setShowPreviewModal,
  user = {},
  onBottomPress,
}) => {
  const insets = useSafeAreaInsets();
  const {i18n} = useTranslations();

  return (
    <Modal
      visible={showPreviewModal}
      transparent={true}
      animationType="slide"
      onRequestClose={() => setShowPreviewModal(false)}>
      <View style={styles.modalContainer}>
        <View style={[styles.container, {marginTop: insets.top + 30}]}>
          <View style={styles.closeContainer}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setShowPreviewModal(false)}>
              <Image
                source={images.Close}
                resizeMode={'cover'}
                style={styles.closeIcon}
              />
            </TouchableOpacity>
          </View>
          <Text style={styles.title}>{i18n.t('review_profile')}</Text>
          <ScrollView
            contentContainerStyle={styles.user}
            showsVerticalScrollIndicator={false}>
            <UserPreview user={user} shouldGoToStoryDetail={false} />
            <View style={styles.flexRow}>
              <TouchableOpacity
                style={styles.edit}
                onPress={() => setShowPreviewModal(false)}>
                <Text style={[textFont(16, '700'), {color: colors.primary}]}>
                  {i18n.t('edit')}
                </Text>
              </TouchableOpacity>
              <Button
                title={i18n.t('confirm')}
                buttonStyle={styles.bottomButton}
                onClick={onBottomPress}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default ModalPreview;
