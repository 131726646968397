import {useCallback, useEffect, useRef, useState} from 'react';
import debounce from 'lodash/debounce';

export const useDebounce = ({value, delay, options}) => {
  const previousValue = useRef(value);
  const [current, setCurrent] = useState(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallBack = useCallback(
    debounce(text => setCurrent(text), delay, options),
    [],
  );

  useEffect(() => {
    debounceCallBack(value);
    previousValue.current = value;
    if (value !== previousValue.current) {
      return debounceCallBack.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return current;
};
