import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  wrapper: {
    backgroundColor: colors.neutral10,
    borderRadius: 38,
    paddingVertical: calculateWidth(6),
    paddingHorizontal: calculateWidth(12),
    marginRight: calculateWidth(6),
    marginBottom: calculateWidth(10),
    justifyContent: 'center',
    alignItems: 'center',
  },
  textPill: {
    ...textFont(14, '400'),
    color: colors.neutral1,
  },
});
