import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {useDispatch} from 'react-redux';

import images from 'src/assets/images';
import {logout} from 'src/api/auth';

import styles from './styles';
import routeNames from 'src/navigation/routeNames';
import {useTranslations} from 'src/hooks';
import Menu from '../Menu';

const MENU_INDEX = {
  user: 0,
  logout: 1,
};

const AdminHeader = ({withBack}) => {
  const navigation = useNavigation();
  const {i18n} = useTranslations();
  const dispatch = useDispatch();
  const renderToggleButton = () => (
    <View>
      <Image
        source={images.Hamburger}
        style={styles.burger}
        resizeMode="contain"
      />
    </View>
  );
  const onItemSelect = index => () => {
    if (index === MENU_INDEX.user) {
      navigation.navigate(routeNames.BottomTab);
    }
    if (index === MENU_INDEX.logout) {
      //TODO: call logout api and purge state
      dispatch(logout({}));
    }
  };

  return (
    <View style={styles.container}>
      {withBack ? (
        <TouchableOpacity
          style={styles.leftIconWrapper}
          onPress={() => {
            navigation.canGoBack() && navigation.goBack();
          }}>
          <Image
            source={images.ArrowLeft}
            style={styles.leftIcon}
            resizeMode="contain"
          />
          <Text style={styles.textTitle}>{i18n.t('back')}</Text>
        </TouchableOpacity>
      ) : (
        <View style={styles.leftIconWrapper} />
      )}

      <Menu
        anchor={renderToggleButton()}
        items={[
          {
            title: 'User',
            onPress: onItemSelect(MENU_INDEX.user),
            titleStyle: styles.menuItem,
          },
          {
            title: 'Logout',
            onPress: onItemSelect(MENU_INDEX.logout),
            titleStyle: styles.menuItem,
          },
        ]}
      />
    </View>
  );
};

export default AdminHeader;
