import {
  View,
  Text,
  SafeAreaView,
  Image,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import React, {useEffect, useState, useRef} from 'react';
import {filter, isEmpty, range, toNumber} from 'lodash';

import {HomeHeader, UserPreview} from 'src/components';
import Swiper from './components/Swiper';

import {useAlert, useTranslations} from 'src/hooks';
import {useDispatch, useSelector} from 'react-redux';
import {
  useGetSuggestUserTrip,
  useGetSuggestUser,
  useUserDislike,
  useFollowUser,
} from 'src/api/users';
import {useCreateInvite} from 'src/api/invitation';
import {useNavigation, useRoute} from '@react-navigation/native';

import images from 'src/assets/images';
import styles from './styles';
import {getUserPhoto} from 'src/utils/common';
import {registerLogin} from 'src/store/slices/auth/authSlice';
import routeNames from 'src/navigation/routeNames';

const FindFriend = () => {
  const {i18n} = useTranslations();
  const {alert} = useAlert();
  const route = useRoute();
  const userData = useSelector(state => state.user.data);
  const tripId = route.params?.tripId;
  const isFromRecommend = route.params?.isFromRecommend;
  // This limit contain 1 current user and 5 invited people
  const inviteLimit = toNumber(route.params?.inviteLimit) || 6;
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [swipedIndex, setSwipedIndex] = useState(-1);

  const [selectedUser, setSelectedUser] = useState([]);

  const [showSuccess, setShowSuccess] = useState(false);
  const successTimeoutRef = useRef();

  const handleShowSuccess = () => {
    setShowSuccess(true);
    if (successTimeoutRef.current) {
      clearTimeout(successTimeoutRef.current);
    }
    successTimeoutRef.current = setTimeout(() => {
      setShowSuccess(false);
    }, 1000);
  };

  const {runRequest: inviteUser} = useCreateInvite({
    successCallback: user => {
      handleShowSuccess();
      setSelectedUser(selected => [...selected, user]);
    },
    failureCallback: user => {
      alert(`Failure to invite ${user.name}`);
    },
  });
  const {runRequest: getSuggestUserTrip, isLoading: isLoadingGetUserTrip} =
    useGetSuggestUserTrip({
      failureCallback: () => {
        alert('Invalid trip to invite', () => {
          navigation.goBack();
        });
      },
    });
  const {runRequest: getSuggestUser, isLoading: isLoadingGetUser} =
    useGetSuggestUser({
      failureCallback: () => {
        alert(i18n.t('something_went_wrong'), () => {
          navigation.goBack();
        });
      },
    });

  const {runRequest: followUser} = useFollowUser({
    successCallback: (_response, userId) => {
      handleShowSuccess();
      const followedUser = userList.find(i => i.id === userId);
      setSelectedUser(selected => [...selected, followedUser]);
    },
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'));
    },
  });

  const {runRequest: userDislike} = useUserDislike({});

  const userList = filter(
    useSelector(state => state.metadata.suggestUserList),
    user => user.id !== userData.id && getUserPhoto(user) && user.name,
  );

  useEffect(() => {
    if (tripId) {
      getSuggestUserTrip(tripId);
    } else {
      getSuggestUser();
    }
    setSwipedIndex(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  const renderCard = card => {
    return <UserPreview user={card} />;
  };

  const renderSelected = index => {
    if (index > 5) {
      return null;
    }
    const isHaveMorePeople = index === 5 && selectedUser.length > 6;
    if (selectedUser[index] && !isHaveMorePeople) {
      const userPhoto = getUserPhoto(selectedUser[index]);
      if (userPhoto) {
        return (
          <Image source={{uri: userPhoto}} style={styles.avatar} key={index} />
        );
      }
      return <View style={styles.avatar} key={index} />;
    }
    return (
      <View style={styles.buttonAdd} key={index}>
        {isHaveMorePeople ? (
          <Text style={styles.textMore}>{`+${selectedUser.length - 5}`}</Text>
        ) : (
          <Text style={styles.textAdd}>+</Text>
        )}
      </View>
    );
  };

  const handleSwipedRight = index => {
    const user = userList[index];
    if (tripId) {
      inviteUser({data: {tripId, userId: user.id}, user});
    } else {
      followUser(user.id);
    }
  };
  const handleSwipedLeft = index => {
    if (tripId) {
      const user = userList[index];
      userDislike(user.id);
    }
  };

  const isLoading = isLoadingGetUser || isLoadingGetUserTrip;

  const renderSwiper = () => {
    if (isLoading) {
      return <ActivityIndicator />;
    }
    if (
      isEmpty(userList) ||
      swipedIndex >= userList.length - 1 ||
      selectedUser.length >= inviteLimit - 1
    ) {
      return (
        <View style={styles.successWrapper}>
          <Image source={images.Success} style={styles.success} />
          <Text style={styles.textSuccess}>
            {i18n.t(tripId ? 'successfully_invited' : 'successfully_followed')}
          </Text>
        </View>
      );
    }
    return (
      <>
        {!tripId && (
          <Text style={styles.title}>{i18n.t('find_friend_title')}</Text>
        )}
        <Swiper
          onSwiped={setSwipedIndex}
          userList={userList || []}
          renderCard={renderCard}
          handleSwipedLeft={handleSwipedLeft}
          handleSwipedRight={handleSwipedRight}
        />
      </>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <HomeHeader
        withBackButton={false}
        withRightButton={false}
        withDoneButton={true}
        containerStyle={styles.header}
        onPressDoneButton={() => {
          setSelectedUser([]);
          if (!tripId && !isFromRecommend) {
            navigation.navigate(routeNames.AuthFlow.WelcomeScreen);
            dispatch(registerLogin());
          } else {
            navigation.goBack();
          }
        }}
      />
      <View style={styles.invite}>
        <Text style={styles.textInvite}>
          {tripId ? i18n.t('invitation.invited') : i18n.t('followed')}
        </Text>
        <View style={styles.flexRow}>
          {range(inviteLimit - 1).map((_, index) => renderSelected(index))}
        </View>
      </View>
      <View style={styles.swiper}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.flex1}>
          {renderSwiper()}
        </ScrollView>
      </View>
      {showSuccess && (
        <View style={styles.notify}>
          <Text style={styles.notifyText}>
            {i18n.t(tripId ? 'successfully_invited' : 'successfully_followed')}
          </Text>
          <TouchableOpacity
            style={styles.close}
            onPress={() => {
              setShowSuccess(false);
            }}>
            <Text style={styles.notifyClose}>✕</Text>
          </TouchableOpacity>
        </View>
      )}
    </SafeAreaView>
  );
};

export default FindFriend;
