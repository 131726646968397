import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  Platform,
  FlatList,
} from 'react-native';
import React, {useEffect, useState, useRef} from 'react';

import styles from './styles';
import {
  StackActions,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {useGetTripDetail} from 'src/api/users';
import {useDispatch, useSelector} from 'react-redux';
import routeNames from 'src/navigation/routeNames';
import {
  Button,
  ImageView,
  Loading,
  Member,
  ModalFailure,
  ModalSuccess,
  ShareCode,
} from 'src/components';
import {useAlert, useTranslations} from 'src/hooks';
import LinearGradient from 'react-native-linear-gradient';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import images from 'src/assets/images';
import dayjs from 'dayjs';
import {
  checkDuplicate,
  formatPrice,
  getTripMemberCount,
  getUserPhoto,
  isWeChat,
} from 'src/utils/common';
import {get, isEmpty, some, toNumber} from 'lodash';
import {useMemo} from 'react';
import {addInvitationId, addTripId} from 'src/store/slices/user/userSlice';
import {useGetInvitationDetail} from 'src/api/invitation';
import {API_URL, IS_STAGING} from 'src/utils/axiosConfig';
import ModalJoinTrip from './ModalJoinTrip';
import ModalDisclaimer from '../deprecated/ActivityDetail/ModalDisclaimer';
import {useGetUserTrip} from 'src/api/user';
import ModalInvite from 'src/components/ModalInvite';
import {textFont} from 'src/utils/styles';
import {colors, PAYMENT_AGREEMENT} from 'src/constants';
import {useGetTripAvailableSlot} from 'src/api/users/hooks/useGetTripAvailableSlot';

const renderTourismActivity = (activity, host, index, onPress) => {
  return (
    <View style={styles.activity} key={index}>
      <TouchableOpacity onPress={onPress}>
        <Image source={{uri: activity.photo}} style={styles.activityImage} />
      </TouchableOpacity>
      <View style={styles.activityDetail}>
        <Text style={styles.activityTitle}>{activity.title}</Text>
        <Text style={styles.activityDescription}>{activity.description}</Text>
        {!!host && (
          <>
            <View style={styles.split} />
            <View style={styles.flexRow}>
              <Image
                source={{uri: host?.avatar ? host.avatar : ''}}
                style={styles.avatar}
              />
              <View style={styles.userWrapper}>
                <Text style={styles.textUserActivity}>{host?.name}</Text>
                <Text style={styles.textUserJob}>{host?.job_title}</Text>
              </View>
            </View>
          </>
        )}
      </View>
    </View>
  );
};
const VolunteerActivity = ({activity, host, onPress}) => {
  return (
    <View style={styles.activity}>
      <TouchableOpacity onPress={onPress}>
        <Image source={{uri: activity.photo}} style={styles.activityImage} />
      </TouchableOpacity>
      <View style={styles.activityDetail}>
        <Text style={styles.activityTitle}>{activity.title}</Text>
        <Text style={styles.activityDescription}>{activity.description}</Text>
        {!!host && (
          <>
            <View style={styles.split} />
            <View style={styles.flexRow}>
              <Image
                source={{uri: host?.avatar ? host.avatar : ''}}
                style={styles.avatar}
              />
              <View style={styles.userWrapper}>
                <Text style={styles.textUserActivity}>{host?.name}</Text>
                <Text style={styles.textUserJob}>{host?.job_title}</Text>
              </View>
            </View>
          </>
        )}
      </View>
    </View>
  );
};
const Schedule = ({schedule}) => {
  const {i18n} = useTranslations();
  return (
    <View style={styles.scheduleDay}>
      <View style={styles.schedule}>
        <View style={styles.scheduleIcon} />
        <Text style={styles.textSchedule}>{`${i18n.t('day')} ${
          schedule?.days
        }`}</Text>
      </View>
      {(schedule?.actions || []).map((action, scheduleIndex) => {
        return (
          <View key={scheduleIndex} style={styles.scheduleItem}>
            <View style={styles.schedulePoint} />
            <Text style={styles.textScheduleDetail}>{action}</Text>
          </View>
        );
      })}
    </View>
  );
};

const FORM_TYPE = {
  INIT: 1,
  DISCLAIMER: 2,
  SUCCESS: 3,
  FAILURE: 4,
  INVITE: 5,
  SUPPORT: 6,
};

const TripDetail = () => {
  const navigation = useNavigation();
  const {i18n} = useTranslations();
  const route = useRoute();
  const tripId = route.params?.tripId;
  const invitationId = route.params?.invitationId;
  const paymentStatus = route.params?.paymentStatus;
  const accompanyCount = route.params?.accompanyCount;
  const isFocused = useIsFocused();
  const {alert} = useAlert();
  const insets = useSafeAreaInsets();

  const [viewImage, setViewImage] = useState([]);
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const userData = useSelector(state => state.user.data);
  const invitationData = useSelector(state => state.invitation.data);
  const userTripData = useSelector(state => state.user.trip);
  const isTripInviteDetail = !!tripId;
  const token = useSelector(state => state.auth.token);
  const [openingModalType, setOpeningModalType] = useState(
    paymentStatus === 'success'
      ? FORM_TYPE.SUCCESS
      : paymentStatus === 'failure'
      ? FORM_TYPE.FAILURE
      : null,
  );
  const [accompanyPeople, setAccompanyPeople] = useState(
    toNumber(accompanyCount) || 0,
  );

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.dispatch(
        StackActions.replace(
          isLoggedIn ? routeNames.BottomTab : routeNames.AuthFlow.WelcomeScreen,
        ),
      );
    }
  };

  const {
    runRequest: getTripDetail,
    isLoading: isLoadingGetTrip,
    responseData,
  } = useGetTripDetail({
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'), handleGoBack);
    },
  });
  const {runRequest: getUserTrip} = useGetUserTrip({});
  const screenData = tripId ? responseData?.data : invitationData;

  const handlePayment = () => {
    const params = new URLSearchParams({
      data: JSON.stringify({
        type: 'trip',
        tripId: isTripInviteDetail ? screenData?.id : screenData?.trip_id,
        price:
          get(
            screenData,
            `prices.details[${
              isNaN(+screenData.trip_tier) ? 0 : screenData.trip_tier
            }].price`,
            0,
          ) *
          (1 + accompanyPeople),
        quantity: accompanyPeople,
        processor: ['wechatpay', 'jsapi'],
      }),
      redirects: JSON.stringify({
        success: `${API_URL}/${isTripInviteDetail ? 'trip' : 'invitation'}/${
          isTripInviteDetail ? tripId : invitationId
        }/success/${accompanyPeople}`,
        error: `${API_URL}/${isTripInviteDetail ? 'trip' : 'invitation'}/${
          isTripInviteDetail ? tripId : invitationId
        }/failure/${accompanyPeople}`,
      }),
      auth: token,
    });
    const payLink = '/api/payment/new-order?' + params;
    window.location.href = payLink;
  };

  const {runRequest: getTripAvailableSlot} = useGetTripAvailableSlot({
    successCallback: response => {
      const availableSlot = toNumber(
        get(response, 'data[0].available_slots', 0),
      );
      if (availableSlot <= 0) {
        alert(`${i18n.t('trip_fulfill')}, ${i18n.t('start_new_event')}`, () => {
          navigation.navigate(routeNames.NonAuthFlow.PlanDetail, {
            activityId: screenData?.plan_id,
          });
        });
      } else if (availableSlot < accompanyPeople + 1) {
        alert(`${i18n.t('remove_accompany')} ${availableSlot - 1}`, () => {
          setOpeningModalType(FORM_TYPE.INIT);
        });
      } else {
        handlePayment();
      }
    },
    failureCallback: handlePayment,
  });
  const processPaymentFlow = () => {
    getTripAvailableSlot(
      isTripInviteDetail ? screenData?.id : screenData?.trip_id,
    );
  };

  const {runRequest: getInvitationDetail, isLoading: isLoadingGetInvitation} =
    useGetInvitationDetail({
      failureCallback: () => {
        alert(i18n.t('cannot_found_invitation'), handleGoBack);
      },
    });

  const ref = useRef(null);
  useEffect(() => {
    if (isFocused) {
      setTimeout(() => {
        ref.current?.scrollTo({x: 0, y: 0, animated: false});
      }, 400);
      if (tripId) {
        getTripDetail(tripId);
      } else if (invitationId) {
        getInvitationDetail(invitationId);
      } else {
        alert(i18n.t('something_went_wrong'), handleGoBack);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId, isFocused]);
  useEffect(() => {
    if (userData.id) {
      getUserTrip(userData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.id]);

  const bottomButtonSection = useMemo(() => {
    if (!isLoggedIn) {
      return (
        <Button
          title={`${i18n.t('trip_detail.sign_up')}`}
          buttonStyle={styles.button}
          onClick={() => {
            if (isTripInviteDetail) {
              dispatch(addTripId(tripId));
            } else {
              dispatch(addInvitationId(invitationId));
            }
            navigation.navigate(routeNames.AuthFlow.WelcomeScreen);
          }}
        />
      );
    }
    const isJoined = some(screenData?.members, i => i.id === userData.id);
    const isFull =
      getTripMemberCount(screenData?.members) >=
      get(screenData, 'maximum_member', 0);

    if (isJoined) {
      if (isFull) {
        return (
          <Button
            title={i18n.t('enter_group_chat')}
            buttonStyle={styles.button}
            onClick={() => {
              navigation.navigate(routeNames.MainFlow.ChatScreen, {
                conversationId: screenData.conversation_id,
              });
            }}
          />
        );
      } else {
        return (
          <Button
            buttonStyle={styles.button}
            title={i18n.t('invitation.invite_friends')}
            onClick={() => {
              setOpeningModalType(FORM_TYPE.INVITE);
            }}
          />
        );
      }
    }
    // logged in and !joined
    const isClosed = dayjs() >= dayjs(screenData?.to_date);
    const isUpcoming =
      dayjs() >= dayjs(screenData?.from_date).startOf('day').add(-12, 'hour');
    const isDuplicated = checkDuplicate(
      userTripData,
      new Date(screenData?.from_date),
      new Date(screenData?.to_date),
    );
    if (isFull || isClosed || isUpcoming || isDuplicated) {
      return (
        <Button
          title={`${i18n.t(
            isDuplicated
              ? 'duplicated_status'
              : isFull
              ? 'full_status'
              : isClosed
              ? 'closed_status'
              : 'ongoing_status',
          )}, ${i18n.t('start_new_event')}`}
          buttonStyle={styles.button}
          onClick={() => {
            navigation.navigate(routeNames.NonAuthFlow.PlanDetail, {
              activityId: screenData?.plan_id,
            });
          }}
        />
      );
    }
    return (
      <Button
        title={IS_STAGING ? i18n.t('join_event') : i18n.t('coming_soon')}
        buttonStyle={styles.button}
        disabled={!IS_STAGING}
        onClick={() => {
          if (Platform.OS === 'web' && isWeChat()) {
            setOpeningModalType(FORM_TYPE.INIT);
          } else {
            alert(i18n.t('please_open_wechat_payment'));
          }
        }}
      />
    );
  }, [
    i18n,
    screenData,
    navigation,
    dispatch,
    tripId,
    isLoggedIn,
    userData.id,
    isTripInviteDetail,
    invitationId,
    alert,
    userTripData,
  ]);

  return (
    <>
      <View style={styles.modalContainer}>
        <ScrollView ref={ref} showsVerticalScrollIndicator={false}>
          <Image
            source={{uri: screenData?.background}}
            resizeMode="cover"
            style={styles.imageBackground}
          />
          <LinearGradient
            colors={['rgba(18, 24, 16, 0)', 'rgba(18, 24, 16, 0.5)', '#000']}
            locations={[0.05, 0.1, 0.2]}
            style={[styles.linearGradientMore, {paddingTop: insets.top}]}>
            <View style={styles.dateContent}>
              <View style={styles.dateWrapperMore}>
                <View style={styles.flexRow}>
                  <Image
                    source={images.CalendarWhite}
                    style={styles.calendar}
                  />
                  <Text style={styles.textDate}>
                    {dayjs(screenData?.from_date).format('YYYY-MM-DD')}
                  </Text>
                </View>
                <Text style={styles.textDate}>{' | '}</Text>
                <View style={styles.flexRow}>
                  <Image
                    source={images.CalendarWhite}
                    style={styles.calendar}
                  />
                  <Text style={styles.textDate}>
                    {dayjs(screenData?.to_date).format('YYYY-MM-DD')}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.titleWrapperMore}>
              <Text style={styles.textTitle} numberOfLines={2}>
                {screenData?.heading}
              </Text>
              <TouchableOpacity
                style={styles.avatarWrapperMore}
                onPress={() => {
                  navigation.navigate(routeNames.NonAuthFlow.UserDetail, {
                    userId: screenData?.created_by?.id,
                  });
                }}>
                <Text style={styles.textInviteBy}>
                  {i18n.t('trip_detail.invite_by')}
                </Text>
                {getUserPhoto(screenData?.created_by) ? (
                  <Image
                    source={{
                      uri: getUserPhoto(screenData?.created_by),
                    }}
                    style={styles.avatarMore}
                  />
                ) : (
                  <View style={styles.avatarMore} />
                )}
                <Text style={styles.textInviteBy}>
                  {screenData?.created_by?.name}
                </Text>
              </TouchableOpacity>
              <Text style={styles.textVillageMore}>{screenData?.location}</Text>
            </View>
            <View style={styles.border} />
            <Text style={styles.description}>{screenData?.introduction}</Text>
            <View style={styles.padding32}>
              <Text style={styles.header}>
                {i18n.t('trip_detail.tourism_activity')}
              </Text>
              {(screenData?.tourism_activities || []).map((i, index) => {
                const host = (screenData?.hosts || []).find(
                  item => item.id === i.host,
                );
                return renderTourismActivity(i, host, index, () => {
                  setViewImage([i.photo]);
                });
              })}
            </View>
            <View style={styles.padding32}>
              <Text style={styles.header}>
                {i18n.t('trip_detail.volunteer_activity')}
              </Text>
              {(screenData?.volunteer_activities || []).map((i, index) => {
                const host = (screenData?.hosts || []).find(
                  item => item.id === i.host,
                );
                return (
                  <VolunteerActivity
                    activity={i}
                    host={host}
                    key={index}
                    onPress={() => {
                      setViewImage([i.photo]);
                    }}
                  />
                );
              })}
            </View>
            <View style={styles.padding32}>
              <Text style={styles.header}>
                {i18n.t('trip_detail.schedule')}
              </Text>
              {(screenData?.schedules || []).map((schedule, index) => {
                return <Schedule schedule={schedule} key={index} />;
              })}
            </View>
            {!isEmpty(screenData?.hosts) && (
              <View style={styles.padding32}>
                <Text style={styles.header}>{i18n.t('trip_detail.hosts')}</Text>
                <View style={styles.hostList}>
                  {(screenData?.hosts || []).map((host, index) => {
                    return (
                      <View style={styles.hostItem} key={index}>
                        <View style={styles.flexRow}>
                          <Image
                            source={{uri: host?.avatar ? host.avatar : ''}}
                            style={styles.avatar}
                          />
                          <View style={styles.userWrapper}>
                            <Text style={styles.textUserActivity}>
                              {host.name}
                            </Text>
                            <Text style={styles.textUserJob}>
                              {host.job_title}
                            </Text>
                          </View>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            )}
            {!isEmpty(screenData?.accommodation) && (
              <View style={styles.padding32}>
                <Text style={styles.header}>
                  {i18n.t('invitation.accommodation')}
                </Text>
                <FlatList
                  data={screenData?.accommodation}
                  renderItem={({item}) => {
                    return (
                      <TouchableOpacity
                        style={styles.activityItem}
                        onPress={() => {
                          navigation.navigate(
                            routeNames.NonAuthFlow.StoryDetail,
                            {
                              storyId: item.cuid,
                            },
                          );
                        }}>
                        <Image
                          style={styles.activityImageItem}
                          source={{uri: item.images?.[0]}}
                        />
                        <Text style={styles.activityNameItem} numberOfLines={2}>
                          {item.title}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                  keyExtractor={item => item.cuid}
                  horizontal
                  style={styles.storyWrapper}
                  contentContainerStyle={styles.storyContainer}
                />
              </View>
            )}
            <View style={styles.cost}>
              <Text style={styles.headerAlign}>
                {i18n.t('trip_detail.disclaimers')}
              </Text>
              <View>
                {(screenData?.disclaimers || []).map((disclaimer, index) => {
                  return (
                    <View key={index} style={styles.disclaimerItem}>
                      <View>
                        <Text style={styles.disclaimerTitle}>
                          {('00' + (index + 1)).slice(-2)}
                        </Text>
                      </View>
                      <Text style={styles.disclaimerDetail}>{disclaimer}</Text>
                    </View>
                  );
                })}
              </View>
            </View>
            {screenData?.maximum_member !== 1 && (
              <View style={styles.joined}>
                <Text style={styles.textJoined}>
                  {i18n.t('trip_detail.user_joined')}
                </Text>
                <View style={styles.joinedImage}>
                  <Member
                    creatorId={
                      isTripInviteDetail
                        ? screenData?.created_by?.id
                        : screenData?.trip_created_by
                    }
                    maximumMember={screenData?.maximum_member}
                    members={screenData?.members}
                    invitedList={screenData?.invited_users}
                    containerStyle={styles.members}
                    handleInvite={() => {
                      setOpeningModalType(FORM_TYPE.INVITE);
                    }}
                    shouldShowInvite={isLoggedIn}
                  />
                </View>
              </View>
            )}
            <Text
              onPress={() => {
                setOpeningModalType(FORM_TYPE.SUPPORT);
              }}
              style={[
                textFont(16, '700'),
                {color: colors.primary},
                styles.wechatQR,
              ]}>
              {i18n.t('contact_support')}
            </Text>
          </LinearGradient>
        </ScrollView>
        {screenData?.maximum_member > 1 ? (
          <LinearGradient
            colors={['rgba(0, 0, 0, 0.01)', '#000']}
            locations={[0, 0.35]}
            style={[styles.bottomButtonMore, {paddingBottom: insets.bottom}]}>
            <View style={styles.bottomSection}>{bottomButtonSection}</View>
          </LinearGradient>
        ) : null}

        <TouchableOpacity
          onPress={handleGoBack}
          style={[styles.backButton, {top: insets.top + 30}]}>
          <Image source={images.ArrowLeftWhite} style={styles.backIcon} />
        </TouchableOpacity>
      </View>

      <ModalDisclaimer
        title={i18n.t('payment_agreement')}
        activityData={{disclaimers: [PAYMENT_AGREEMENT]}}
        show={openingModalType === FORM_TYPE.DISCLAIMER}
        onClose={() => setOpeningModalType(FORM_TYPE.INIT)}
        onNext={() => setOpeningModalType(FORM_TYPE.INIT)}
        isHideButton
      />

      <ModalJoinTrip
        data={screenData}
        show={openingModalType === FORM_TYPE.INIT}
        onClose={() => {
          setOpeningModalType(null);
        }}
        onNext={processPaymentFlow}
        nextButtonTitle={`${i18n.t('activity_detail.next')}    ¥${formatPrice(
          get(
            screenData,
            `prices.details[${screenData?.trip_tier || 0}].price`,
            0,
          ) *
            (1 + accompanyPeople),
        )}`}
        accompanyPeople={accompanyPeople}
        setAccompanyPeople={setAccompanyPeople}
        handleAgreement={() => {
          setOpeningModalType(FORM_TYPE.DISCLAIMER);
        }}
      />
      <ModalSuccess
        title={screenData?.heading}
        show={openingModalType === FORM_TYPE.SUCCESS}
        onClose={() => {
          setOpeningModalType(null);
        }}
        onNext={() => {
          setOpeningModalType(null);
          navigation.navigate(routeNames.MainFlow.MyTrip);
        }}
        nextButtonTitle={'go_to_my_trip'}
      />
      <ModalFailure
        title={screenData?.heading}
        show={openingModalType === FORM_TYPE.FAILURE}
        onClose={() => {
          setOpeningModalType(null);
        }}
        onNext={() => {
          setOpeningModalType(null);
          processPaymentFlow();
        }}
      />
      {(isLoadingGetTrip || isLoadingGetInvitation) && <Loading />}
      <ImageView
        visible={!isEmpty(viewImage)}
        setVisible={() => {
          setViewImage([]);
        }}
        imageList={viewImage}
      />
      <ModalInvite
        creatorId={
          isTripInviteDetail
            ? screenData?.created_by?.id
            : screenData?.trip_created_by
        }
        data={screenData}
        modalVisible={openingModalType === FORM_TYPE.INVITE}
        onClose={() => {
          setOpeningModalType(null);
        }}
        tripId={isTripInviteDetail ? screenData?.id : screenData?.trip_id}
      />
      <ShareCode
        visible={openingModalType === FORM_TYPE.SUPPORT}
        onClose={() => {
          setOpeningModalType(null);
        }}
      />
    </>
  );
};

export default TripDetail;
