import axios from 'axios';

export const API_URL =
  process.env.REACT_APP_API_URL || 'https://staging.2myvillage.com';

export const IS_STAGING = API_URL.includes('staging');

export const axiosBaseConfig = () => {
  axios.defaults.baseURL = API_URL + '/api';
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
};

export const accessTokenIsPresent = () => {
  return !!axios?.defaults?.headers?.common?.Authorization;
};

export const axiosSetAuthToken = token => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const axiosResetAuthToken = () => {
  delete axios.defaults.headers.common.Authorization;
};
