import {endsWith, startsWith} from 'lodash';
import dayjs from 'dayjs';
import {logout} from 'src/api/auth';

let lastLogoutTrigger = null;

export default storeAPI => next => action => {
  if (
    endsWith(action?.type, '/rejected') &&
    !startsWith(action?.type, 'auth/logout')
  ) {
    if (
      action?.payload?.message === 'Invalid token' ||
      action?.payload?.message === 'Token expired'
    ) {
      if (
        !lastLogoutTrigger ||
        Math.abs(lastLogoutTrigger.diff(dayjs(), 'second')) > 2
      ) {
        lastLogoutTrigger = dayjs();
        storeAPI.dispatch(logout({}));
      }
    }
  }
  return next(action);
};
