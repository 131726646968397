import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  FlatList,
  Image,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import styles from './styles';
import {HomeHeader, Loading, Pill} from 'src/components';
import {useTranslations} from 'src/hooks';
import {getUserPhoto, getUserAge} from 'src/utils/common';
import {useGetUserFollower} from 'src/api/users/hooks/useGetUserFollower';
import {useGetUserFollowing} from 'src/api/users/hooks/useGetUserFollowing';
import {useNavigation, useRoute} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import {includes, isEmpty, map} from 'lodash';
import {USER_DATING} from 'src/constants';

const FOLLOW_TYPE = {
  FOLLOWING: 'following',
  FOLLOWER: 'follower',
};

const FollowStatus = () => {
  const {i18n} = useTranslations();
  const route = useRoute();
  const userId = route.params?.userId;
  const [followType, setFollowType] = useState(FOLLOW_TYPE.FOLLOWING);
  const navigation = useNavigation();
  const {
    runRequest: getUserFollower,
    responseData: follower,
    isLoading: loadingGetFollower,
  } = useGetUserFollower({});
  const {
    runRequest: getUserFollowing,
    responseData: following,
    isLoading: loadingGetFollowing,
  } = useGetUserFollowing({});
  useEffect(() => {
    getUserFollower(userId);
    getUserFollowing(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const followerData = follower?.data || [];
  const followingData = following?.data || [];

  const renderUser = ({item}) => {
    const hobby = [
      `${item.gender === 'male' ? '♂' : '♀'} ${getUserAge(item)} ${i18n.t(
        'invitation.age',
      )}`,
      ...(item?.job ? [item?.job] : []),
      ...(item?.industry ? [item?.industry] : []),
      ...(item?.lifestyle || []),
      ...(isEmpty(USER_DATING.filter(i => includes(item?.dating_standard, i)))
        ? []
        : [
            map(
              USER_DATING.filter(i => includes(item?.dating_standard, i)),
              i18n.t,
            ).join(' / '),
          ]),
    ];

    const lengthPil = hobby.length;
    return (
      <TouchableOpacity
        style={styles.user}
        onPress={() => {
          navigation.navigate(routeNames.NonAuthFlow.UserDetail, {
            userId: item.id,
          });
        }}>
        <View style={styles.username}>
          <Image style={styles.avatar} source={{uri: getUserPhoto(item)}} />
          <Text style={styles.textName}>{item.name}</Text>
        </View>
        <View style={styles.pillWrapper}>
          {hobby.slice(0, 4).map((data, index) => {
            return <Pill content={data} key={index} />;
          })}
          {lengthPil > 4 && (
            <View style={styles.morePill}>
              <Text>{`+${lengthPil - 4}`}</Text>
            </View>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  const isLoading =
    followType === FOLLOW_TYPE.FOLLOWER
      ? loadingGetFollower
      : loadingGetFollowing;

  return (
    <>
      <SafeAreaView style={styles.container}>
        <HomeHeader withBackButton />
        <View style={styles.tabWrapper}>
          <TouchableOpacity
            onPress={() => {
              setFollowType(FOLLOW_TYPE.FOLLOWING);
            }}
            style={[styles.tabItem, styles.borderRight]}>
            <Text
              style={
                followType === FOLLOW_TYPE.FOLLOWING
                  ? styles.textHeaderSelected
                  : styles.textHeader
              }>
              {i18n.t('following')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setFollowType(FOLLOW_TYPE.FOLLOWER);
            }}
            style={styles.tabItem}>
            <Text
              style={
                followType === FOLLOW_TYPE.FOLLOWER
                  ? styles.textHeaderSelected
                  : styles.textHeader
              }>
              {i18n.t('follower')}
            </Text>
          </TouchableOpacity>
        </View>
        <FlatList
          style={styles.listUser}
          showsVerticalScrollIndicator={false}
          data={
            followType === FOLLOW_TYPE.FOLLOWER ? followerData : followingData
          }
          renderItem={renderUser}
          keyExtractor={item => item.id}
        />
      </SafeAreaView>
      {isLoading && <Loading />}
    </>
  );
};

export default FollowStatus;
