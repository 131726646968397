import {createSlice} from '@reduxjs/toolkit';
import {logout} from 'src/api/auth';
import {getHomeDataRequest} from 'src/api/home';

const initialState = {
  isLoading: false,
  error: false,
  data: [],
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getHomeDataRequest.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getHomeDataRequest.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
    builder.addCase(getHomeDataRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.data = action.payload.data;
    });

    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

const {reducer: homeReducer} = homeSlice;

export default homeReducer;
