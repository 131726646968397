import React from 'react';
import {TouchableOpacity, Platform, ScrollView} from 'react-native';
import {View, Text, TextInput} from 'react-native';
import {clone} from 'lodash';

import styles from './styles';
import PricesRow from './PricesRow';

const RNKeyboardScrollView = Platform.select({
  native: () => require('react-native-keyboard-aware-scroll-view'),
  default: () => ({KeyboardAwareScrollView: ScrollView}),
})();

//prices datatype {personsCount:number,price:number,currency:USD or Yuan}
const EditPrice = ({
  minPerson,
  maxPerson,
  setMinPerson,
  setMaxPerson,
  prices,
  setPrices,
}) => {
  const onChangePrice = (newPrice, index) => {
    setPrices(oldPrices => {
      const cloneArr = clone(oldPrices);
      cloneArr[index].price = newPrice;
      return cloneArr;
    });
  };
  const onChangePerson = (newNoOfPerson, index) => {
    setPrices(oldPrices => {
      const cloneArr = clone(oldPrices);
      cloneArr[index].personsCount = newNoOfPerson;
      return cloneArr;
    });
  };
  const addMorePerson = () => {
    setPrices(oldPrices => {
      const cloneArr = clone(oldPrices);
      cloneArr.push({personsCount: '', price: ''});
      return cloneArr;
    });
  };
  return (
    <RNKeyboardScrollView.KeyboardAwareScrollView
      style={styles.priceContainer}
      showsVerticalScrollIndicator={false}>
      <View style={styles.personRow}>
        <View style={styles.personColumn}>
          <Text style={styles.personLabel}>Minimum person</Text>
          <TextInput
            style={styles.priceInput}
            keyboardType="decimal-pad"
            value={minPerson ? String(minPerson) : ''}
            onChangeText={setMinPerson}
          />
        </View>
        <View style={styles.personColumn}>
          <Text style={styles.personLabel}>Maximum person</Text>
          <TextInput
            style={styles.priceInput}
            keyboardType="decimal-pad"
            value={maxPerson ? String(maxPerson) : ''}
            onChangeText={setMaxPerson}
          />
        </View>
      </View>
      <View style={styles.divider} />
      {prices.map((p, index) => (
        <PricesRow
          price={String(p.price)}
          person={String(p.personsCount)}
          onChangePrice={newPrice => onChangePrice(newPrice, index)}
          onChangePerson={newPerson => onChangePerson(newPerson, index)}
        />
      ))}

      <View style={styles.divider} />
      <TouchableOpacity style={styles.addMoreWrapper} onPress={addMorePerson}>
        <Text style={styles.addMore}>+ Add more prices</Text>
      </TouchableOpacity>
    </RNKeyboardScrollView.KeyboardAwareScrollView>
  );
};

export default EditPrice;
