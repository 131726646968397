import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getAllTripRequest} from '../requests';

export const useGetAllTrip = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(state => state.metadata.isLoadingGetAllTrip);
  const [responseData, setResponseData] = useState(null);
  const dispatch = useDispatch();

  const runRequest = useCallback(() => {
    dispatch(
      getAllTripRequest({
        failureCallback: err => {
          failureCallback && failureCallback(err);
        },
        successCallback: data => {
          setResponseData(data);
          successCallback && successCallback(data);
        },
      }),
    );
  }, [failureCallback, successCallback, dispatch]);

  const returnObject = {
    isLoading,
    runRequest,
    responseData,
  };

  return returnObject;
};
