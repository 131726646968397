import React, {useState} from 'react';
import {
  Text,
  SafeAreaView,
  View,
  Image,
  Platform,
  ScrollView,
  KeyboardAvoidingView,
  TextInput,
  TouchableOpacity,
} from 'react-native';

import {useAlert, useTranslations} from 'src/hooks';
import images from 'src/assets/images';
import {useUpload} from 'src/api/upload/hooks/useUpload';

import Header from './components/Header';
import SearchDropdown from 'src/components/SearchDropdown';
import ImageSelector from 'src/components/ImageSelector';
import {ImageRatio, Loading, StoryPreviewModal} from 'src/components';
import SearchVillage from 'src/components/SearchVillage';
import styles from './styles';
import AddSection from './components/AddSection';
import {get, isArray, isEmpty} from 'lodash';
import {useCreateStory, useUpdateStory} from 'src/api/story';
import CreateStorySuccess from './components/CreateStorySuccess';
import {useNavigation} from '@react-navigation/native';
import {useDispatch, useSelector} from 'react-redux';
import {resetStory, saveStory} from 'src/store/slices/story/storySlice';
import {colors, USER_ROLE} from 'src/constants';
import {textFont} from 'src/utils/styles';

const Type = {
  Story: 'invitation.story',
  Accommodation: 'invitation.accommodation',
};

const AddStory = () => {
  const {i18n} = useTranslations();
  const {alert} = useAlert();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user.data);
  const storyData = useSelector(state => state.story.storyData);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showAddSection, setShowAddSection] = useState(false);
  const [currentEditContentIndex, setCurrentEditContentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [storyType, setStoryType] = useState(storyData.type || Type.Story);

  const storyContent = storyData?.sections || [];

  const [storyText, setStoryText] = useState(
    get(storyContent, '[0].content', ''),
  );
  const title = storyData?.title || '';
  const bgImage = storyData?.background;
  const location = storyData?.location || {
    name: i18n.t('addStory.chooseVillage'),
  };

  const navigation = useNavigation();
  const {runRequest: uploadImageRequest} = useUpload();

  const {runRequest: updateStoryRequest} = useUpdateStory({
    failureCallback: () => {
      alert(i18n.t('addStory.errorTrip'));
      setLoading(false);
    },
    successCallback: data => {
      setLoading(false);
      setShowSuccess(true);
    },
  });

  const {runRequest: createStoryRequest} = useCreateStory({
    failureCallback: () => {
      alert(i18n.t('addStory.errorTrip'));
      setLoading(false);
    },
    successCallback: async storyResponse => {
      const storyId = storyResponse?.data?.[0]?.id;
      if (!storyId) {
        alert(i18n.t('addStory.errorTrip'));
        setLoading(false);
        return;
      }
      const uploadImagePromise = [
        new Promise((resolve, reject) => {
          onUploadContentImage(
            bgImage,
            storyId,
            response => {
              resolve(response.data.Location);
            },
            reject,
          );
        }),
      ];
      storyContent.forEach(section => {
        uploadImagePromise.push(
          new Promise((resolve, reject) => {
            if (section.photo) {
              if (typeof section.photo === 'string') {
                onUploadContentImage(
                  section.photo,
                  storyId,
                  response => {
                    resolve({
                      content: section.content,
                      photo: response.data.Location,
                    });
                  },
                  reject,
                );
              } else {
                Promise.all(
                  section.photo.map(item => {
                    return new Promise((itemResolve, itemReject) => {
                      onUploadContentImage(
                        item,
                        storyId,
                        response => {
                          itemResolve(response.data.Location);
                        },
                        itemReject,
                      );
                    });
                  }),
                )
                  .then(data =>
                    resolve({
                      content: section.content,
                      photo: data,
                    }),
                  )
                  .catch(reject);
              }
            } else {
              resolve(section);
            }
          }),
        );
      });
      const [background, ...sections] = await Promise.all(uploadImagePromise);
      updateStoryRequest(storyId, {
        title,
        background,
        sections,
        ...(location.id && {villageId: location.id}),
        ...(storyType === Type.Accommodation && {story_type: 'accommodation'}),
      });
    },
  });

  const onUploadContentImage = (image, storyId, onSuccess, onFailure) => {
    uploadImageRequest({
      failureCallback: onFailure,
      successCallback: onSuccess,
      params: {file: image, tags: ['story', `${storyId}`]},
    });
  };

  const thumbnail = (
    <View style={styles.thumb}>
      <Image source={images.Gallery} style={styles.gallery} />
      <Text style={styles.upload}>{i18n.t('addStory.upload')}</Text>
    </View>
  );
  const thumbnailIcon = (
    <View style={styles.thumbIcon}>
      <Image source={images.Gallery} style={styles.thumbGallery} />
      <Text style={styles.upload}>{i18n.t('addStory.upload')}</Text>
    </View>
  );

  const handlePublish = async () => {
    setShowPreviewModal(false);
    setLoading(true);
    try {
      createStoryRequest({
        title,
        ...(location.id && {villageId: location.id}),
        ...(storyType === Type.Accommodation && {story_type: 'accommodation'}),
      });
    } catch (error) {
      alert(i18n.t('addStory.errorImage'));
      setLoading(false);
    }
  };

  const renderNormalInput = () => {
    return (
      <View>
        <View style={styles.inputImageWrapper}>
          {[...get(storyContent, '[0].photo', []), ''].map((item, index) => (
            <View key={index}>
              <ImageSelector
                containerStyle={styles.inputImage}
                thumbnail={
                  <View style={styles.inputImageThump}>
                    <Text
                      style={[textFont(40, '600'), {color: colors.neutral4}]}>
                      +
                    </Text>
                  </View>
                }
                multiple
                image={item}
                onSelectImage={image => {
                  const newImage = [...get(storyContent, '[0].photo', [])];
                  if (isArray(image)) {
                    newImage.splice(index, 1, ...image);
                  } else {
                    newImage.splice(index, 1, image);
                  }
                  dispatch(
                    saveStory({
                      ...(storyData || {}),
                      sections: [
                        {...get(storyContent, '[0]', {}), photo: newImage},
                      ],
                    }),
                  );
                }}
              />
              {!!item && (
                <TouchableOpacity
                  style={styles.inputClearButton}
                  onPress={() => {
                    const newImage = [...get(storyContent, '[0].photo', [])];
                    newImage.splice(index, 1);
                    dispatch(
                      saveStory({
                        ...(storyData || {}),
                        sections: [
                          {...get(storyContent, '[0]', {}), photo: newImage},
                        ],
                      }),
                    );
                  }}>
                  <Image source={images.Close} style={styles.inputClear} />
                </TouchableOpacity>
              )}
            </View>
          ))}
        </View>
        <TextInput
          style={styles.inputSection}
          placeholder={i18n.t('addStory.paragraph')}
          multiline={true}
          value={storyText}
          onChangeText={c => {
            setStoryText(c);
            dispatch(
              saveStory({
                ...(storyData || {}),
                sections: [{...get(storyContent, '[0]', {}), content: c}],
              }),
            );
          }}
          size={'large'}
          autoCorrect={false}
        />
      </View>
    );
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <KeyboardAvoidingView
          behavior={'padding'}
          enabled={Platform.OS === 'ios'}
          style={styles.container}>
          <Header
            onGoBack={() => {
              setShowSuccess(false);
            }}
            onPreview={() => setShowPreviewModal(true)}
            disabled={!title || !bgImage || isEmpty(storyContent)}
          />
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={styles.scroll}>
            <Text style={styles.bg}>{i18n.t('addStory.addBackground')}</Text>
            <View style={styles.pickerWrapper}>
              <ImageSelector
                containerStyle={styles.picker}
                thumbnail={thumbnail}
                image={bgImage}
                onSelectImage={image => {
                  dispatch(
                    saveStory({...(storyData || {}), background: image}),
                  );
                }}
                thumbnailIcon={thumbnailIcon}
                thumbnailStyle={styles.thumbnailStyle}
              />
            </View>
            <Text style={styles.title}>{i18n.t('addStory.title')}</Text>
            <TextInput
              value={title}
              onChangeText={t => {
                dispatch(saveStory({...(storyData || {}), title: t}));
              }}
              style={styles.inputStyle}
              placeholder={i18n.t('addStory.titlePlaceholder')}
            />
            <Text style={styles.title}>{i18n.t('addStory.village')}</Text>
            <SearchVillage
              value={location}
              onChangeValue={val => {
                dispatch(saveStory({...(storyData || {}), location: val}));
              }}
              placeholder={i18n.t('addStory.villagePlaceholder')}
            />
            {userData.role === USER_ROLE.EDITOR ? (
              <>
                <Text style={styles.title}>{i18n.t('type')}</Text>
                <SearchDropdown
                  value={i18n.t(storyType)}
                  onChangeValue={value => {
                    if (value === i18n.t(Type.Story)) {
                      setStoryType(Type.Story);
                      dispatch(
                        saveStory({...(storyData || {}), type: Type.Story}),
                      );
                    } else {
                      setStoryType(Type.Accommodation);
                      dispatch(
                        saveStory({
                          ...(storyData || {}),
                          type: Type.Accommodation,
                        }),
                      );
                    }
                  }}
                  data={[i18n.t(Type.Story), i18n.t(Type.Accommodation)]}
                  placeholder={i18n.t('profile.college.placeholder')}
                />
                <View style={styles.flexRow}>
                  <Text style={styles.title}>
                    {i18n.t('addStory.storyContent')}
                  </Text>
                  <Text
                    style={styles.textAddSection}
                    onPress={() => {
                      setShowAddSection(true);
                      setCurrentEditContentIndex(storyContent.length);
                    }}>{`+ ${i18n.t('addStory.addSection')}`}</Text>
                </View>
                {storyContent.map((story, index) => {
                  return (
                    <View style={styles.storyLine} key={index}>
                      <View style={styles.storyItem}>
                        <Text style={styles.storyText} numberOfLines={6}>
                          {story.content}
                        </Text>
                        {story.photo && (
                          <View style={styles.imageWrapper}>
                            {typeof story.photo === 'string' ? (
                              <ImageRatio
                                style={styles.storyImage}
                                source={{uri: story.photo}}
                                imageWidth={120}
                              />
                            ) : (
                              story.photo.map((item, key) => (
                                <Image
                                  key={key}
                                  style={styles.imageItem}
                                  source={{uri: item}}
                                />
                              ))
                            )}
                          </View>
                        )}
                      </View>
                      <TouchableOpacity
                        onPress={() => {
                          const newContent = [...storyContent];
                          newContent.splice(index, 1);
                          dispatch(
                            saveStory({
                              ...(storyData || {}),
                              sections: newContent,
                            }),
                          );
                        }}>
                        <Image source={images.Close} style={styles.clear} />
                      </TouchableOpacity>
                    </View>
                  );
                })}
              </>
            ) : (
              renderNormalInput()
            )}
          </ScrollView>
        </KeyboardAvoidingView>
        <StoryPreviewModal
          isVisible={showPreviewModal}
          setVisible={setShowPreviewModal}
          storyData={{
            title,
            background: bgImage,
            location,
            sections: storyContent,
          }}
          handlePublish={handlePublish}
        />
        <AddSection
          isVisible={showAddSection}
          setVisible={setShowAddSection}
          handleAddSection={content => {
            const newContent = [...storyContent];
            newContent.splice(currentEditContentIndex, 1, content);
            dispatch(saveStory({...(storyData || {}), sections: newContent}));
          }}
        />
        <CreateStorySuccess
          show={showSuccess}
          onClose={() => {
            setShowSuccess(false);
            navigation.goBack();
            dispatch(resetStory());
          }}
        />
      </SafeAreaView>
      {loading && <Loading />}
    </>
  );
};

export default AddStory;
