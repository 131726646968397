import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
  },
  container: {
    flex: 1,
    backgroundColor: colors.neutral9,
    paddingVertical: calculateWidth(16),
  },
  close: {
    width: 20,
    height: 20,
    tintColor: colors.neutral1,
    marginRight: calculateWidth(18),
  },
  header: {
    paddingHorizontal: calculateWidth(16),
    paddingVertical: calculateWidth(24),
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: colors.neutral7,
    borderBottomWidth: 1,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleText: {
    ...textFont(18, '600'),
  },
  done: {
    ...textFont(16, '600'),
  },
});
