import {View, Text, Modal, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import styles from './styles';
import QRCode from 'react-qr-code';
import images from 'src/assets/images';

const ShareCode = ({onClose, value, visible}) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}>
      <View style={styles.container}>
        <View style={styles.content}>
          {value ? (
            <QRCode value={value} />
          ) : (
            <Image source={images.ContactSupport} style={styles.images} />
          )}
          <TouchableOpacity style={styles.buttonClose} onPress={onClose}>
            <Text style={styles.textClose}>+</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ShareCode;
