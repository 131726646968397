import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    width: calculateWidth(164),
    height: calculateWidth(260),
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: calculateWidth(10),
    marginBottom: calculateWidth(16),
  },
  invitation: {
    flex: 1,
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(8),
    padding: calculateWidth(8),
    shadowColor: colors.neutral5,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,

    elevation: 7,
  },
  profile: {
    padding: calculateWidth(12),
    paddingTop: calculateWidth(8),
  },
  information: {
    marginTop: calculateWidth(6),
    flexDirection: 'row',
    alignItems: 'center',
  },
  textInfo: {
    ...textFont(9, '400'),
    color: colors.neutral4,
  },

  textAge: {
    ...textFont(10, '400'),
    color: colors.neutral4,
    marginLeft: calculateWidth(6),
    lineHeight: calculateWidth(10),
  },

  userWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: calculateWidth(30),
    height: calculateWidth(30),
    borderRadius: 50,
    backgroundColor: colors.neutral6,
  },
  textName: {
    marginTop: calculateWidth(2),
    marginLeft: calculateWidth(6),
    ...textFont(11, '400'),
    color: colors.neutral1,
    flex: 1,
  },

  image: {
    width: calculateWidth(146),
    flex: 1,
    borderRadius: calculateWidth(9),
    backgroundColor: 'gray',
  },
  textVillage: {
    ...textFont(12, '400'),
    color: colors.black,
    marginTop: calculateWidth(4),
    textTransform: 'uppercase',
    marginRight: calculateWidth(16),
    lineHeight: calculateWidth(16),
    height: calculateWidth(32),
  },

  textDate: {
    ...textFont(10, '400'),
    color: colors.neutral4,
    flex: 1,
    textAlign: 'right',
  },

  location: {
    width: calculateWidth(13),
    height: calculateWidth(13),
    tintColor: colors.neutral4,
  },
  village: {
    marginTop: calculateWidth(4),
    flexDirection: 'row',
    alignItems: 'center',
  },
  invite: {
    alignItems: 'center',
  },
  person: {
    width: calculateWidth(13),
    height: calculateWidth(14),
    marginBottom: calculateWidth(2),
    tintColor: '#8dbf82',
  },
  info: {
    flex: 1,
    marginRight: calculateWidth(5),
  },
  name: {
    flex: 1,
  },
  inviteText: {
    ...textFont(8, '400'),
    color: '#8dbf82',
  },
});

export default styles;
