import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';

const styles = StyleSheet.create({
  container: {flex: 1, backgroundColor: colors.neutral9},
  promotion: {
    width: calculateWidth(343),
    height: calculateWidth(138),
    backgroundColor: 'lightgray',
    alignSelf: 'center',
    borderRadius: calculateWidth(12),
    marginVertical: calculateWidth(16),
    padding: calculateWidth(20),
    justifyContent: 'space-between',
  },
  imageStyle: {
    borderRadius: calculateWidth(12),
  },
  dotActiveStyle: {
    backgroundColor: colors.secondary1,
    width: calculateWidth(24),
    height: calculateWidth(4),
    borderRadius: calculateWidth(4),
    marginHorizontal: calculateWidth(4),
  },

  dotsNormalStyle: {
    backgroundColor: colors.neutral9,
    width: calculateWidth(12),
    height: calculateWidth(4),
    borderRadius: calculateWidth(4),
    marginHorizontal: calculateWidth(4),
  },
  innerContainerStyle: {
    height: calculateWidth(180),
  },
  slideContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  columnWrapper: {},
  homeWrapper: {
    paddingHorizontal: calculateWidth(16),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default styles;
