import {
  View,
  Text,
  Image,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import React, {useState} from 'react';
import {getUserAge, getUserPhoto} from 'src/utils/common';
import {useTranslations} from 'src/hooks';
import Pill from '../Pill';

import styles from './styles';
import {filter, includes, isEmpty, map, omit} from 'lodash';
import images from 'src/assets/images';
import {useSelector} from 'react-redux';
import {colors, USER_DATING, USER_PHOTO_KEY} from 'src/constants';
import {useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import ImageView from '../ImageView';

const UserPreview = ({user, shouldGoToStoryDetail = true}) => {
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const [visible, setVisible] = useState(false);
  const isLoading = useSelector(state => state.metadata.isLoadingGetStory);
  const hobby = [
    `${user.gender === 'male' ? '♂' : '♀'} ${getUserAge(user)} ${i18n.t(
      'invitation.age',
    )}`,
    ...(user?.job ? [user?.job] : []),
    ...(user?.industry ? [user?.industry] : []),
    ...(user?.lifestyle || []),
    ...(isEmpty(USER_DATING.filter(i => includes(user?.dating_standard, i)))
      ? []
      : [
          map(
            USER_DATING.filter(i => includes(user?.dating_standard, i)),
            i18n.t,
          ).join(' / '),
        ]),
  ];
  const avatar = getUserPhoto(user);
  const userPhotoList = filter(
    Object.values(omit(user.photos, USER_PHOTO_KEY.PORTRAIT)),
    i => !!i,
  );
  const photoList = userPhotoList.concat(['', '', '', '', '']).slice(0, 5);

  const userStory = useSelector(state => state.metadata.story);

  const renderItem = ({item, index}) => {
    return (
      <TouchableOpacity
        key={index}
        style={styles.container}
        onPress={() => {
          shouldGoToStoryDetail &&
            navigation.navigate(routeNames.NonAuthFlow.StoryDetail, {
              storyId: item.cuid,
            });
        }}>
        <Image source={{uri: item.background}} style={styles.imageStory} />
        <Text style={styles.des} numberOfLines={2}>
          {item.title}
        </Text>
        <View style={styles.row}>
          <Image
            source={{uri: getUserPhoto(item.publisher)}}
            style={styles.avatar}
          />
          <Text style={styles.name} numberOfLines={2}>
            {item.publisher.name}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.card}>
      {avatar ? (
        <Image style={styles.image} source={{uri: avatar}} />
      ) : (
        <View style={styles.image} />
      )}
      <View style={styles.profile}>
        <Text style={styles.textName}>{user.name}</Text>
        <Text style={styles.textFollow}>
          {`${user?.followers?.length || 0} ${i18n.t('follower')}    `}
          <Image source={images.Circle} style={styles.circle} />
          {`    ${user?.following?.length || 0} ${i18n.t('following')}`}
        </Text>
        <View style={styles.tagWrapper}>
          {hobby.map((data, index) => {
            return <Pill content={data} key={index} />;
          })}
        </View>
        <Text style={styles.textBio}>{user.bio}</Text>
        {!isEmpty(userPhotoList) && (
          <TouchableOpacity
            style={styles.imageWrapper}
            onPress={() => {
              setVisible(true);
            }}>
            {photoList.map((photo, index) => {
              if (photo) {
                return (
                  <Image
                    key={index}
                    source={{uri: photo}}
                    style={styles.publishImage}
                  />
                );
              } else {
                return <View key={index} style={styles.publishImage} />;
              }
            })}
          </TouchableOpacity>
        )}
        {isLoading ? (
          <View style={styles.loading}>
            <ActivityIndicator color={colors.primary} />
          </View>
        ) : isEmpty(userStory) ? null : (
          <FlatList
            data={userStory}
            renderItem={renderItem}
            keyExtractor={item => item.id}
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={styles.storyWrapper}
          />
        )}
      </View>
      <ImageView
        visible={visible}
        setVisible={setVisible}
        imageList={userPhotoList}
      />
    </View>
  );
};

export default UserPreview;
