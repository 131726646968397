import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerUrl} from '../../endpoints';

export const registerRequest = createAsyncThunk(
  'auth/register',
  async ({params, successCallback, failureCallback}, {rejectWithValue}) => {
    try {
      const response = await axios.request({
        method: 'POST',
        url: registerUrl,
        data: {...params},
      });
      successCallback?.(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback?.(err);
        return rejectWithValue(err.response.data.errors);
      }
    }
  },
);
