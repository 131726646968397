import {View, Text, Image, TouchableOpacity} from 'react-native';
import React from 'react';

import styles from './styles';
import images from 'src/assets/images';
import {useTranslations} from 'src/hooks';

const Header = ({
  title,
  withBackButton,
  onPressBackButton,
  skipable,
  onSkip,
  rightContent,
  isDarkHeader,
}) => {
  const {i18n} = useTranslations();
  const renderRightContent = () => {
    if (skipable) {
      return (
        <TouchableOpacity style={styles.leftIconWrapper} onPress={onSkip}>
          <Text style={styles.skip}>{i18n.t('profile.skip')}</Text>
        </TouchableOpacity>
      );
    } else if (rightContent) {
      return rightContent();
    } else {
      return <View style={styles.rightIconWrapper} />;
    }
  };
  return (
    <View style={styles.container}>
      {withBackButton ? (
        <TouchableOpacity
          style={styles.leftIconWrapper}
          onPress={onPressBackButton}>
          <Image
            source={isDarkHeader ? images.ArrowLeftWhite : images.ArrowLeft}
            style={styles.leftIcon}
            resizeMode="contain"
          />
        </TouchableOpacity>
      ) : (
        <View style={styles.leftIconWrapper} />
      )}
      <Text style={[styles.textTitle, isDarkHeader && styles.titleDark]}>
        {title}
      </Text>

      {renderRightContent()}
    </View>
  );
};

export default Header;
