import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  bin: {
    width: 20,
    height: 20,
    tintColor: colors.neutral1,
  },
  thumb: {
    width: '100%',
    height: 581,
  },
  contentContainer: {
    marginHorizontal: calculateWidth(32),
  },
  scroll: {
    marginBottom: 32,
  },
  name: {
    ...textFont(32, '600'),
    marginTop: calculateWidth(32),
    marginBottom: calculateWidth(14),
  },
  locationWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  locationImg: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  location: {
    ...textFont(16, '400'),
    color: colors.neutral1,
  },
  heading: {
    ...textFont(22, '600'),
    marginBottom: calculateWidth(16),
    marginTop: calculateWidth(32),
  },
  paragraph: {
    ...textFont(18, '400'),
    color: colors.neutral4,
  },
  subHeadingWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  dot: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  goal: {
    ...textFont(18, '400'),
  },
  line: {
    marginBottom: 12,
  },
  card: {
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(12),
    padding: calculateWidth(24),
    shadowColor: colors.neutral1,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    marginTop: calculateWidth(24),
    elevation: 5,
  },
  num: {
    ...textFont(22, '600'),
    marginBottom: calculateWidth(12),
  },
  numWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  user: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  number: {
    ...textFont(18, '400'),
    color: colors.neutral4,
  },
  divider: {
    marginVertical: calculateWidth(20),
    height: 1,
    backgroundColor: colors.neutral7,
  },
  personWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  p1: {
    ...textFont(16, '400'),
    color: colors.neutral4,
  },
  p2: {
    ...textFont(16, '400'),
    marginBottom: calculateWidth(8),
  },
});
