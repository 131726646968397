import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(18, 24, 16, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    width: calculateWidth(375),
    alignSelf: 'center',
  },
  popup: {
    width: calculateWidth(335),
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(24),
    padding: calculateWidth(36),
  },
  buttonClose: {
    width: calculateWidth(36),
    height: calculateWidth(36),
    backgroundColor: colors.neutral8,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 12,
    top: calculateWidth(-18),
  },
  textClose: {
    fontSize: calculateWidth(25),
    marginBottom: calculateWidth(2),
    marginLeft: calculateWidth(3),
    color: colors.neutral1,
    transform: [{rotate: '45deg'}],
  },

  headerAlign: {
    ...textFont(32, '600'),
    color: colors.neutral1,
    textAlign: 'center',
    marginBottom: calculateWidth(12),
  },
  textDetail: {
    ...textFont(16, '400'),
    color: colors.neutral1,
    textAlign: 'center',
    marginBottom: calculateWidth(40),
  },

  bottomButton: {
    marginTop: calculateWidth(32),
  },

  publicRadio: {
    marginTop: calculateWidth(16),
    alignItems: 'center',
    flexDirection: 'row',
  },
  people: {
    flex: 1,
    marginRight: calculateWidth(4),
  },
  buttonCount: {
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: calculateWidth(16),
    height: calculateWidth(16),
    borderRadius: 20,
  },
  accompany: {
    marginHorizontal: calculateWidth(8),
  },

  confirm: {
    marginHorizontal: calculateWidth(-10),
    marginTop: calculateWidth(16),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
  },
  plusText: {
    lineHeight: 0,
  },
});

export default styles;
