import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  container: {
    marginBottom: calculateWidth(16),
    flexDirection: 'row',
  },
  avatar: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    borderRadius: 100,
    backgroundColor: colors.neutral6,
  },
  flex1: {
    flex: 1,
  },
  textName: {
    marginLeft: calculateWidth(16),
    ...textFont(14, '600'),
    color: colors.neutral4,
  },
  textContent: {
    marginLeft: calculateWidth(16),
    marginTop: 2,
    ...textFont(14, '600'),
    color: colors.neutral1,
  },
  textTime: {
    ...textFont(12, '400'),
    color: colors.neutral5,
  },
  reply: {
    ...textFont(12, '400'),
    marginLeft: calculateWidth(8),
    color: colors.neutral4,
  },
  replyWrapper: {
    marginLeft: calculateWidth(16),
    marginTop: 2,
    flexDirection: 'row',
  },
  replyComment: {
    paddingTop: calculateWidth(16),
    paddingLeft: calculateWidth(16),
    marginBottom: calculateWidth(-16),
  },
});
