import {createSlice} from '@reduxjs/toolkit';
import {logout} from 'src/api/auth';
import {getVillageListRequest, getVillageDetailRequest} from 'src/api/village';

const initialState = {
  isLoadingGetList: false,
  errorGetList: false,
  listVillage: [],

  isLoadingGetDetail: false,
  errorGetDetail: false,
};

export const invitationSlice = createSlice({
  name: 'invitation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getVillageListRequest.pending, state => {
      state.isLoadingGetList = true;
      state.errorGetList = false;
    });
    builder.addCase(getVillageListRequest.rejected, state => {
      state.isLoadingGetList = false;
      state.errorGetList = true;
    });
    builder.addCase(getVillageListRequest.fulfilled, (state, action) => {
      state.isLoadingGetList = false;
      state.errorGetList = false;
      state.listVillage = action.payload.data;
    });

    builder.addCase(getVillageDetailRequest.pending, state => {
      state.isLoadingGetDetail = true;
      state.errorGetDetail = false;
    });
    builder.addCase(getVillageDetailRequest.rejected, state => {
      state.isLoadingGetDetail = false;
      state.errorGetDetail = true;
    });
    builder.addCase(getVillageDetailRequest.fulfilled, state => {
      state.isLoadingGetDetail = false;
      state.errorGetDetail = false;
    });

    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

const {reducer: invitationReducer} = invitationSlice;

export default invitationReducer;
