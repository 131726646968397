import {
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Image,
  TextInput,
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform,
  Keyboard,
} from 'react-native';
import React, {useMemo, useState} from 'react';
import Swiper from 'react-native-web-swiper';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import styles from './styles';
import images from 'src/assets/images';

import {useVerifyPhone, useRegisterPhone} from 'src/api/auth';
import {useNavigation} from '@react-navigation/native';
import {useAlert, useTranslations} from 'src/hooks';
import {getNextNavigateOnboarding} from 'src/utils/common';
import {colors, PRIVACY, TERM_OF_SERVICE} from 'src/constants';
import {opacityColor, textFont} from 'src/utils/styles';
import ModalDisclaimer from '../deprecated/ActivityDetail/ModalDisclaimer';
import {isEmpty} from 'lodash';
import {Checkbox} from 'src/components';

const STATUS = {
  INIT: 0,
  SENT_CODE: 1,
  VERIFY_CODE: 2,
};
const TIME_OUT = 60;
const digitsOnly = value => /^\d+$/.test(value);

const VerifyPhone = () => {
  const {alert} = useAlert();
  const {i18n} = useTranslations();
  const phone = useSelector(state => state.user.data.phone);
  const [checkedAgreement, setCheckedAgreement] = useState('unchecked');
  const [modalContent, setModalContent] = useState({});

  const {runRequest: registerPhone, isLoading: registerPhoneLoading} =
    useRegisterPhone({
      failureCallback: error => {
        alert(
          i18n.t(error?.response?.data?.message) === '! missing translation !'
            ? i18n.t('something_went_wrong')
            : i18n.t(error?.response?.data?.message),
        );
      },
      successCallback: () => {
        setFieldValue('openInputStatus', STATUS.SENT_CODE);
      },
    });
  const {runRequest: verifyPhone} = useVerifyPhone({
    failureCallback: error => {
      alert(
        i18n.t(error?.response?.data?.message) === '! missing translation !'
          ? i18n.t('something_went_wrong')
          : i18n.t(error?.response?.data?.message),
      );
    },
    successCallback: response => {
      navigation.navigate(getNextNavigateOnboarding(response.data));
    },
  });

  const [timer, setTimer] = React.useState(0);
  const [disableSendButton, setDisableSendButton] = React.useState(false);
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };
  React.useEffect(() => {
    return () => clear();
  }, []);

  React.useEffect(() => {
    if (timer === 0) {
      setDisableSendButton(false);
      clear();
      setTimer(TIME_OUT);
    }
  }, [timer]);

  React.useEffect(() => {
    if (disableSendButton) {
      id.current = window.setInterval(() => {
        setTimer(time => time - 1);
      }, 1000);
    }
  }, [disableSendButton]);

  const navigation = useNavigation();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      phone: phone || '',
      verifyCode: '',
      openInputStatus: STATUS.INIT,
    },
    validationSchema: yup.object({
      phone: yup
        .string()
        .typeError(i18n.t('profile.verifyPhone.validate_number_field'))
        .required(i18n.t('profile.welcome.required'))
        .test(
          'Digits only',
          i18n.t('profile.verifyPhone.validate_number_field'),
          digitsOnly,
        ),
      verifyCode: yup.string().when('openInputStatus', {
        is: STATUS.VERIFY_CODE,
        then: yup
          .string()
          .typeError(i18n.t('profile.verifyPhone.validate_number_field'))
          .required(i18n.t('profile.welcome.required'))
          .test(
            'Digits only',
            i18n.t('profile.verifyPhone.validate_number_field'),
            digitsOnly,
          ),
      }),
    }),
    onSubmit: () => {
      if (
        values.openInputStatus === 0 ||
        values.openInputStatus === STATUS.SENT_CODE
      ) {
        registerPhone({
          phone: values.phone,
        });
      }
      if (values.openInputStatus === STATUS.VERIFY_CODE) {
        verifyPhone({
          phone: values.phone,
          code: values.verifyCode,
        });
      }
    },
  });

  const handleVerifyCode = () => {
    setFieldValue('openInputStatus', STATUS.VERIFY_CODE);
    setTimeout(() => {
      handleSubmit();
    }, 100);
  };
  const handleSentCode = () => {
    handleSubmit();
    setDisableSendButton(true);
    setTimer(TIME_OUT);
  };
  const handleResentCode = () => {
    setDisableSendButton(true);
    setTimeout(() => {
      handleSubmit();
    }, 100);
  };

  const isShowErrorField = React.useCallback(
    fieldName => {
      return errors?.[fieldName] && touched?.[fieldName];
    },
    [errors, touched],
  );

  const inputSection = useMemo(() => {
    return (
      <View>
        {values.openInputStatus === STATUS.INIT && (
          <>
            <TextInput
              style={styles.input}
              onChangeText={handleChange('phone')}
              onBlur={handleBlur('phone')}
              value={values.phone}
              placeholder={i18n.t('profile.verifyPhone.phone_number')}
              autoCapitalize={'none'}
              autoComplete="phone"
              keyboardType="numeric"
            />
            <Text style={styles.textError}>
              {isShowErrorField('phone') ? errors.phone : ''}
            </Text>
          </>
        )}

        {values.openInputStatus !== STATUS.INIT && (
          <>
            <TextInput
              maxLength={6}
              style={styles.input}
              onChangeText={handleChange('verifyCode')}
              onBlur={handleBlur('verifyCode')}
              value={values.verifyCode}
              placeholder={i18n.t('profile.verifyPhone.verify_code')}
              autoComplete={'verifyCode'}
            />
            <Text style={styles.textError}>
              {isShowErrorField('verifyCode') ? errors.verifyCode : ''}
            </Text>
          </>
        )}
      </View>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleBlur, handleChange, values, errors, isShowErrorField]);

  return (
    <KeyboardAvoidingView
      behavior={'padding'}
      enabled={Platform.OS === 'ios'}
      style={styles.container}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <ImageBackground
          source={images.WelcomeBackground}
          style={styles.imageBackground}
          resizeMode="cover">
          <View style={styles.flexContainer}>
            <Swiper
              timeout={3}
              loop
              controlsProps={{
                dotsPos: 'top-left',
                DotComponent: ({isActive}) => (
                  <View
                    style={
                      isActive ? styles.dotActiveStyle : styles.dotsNormalStyle
                    }
                  />
                ),
                dotsWrapperStyle: {marginTop: 40},
                prevPos: false,
                nextPos: false,
              }}>
              <View style={styles.slide1}>
                <Text style={styles.textSlide1Title}>
                  {i18n.t('profile.welcome.starting')}
                </Text>
                <Text style={styles.textSlide1Description}>
                  {i18n.t('profile.welcome.the_travel')}
                </Text>
                <Image source={images.Slider1} style={styles.imageSlide1} />
              </View>
              <View style={styles.slide2}>
                <View style={styles.slide2Wrapper}>
                  <Image source={images.Slider2} style={styles.imageSlide2} />
                  <View style={styles.flexContainer}>
                    <Text style={styles.textSlide2Title}>
                      {i18n.t('profile.welcome.explore')}
                    </Text>
                  </View>
                </View>
                <Text style={styles.textSlide2Description}>
                  {i18n.t('profile.welcome.explore_description')}
                </Text>
              </View>
              <View style={styles.slide3}>
                <View style={styles.slide2Wrapper}>
                  <Image source={images.Slider3} style={styles.imageSlide2} />
                  <View style={styles.flexContainer}>
                    <Text style={styles.textSlide2Title}>
                      {i18n.t('profile.welcome.travel')}
                    </Text>
                  </View>
                </View>
                <Text style={styles.textSlide2Description}>
                  {i18n.t('profile.welcome.travel_description')}
                </Text>
              </View>
            </Swiper>

            <View style={styles.buttonWrapperBorder}>
              <View style={styles.buttonWrapper}>
                {inputSection}
                {values.openInputStatus !== STATUS.INIT && (
                  <TouchableOpacity
                    style={styles.buttonSignIn}
                    disabled={disableSendButton}
                    onPress={handleResentCode}>
                    <Text style={styles.textSignIn}>
                      {i18n.t('profile.verifyPhone.resend')}{' '}
                      {disableSendButton &&
                        `(${timer}${i18n.t('profile.verifyPhone.second')} )`}
                    </Text>
                  </TouchableOpacity>
                )}
                {values.openInputStatus !== STATUS.INIT && (
                  <View style={styles.confirm}>
                    <Checkbox
                      status={checkedAgreement}
                      onPress={() => {
                        setCheckedAgreement(
                          checkedAgreement === 'checked'
                            ? 'unchecked'
                            : 'checked',
                        );
                      }}
                      color={colors.primary}
                      uncheckedColor={colors.neutral5}
                    />
                    <Text
                      style={[textFont(14, '400'), {color: colors.neutral4}]}>
                      {`${i18n.t('agree_with')} `}
                      <Text
                        onPress={() => {
                          setModalContent({
                            title: i18n.t('term_service'),
                            content: [TERM_OF_SERVICE],
                          });
                        }}
                        style={[
                          textFont(14, '900'),
                          {color: opacityColor(colors.primary, 0.7)},
                        ]}>
                        {`《${i18n.t('term_service')}》`}
                      </Text>
                      {i18n.t('and')}
                      <Text
                        onPress={() => {
                          setModalContent({
                            title: i18n.t('privacy_policy'),
                            content: [PRIVACY],
                          });
                        }}
                        style={[
                          textFont(14, '900'),
                          {color: opacityColor(colors.primary, 0.7)},
                        ]}>
                        {`《${i18n.t('privacy_policy')}》`}
                      </Text>
                    </Text>
                  </View>
                )}
                <TouchableOpacity
                  style={[
                    styles.buttonSignUp,
                    values.openInputStatus !== STATUS.INIT &&
                      checkedAgreement === 'unchecked' &&
                      styles.disabled,
                  ]}
                  onPress={
                    values.openInputStatus === 0
                      ? handleSentCode
                      : handleVerifyCode
                  }
                  disabled={
                    values.openInputStatus !== STATUS.INIT &&
                    checkedAgreement === 'unchecked'
                  }>
                  {registerPhoneLoading ? (
                    <ActivityIndicator color={'#fff'} />
                  ) : (
                    <Text style={styles.textSignUp}>
                      {values.openInputStatus === 0
                        ? i18n.t('profile.verifyPhone.send_code')
                        : i18n.t('profile.verifyPhone.confirm')}
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ImageBackground>
      </TouchableWithoutFeedback>
      <ModalDisclaimer
        show={!isEmpty(modalContent.content)}
        onClose={() => setModalContent({})}
        activityData={{disclaimers: modalContent.content}}
        isHideButton
        title={modalContent.title}
      />
    </KeyboardAvoidingView>
  );
};

export default VerifyPhone;
