import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getMarketActivityRequest} from '../requests';

export const useGetMarketActivity = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(
    state => state.activities.isLoadingGetMarketActivity,
  );

  const [responseData, setResponseData] = useState(null);

  const dispatch = useDispatch();

  const runRequest = useCallback(
    activityId => {
      dispatch(
        getMarketActivityRequest({
          activityId,
          failureCallback: err => {
            failureCallback?.(err);
          },
          successCallback: data => {
            setResponseData(data);
            successCallback?.(data);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  return {
    isLoading,
    runRequest,
    responseData,
  };
};
