import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  card: {
    height: 108,
    backgroundColor: colors.neutral9,
    width: '100%',
    borderRadius: 8,
    marginBottom: 16,
    paddingHorizontal: calculateWidth(16),
    paddingVertical: calculateWidth(24),
    flexDirection: 'row',
  },
  container: {
    marginHorizontal: calculateWidth(32),
  },
  img: {
    width: 60,
    height: 60,
    borderRadius: 4,
    marginRight: calculateWidth(16),
  },
  title: {
    ...textFont(16, '400'),
    marginBottom: 9,
  },
  content: {},
  secondRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  secondText: {
    ...textFont(14, '400'),
    color: colors.neutral4,
  },
  more: {
    width: 24,
    height: 24,
  },
  buttonWrapper: {
    width: 24,
    height: 24,
  },
  menuIcon: {
    width: 16,
    height: 16,
  },
  overflow: {
    borderRadius: 12,
    borderWidth: 1,
    borderColor: colors.neutral9,
  },
  edit: {
    backgroundColor: colors.neutral7,
  },
  delete: {
    backgroundColor: colors.neutral7,
    color: 'red',
  },
  menuWrapper: {
    flex: 1,
    marginLeft: 8,
  },
  menuText: {
    ...textFont(16, '600'),
  },
  deleteText: {
    ...textFont(16, '400'),
    color: colors.neutral4,
  },
});
