import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  skip: {
    ...textFont(15, '400'),
    color: colors.neutral1,
  },
  skipButton: {
    paddingHorizontal: 10,
    height: calculateWidth(40),
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    flex: 1,
    marginLeft: calculateWidth(12),
  },
  textTitle: {
    ...textFont(14, '400'),
    color: colors.neutral1,
  },
  textTitleObject: {
    ...textFont(14, '600'),
    color: colors.neutral1,
  },
  textTime: {
    ...textFont(12, '400'),
    color: colors.neutral5,
  },
  notification: {
    padding: calculateWidth(12),
    flexDirection: 'row',
  },
  unread: {
    backgroundColor: colors.neutral7,
  },
  avatar: {
    width: calculateWidth(35),
    height: calculateWidth(35),
    borderRadius: 100,
    backgroundColor: colors.neutral6,
  },
});
