import {Text, TouchableOpacity, StyleSheet} from 'react-native';
import React from 'react';
import styles from './styles';

const Button = ({onClick, title, active, style = {}}) => {
  return (
    <TouchableOpacity
      style={
        !active
          ? StyleSheet.flatten([styles.buttonDisable, style])
          : StyleSheet.flatten([styles.button, style])
      }
      onPress={onClick}>
      <Text style={active ? styles.textButton : styles.deActive}>{title}</Text>
    </TouchableOpacity>
  );
};

export default Button;
