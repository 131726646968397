import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getHomeDataRequest} from '../requests';

export const useGetHomeData = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(state => state.home.isLoading);
  const [isSuccess, setIsSuccess] = useState(null);
  const [isError, setIsError] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();

  const resetErrors = useCallback(() => {
    setErrors(null);
  }, []);

  const runRequest = useCallback(() => {
    dispatch(
      getHomeDataRequest({
        failureCallback: err => {
          failureCallback?.(err);
          setIsError(true);
          setIsSuccess(false);
          setErrors(err.response?.data);
        },
        successCallback: data => {
          setResponseData(data);
          successCallback?.(data);
          setIsSuccess(true);
          setIsError(false);
        },
      }),
    );
  }, [failureCallback, successCallback, dispatch, setIsError, setIsSuccess]);

  return {
    isLoading,
    runRequest,
    responseData,
    isError,
    isSuccess,
    errors,
    resetErrors,
  };
};
