import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getNotificationRequest} from '../requests';

export const useGetNotification = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(
    state => state.invitation.isLoadingGetNotification,
  );
  const [responseData, setResponseData] = useState(null);
  const dispatch = useDispatch();

  const runRequest = useCallback(() => {
    dispatch(
      getNotificationRequest({
        failureCallback: err => {
          failureCallback?.(err);
        },
        successCallback: response => {
          setResponseData(response);
          successCallback?.(response);
        },
      }),
    );
  }, [failureCallback, successCallback, dispatch]);

  return {
    isLoading,
    runRequest,
    responseData,
  };
};
