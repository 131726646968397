import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.neutral9,
  },
  buttonWrapper: {
    paddingHorizontal: calculateWidth(16),
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: calculateWidth(16),
  },
  button: {
    width: calculateWidth(170),
    height: calculateWidth(40),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.neutral8,
    borderRadius: calculateWidth(4),
  },
  buttonSelected: {
    width: calculateWidth(160),
    height: calculateWidth(40),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
    borderRadius: calculateWidth(4),
  },
  buttonTitle: {
    ...textFont(15, '400'),
    color: colors.neutral4,
  },
  buttonTitleSelected: {
    ...textFont(15, '400'),
    color: colors.neutral9,
  },

  searchContent: {
    margin: calculateWidth(16),
    height: calculateWidth(48),
    flexDirection: 'row',
    alignItems: 'center',
  },

  searchWrapper: {
    flex: 1,
    borderWidth: 1,
    borderColor: colors.neutral6,
    borderRadius: calculateWidth(8),
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: calculateWidth(20),
    height: calculateWidth(20),
    marginHorizontal: calculateWidth(12),
  },
  search: {
    flex: 1,
    height: calculateWidth(46),
    ...textFont(16, '400'),
    color: colors.neutral1,
  },

  buttonClose: {
    width: calculateWidth(36),
    height: calculateWidth(36),
    backgroundColor: colors.neutral8,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: calculateWidth(10),
  },
  textClose: {
    fontSize: calculateWidth(25),
    marginBottom: calculateWidth(2),
    marginLeft: calculateWidth(3),
    color: colors.neutral1,
    transform: [{rotate: '45deg'}],
  },

  //  Village
  village: {
    width: calculateWidth(163),
    height: calculateWidth(221),
    borderRadius: calculateWidth(8),
    backgroundColor: colors.neutral9,
    marginLeft: calculateWidth(16),
    marginTop: calculateWidth(16),
    padding: calculateWidth(16),
  },
  villageImage: {
    width: calculateWidth(137),
    height: calculateWidth(131),
    backgroundColor: colors.neutral6,
    marginBottom: calculateWidth(12),
    borderRadius: calculateWidth(4),
  },
  villageName: {
    ...textFont(16, '600'),
    color: colors.neutral1,
    flex: 1,
  },
  location: {
    width: calculateWidth(16),
    height: calculateWidth(16),
    marginRight: calculateWidth(6),
  },
  locationWrapper: {
    flexDirection: 'row',
    marginTop: calculateWidth(4),
    alignItems: 'center',
  },
  textCity: {
    ...textFont(14, '400'),
    color: colors.neutral4,
    flex: 1,
  },

  // Activity
  activityWrapper: {
    backgroundColor: colors.neutral8,
  },

  activity: {
    width: calculateWidth(163),
    height: calculateWidth(228),
    borderRadius: calculateWidth(8),
    backgroundColor: colors.neutral9,
    marginLeft: calculateWidth(16),
    marginTop: calculateWidth(16),
    overflow: 'hidden',
  },
  activityImage: {
    width: calculateWidth(163),
    height: calculateWidth(160),
    backgroundColor: colors.neutral6,
  },
  activityName: {
    margin: calculateWidth(12),
    ...textFont(16, '400'),
  },
});

export default styles;
