// This source is web starting point
import {AppRegistry, Text} from 'react-native';
import App from './App';
import './assets/fonts/SourceSansPro-Regular.ttf';
import './index.css';

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = false;

AppRegistry.registerComponent('App', () => App);

AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
});
