import React, {useState} from 'react';
import {
  Text,
  View,
  Image,
  Platform,
  KeyboardAvoidingView,
  Modal,
  TouchableOpacity,
  TextInput,
} from 'react-native';

import {useTranslations} from 'src/hooks';
import images from 'src/assets/images';

import styles from './styles';
import {ImageSelector} from 'src/components';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const AddSection = ({isVisible, setVisible, handleAddSection}) => {
  const [selectedImage, setSelectedImage] = useState();
  const [storyContent, setStoryContent] = useState('');
  const {i18n} = useTranslations();
  const insets = useSafeAreaInsets();

  const thumbnail = (
    <View style={styles.thumb}>
      <Image source={images.Gallery} style={styles.gallery} />
      <Text style={styles.upload}>{i18n.t('addStory.upload')}</Text>
    </View>
  );

  const handleClose = () => {
    setVisible(false);
    setSelectedImage();
    setStoryContent('');
  };
  return (
    <Modal visible={isVisible} transparent={true} onRequestClose={handleClose}>
      <View style={[styles.modal, {paddingTop: insets.top + 30}]}>
        <View style={styles.container}>
          <View style={styles.header}>
            <TouchableOpacity onPress={handleClose}>
              <Image
                source={images.Close}
                resizeMode={'cover'}
                style={styles.closeIcon}
              />
            </TouchableOpacity>

            <Text style={styles.headerTitle}>
              {i18n.t('addStory.addSection')}
            </Text>
            <Text
              style={styles.headerButton}
              onPress={() => {
                if (storyContent || selectedImage) {
                  handleClose();
                  handleAddSection({
                    content: storyContent,
                    photo: selectedImage,
                  });
                }
              }}>
              {i18n.t('addStory.done')}
            </Text>
          </View>
          <View style={styles.divider} />
          <KeyboardAvoidingView
            behavior={'padding'}
            enabled={Platform.OS === 'ios'}
            style={styles.content}>
            <TextInput
              style={styles.inputStyle}
              placeholder={i18n.t('addStory.paragraph')}
              multiline={true}
              value={storyContent}
              onChangeText={c => setStoryContent(c)}
              size={'large'}
              autoCorrect={false}
            />
            <ImageSelector
              containerStyle={styles.contentPicker}
              thumbnail={thumbnail}
              image={selectedImage}
              onSelectImage={image => {
                setSelectedImage(image);
              }}
              multiple={false}
            />
          </KeyboardAvoidingView>
        </View>
      </View>
    </Modal>
  );
};

export default AddSection;
