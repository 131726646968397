import {createSlice} from '@reduxjs/toolkit';
import {logout} from 'src/api/auth';
import {
  getMetadata,
  getTripDetailRequest,
  getUserDetailRequest,
  getUserList,
  getSuggestUserTripRequest,
  getUserStoryRequest,
  followUserRequest,
  getUserFollowerRequest,
  getUserFollowingRequest,
  unFollowUserRequest,
  userDislikeRequest,
  getSuggestUserRequest,
  getAllTripRequest,
  deleteTripRequest,
  updateTripRequest,
  checkUserPhoneRequest,
  getTripAvailableSlotRequest,
} from 'src/api/users';

const initialState = {
  isLoading: false,
  error: false,
  metadata: {
    hometowns: [],
    college: [],
    lifestyles: [],
    hobby: [],
  },
  isLoadingGetUserList: false,
  errorGetUserList: false,
  userList: [],

  isLoadingGetUserDetail: false,
  errorGetUserDetail: false,
  userDetail: {},

  isLoadingGetTripDetail: false,
  errorGetTripDetail: false,

  isLoadingGetSuggestUserTrip: false,
  errorGetSuggestUserTrip: false,
  isLoadingGetSuggestUser: false,
  errorGetSuggestUser: false,
  suggestUserList: [],

  isLoadingGetStory: false,
  errorGetStory: false,
  story: [],

  isLoadingFollowUser: false,
  errorFollowUser: false,
  isLoadingUnFollowUser: false,
  errorUnFollowUser: false,

  isLoadingGetUserFollower: false,
  errorGetUSerFollower: false,

  isLoadingGetUSerFollowing: false,
  errorGetUSerFollowing: false,

  isLoadingUserDislike: false,
  errorUserDislike: false,

  isLoadingGetAllTrip: false,
  errorGetAllTrip: false,
  allTrip: [],

  isLoadingDeleteTrip: false,
  errorDeleteTrip: false,

  isLoadingUpdateTrip: false,
  errorUpdateTrip: false,

  isLoadingCheckUserPhone: false,

  isLoadingGetTripAvailableSlot: false,
};

export const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMetadata.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getMetadata.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
    builder.addCase(getMetadata.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.metadata = action.payload.data;
    });
    builder.addCase(getUserList.pending, state => {
      state.isLoadingGetUserList = true;
      state.errorGetUserList = false;
    });
    builder.addCase(getUserList.rejected, state => {
      state.isLoadingGetUserList = false;
      state.errorGetUserList = true;
    });
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.isLoadingGetUserList = false;
      state.errorGetUserList = false;
      state.userList = action.payload.data;
    });
    builder.addCase(getUserDetailRequest.pending, state => {
      state.isLoadingGetUserDetail = true;
      state.errorGetUserDetail = false;
    });
    builder.addCase(getUserDetailRequest.rejected, state => {
      state.isLoadingGetUserDetail = false;
      state.errorGetUserDetail = true;
    });
    builder.addCase(getUserDetailRequest.fulfilled, (state, action) => {
      state.isLoadingGetUserDetail = false;
      state.errorGetUserDetail = false;
      state.userDetail = action.payload.data;
    });

    builder.addCase(getTripDetailRequest.pending, state => {
      state.isLoadingGetTripDetail = true;
      state.errorGetTripDetail = false;
    });
    builder.addCase(getTripDetailRequest.rejected, state => {
      state.isLoadingGetTripDetail = false;
      state.errorGetTripDetail = true;
    });
    builder.addCase(getTripDetailRequest.fulfilled, state => {
      state.isLoadingGetTripDetail = false;
      state.errorGetTripDetail = false;
    });

    builder.addCase(getSuggestUserTripRequest.pending, state => {
      state.isLoadingGetSuggestUserTrip = true;
      state.errorGetSuggestUserTrip = false;
      state.suggestUserList = [];
    });
    builder.addCase(getSuggestUserTripRequest.rejected, state => {
      state.isLoadingGetSuggestUserTrip = false;
      state.errorGetSuggestUserTrip = true;
    });
    builder.addCase(getSuggestUserTripRequest.fulfilled, (state, action) => {
      state.isLoadingGetSuggestUserTrip = false;
      state.errorGetSuggestUserTrip = false;
      state.suggestUserList = action.payload.data;
    });

    builder.addCase(getUserStoryRequest.pending, state => {
      state.isLoadingGetStory = true;
      state.errorGetStory = false;
      state.story = [];
    });
    builder.addCase(getUserStoryRequest.rejected, state => {
      state.isLoadingGetStory = false;
      state.errorGetStory = true;
    });
    builder.addCase(getUserStoryRequest.fulfilled, (state, action) => {
      state.isLoadingGetStory = false;
      state.errorGetStory = false;
      state.story = action.payload.data;
    });

    builder.addCase(followUserRequest.pending, state => {
      state.isLoadingFollowUser = true;
      state.errorFollowUser = false;
    });
    builder.addCase(followUserRequest.rejected, state => {
      state.isLoadingFollowUser = false;
      state.errorFollowUser = true;
    });
    builder.addCase(followUserRequest.fulfilled, (state, action) => {
      state.isLoadingFollowUser = false;
      state.errorFollowUser = false;
    });

    builder.addCase(unFollowUserRequest.pending, state => {
      state.isLoadingUnFollowUser = true;
      state.errorUnFollowUser = false;
    });
    builder.addCase(unFollowUserRequest.rejected, state => {
      state.isLoadingUnFollowUser = false;
      state.errorUnFollowUser = true;
    });
    builder.addCase(unFollowUserRequest.fulfilled, (state, action) => {
      state.isLoadingUnFollowUser = false;
      state.errorUnFollowUser = false;
    });

    builder.addCase(getUserFollowerRequest.pending, state => {
      state.isLoadingGetUserFollower = true;
      state.errorGetUSerFollower = false;
    });
    builder.addCase(getUserFollowerRequest.rejected, state => {
      state.isLoadingGetUserFollower = false;
      state.errorGetUSerFollower = true;
    });
    builder.addCase(getUserFollowerRequest.fulfilled, (state, action) => {
      state.isLoadingGetUserFollower = false;
      state.errorGetUSerFollower = false;
    });

    builder.addCase(getUserFollowingRequest.pending, state => {
      state.isLoadingGetUserFollowing = true;
      state.errorGetUSerFollowing = false;
    });
    builder.addCase(getUserFollowingRequest.rejected, state => {
      state.isLoadingGetUserFollowing = false;
      state.errorGetUSerFollowing = true;
    });
    builder.addCase(getUserFollowingRequest.fulfilled, (state, action) => {
      state.isLoadingGetUserFollowing = false;
      state.errorGetUSerFollowing = false;
    });

    builder.addCase(userDislikeRequest.pending, state => {
      state.isLoadingUserDislike = true;
      state.errorUserDislike = false;
    });
    builder.addCase(userDislikeRequest.rejected, state => {
      state.isLoadingUserDislike = false;
      state.errorUserDislike = true;
    });
    builder.addCase(userDislikeRequest.fulfilled, state => {
      state.isLoadingUserDislike = false;
      state.errorUserDislike = false;
    });

    builder.addCase(getSuggestUserRequest.pending, state => {
      state.isLoadingGetSuggestUser = true;
      state.errorGetSuggestUser = false;
      state.suggestUserList = [];
    });
    builder.addCase(getSuggestUserRequest.rejected, state => {
      state.isLoadingGetSuggestUser = false;
      state.errorGetSuggestUser = true;
    });
    builder.addCase(getSuggestUserRequest.fulfilled, (state, action) => {
      state.isLoadingGetSuggestUser = false;
      state.errorGetSuggestUser = false;
      state.suggestUserList = action.payload.data;
    });

    builder.addCase(getAllTripRequest.pending, state => {
      state.isLoadingGetAllTrip = true;
      state.errorGetAllTrip = false;
      state.allTrip = [];
    });
    builder.addCase(getAllTripRequest.rejected, state => {
      state.isLoadingGetAllTrip = false;
      state.errorGetAllTrip = true;
    });
    builder.addCase(getAllTripRequest.fulfilled, (state, action) => {
      state.isLoadingGetAllTrip = false;
      state.errorGetAllTrip = false;
      state.allTrip = action.payload.data;
    });

    builder.addCase(deleteTripRequest.pending, state => {
      state.isLoadingDeleteTrip = true;
      state.errorDeleteTrip = false;
    });
    builder.addCase(deleteTripRequest.rejected, state => {
      state.isLoadingDeleteTrip = false;
      state.errorDeleteTrip = true;
    });
    builder.addCase(deleteTripRequest.fulfilled, (state, action) => {
      state.isLoadingDeleteTrip = false;
      state.errorDeleteTrip = false;
    });

    builder.addCase(updateTripRequest.pending, state => {
      state.isLoadingUpdateTrip = true;
      state.errorUpdateTrip = false;
    });
    builder.addCase(updateTripRequest.rejected, state => {
      state.isLoadingUpdateTrip = false;
      state.errorUpdateTrip = true;
    });
    builder.addCase(updateTripRequest.fulfilled, (state, action) => {
      state.isLoadingUpdateTrip = false;
      state.errorUpdateTrip = false;
    });

    builder.addCase(checkUserPhoneRequest.pending, state => {
      state.isLoadingCheckUserPhone = true;
    });
    builder.addCase(checkUserPhoneRequest.rejected, state => {
      state.isLoadingCheckUserPhone = false;
    });
    builder.addCase(checkUserPhoneRequest.fulfilled, (state, action) => {
      state.isLoadingCheckUserPhone = false;
    });

    builder.addCase(getTripAvailableSlotRequest.pending, state => {
      state.isLoadingGetTripAvailableSlot = true;
    });
    builder.addCase(getTripAvailableSlotRequest.rejected, state => {
      state.isLoadingGetTripAvailableSlot = false;
    });
    builder.addCase(getTripAvailableSlotRequest.fulfilled, (state, action) => {
      state.isLoadingGetTripAvailableSlot = false;
    });

    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

const {reducer: usersReducer} = metadataSlice;

export default usersReducer;
