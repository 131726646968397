import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import AsyncStorage from '@react-native-async-storage/async-storage';
import unAuthMiddleware from './unAuthMiddleware';

import {auth} from './slices/auth';
import {metadata} from './slices/metadata';
import {user} from './slices/user';
import {upload} from './slices/upload';
import {conversation} from './slices/conversation';
import {home} from './slices/home';
import {activities} from './slices/admin/activities';
import {invitation} from './slices/invitation';
import {story} from './slices/story';
import {village} from './slices/village';
import {notification} from './slices/notification';
import {wechat} from './slices/wechat';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  version: 1,
  whitelist: ['auth', 'user', 'story'],
};

const reducer = combineReducers({
  auth,
  metadata,
  user,
  upload,
  conversation,
  home,
  activities,
  invitation,
  story,
  village,
  notification,
  wechat,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(unAuthMiddleware),
});

export const persistor = persistStore(store);
