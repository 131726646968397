import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 12,
    borderWidth: 1,
    borderColor: colors.neutral7,
    padding: calculateWidth(16),
  },
  image: {
    borderRadius: calculateWidth(8),
    height: calculateWidth(106),
  },
  contentWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: calculateWidth(8),
    marginTop: calculateWidth(8),
    alignItems: 'center',
  },
  province: {
    ...textFont(16, '600'),
    flex: 1,
  },
  city: {
    ...textFont(14, '400'),
    color: colors.neutral4,
    flex: 1,
  },
  rightWrapper: {
    width: calculateWidth(28),
    height: calculateWidth(28),
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colors.neutral6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrow: {
    width: calculateWidth(18),
    height: calculateWidth(18),
  },
  flex1: {
    flex: 1,
  },
});
