import {View, ActivityIndicator} from 'react-native';
import React from 'react';
import styles from './styles';
import {colors} from 'src/constants';

const Loading = ({containerStyle}) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <ActivityIndicator size={'large'} color={colors.primary} />
    </View>
  );
};

export default Loading;
