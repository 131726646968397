import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
} from 'react-native';
import React, {useState, useRef} from 'react';
import styles from './styles';

const Menu = ({anchor, items}) => {
  const [topPosition, setTopPosition] = useState(0);
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef();
  buttonRef.current?.measureInWindow((...data) => {
    setTopPosition(data[1] + data[3]);
  });

  const onDismiss = () => {
    setVisible(false);
  };

  return (
    <View ref={buttonRef}>
      <TouchableOpacity
        onPress={() => {
          setVisible(true);
        }}>
        {anchor}
      </TouchableOpacity>
      <Modal transparent={true} visible={visible} onRequestClose={onDismiss}>
        <TouchableWithoutFeedback onPress={onDismiss}>
          <View style={styles.container}>
            <View style={[styles.menu, {top: topPosition}]}>
              {items.map((i, index) => (
                <TouchableOpacity
                  onPress={() => {
                    setVisible(false);
                    i.onPress();
                  }}
                  key={index}
                  style={styles.item}>
                  <Text numberOfLines={1} style={i.titleStyle}>
                    {i.title}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
};

export default Menu;
