export default {
  something_went_wrong: 'Something went wrong. Please try again later!',
  profile: {
    skip: 'Skip',
    welcome: {
      sign_up: 'Sign up',
      sign_in: 'Sign in',
      starting: 'Starting',
      the_travel: 'the travel',
      explore: 'Explore villages',
      explore_description:
        'Exploring the cultures, the foods, peoples, participate in activities...',
      travel: 'Travel together',
      travel_description:
        'Inviting your friends to join the journey together to discover the new land.',
      email: 'Enter your email',
      password: 'Enter your password',
      confirm_pass: 'Confirm your password',
      required: 'Required',
      pass_match: 'Passwords must match',
      invalid_email: 'Invalid email address',
    },
    verifyPhone: {
      phone_number: 'Phone number',
      verify_code: 'Code',
      resend: 'Resend',
      send_code: 'Send code',
      confirm: 'Confirm',
      validate_number_field: 'The field should have digits only',
      second: 's',
    },
    MyWorkAndIndustry: 'My work & industry',
    bio: {
      title: 'Complete Profile',
      the_more:
        'The more complete your profile is, the more likely other people will trust you.',
      header: 'Profile',
      date_of_birth: 'Date of birth',
      about_me: 'About me',
      help: 'Helps you better find townsfolks',
      male: 'Male',
      female: 'Female',
      fill: ' Fill information in 1 minutes to get your perfect match',
      next: 'Next',
      bio: 'Bio',
      select: 'Select',
    },
    college: {
      the_more:
        'The more complete your profile is, the more likely other people will trust you.',
      my_college: 'My College',
      help: 'Helps increase your chances of successful matches',
      enter_name: 'Enter college name',
      fill: 'Fill information in 1 minutes to get your perfect match',
      next: 'Next',
      collge_name: 'Enter college name',
      placeholder: 'Search college',
    },
    lifeStyle: {
      placeholder: 'Search lifestyle',
      my_lifestyle: 'My lifestyle',
      the_more:
        'The more complete your profile is, the more likely other people will trust you.',
      tell: 'Tell a bit about your daily life to get similar friends',
      fill: 'Fill information in 1 minutes to get your perfect match',
    },
    wish: {
      my_wish: 'My Wish',
      the: 'The more complete your profile is, the more likely other people will trust you.',
      tell: 'Tell a bit about your daily life to get similar friends',
      fill: 'Fill information in 1 minutes to get your perfect match',
      placeholder: 'Search wish',
    },
    photo: {
      my_photo: 'My profile photos',
      complete: 'Complete',
      portrait: ' usual portrait',
      travel: ' travel snapshot',
      hobby: ' doing hobby',
    },
  },
  home: {
    promotion_title: 'Summer Promotion Title',
    promotion_amount: '20% OFF',
    trip: {
      age: 'age',
    },
    my_trip: 'My Trip',
    confirmed: 'Confirmed',
    waiting_for_confirm: 'Waiting for confirm',
    search: {
      village: 'Village',
      activity: 'Activity',
      search_village: 'Search village',
      nearby_village: 'NEARBY VILLAGE',
      tourism_activity: 'Tourism activity',
    },
  },
  invitation: {
    become_member: 'Become a Member',
    invite: ' invite you to jump on tourism activity',
    activity: 'Activity',
    accommodation: 'Accommodation',
    story: 'Story',
    host: 'Host:',
    invite_friends: 'Invite friends',
    join_now: 'Join now',
    join_point: 'Joining a trip ( +5 points )',
    show_more: 'Show more',
    write_comment: 'Write a comment',
    find_on_we_chat: 'Find on WeChat',
    invited: 'Invited',
    age: 'age',
  },
  villageDetails: {
    overview: 'Overview of the village',
    geo: 'Geographical location',
    population: 'Population Industry',
    topo: 'Topography, mountains and river',
    season: 'Seasons of the village',
    overviewSeason: 'Overview of the four seasons',
    details: 'Details every month',
    month: '月',
    folklore: 'Folklore',
    nature: 'Natural landscape',
    food: 'Food set',
    Myth: 'Myth history',
    feature: 'Feature attractions',
    history: 'History and culture',
    his: 'History',
    celebrate: 'Celebrate, myth, anecdotes',
    traditional: 'Traditional craftsmen',
    featured: 'Featured building',
    scenery: 'Natural scenery',
    foodSingle: 'Food',
    facing: 'Facing problems',
    traffic: 'Traffic information',
  },
  addStory: {
    create: 'Create a story',
    preview: 'Preview',
    addBackground: 'Upload your background',
    upload: 'Upload photo (png, jpg)',
    title: 'Story title',
    titlePlaceholder: 'e.g  ‘Story title’',
    village: 'Village Name',
    villagePlaceholder: 'e.g  ‘Village, City’',
    storyContent: 'Content of the Story',
    paragraph: 'Paragraphs of the content',
    addSection: 'Add section',
    done: 'Done',
    congratulation: 'Congratulation',
    goToHome: 'Go to home',
    errorTrip: 'Something went wrong when create trip!',
    errorImage: 'Something went wrong when upload image!',
    chooseVillage: 'Choose village',
  },
  previewStory: {
    review: 'Review Story',
    publish: 'Publishing a post (+10 points)',
    publishStory: 'Publishing Story',
  },
  storyDetail: {
    storyNotFound: 'Story not found!',
  },
  trip_detail: {
    user_joined: 'User who joined',
    sign_up: 'Sign up & Join',
    learn_more: 'Learn more',
    invite_you: 'invites you to ...',
    invite_by: 'Invited by',
    tourism_activity: 'Tourism Activities',
    volunteer_activity: 'Volunteer Activities',
    schedule: 'Schedule',
    hosts: 'Hosts',
    trip_cost: 'Trip cost',
    disclaimers: 'Disclaimers',
    trip_date: 'Trip start date',
    date_placeholder: 'Select start and end date',
  },
  main_admin: {
    my_activities: 'My activities',
    booked: 'Booked Trip',
    village_management: 'Village management',
    achi: 'Achievements',
    payouts: 'Payouts',
  },
  activity_detail: {
    next: 'Next',
    confirm: 'Confirm and Payment',
    invite: 'Invite friend',
    inti_activity: 'Initiate activities',
    person: 'person',
    public_activity: 'for public (anyone can join)',
    private_activity: 'for private (invite only)',
  },
  tab_bar: {
    home: 'Home',
    profile: 'Profile',
  },
  delete: 'Delete',
  are_you_sure: 'Are you sure?',
  done: 'Done',
  back: 'Back',
  or: 'OR',
  publish_by: 'Published by',
  follower: 'follower',
  following: 'following',
  camera: 'Camera',
  electrical: 'Electrical devices',
  backpack: 'Backpack',
  cash: 'Cash or credit card',
  introduction: 'Introduction',
  volunteer_activity: 'Volunteer Activity',
  hosts: 'Hosts',
  number_of_people: 'Number of people',
  people: 'people',
  need_to_prepare: 'Need to prepare',
  trip_data: 'Trip date',
  activity_init_info: 'Initiator enjoy the lowest discount at only 300',
  confirm: 'Confirm',
  start_trip_points: 'Starting a trip (+10 points)',
  invite_user_points: 'Invite more user (+2 points)',
  person: 'person',
  share: 'Share',
  like: 'Like',
  update_photo: 'Change photo (JPG,PNG)',
  create_activity: 'Create an activity',
  follow: 'Follow',
  my_story: 'My Story',
  user_story: 'User Story',
  achievements: 'Achievements',
  edit_profile: 'Edit Profile',
  ok: 'OK',
  cancel: 'Cancel',
  un_follow_confirm: 'Are you sure to un-follow?',
  cannot_found_invitation: 'Cannot found invitation!',
  invalid_invitation: 'Invalid invitation!',
  village_detail: 'Village Details',
  objective: 'Objective',
  day: 'Day',
  fee: 'Fee',
  followed: 'Followed',
  payment_again: 'Payment again',
  join_trip_chat: 'Join chat',
  search_work_industry: 'Search work & industry',
  wishDate: 'Date',
  dateDescription: 'I want to find a soulmate of the opposite sex',
  wishFriend: 'Make friends',
  friendDescription: 'I wanna meet friends with common interest',
  wishNetwork: 'Build my network',
  networkDescription: 'Meet other professionals in my industry',
  unFollow: 'Unfollow',
  successfully_followed: 'Successfully followed',
  successfully_invited: 'Successfully invited',
  find_friend_title: 'You might be interesting with this people',
  put_your_comment: 'Put your comment',
  reply_to: 'Replying to',
  reply: 'Reply',
  'missing field villageId': 'missing field villageId',
  'village not found': 'village not found',
  'missing username': 'missing username',
  'missing password': 'missing password',
  'missing email': 'missing email',
  'username or email already exist': 'username or email already exist',
  'lifestyle must be a array': 'lifestyle must be a array',
  'photos must be a array': 'photos must be a array',
  'datingStandard must be a array': 'datingStandard must be a array',
  'gender: [ male, female, other ]': 'gender: [ male, female, other ]',
  'birthday shoud be YYYY-MM-DD format': 'birthday shoud be YYYY-MM-DD format',
  'missing user': 'missing user',
  'user not found': 'user not found',
  'username or password do not match': 'username or password do not match',
  'trip not found': 'trip not found',
  'stories not found': 'stories not found',
  'you are not a publisher': 'you are not a publisher',
  'comment not found': 'comment not found',
  'plan not found': 'plan not found',
  'plan not found, can not init trip': 'plan not found, can not init trip',
  'invalid start date': 'invalid start date',
  'invitation not found': 'invitation not found',
  'missing field tripId or trip_id': 'missing field tripId or trip_id',
  'user already joined': 'user already joined',
  'you not belong to this invitation': 'you not belong to this invitation',
  'conversation not found': 'conversation not found',
  'Something went wrong': 'Something went wrong',
  'wrong information': 'The inputs are invalid! Please try again.',
  'phone is used! ': 'Phone is used!',
  'rate limit': 'Please try after a minutes!',
  forbiden: 'forbiden',
  go_to_my_trip: 'Go to my trip',
  trip_fulfill: 'The trip is full of members',
  notification: 'Notification',
  empty: 'Empty',
  potential_teammate: 'Potential Teammate',
  potential_teammate_description:
    'This user looking at the same village as you!',
  check_their_profile: 'Check their profile',
  your_work: 'Your work',
  your_industry: 'Your industry',
  nickname: 'Nickname',
  gender: 'Gender',
  job: 'Job',
  industry: 'Industry',
  lifestyle: 'Lifestyle',
  wish: 'Wish',
  photo: 'Photos',
  review_profile: 'Review profile',
  update_successful: 'Update successful',
  comment_story: 'commented on your story',
  reply_comment: 'replied your comment on story',
  like_story: 'liked your story',
  follow_you: 'is following you',
  accept_invite: 'accepts an invitation to join',
  send_message: 'sent a message',
  invite_trip: 'invites you to a trip',
  trip_upcoming: 'is coming on 72 hours',
  logout: 'Logout',
  view_order: 'View order',
  share_friend: 'Share friend',
  total_price: 'Total price',
  buy_now: 'Buy now',
  people_going_with_me: 'People going with me',
  add: 'Add',
  add_people: 'Add people',
  phone: 'Phone',
  phone_invalid: 'Phone is not valid',
  payment: 'Payment',
  order_detail: 'Order detail',
  please_open_wechat_payment: 'Please open wechat to make payment!',
  invite_friends_play: 'Invite friends to play together',
  what_included: "What's included",
  ticket_details: 'Ticket details',
  paid_at: 'Paid at',
  paid_for: 'Paid for',
  order_id: 'Order ID',
  see_more: 'See more',
  see_less: 'See less',
  story_recommended: 'Story recommended',
  phone_not_valid: 'Phone number is not valid',
  this_is_required: 'This is required',
  agree_with: 'I agree with the',
  payment_agreement: 'Payment Agreement',
  term_service: 'Terms of Service',
  privacy_policy: 'Privacy Policy',
  and: 'and',
  related_plan: 'Related plan',
  initiate_new_event: 'Initiate a New Event',
  join_event: 'Join Event',
  start_new_event: 'Start a new event',
  enter_group_chat: 'Enter group chat',
  ongoing_status: 'Ongoing',
  closed_status: 'Closed',
  full_status: 'Full',
  duplicated_status: 'Duplicated',
  duplicate_trip: 'There is a trip on this date, please select again',
  pay_for_your_friend: 'Pay for your friend',
  share_to_invite: 'or pay by yourself and then invite friends to pay',
  cities: {
    beijing: 'Beijing',
    hangzhou_jiachang: 'Hangzhou',
    hangzhou_yichang: 'Hangzhou Yichang',
    guangzhou: 'Guangzhou',
    shenzhen: 'Shenzhen',
    changsha: 'Changsha',
    wuhan: 'Wuhan',
    chengdu: 'Chengdu',
    chongqing: 'Chongqing',
    xian: "Xi'an",
    nanjing: 'Nanjing',
  },
  screen_select_city: {
    go_to_bazaar: 'Go to bazaar',
    find_coffee_bazaars_near_you: 'Find coffee bazaars near you',
  },
  contact_support: 'Contact support',
  got_questions: 'Got questions?',
  edit: 'Edit',
  hint_download_poster:
    'Hint: You can long press the image to download the poster',
  wechat: 'Wechat',
  this_user_not_sign_up: "This user hasn't signup",
  museum: 'Museum',
  invite: 'Invite',
  to_my_village: 'ToMyVillage',
  tomyvillage_official: '2myvillage Official',
  age: 'Age',
  follow_us_wechat: 'Follow us on WeChat',
  type: 'Type',
  trip_for: 'Trip for',
  remove_accompany: 'Trip is almost full, Please reduce your companion to',
  standard_version: 'Standard version',
  sport_version: 'Sport version',
  coming_soon: 'Coming soon',
  required: 'required',
  go_to_bottom: 'Go to bottom',
  organizer: 'Organizer',
  no_permission: 'You have no permission to scan tickets',
  scan_successful: 'Successfully scanned',
  'invalid, ticket already used': 'Ticket already scanned',
  onetime_ticket: 'One-time ticket',
};
