import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  ScrollView,
  ImageBackground,
  Image,
  TouchableOpacity,
} from 'react-native';
import {useIsFocused, useNavigation} from '@react-navigation/native';
import {useDispatch, useSelector} from 'react-redux';

import images from 'src/assets/images';
import routeNames from 'src/navigation/routeNames';
import {logout} from 'src/api/auth';

import {UserCard, Pill, Story, ShareCode, Menu} from 'src/components';

import styles from './styles';
import {getUserAge, getUserPhoto} from 'src/utils/common';
import {useTranslations} from 'src/hooks';
import {useGetUserStory, useGetUserDetail} from 'src/api/users';
import {includes, isEmpty, map} from 'lodash';
import {API_URL} from 'src/utils/axiosConfig';
import {USER_DATING, USER_ROLE} from 'src/constants';

const MENU_INDEX = {
  admin: 0,
  logout: 1,
  myTrip: 2,
};

const MainProfile = () => {
  const navigation = useNavigation();
  const [visibleShare, setVisibleShare] = useState(false);
  const dispatch = useDispatch();
  const {i18n} = useTranslations();
  const userData = useSelector(state => state.user.data);
  const isFocused = useIsFocused();

  const {runRequest: getUserStory} = useGetUserStory({});
  const {runRequest: getUserDetail} = useGetUserDetail({});
  const userStory = useSelector(state => state.metadata.story);
  // const userAchievement = useSelector(
  //   state => state.user.data?.achievements || [],
  // );
  const userMetadata = useSelector(state => state.user.data);

  useEffect(() => {
    if (isFocused) {
      getUserStory(userData.id);
      getUserDetail(userData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const onItemSelect = index => () => {
    if (index === MENU_INDEX.admin) {
      navigation.navigate(routeNames.MainFlow.MainAdmin);
    }
    if (index === MENU_INDEX.logout) {
      //TODO: call logout api and purge state
      dispatch(logout({}));
    }
    if (index === MENU_INDEX.myTrip) {
      navigation.navigate(routeNames.MainFlow.MyTrip);
    }
  };

  const renderToggleButton = () => (
    <View style={styles.ss}>
      <Image
        source={images.Hamburger}
        resizeMode="contain"
        style={styles.profileIcon}
      />
    </View>
  );

  const hobby = [
    `${userMetadata.gender === 'male' ? '♂' : '♀'} ${getUserAge(
      userMetadata,
    )} ${i18n.t('invitation.age')}`,
    ...(userMetadata?.job ? [userMetadata?.job] : []),
    ...(userMetadata?.industry ? [userMetadata?.industry] : []),
    ...(userMetadata?.lifestyle || []),
    ...(isEmpty(
      USER_DATING.filter(i => includes(userMetadata?.dating_standard, i)),
    )
      ? []
      : [
          map(
            USER_DATING.filter(i => includes(userMetadata?.dating_standard, i)),
            i18n.t,
          ).join(' / '),
        ]),
  ];

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.header}>
          <ImageBackground
            source={images.Background}
            style={styles.image}
            resizeMode="cover">
            {/* <TouchableOpacity
              onPress={() => {
                setVisibleShare(true);
              }}>
              <Image
                source={images.ProfileCard}
                resizeMode="contain"
                style={styles.profileIcon}
              />
            </TouchableOpacity> */}
            <View>
              <Menu
                anchor={renderToggleButton()}
                items={[
                  ...(userData.role === USER_ROLE.ADMIN
                    ? [
                        {
                          title: 'Admin',
                          onPress: onItemSelect(MENU_INDEX.admin),
                          titleStyle: styles.menuItem,
                        },
                      ]
                    : [
                        {
                          title: i18n.t('home.my_trip'),
                          onPress: onItemSelect(MENU_INDEX.myTrip),
                          titleStyle: styles.menuItem,
                        },
                      ]),

                  {
                    title: i18n.t('logout'),
                    onPress: onItemSelect(MENU_INDEX.logout),
                    titleStyle: styles.menuItem,
                  },
                ]}
              />
            </View>
          </ImageBackground>
        </View>
        <View style={styles.userWrapper}>
          <UserCard
            userId={userMetadata.id}
            source={
              getUserPhoto(userMetadata)
                ? {uri: getUserPhoto(userMetadata)}
                : undefined
            }
            userName={userMetadata.name}
            follower={`${userMetadata?.followers?.length || 0} ${i18n.t(
              'follower',
            )}`}
            following={`${userMetadata?.following?.length || 0} ${i18n.t(
              'following',
            )}`}
            isOrganizer={userData.role === USER_ROLE.ORGANIZER}
          />
        </View>
        <Text style={styles.bio}>{userMetadata.bio}</Text>
        <View style={styles.pillWrapper}>
          {hobby.map((data, index) => {
            return <Pill content={data} key={index} />;
          })}
        </View>
        <View style={styles.divider} />
        <TouchableOpacity
          onPress={() => {
            navigation.navigate(routeNames.MainFlow.EditProfile, {
              isFromMyProfile: true,
            });
          }}
          style={styles.edit}>
          <View style={styles.contentWrapper}>
            <Image
              source={images.UserEdit}
              resizeMode="contain"
              style={styles.editIcon}
            />
            <Text style={styles.editText}>{i18n.t('edit_profile')}</Text>
          </View>
        </TouchableOpacity>
        <View style={styles.bottomWrapper}>
          {/* <View style={styles.achievementWrapper}>
            <Text style={styles.achievements}>{i18n.t('achievements')}</Text>
          </View>
          <ScrollView
            horizontal
            style={styles.achievement}
            showsHorizontalScrollIndicator={false}>
            {userAchievement.map((achievement, index) => {
              return <Achievement key={index} achievement={achievement} />;
            })}
          </ScrollView> */}
          <Image
            source={images.Divider}
            resizeMode="contain"
            style={styles.dividerImg}
          />
          <View style={styles.journey}>
            <ImageBackground
              style={styles.journeyBackground}
              source={images.Journey}
              resizeMode="contain">
              <Text style={styles.story}>{i18n.t('my_story')}</Text>
            </ImageBackground>
          </View>
          <Story userStory={userStory} />
        </View>
      </ScrollView>
      <ShareCode
        value={`${API_URL}/user/${userData.id}`}
        visible={visibleShare}
        onClose={() => {
          setVisibleShare(false);
        }}
      />
    </View>
  );
};

export default MainProfile;
