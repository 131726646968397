import {View, Image, TouchableOpacity, ScrollView, Text} from 'react-native';
import React from 'react';
import {useNavigation} from '@react-navigation/native';

import styles from './styles';
import routeNames from 'src/navigation/routeNames';
import images from 'src/assets/images';
import {useSelector} from 'react-redux';
import {filter, find, forEach, toNumber} from 'lodash';
import {textFont} from 'src/utils/styles';
import {colors} from 'src/constants';

const MAX_SHOW_MEMBER = 6;

const Member = ({
  members,
  maximumMember = MAX_SHOW_MEMBER,
  emptyComponent,
  shouldNavigateUserDetail = true,
  invitedList,
  containerStyle = {},
  itemStyle = {},
  shouldShowInvite = true,
  handleInvite = () => {},
  creatorId,
}) => {
  const navigation = useNavigation();
  const userData = useSelector(state => state.user.data);
  const currentUser = find(members, i => i.id === userData.id);
  const filteredMember = filter(members, i => i.id !== userData.id);
  if (currentUser) {
    filteredMember.splice(
      filteredMember.length > 0 && userData.id !== creatorId ? 1 : 0,
      0,
      currentUser,
    );
  }
  let listConcat = [...filteredMember];
  forEach(filteredMember, item => {
    if (toNumber(item.accompany_persons)) {
      listConcat = listConcat.concat(
        new Array(toNumber(item.accompany_persons))
          .fill({})
          .map((_, index) => ({
            id: `${item.id}_${index}`,
            isAccompany: true,
          })),
      );
    }
  });

  const listMember = listConcat
    .map(i => ({...i, isMember: true}))
    // .concat(invitedList || [])
    .concat(new Array(maximumMember).fill({}))
    .slice(0, maximumMember);

  const handleGoToUserDetail = userId => () => {
    if (shouldNavigateUserDetail) {
      navigation.navigate(
        routeNames.NonAuthFlow.UserDetail,
        userId
          ? {
              userId,
            }
          : undefined,
      );
    }
  };
  return (
    <ScrollView
      horizontal
      scrollEnabled={maximumMember > MAX_SHOW_MEMBER}
      containerStyle={[containerStyle]}>
      {listMember.map((i, index) => {
        if (i.id) {
          const userPhoto = i.avatar;
          return (
            <TouchableOpacity
              key={index}
              onPress={handleGoToUserDetail(i.isAccompany ? undefined : i.id)}>
              {i.isAccompany ? (
                <Image
                  source={images.MockAvatar}
                  style={[styles.avatar, itemStyle]}
                />
              ) : userPhoto ? (
                <Image
                  source={{uri: userPhoto}}
                  style={[styles.avatar, itemStyle]}
                />
              ) : (
                <View style={[styles.avatar, itemStyle]} />
              )}
              {/* {i.isMember && (
                <View style={styles.success}>
                  <Image source={images.Check} style={styles.check} />
                </View>
              )} */}
            </TouchableOpacity>
          );
        } else {
          if (emptyComponent) {
            return (
              <View style={[styles.avatar, itemStyle]} key={index}>
                {emptyComponent}
              </View>
            );
          } else {
            return (
              <TouchableOpacity
                disabled={!shouldShowInvite}
                onPress={handleInvite}
                key={index}
                style={[styles.buttonAdd, itemStyle]}>
                {shouldShowInvite && (
                  <Text style={[textFont(20, '600'), {color: colors.neutral6}]}>
                    +
                  </Text>
                )}
              </TouchableOpacity>
            );
          }
        }
      })}
    </ScrollView>
  );
};

export default Member;
