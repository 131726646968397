import {StyleSheet} from 'react-native';

import {calculateWidth, windowHeight} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';
import {colors} from 'src/constants';

export default StyleSheet.create({
  header: {
    flexDirection: 'row',
    paddingVertical: calculateWidth(24),
    paddingHorizontal: calculateWidth(16),
    alignItems: 'center',
  },
  divider: {
    backgroundColor: colors.neutral7,
    height: 1,
  },
  headerTitle: {
    ...textFont(18, '600'),
    color: colors.neutral1,
    flex: 1,
  },
  headerButton: {
    ...textFont(18, '600'),
    color: colors.primary,
  },

  modal: {
    flex: 1,
    backgroundColor: 'rgba(18, 24, 16, 0.4)',
    width: calculateWidth(375),
    alignSelf: 'center',
  },
  contentPicker: {
    borderWidth: 1,
    flex: 1,
    height: windowHeight / 2,
    backgroundColor: colors.neutral8,
  },
  container: {
    flex: 1,
    backgroundColor: colors.neutral9,
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
  },

  closeIcon: {
    width: calculateWidth(18),
    height: calculateWidth(18),
    marginRight: 16,
  },

  upload: {
    ...textFont(14, '400'),
    color: colors.neutral4,
  },
  gallery: {
    width: 20,
    height: 20,
    marginBottom: 6,
  },
  thumb: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  content: {
    flex: 1,
  },

  inputStyle: {
    borderColor: colors.primary,
    borderStyle: 'dashed',
    borderWidth: 1,
    flex: 1,
    margin: calculateWidth(16),
    paddingHorizontal: calculateWidth(16),
    ...textFont(18, '400'),
    color: colors.neutral1,
  },
});
