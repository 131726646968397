import React from 'react';
import {ScrollView} from 'react-native';
import {range} from 'lodash';

import MonthCard from './MonthCard';

import styles from './styles';

const MONTHS = range(1, 13);

const MonthPicker = ({currentMonth, onChange}) => {
  return (
    <ScrollView
      style={styles.container}
      horizontal
      showsHorizontalScrollIndicator={false}>
      {MONTHS.map((m, index) => (
        <MonthCard
          key={index}
          month={m}
          onPress={onChange}
          active={currentMonth === m}
        />
      ))}
    </ScrollView>
  );
};

export default MonthPicker;
