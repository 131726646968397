import {useNavigation} from '@react-navigation/native';
import {forEach, map} from 'lodash';
import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import routeNames from 'src/navigation/routeNames';
import {getUserPhoto} from 'src/utils/common';
import styles from './styles';

const Story = ({userStory}) => {
  const navigation = useNavigation();

  const renderItem = ({storyData, index}) => {
    return (
      <TouchableOpacity
        key={index}
        style={styles.container}
        onPress={() => {
          navigation.navigate(routeNames.NonAuthFlow.StoryDetail, {
            storyId: storyData.cuid,
          });
        }}>
        <Image source={{uri: storyData.background}} style={styles.image} />
        <Text style={styles.des} numberOfLines={2}>
          {storyData.title}
        </Text>
        <View style={styles.row}>
          <Image
            source={{uri: getUserPhoto(storyData.publisher)}}
            style={styles.avatar}
          />
          <Text style={styles.name} numberOfLines={2}>
            {storyData.publisher.name}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const {leftColumnData, rightColumnData} = React.useMemo(() => {
    const leftData = [];
    const rightData = [];
    forEach(userStory, (item, index) => {
      if (index % 2 === 0) {
        leftData.push(item);
      } else {
        rightData.push(item);
      }
    });
    return {
      leftColumnData: leftData,
      rightColumnData: rightData,
    };
  }, [userStory]);

  return (
    <View style={styles.homeWrapper}>
      <View style={styles.columnWrapper}>
        {map(leftColumnData, (item, index) =>
          renderItem({storyData: item, index: index}),
        )}
      </View>
      <View style={styles.columnWrapper}>
        {map(rightColumnData, (item, index) =>
          renderItem({storyData: item, index: index}),
        )}
      </View>
    </View>
  );
};

export default Story;
