import {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {trackEventRequest} from '../requests';

export const useTrackEvent = ({failureCallback, successCallback}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const dispatch = useDispatch();

  const runRequest = useCallback(
    params => {
      setIsLoading(true);
      dispatch(
        trackEventRequest({
          params,
          failureCallback: err => {
            setIsLoading(false);
            failureCallback?.(err);
          },
          successCallback: data => {
            setResponseData(data);
            setIsLoading(false);
            successCallback?.(data);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  return {
    isLoading,
    runRequest,
    responseData,
  };
};
