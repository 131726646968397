import {createSlice} from '@reduxjs/toolkit';
import {
  addActivityRequest,
  getListActivityRequest,
  getActivityDetailRequest,
  initTripFromActivityRequest,
  getMarketActivityRequest,
  getOrderDetailRequest,
  getSharePosterRequest,
  scanTicketMarketRequest,
  getSharePosterTripRequest,
} from 'src/api/activities';
import {logout} from 'src/api/auth';

const initialState = {
  error: false,
  isLoading: false,
  isLoadingGetList: false,
  errorGetList: false,
  listActivity: [],

  isLoadingGetActivityDetail: false,
  errorGetActivityDetail: false,

  isLoadingInitTrip: false,
  errorInitTrip: false,

  isLoadingGetMarketActivity: false,
  errorGetMarketActivity: false,

  isLoadingGetOrderDetail: false,
  isLoadingGetSharePoster: false,

  isLoadingScanTicket: false,

  isLoadingGetSharePosterTrip: false,
};

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(addActivityRequest.pending, state => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(addActivityRequest.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
    builder.addCase(addActivityRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
    });

    builder.addCase(getListActivityRequest.pending, state => {
      state.isLoadingGetList = true;
      state.errorGetList = false;
    });
    builder.addCase(getListActivityRequest.rejected, state => {
      state.isLoadingGetList = false;
      state.errorGetList = true;
    });
    builder.addCase(getListActivityRequest.fulfilled, (state, action) => {
      state.isLoadingGetList = false;
      state.errorGetList = false;
      state.listActivity = action.payload.data;
    });

    builder.addCase(getActivityDetailRequest.pending, state => {
      state.isLoadingGetActivityDetail = true;
      state.errorGetActivityDetail = false;
    });
    builder.addCase(getActivityDetailRequest.rejected, state => {
      state.isLoadingGetActivityDetail = false;
      state.errorGetActivityDetail = true;
    });
    builder.addCase(getActivityDetailRequest.fulfilled, state => {
      state.isLoadingGetActivityDetail = false;
      state.errorGetActivityDetail = false;
    });

    builder.addCase(initTripFromActivityRequest.pending, state => {
      state.isLoadingInitTrip = true;
      state.errorInitTrip = false;
    });
    builder.addCase(initTripFromActivityRequest.rejected, state => {
      state.isLoadingInitTrip = false;
      state.errorInitTrip = true;
    });
    builder.addCase(initTripFromActivityRequest.fulfilled, state => {
      state.isLoadingInitTrip = false;
      state.errorInitTrip = false;
    });

    builder.addCase(getMarketActivityRequest.pending, state => {
      state.isLoadingGetMarketActivity = true;
      state.errorGetMarketActivity = false;
    });
    builder.addCase(getMarketActivityRequest.rejected, state => {
      state.isLoadingGetMarketActivity = false;
      state.errorGetMarketActivity = true;
    });
    builder.addCase(getMarketActivityRequest.fulfilled, state => {
      state.isLoadingGetMarketActivity = false;
      state.errorGetMarketActivity = false;
    });

    builder.addCase(getOrderDetailRequest.pending, state => {
      state.isLoadingGetOrderDetail = true;
    });
    builder.addCase(getOrderDetailRequest.rejected, state => {
      state.isLoadingGetOrderDetail = false;
    });
    builder.addCase(getOrderDetailRequest.fulfilled, state => {
      state.isLoadingGetOrderDetail = false;
    });

    builder.addCase(getSharePosterRequest.pending, state => {
      state.isLoadingGetSharePoster = true;
    });
    builder.addCase(getSharePosterRequest.rejected, state => {
      state.isLoadingGetSharePoster = false;
    });
    builder.addCase(getSharePosterRequest.fulfilled, state => {
      state.isLoadingGetSharePoster = false;
    });

    builder.addCase(getSharePosterTripRequest.pending, state => {
      state.isLoadingGetSharePosterTrip = true;
    });
    builder.addCase(getSharePosterTripRequest.rejected, state => {
      state.isLoadingGetSharePosterTrip = false;
    });
    builder.addCase(getSharePosterTripRequest.fulfilled, state => {
      state.isLoadingGetSharePosterTrip = false;
    });

    builder.addCase(scanTicketMarketRequest.pending, state => {
      state.isLoadingScanTicket = true;
    });
    builder.addCase(scanTicketMarketRequest.rejected, state => {
      state.isLoadingScanTicket = false;
    });
    builder.addCase(scanTicketMarketRequest.fulfilled, state => {
      state.isLoadingScanTicket = false;
    });

    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

const {reducer: activitiesReducer} = activitiesSlice;

export default activitiesReducer;
