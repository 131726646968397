import {
  View,
  SafeAreaView,
  ScrollView,
  RefreshControl,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  Linking,
} from 'react-native';
import {HomeHeader} from 'src/components';
import React, {useEffect, useRef} from 'react';

import styles from './styles';
import {StoryItem, PublicTripItem, PlanItem} from './components';
import {isEmpty, map, max} from 'lodash';
import {useGetHomeData} from 'src/api/home';
import {useDispatch, useSelector} from 'react-redux';
import images from 'src/assets/images';
import {useIsFocused, useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import {colors, HOME_ITEM_TYPE} from 'src/constants';
import {
  removeActivity,
  removeInvitationId,
  removeMarketActivity,
  removeTripId,
} from 'src/store/slices/user/userSlice';

import Swiper from 'react-native-web-swiper';
import {calculateWidth} from 'src/utils/dimensions';
import BazaarItem from './components/BazaarItem';

const MainHome = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const fetchDataTimeout = useRef();
  // const timeStampGetSuggestUser = useSelector(
  //   state => state.user.timeStampGetSuggestUser,
  // );

  // const {show} = useContext(FollowFriendContext);

  const {runRequest: fetchHomeData, isLoading} = useGetHomeData({
    successCallback: () => {
      fetchDataTimeout.current = setTimeout(fetchHomeData, 30000);
    },
  });

  // const {runRequest: getSuggestUser} = useGetSuggestUser({
  //   successCallback: response => {
  //     if (!isEmpty(response.data)) {
  //       show();
  //     }
  //   },
  // });

  const getHomeData = () => {
    if (!isLoading) {
      clearFetchTimeout();
      fetchHomeData();
      // Platform.OS === 'web' && isWeChat() && getWechatConfig();
    }
  };

  const data = useSelector(state => state.home.data);

  const clearFetchTimeout = () => {
    if (fetchDataTimeout.current) {
      clearTimeout(fetchDataTimeout.current);
      fetchDataTimeout.current = undefined;
    }
  };

  useEffect(() => {
    if (isFocused) {
      getHomeData();
    } else {
      clearFetchTimeout();
    }
    return () => {
      clearFetchTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  // useEffect(() => {
  //   let timeoutId;
  //   if (!timeStampGetSuggestUser || !dayjs(timeStampGetSuggestUser).isToday()) {
  //     timeoutId = setTimeout(() => {
  //       getSuggestUser();
  //     }, 60000);
  //   }
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [timeStampGetSuggestUser]);

  // <----- Start handle deeplink go back to item ---->

  const pendingInviteId = useSelector(state => state.user.invitationId);
  useEffect(() => {
    if (pendingInviteId) {
      navigation.navigate(routeNames.NonAuthFlow.InvitationDetail, {
        invitationId: pendingActivityId,
      });
      dispatch(removeInvitationId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingInviteId]);

  const marketActivityId = useSelector(state => state.user.marketActivityIds);
  useEffect(() => {
    if (marketActivityId) {
      navigation.navigate(routeNames.NonAuthFlow.MarketActivity, {
        activityId: marketActivityId,
      });
      dispatch(removeMarketActivity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketActivityId]);

  const pendingActivityId = useSelector(state => state.user.activityId);
  useEffect(() => {
    if (pendingActivityId) {
      navigation.navigate(routeNames.NonAuthFlow.PlanDetail, {
        activityId: pendingActivityId,
      });
      dispatch(removeActivity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingActivityId]);

  const pendingTripId = useSelector(state => state.user.tripId);
  useEffect(() => {
    if (pendingTripId) {
      navigation.navigate(routeNames.NonAuthFlow.TripDetail, {
        tripId: pendingTripId,
      });
      dispatch(removeTripId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingTripId]);

  // <----- Start handle deeplink go back to item ---->

  const renderItem = ({item, index}) => {
    switch (item.type) {
      case HOME_ITEM_TYPE.STORY:
        return <StoryItem key={index} item={item} />;
      case HOME_ITEM_TYPE.TRIP:
        return <PublicTripItem key={index} item={item} />;
      case HOME_ITEM_TYPE.PLAN:
        return <PlanItem key={index} item={item} />;
      case HOME_ITEM_TYPE.BAZAAR_TRIP:
        return <BazaarItem key={index} />;
      default:
        break;
    }
  };

  const {leftColumnData, rightColumnData} = React.useMemo(() => {
    const leftData = [];
    const rightData = [];
    const tripInviteData = (data || []).filter(
      i => i.type === HOME_ITEM_TYPE.TRIP,
    );
    const storyData = (data || []).filter(i => i.type === HOME_ITEM_TYPE.STORY);
    const planData = (data || []).filter(i => i.type === HOME_ITEM_TYPE.PLAN);
    const homeData = [];
    for (
      let index = 0;
      index < max([tripInviteData.length, storyData.length, planData.length]);
      index++
    ) {
      if (tripInviteData[index]) {
        homeData.push(tripInviteData[index]);
      }
      if (storyData[index]) {
        homeData.push(storyData[index]);
      }
      // if (planData[index]) {
      //   homeData.push(planData[index]);
      // }
    }
    !isEmpty(data) && homeData.splice(1, 0, {type: HOME_ITEM_TYPE.BAZAAR_TRIP});
    homeData.forEach((item, index) => {
      if (index % 2 === 0) {
        leftData.push(item);
      } else {
        rightData.push(item);
      }
    });
    return {
      leftColumnData: leftData,
      rightColumnData: rightData,
    };
  }, [data]);

  return (
    <SafeAreaView style={styles.container}>
      <HomeHeader withSearchButton />
      <ScrollView
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={true}
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={getHomeData} />
        }>
        <Swiper
          timeout={0}
          loop={true}
          // eslint-disable-next-line react-native/no-inline-styles
          innerContainerStyle={{
            height: calculateWidth(170),
            position: 'relative',
          }}
          controlsProps={{
            dotsPos: 'bottom',
            DotComponent: ({isActive}) => (
              <View
                style={
                  isActive ? styles.dotActiveStyle : styles.dotsNormalStyle
                }
              />
            ),
            dotsWrapperStyle: {
              marginBottom: calculateWidth(20),
              display: 'none',
            },
            prevPos: false,
            nextPos: false,
          }}>
          <View style={styles.slideContainer}>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(
                  'https://mp.weixin.qq.com/s/88zSoSVLPeRdWw27GwKpdQ',
                );
              }}>
              <Image
                source={images.HomeBackground}
                style={styles.promotion}
                imageStyle={styles.imageStyle}
              />
            </TouchableOpacity>
          </View>
        </Swiper>
        {isLoading && <ActivityIndicator color={colors.primary} />}
        <View style={styles.homeWrapper}>
          <View style={styles.columnWrapper}>
            {map(leftColumnData, (item, index) =>
              renderItem({item, index: index + 1}),
            )}
          </View>
          <View style={styles.columnWrapper}>
            {map(rightColumnData, (item, index) => renderItem({item, index}))}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default MainHome;
