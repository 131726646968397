import {View, Text, Modal, TouchableOpacity, ScrollView} from 'react-native';
import React from 'react';
import styles from './styles';
import {Button} from 'src/components';
import {useTranslations} from 'src/hooks';

const ModalDisclaimer = ({
  show,
  onClose,
  onNext,
  activityData,
  isHideButton,
  title,
}) => {
  const {i18n} = useTranslations();
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={show}
      onRequestClose={onClose}>
      <View style={styles.container}>
        <View style={styles.popup}>
          <Text style={styles.headerAlign}>
            {title || i18n.t('trip_detail.disclaimers')}
          </Text>
          <ScrollView>
            {(activityData?.disclaimers || []).map((disclaimer, index) => {
              return (
                <View style={styles.disclaimerItem} key={index}>
                  <View>
                    <Text style={styles.disclaimerTitle}>
                      {('00' + (index + 1)).slice(-2)}
                    </Text>
                  </View>
                  <Text style={styles.disclaimerDetail}>{disclaimer}</Text>
                </View>
              );
            })}
          </ScrollView>
          {!isHideButton && (
            <Button
              title={i18n.t('activity_detail.confirm')}
              buttonStyle={styles.bottomButton}
              onClick={onNext}
            />
          )}
          <TouchableOpacity style={styles.buttonClose} onPress={onClose}>
            <Text style={styles.textClose}>+</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ModalDisclaimer;
