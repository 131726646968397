import {View, Modal} from 'react-native';
import React, {useCallback, useState} from 'react';
import styles from './styles';
import Header from '../Header';

import Swiper from 'react-native-web-swiper';
import {map} from 'lodash';
import ImageRatio from '../ImageRatio';
import {windowHeight} from 'src/utils/dimensions';
import {HEADER_HEIGHT} from 'src/constants';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const ImageView = ({
  visible = false,
  setVisible = () => {},
  imageList = [],
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const insets = useSafeAreaInsets();

  const handleClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <Modal
      visible={visible}
      transparent
      animationType="fade"
      onRequestClose={handleClose}>
      <View style={[styles.container, {paddingTop: insets.top}]}>
        <Header
          withBackButton
          withRightButton={false}
          isDarkHeader
          title={`${currentIndex + 1}/${imageList.length}`}
          onPressBackButton={handleClose}
        />
        <Swiper controlsEnabled={false} onIndexChanged={setCurrentIndex}>
          {map(imageList, (item, index) => {
            return (
              <View key={index} style={styles.imageWrapper}>
                <ImageRatio
                  source={{uri: item}}
                  imageHeight={windowHeight - HEADER_HEIGHT}
                />
              </View>
            );
          })}
        </Swiper>
      </View>
    </Modal>
  );
};

export default ImageView;
