import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';

export default StyleSheet.create({
  container: {
    borderStyle: 'dashed',
    borderColor: colors.neutral6,
    borderWidth: 1,
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    justifyContent: 'space-between',
  },
  image: {
    width: 20,
    height: 20,
  },
  icon: {
    width: 20,
    height: 20,
    tintColor: colors.neutral1,
    marginRight: calculateWidth(12),
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginRight: 40,
  },
});
