import {Dimensions, Platform} from 'react-native';

export const windowHeight = Dimensions.get('window').height;
export const windowWidth =
  Platform.OS === 'web'
    ? Dimensions.get('window').width > 450
      ? 450
      : Dimensions.get('window').width
    : Dimensions.get('window').width;

export const DESIGN_WIDTH = 375;
export const DESIGN_HEIGH = 812;

export const calculateWidth = width => {
  return (width * windowWidth) / DESIGN_WIDTH;
};

export const calculateHeight = height => {
  return (height * windowHeight) / DESIGN_HEIGH;
};
