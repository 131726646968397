import React from 'react';
import {Text, Platform, ScrollView} from 'react-native';

import {AdminTextInput} from 'src/components';

const RNKeyboardScrollView = Platform.select({
  native: () => require('react-native-keyboard-aware-scroll-view'),
  default: () => ({KeyboardAwareScrollView: ScrollView}),
})();

import styles from './styles';

const EditVolunteer = ({
  goals,
  contribute,
  onChangeGoals,
  onChangeContribute,
}) => {
  return (
    <RNKeyboardScrollView.KeyboardAwareScrollView style={styles.vContainer}>
      <Text style={styles.vLabel}>Goal of the activity</Text>
      <AdminTextInput
        value={goals}
        onChangeText={onChangeGoals}
        textStyle={styles.vInput}
        viewStyle={styles.vInputContainer}
      />
      <Text style={styles.vLabel}>Contribute</Text>
      <AdminTextInput
        value={contribute}
        onChangeText={onChangeContribute}
        textStyle={styles.vInput}
        viewStyle={styles.vInputContainer}
      />
    </RNKeyboardScrollView.KeyboardAwareScrollView>
  );
};

export default EditVolunteer;
