import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(18, 24, 16, 0.4)',
  },
  content: {
    zIndex: 100,
    position: 'absolute',
    bottom: 0,
    height: calculateWidth(300),
    width: calculateWidth(375),
    backgroundColor: colors.neutral8,
    paddingTop: calculateWidth(32),
    alignSelf: 'center',
  },
  wrapper: {
    height: calculateWidth(200),
    backgroundColor: colors.neutral9,
    paddingVertical: calculateWidth(24),
    paddingHorizontal: calculateWidth(16),
    marginTop: calculateWidth(16),
    marginHorizontal: calculateWidth(32),
    borderRadius: calculateWidth(12),
    justifyContent: 'space-between',
  },

  border: {
    borderWidth: 0.5,
    borderStyle: 'dashed',
    marginVertical: calculateWidth(1),
    borderColor: colors.neutral7,
  },

  text15400: {
    ...textFont(15, '400'),
    color: colors.neutral1,
    textTransform: 'uppercase',
    textAlign: 'center',
  },

  text16600: {
    ...textFont(16, '600'),
    color: colors.primary,
  },

  textOr: {
    backgroundColor: colors.neutral9,
    width: calculateWidth(43),
    textAlign: 'center',
    ...textFont(15, '400'),
    color: colors.neutral4,
    lineHeight: calculateWidth(20),
  },
  moveOrText: {
    position: 'absolute',
    top: calculateWidth(-8),
    alignSelf: 'center',
  },

  profile: {padding: 0},

  buttonFind: {
    height: calculateWidth(48),
    backgroundColor: colors.neutral8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: calculateWidth(8),
  },

  overlap: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

export default styles;
