import React, {useState} from 'react';
import {View, Text, Image, Platform, ScrollView} from 'react-native';

const RNKeyboardScrollView = Platform.select({
  native: () => require('react-native-keyboard-aware-scroll-view'),
  default: () => ({KeyboardAwareScrollView: ScrollView}),
})();

import images from 'src/assets/images';

import {
  AdminModal,
  ImageSelector,
  AdminTextField,
  AdminEditModal,
} from 'src/components';
import EditHeading from './EditHeading';
import EditLocation from './EditLocation';
import EditIntroduction from './EditIntroduction';
import EditVolunteer from './EditVolunteer';
import EditPrice from './EditPrice';
import EditPreparing from './EditPreparing';

import styles from './styles';

const EDIT_TYPE = {
  heading: 'HEADING',
  location: 'LOCATION',
  introduction: 'INTRODUCTION',
  volunteer: 'VOLUNTEER',
  prices: 'PRICES',
  preparing: 'PREPARING',
};

const EditActivity = ({isVisible, setVisible}) => {
  const [bgImage, setBgImage] = useState(
    'https://images.unsplash.com/photo-1571501679680-de32f1e7aad4',
  );

  const [editVisible, setEditVisible] = useState(false);
  const [editType, setEditType] = useState(EDIT_TYPE.heading);
  const [heading, setHeading] = useState('Hiking moutain mount roskill');
  const [location, setLocation] = useState('Lake Geneland, Stanhaven');
  const [introduction, setIntroduction] = useState('Introduction');
  const [vGoals, setVGoals] = useState('');
  const [vContribute, setVContribute] = useState('');
  const [minPerson, setMinPerson] = useState(1);
  const [maxPerson, setMaxPerson] = useState(8);
  const [prices, setPrices] = useState([
    {personsCount: 1, price: 3000},
    {personsCount: 2, price: 5000},
  ]);
  const [preparing, setPreparing] = useState([]);

  const onCloseEdit = () => {
    setEditVisible(false);
  };
  const onDoneEdit = () => {
    setEditVisible(false);
  };

  const onSetMin = value => {
    setMinPerson(value ? parseInt(value, 10) : '');
  };
  const onSetMax = value => {
    setMaxPerson(value ? parseInt(value, 10) : '');
  };

  const thumbnailIcon = (
    <View style={styles.thumbIcon}>
      <Image source={images.Gallery} style={styles.thumbGallery} />
      <Text style={styles.upload}>Change photo (JPG,PNG)</Text>
    </View>
  );

  const renderEditContent = () => {
    switch (editType) {
      case EDIT_TYPE.heading:
        return <EditHeading value={heading} onChangeText={setHeading} />;
      case EDIT_TYPE.location:
        return <EditLocation value={location} onChangeText={setLocation} />;
      case EDIT_TYPE.introduction:
        return (
          <EditIntroduction
            value={introduction}
            onChangeText={setIntroduction}
          />
        );
      case EDIT_TYPE.volunteer:
        return (
          <EditVolunteer
            goals={vGoals}
            contribute={vContribute}
            onChangeGoals={setVGoals}
            onChangeContribute={setVContribute}
          />
        );
      case EDIT_TYPE.prices:
        return (
          <EditPrice
            minPerson={minPerson}
            maxPerson={maxPerson}
            setMinPerson={onSetMin}
            setMaxPerson={onSetMax}
            prices={prices}
            setPrices={setPrices}
          />
        );
      case EDIT_TYPE.preparing:
        return (
          <EditPreparing preparing={preparing} setPreparing={setPreparing} />
        );
      default:
        return <></>;
    }
  };

  const renderEditTitle = () => {
    switch (editType) {
      case EDIT_TYPE.heading:
        return 'Edit heading';
      case EDIT_TYPE.location:
        return 'Edit location';
      case EDIT_TYPE.introduction:
        return 'Edit Introduction';
      case EDIT_TYPE.introduction:
        return 'Edit Volunteer information';
      case EDIT_TYPE.prices:
        return 'Edit prices';
      case EDIT_TYPE.preparing:
        return 'Edit preparing';
      default:
        break;
    }
  };

  return (
    <AdminModal
      isVisible={isVisible}
      setVisible={setVisible}
      title="Edit Activity">
      <RNKeyboardScrollView.KeyboardAwareScrollView
        style={styles.scroll}
        showsVerticalScrollIndicator={false}>
        <ImageSelector
          containerStyle={styles.imagePicker}
          thumbnailIcon={thumbnailIcon}
          image={bgImage}
          onSelectImage={img => setBgImage(img)}
          thumbnailStyle={styles.thumbnailStyle}
        />
        <View style={styles.wrapper}>
          <AdminTextField
            content={heading}
            textStyle={styles.name}
            containerStyle={styles.bottom}
            onEdit={() => {
              setEditType(EDIT_TYPE.heading);
              setEditVisible(true);
            }}
          />
          <AdminTextField
            content={location}
            textStyle={styles.location}
            icon={images.Location}
            containerStyle={styles.bottom}
            onEdit={() => {
              setEditType(EDIT_TYPE.location);
              setEditVisible(true);
            }}
          />
          <AdminTextField
            content={'Introduction'}
            textStyle={styles.intro}
            containerStyle={styles.bottom}
            onEdit={() => {
              setEditType(EDIT_TYPE.introduction);
              setEditVisible(true);
            }}
          />
          <AdminTextField
            content={'Volunteer Activity'}
            textStyle={styles.intro}
            containerStyle={styles.bottom}
            onEdit={() => {
              setEditType(EDIT_TYPE.volunteer);
              setEditVisible(true);
            }}
          />
          <AdminTextField
            content={'Number & prices'}
            textStyle={styles.intro}
            containerStyle={styles.bottom}
            onEdit={() => {
              setEditType(EDIT_TYPE.prices);
              setEditVisible(true);
            }}
          />
          <AdminTextField
            content={'Need to prepare'}
            textStyle={styles.intro}
            containerStyle={styles.bottom}
            onEdit={() => {
              setEditType(EDIT_TYPE.preparing);
              setEditVisible(true);
            }}
          />
        </View>
      </RNKeyboardScrollView.KeyboardAwareScrollView>
      <AdminEditModal
        title={renderEditTitle()}
        visible={editVisible}
        onClose={onCloseEdit}
        onDone={onDoneEdit}>
        {renderEditContent()}
      </AdminEditModal>
    </AdminModal>
  );
};

export default EditActivity;
