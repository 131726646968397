import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    height: calculateWidth(4),
    backgroundColor: colors.neutral8,
    borderRadius: 8,
    marginVertical: calculateWidth(16),
    flexDirection: 'row',
  },
  active: {
    backgroundColor: colors.primary,
    borderRadius: 8,
  },
  thumbWrapper: {
    position: 'absolute',
    top: calculateWidth(-9),
    width: calculateWidth(37),
    height: calculateWidth(22),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderRadius: calculateWidth(48),
    borderColor: colors.primary,
    shadowColor: colors.primary,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  textThumb: {
    ...textFont(14, '600'),
    color: colors.primary,
  },
});

export default styles;
