import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  image: {width: '100%', height: 311},
  textDetail: {
    ...textFont(18, '400'),
    color: colors.neutral4,
    marginBottom: calculateWidth(10),
  },
});
