import {
  View,
  Text,
  Modal,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from 'react-native';
import React from 'react';
import styles from './styles';
import {Button} from 'src/components';
import {useTranslations} from 'src/hooks';
import images from 'src/assets/images';
import {textFont} from 'src/utils/styles';
import {colors} from 'src/constants';
import {useState} from 'react';

const ModalSuccess = ({
  show,
  onClose,
  onNext,
  title,
  nextButtonTitle,
  isLoading,
  disabled,
}) => {
  const {i18n} = useTranslations();
  const [showWechatQR, setShowWechatQR] = useState(false);
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={show}
      onRequestClose={onClose}>
      <View style={styles.container}>
        <View style={styles.popup}>
          {showWechatQR ? (
            <Image source={images.FollowUs} style={styles.code} />
          ) : (
            <View>
              <Text style={styles.headerAlign}>{i18n.t('payment')}</Text>
              <Text style={styles.textDetail}>{title}</Text>
              <Image source={images.Success} style={styles.success} />
              {/* <View style={styles.adWapper}>
            <Image
              source={images.Award}
              style={styles.award}
              resizeMode="cover"
            />
            <Text style={styles.ad}>{i18n.t('invite_user_points')}</Text>
          </View> */}
              <Button
                title={i18n.t('follow_us_wechat')}
                buttonStyle={styles.bottomButton}
                onClick={() => setShowWechatQR(true)}
                isLoading={isLoading}
                disabled={isLoading || disabled}
              />
              <TouchableOpacity
                disabled={isLoading || disabled}
                style={styles.primaryButton}
                onPress={onNext}>
                {isLoading ? (
                  <ActivityIndicator color={colors.primary} />
                ) : (
                  <Text style={[textFont(16, '700'), {color: colors.primary}]}>
                    {i18n.t(nextButtonTitle)}
                  </Text>
                )}
              </TouchableOpacity>
            </View>
          )}
          {!isLoading && (
            <TouchableOpacity
              style={styles.buttonClose}
              onPress={() => {
                if (showWechatQR) {
                  setShowWechatQR(false);
                } else {
                  onClose();
                }
              }}>
              <Text style={styles.textClose}>+</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default ModalSuccess;
