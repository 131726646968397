import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getStoryDetailRequest} from '../requests';

export const useGetStoryDetail = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(state => state.story.isLoadingGetStoryDetail);
  const [responseData, setResponseData] = useState({});
  const dispatch = useDispatch();

  const runRequest = useCallback(
    (storyId, isResetData) => {
      isResetData && setResponseData({});
      dispatch(
        getStoryDetailRequest({
          storyId,
          failureCallback: err => {
            failureCallback?.(err);
          },
          successCallback: response => {
            setResponseData(response);
            successCallback?.(response);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  return {
    isLoading,
    runRequest,
    responseData,
  };
};
