import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';

const styles = StyleSheet.create({
  container: {
    width: calculateWidth(343),
    alignSelf: 'center',
  },

  buttonClose: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    backgroundColor: 'rgba(18, 24, 16, 0.3);',
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: calculateWidth(16),
    left: calculateWidth(16),
  },
  textClose: {
    fontSize: calculateWidth(25),
    marginBottom: calculateWidth(2),
    marginLeft: calculateWidth(3),
    color: colors.neutral9,
    transform: [{rotate: '45deg'}],
  },

  buttonChoose: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    backgroundColor: 'rgba(18, 24, 16, 0.3);',
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: calculateWidth(16),
    right: calculateWidth(16),
  },
  chooseIcon: {
    width: calculateWidth(24),
    height: calculateWidth(24),
  },
});

export default styles;
