import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  flex: {flex: 1},
  container: {flex: 1, width: '100%', height: '100%', backgroundColor: '#fff'},
  content: {
    paddingHorizontal: calculateWidth(20),
    paddingBottom: calculateWidth(20),
    flex: 1,
  },
  textDescriptionWrapper: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: 'rgba(255, 166, 46, 0.5)',
    borderRadius: calculateWidth(8),
    padding: calculateWidth(12),
    marginBottom: calculateWidth(10),
  },
  textDescription: {
    ...textFont(14, '400'),
    color: colors.secondary1,
  },

  textTitle: {
    ...textFont(32, '600'),
    color: colors.neutral1,
  },

  textTitleDetail: {
    ...textFont(14, '400'),
    color: colors.neutral1,
    marginTop: calculateWidth(5),
  },

  textBottom: {
    ...textFont(15, '400'),
    color: colors.primary,
    textAlign: 'center',
    paddingBottom: calculateWidth(20),
    paddingHorizontal: calculateWidth(50),
  },

  input: {
    borderWidth: 1,
    borderRadius: calculateWidth(8),
    borderColor: colors.neutral6,
    paddingVertical: calculateWidth(10),
    paddingHorizontal: calculateWidth(15),
    ...textFont(16, '400'),
    marginTop: calculateWidth(20),
  },

  buttonSeeMore: {
    marginTop: calculateWidth(20),
    height: calculateWidth(50),
    flexDirection: 'row',
    borderRadius: calculateWidth(8),
    backgroundColor: colors.neutral9,
    alignItems: 'center',
    paddingHorizontal: calculateWidth(20),

    shadowColor: colors.neutral1,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  textSeeMore: {
    flex: 1,
    ...textFont(14, '600'),
    color: colors.primary,
    marginLeft: calculateWidth(10),
  },
  iconSeeMoreWrapper: {
    width: calculateWidth(36),
    height: calculateWidth(36),
    backgroundColor: colors.neutral8,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconSeeMore: {
    fontSize: calculateWidth(20),
  },
  icon: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
  itemsWrapper: {
    marginVertical: calculateWidth(24),
    gap: calculateWidth(16),
  },

  item: {
    flexDirection: 'row',
  },
  itemTitle: {
    ...textFont(16, '400'),
    color: colors.neutral1,
  },
  itemDescription: {
    ...textFont(14, '400'),
    color: colors.neutral5,
  },
});
export default styles;
