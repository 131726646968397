import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.neutral9,
  },
  title: {
    ...textFont(22, '600'),
    color: colors.neutral0,
    textAlign: 'center',
    padding: calculateWidth(30),
  },
  textDate: {color: colors.neutral4},
  chatContent: {flex: 1},
  inputWrapper: {
    flexDirection: 'row',
    paddingHorizontal: calculateWidth(24),
    paddingBottom: calculateWidth(24),
    backgroundColor: 'transparent',
  },
  input: {
    flex: 1,
    borderWidth: 1,
    borderColor: colors.neutral5,
    borderRadius: calculateWidth(8),
    height: calculateWidth(40),
    paddingHorizontal: calculateWidth(20),
    ...textFont(16, '400'),
    color: colors.neutral1,
  },
  buttonSend: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#D6F0D0',
    borderRadius: calculateWidth(12),
    marginLeft: calculateWidth(12),
  },
  send: {
    width: calculateWidth(24),
    height: calculateWidth(24),
  },
});

export default styles;
