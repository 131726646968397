import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {getConversationDetailUrl} from '../../endpoints';

export const getConversationDetailRequest = createAsyncThunk(
  'conversation/detail',
  async (
    {successCallback, failureCallback, conversationId},
    {rejectWithValue},
  ) => {
    try {
      const response = await axios.request({
        method: 'GET',
        url: getConversationDetailUrl(conversationId),
      });
      successCallback?.(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback?.(err);
        return rejectWithValue(err.response.data);
      }
    }
  },
);
