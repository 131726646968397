import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(18, 24, 16, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    width: calculateWidth(375),
    alignSelf: 'center',
  },
  popup: {
    width: calculateWidth(335),
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(24),
    padding: calculateWidth(36),
  },
  buttonClose: {
    width: calculateWidth(36),
    height: calculateWidth(36),
    backgroundColor: colors.neutral8,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 12,
    top: calculateWidth(-18),
  },
  textClose: {
    fontSize: calculateWidth(25),
    marginBottom: calculateWidth(2),
    marginLeft: calculateWidth(3),
    color: colors.neutral1,
    transform: [{rotate: '45deg'}],
  },

  headerAlign: {
    ...textFont(32, '600'),
    color: colors.neutral1,
    textAlign: 'center',
    marginBottom: calculateWidth(16),
  },
  bottomButton: {
    marginTop: calculateWidth(32),
  },
  nameInput: {
    ...textFont(16, '400'),
    marginTop: calculateWidth(16),
    height: calculateWidth(48),
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(8),
    color: colors.neutral1,
    borderWidth: 1,
    borderColor: colors.neutral4,
    paddingHorizontal: calculateWidth(10),
  },
  error: {
    borderColor: colors.error,
  },
  label: {
    ...textFont(11, '400'),
    position: 'absolute',
    top: calculateWidth(9),
    left: calculateWidth(10),
    paddingHorizontal: calculateWidth(2),
    backgroundColor: colors.neutral9,
  },
  errorText: {
    ...textFont(14, '400'),
    color: colors.error,
  },
});

export default styles;
