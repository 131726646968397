import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
  Modal,
} from 'react-native';

import images from 'src/assets/images';
import {useTranslations} from 'src/hooks';

import {Button} from 'src/components';

import styles from './styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useSelector} from 'react-redux';
import {getUserPhoto} from 'src/utils/common';
import ImageRatio from '../ImageRatio';
import {isEmpty, map} from 'lodash';

const StoryPreviewModal = ({
  isVisible,
  setVisible,
  storyData,
  handlePublish,
}) => {
  const userData = useSelector(state => state.user.data);
  const {i18n} = useTranslations();
  const insets = useSafeAreaInsets();

  const renderSection = () => {
    return storyData.sections.map((section, index) => {
      return (
        <View key={index}>
          {isEmpty(section.photo) ? null : (
            <View>
              {map(section.photo, (image, imageIndex) => (
                <ImageRatio
                  source={{uri: image}}
                  imageWidth={311}
                  style={styles.imageWrapper}
                  key={imageIndex}
                />
              ))}
            </View>
          )}
          {!!section.content && (
            <Text style={styles.sectionText}>{section.content}</Text>
          )}
          {typeof section.photo === 'string' && (
            <ImageRatio
              style={styles.sectionImage}
              source={{uri: section.photo}}
              imageWidth={311}
            />
          )}
        </View>
      );
    });
  };
  return (
    <Modal
      visible={isVisible}
      transparent={true}
      animationType="slide"
      onRequestClose={() => setVisible(false)}>
      <View style={[styles.modal, {paddingTop: insets.top + 30}]}>
        <View style={styles.container}>
          <View style={styles.closeContainer}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setVisible(false)}>
              <Image
                source={images.Close}
                resizeMode={'cover'}
                style={styles.closeIcon}
              />
            </TouchableOpacity>
          </View>
          <View style={styles.titleWrapper}>
            <Text style={styles.title}>{i18n.t('previewStory.review')}</Text>
          </View>
          <ScrollView showsVerticalScrollIndicator={false}>
            <ImageRatio
              imageWidth={375}
              style={styles.backgroundImage}
              source={{uri: storyData.background}}
            />
            <Text style={styles.titlePreview}>{storyData.title}</Text>
            <View style={styles.row}>
              <Text style={styles.publish}>{i18n.t('publish_by')}</Text>
              <Image
                source={{uri: getUserPhoto(userData)}}
                style={styles.avatar}
              />
              <Text style={styles.author}>{userData.name}</Text>
            </View>
            {!!storyData.location.name && (
              <View style={styles.row}>
                <Image
                  source={images.Location}
                  style={styles.location}
                  resizeMode="cover"
                />
                <Text style={styles.locationText}>
                  {storyData.location.name}
                </Text>
              </View>
            )}
            <View style={styles.contentWrapper}>{renderSection()}</View>
            {/* <View style={styles.adWapper}>
              <Image
                source={images.Award}
                style={styles.award}
                resizeMode="cover"
              />
              <Text style={styles.ad}>{i18n.t('previewStory.publish')}</Text>
            </View> */}
            <Button
              title={i18n.t('previewStory.publishStory')}
              buttonStyle={styles.add}
              onClick={handlePublish}
            />
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default StoryPreviewModal;
