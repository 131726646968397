import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';

export const ITEM_HEIGHT = calculateWidth(40);

export default StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  container: {
    height: 5 * ITEM_HEIGHT,
  },
  item: {
    height: ITEM_HEIGHT,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemFocus: {
    color: colors.neutral1,
  },
  itemNormal: {
    color: colors.neutral6,
  },
  borderTop: {
    width: '100%',
    height: 1,
    position: 'absolute',
    top: 2 * ITEM_HEIGHT,
    left: 0,
    backgroundColor: colors.neutral6,
  },
  borderBottom: {
    width: '100%',
    height: 1,
    position: 'absolute',
    top: 3 * ITEM_HEIGHT,
    left: 0,
    backgroundColor: colors.neutral6,
  },
});
