import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {addActivityUrl} from '../../endpoints';

export const addActivityRequest = createAsyncThunk(
  'admin/activity/add',
  async ({params, successCallback, failureCallback}, {rejectWithValue}) => {
    try {
      const response = await axios.request({
        method: 'POST',
        url: addActivityUrl,
        data: {...params},
      });
      successCallback?.(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback?.(err);
        return rejectWithValue(err.response.data.errors);
      }
    }
  },
);
