import {View, Text, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import styles from './styles';
import {useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import images from 'src/assets/images';
import {useTranslations} from 'src/hooks';
import {textFont} from 'src/utils/styles';
import {colors} from 'src/constants';

const StoryItem = () => {
  const navigation = useNavigation();
  const {i18n} = useTranslations();
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        navigation.navigate(routeNames.NonAuthFlow.ChooseCity);
      }}>
      <View style={styles.content}>
        <Image source={images.MarketActivityBackground} style={styles.image} />
        <Text numberOfLines={2} style={styles.title}>
          {i18n.t('screen_select_city.find_coffee_bazaars_near_you')}
        </Text>
      </View>
      <View style={styles.userWrapper}>
        <Image source={images.Logo} style={styles.avatar} />
        <View style={styles.name}>
          <Text numberOfLines={1} style={styles.textName}>
            {i18n.t('tomyvillage_official')}
          </Text>
          <Text style={[textFont(10, '400'), {color: colors.neutral2}]}>
            08/10
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default StoryItem;
