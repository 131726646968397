import React, {useEffect} from 'react';
import {store, persistor} from './store/store';
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {NavigationContainer} from '@react-navigation/native';

import {API_URL, axiosBaseConfig} from './utils/axiosConfig';

import Navigation from './navigation/Navigation';
import routeNames from './navigation/routeNames';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import {FollowFriendProvider} from './contexts/FollowFriendContext';
require('dayjs/locale/zh');
dayjs.locale('zh');
dayjs.extend(isToday);
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function App() {
  useEffect(() => {
    axiosBaseConfig();
  }, []);

  const linking = {
    prefixes: ['tomyvillage://', API_URL],
    config: {
      screens: {
        [routeNames.BottomTab]: {
          screens: {[routeNames.MainFlow.MainHome]: 'home'},
        },
        [routeNames.MainFlow.OrderDetail]: 'orderDetail/:orderId?',
        [routeNames.MainFlow.OrderConfirm]:
          'order/:activityId/:paymentStatus?/:participants?',

        [routeNames.NonAuthFlow.ChooseCity]: 'bazaar',
        [routeNames.NonAuthFlow.TripDetail]:
          'trip/:tripId/:paymentStatus?/:accompanyCount?/:tierIndex?',
        [routeNames.NonAuthFlow.InvitationDetail]:
          'invite/:invitationId/:paymentStatus?/:accompanyCount?',
        [routeNames.NonAuthFlow.StoryDetail]: 'story/:storyId/:commentId?',
        [routeNames.NonAuthFlow.VillageDetail]: 'village/:villageId',
        [routeNames.NonAuthFlow.MarketActivity]: 'marketActivity/:activityId',
        [routeNames.NonAuthFlow.PlanDetail]:
          'plan/:activityId/:tripId?/:paymentStatus?/:accompanyCount?/:tierIndex?',
        [routeNames.NonAuthFlow.UserDetail]: 'user/:userId?',

        [routeNames.AuthFlow.WelcomeScreen]:
          'loginScreen/:loginStatus?/:userId?/:token?',
      },
    },
  };

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NavigationContainer linking={linking}>
          <FollowFriendProvider>
            <Navigation />
          </FollowFriendProvider>
        </NavigationContainer>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
