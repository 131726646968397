import {createSlice} from '@reduxjs/toolkit';

import {loginRequest, logout} from 'src/api/auth';
import {updateUserProfile, getUserTripRequest} from 'src/api/user/requests';
import {getSuggestUserRequest, getUserDetailRequest} from 'src/api/users';

const initialState = {
  isLoading: false,
  error: false,
  data: {},

  isLoadingGetUserTrip: false,
  errorGetUserTrip: false,
  trip: [],

  timeStampGetSuggestUser: '',

  invitationId: '',
  marketActivityIds: '',
  activityId: '',
  tripId: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addTripId: (state, action) => {
      state.tripId = action.payload;
    },
    removeTripId: state => {
      state.tripId = '';
    },
    addInvitationId: (state, action) => {
      state.invitationId = action.payload;
    },
    removeInvitationId: state => {
      state.invitationId = '';
    },
    addMarketActivity: (state, action) => {
      state.marketActivityIds = action.payload;
    },
    removeMarketActivity: state => {
      state.marketActivityIds = '';
    },
    addActivity: (state, action) => {
      state.activityId = action.payload;
    },
    removeActivity: state => {
      state.activityId = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(updateUserProfile.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateUserProfile.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.data = action.payload.data;
    });
    builder.addCase(loginRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.data = action.payload.data;
    });

    builder.addCase(getUserTripRequest.pending, state => {
      state.isLoadingGetUserTrip = true;
      state.errorGetUserTrip = false;
      state.trip = [];
    });
    builder.addCase(getUserTripRequest.rejected, state => {
      state.isLoadingGetUserTrip = false;
      state.errorGetUserTrip = true;
    });
    builder.addCase(getUserTripRequest.fulfilled, (state, action) => {
      state.isLoadingGetUserTrip = false;
      state.errorGetUserTrip = false;
      state.trip = action.payload.data;
    });

    builder.addCase(getSuggestUserRequest.fulfilled, (state, action) => {
      state.timeStampGetSuggestUser = new Date().toISOString();
    });

    builder.addCase(getUserDetailRequest.fulfilled, (state, action) => {
      if (!state.data.id || action.payload.data.id === state.data.id) {
        state.data = action.payload.data;
      }
    });

    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

export const {
  addTripId,
  removeTripId,
  addInvitationId,
  removeInvitationId,
  addMarketActivity,
  removeMarketActivity,
  addActivity,
  removeActivity,
} = userSlice.actions;

const {reducer: userReducer} = userSlice;

export default userReducer;
