export default {
  something_went_wrong: '遇到了错误。也许是网络不好？',
  profile: {
    skip: '跳过',
    welcome: {
      sign_up: '注册',
      sign_in: '登录',
      starting: '开始',
      the_travel: '旅行',
      explore: '探索村庄',
      explore_description: '探索文化，食物，人民，参加活动...',
      travel: '一起旅行',
      travel_description: '邀请你的朋友一起加入探索新天地的旅程',
      email: '输入邮箱',
      password: '输入密码',
      confirm_pass: '确认密码',
      required: '必填',
      pass_match: '密码必须匹配',
      invalid_email: '邮件地址无效',
    },
    verifyPhone: {
      phone_number: '手机号码',
      verify_code: '验证码',
      resend: '重新发送',
      send_code: '发送验证码',
      confirm: '确认',
      validate_number_field: '只能输入数字',
      second: 's',
    },
    MyWorkAndIndustry: '我的工作和行业',
    bio: {
      title: '完善个人信息',
      the_more: '完善的个人信息能带给你更好的使用体验',
      header: '档案',
      date_of_birth: '出生日期',
      about_me: '个人简介',
      help: '帮助其他人了解你！',
      male: '男性',
      female: '女性',
      fill: '用一分钟时间填写信息，找到契合的灵魂',
      next: '下一步',
      bio: '个人简介',
      select: '选择',
    },
    college: {
      the_more: '完善的个人信息能带给你更好的使用体验',
      my_college: '毕业院校',
      help: '增加匹配成功率',
      enter_name: '输入毕业院校',
      fill: '用一分钟时间填写信息，找到契合的灵魂',
      next: '下一步',
      collge_name: '院校名称',
      placeholder: '搜索院校',
    },
    lifeStyle: {
      placeholder: '搜索兴趣',
      my_lifestyle: '我的兴趣',
      the_more: '完善的个人信息能带给你更好的使用体验',
      tell: '帮助其他人了解你！',
      fill: '用一分钟时间填写信息，找到契合的灵魂',
    },
    wish: {
      my_wish: '我的愿望',
      the: '完善的个人信息能带给你更好的使用体验',
      tell: '帮助其他人了解你！',
      fill: '用一分钟时间填写信息，找到契合的灵魂',
      placeholder: '搜索愿望',
    },
    photo: {
      my_photo: '我的个人资料照片',
      complete: '完成',
      portrait: '头像',
      travel: '旅行照片',
      hobby: '兴趣照片',
    },
  },
  home: {
    promotion_title: '夏季促销标题',
    promotion_amount: '20% 折扣',
    trip: {
      age: '岁',
    },
    my_trip: '我的行程',
    confirmed: '已确认',
    waiting_for_confirm: '等待确认',
    search: {
      village: '村庄',
      activity: '项目',
      search_village: '搜索村庄',
      nearby_village: '附近村庄',
      tourism_activity: '旅游项目',
    },
  },
  invitation: {
    become_member: '参加旅行',
    invite: '邀请你加入旅行',
    activity: '项目',
    accommodation: '住宿',
    story: '故事',
    host: '村民',
    invite_friends: '邀请朋友',
    join_now: '立即加入',
    join_point: '加入旅行获得+5积分',
    show_more: '查看更多',
    write_comment: '留言',
    find_on_we_chat: '去微信邀请',
    invited: '已邀请',
    age: '岁',
  },
  villageDetails: {
    overview: '村庄概述',
    geo: '地理区位',
    population: '人口产业',
    topo: '地形地貌山川河流',
    season: '季节',
    overviewSeason: '四季风物',
    details: '详细',
    month: '月',
    folklore: '民俗',
    nature: '风景',
    food: '美食',
    Myth: '神话历史',
    feature: '特色景点',
    history: '历史文化',
    his: '历史',
    celebrate: '名人轶事 / 神话故事',
    traditional: '村民故事',
    featured: '特色建筑',
    scenery: '自然风景',
    foodSingle: '食物',
    facing: '面临问题',
    traffic: '交通信息',
  },
  addStory: {
    create: '创建游记',
    preview: '确认预览',
    addBackground: '添加背景',
    upload: '上传照片 (png, jpg)',
    title: '游记标题',
    titlePlaceholder: '输入游记标题',
    village: '村庄名称',
    villagePlaceholder: '输入村庄',
    storyContent: '游记内容',
    paragraph: '内容段落',
    addSection: '添加板块',
    done: '完成',
    congratulation: '祝贺',
    goToHome: '回到首页',
    errorTrip: '创建旅行时遇到错误！',
    errorImage: '上传图片时遇到错误！',
    chooseVillage: '选择村庄',
  },
  previewStory: {
    review: '检查',
    publish: '发布获得+10积分',
    publishStory: '发布游记',
  },
  storyDetail: {
    storyNotFound: '游记不存在',
  },
  trip_detail: {
    user_joined: '已加入的用户',
    sign_up: '注册加入',
    learn_more: '了解更多',
    invite_you: '邀请你去...',
    invite_by: '邀请人',
    tourism_activity: '旅游活动',
    volunteer_activity: '共建活动',
    accommodation: '住宿',
    schedule: '日程',
    hosts: '村民',
    trip_cost: '旅行花费',
    disclaimers: '注意事项',
    trip_date: '旅行开始日期',
    date_placeholder: '选择起始日期',
  },
  main_admin: {
    my_activities: '我的活动',
    booked: '活动预约',
    village_management: '村庄管理',
    achi: '成就',
    payouts: '支付记录',
  },
  activity_detail: {
    next: '下一步',
    confirm: '确认支付',
    invite: '邀请朋友',
    inti_activity: '发起旅行',
    person: '人',
    public_activity: '公众可见',
    private_activity: '私人可见 (仅限邀请)',
  },
  tab_bar: {
    home: '主页',
    profile: '我',
  },
  delete: '删除',
  are_you_sure: '你确定吗?',
  done: '完成',
  back: '后退',
  or: '或者',
  publish_by: '作者',
  follower: '被关注',
  following: '关注',
  camera: '相机',
  electrical: '电子设备',
  backpack: '背包',
  cash: '现金',
  introduction: '介绍',
  volunteer_activity: '共建活动',
  accommodation: '住宿',
  hosts: '村民',
  number_of_people: '人数',
  to: '至',
  people: '人',
  need_to_prepare: '需要准备',
  trip_data: '行程日期',
  activity_init_info: '发起人享受折扣',
  confirm: '确认',
  start_trip_points: '开始旅行（+10 分)',
  invite_user_points: '邀请更多用户（+2 分)',
  person: '人',
  share: '分享',
  like: '喜欢',
  update_photo: '更改照片 (JPG, PNG)',
  create_activity: '创建活动',
  follow: '关注',
  my_story: '我的故事',
  user_story: '用户故事',
  achievements: '成就',
  edit_profile: '编辑个人资料',
  ok: '好的',
  cancel: '取消',
  un_follow_confirm: '你确定要取消关注吗？',
  cannot_found_invitation: '找不到邀请',
  invalid_invitation: '无效邀请',
  village_detail: '村庄详情',
  objective: '目标',
  day: 'Day',
  fee: '费用',
  followed: '关注',
  payment_again: '重新付款',
  join_trip_chat: '加入聊天',
  search_work_industry: '搜索工作和行业',
  wishDate: '灵魂伴侣',
  dateDescription: '希望遇到那个ta',
  wishFriend: '亲子拼团',
  friendDescription: '希望带自己的家人去看世界',
  wishNetwork: '拓展人脉',
  networkDescription: '希望认识有趣的朋友',
  unFollow: '取消关注',
  successfully_followed: '已成功关注',
  successfully_invited: '已成功邀请',
  find_friend_title: '你可能想关注这些用户',
  put_your_comment: '输入评论',
  reply_to: '回复',
  reply: '回复',
  'missing field villageId': '缺 villageId',
  'village not found': '找不到村庄',
  'missing username': '缺用户名',
  'missing password': '缺密码',
  'missing email': '缺邮箱',
  'username or email already exist': '用户名或邮箱已存在',
  'lifestyle must be a array': 'lifestyle must be a array',
  'photos must be a array': 'photos must be a array',
  'datingStandard must be a array': 'datingStandard must be a array',
  'gender: [ male, female, other ]': 'gender: [ male, female, other ]',
  'birthday should be YYYY-MM-DD format':
    'birthday should be YYYY-MM-DD format',
  'missing user': '缺用户',
  'user not found': '找不到用户',
  'username or password do not match': '用户名或密码不正确',
  'trip not found': '找不到旅行',
  'stories not found': '找不到故事',
  'you are not a publisher': '你不是发布者',
  'comment not found': '找不到评论',
  'plan not found': '找不到活动',
  'plan not found, can not init trip': '找不到活动，无法创建旅行',
  'invalid start date': '开始日期不正确',
  'invitation not found': '找不到邀请',
  'missing field tripId or trip_id': 'missing field tripId or trip_id',
  'user already joined': '该用户已经加入',
  'you not belong to this invitation': '你不是被邀请人',
  'conversation not found': '找不到聊天',
  'Something went wrong': '遇到了某些问题',
  'wrong information': '验证码输入不正确',
  'phone is used! ': '这个手机号已经被占用了',
  'rate limit': '发送太频繁，请等待一分钟再试',
  forbiden: '禁止',
  go_to_my_trip: '前往行程页面',
  trip_fulfill: '这次旅行已经满员',
  notification: '通知',
  empty: '全部已读',
  potential_teammate: '推荐好友',
  potential_teammate_description: '这位用户刚和你看过一样的村庄',
  check_their_profile: '去看看',
  your_work: '你的职位',
  your_industry: '你的行业',
  nickname: '昵称',
  gender: '性别',
  job: '工作',
  industry: '行业',
  lifestyle: '爱好',
  wish: '愿望',
  photo: '照片',
  review_profile: '预览个人资料',
  update_successful: '已成功更新',
  comment_story: '评论了你的故事',
  reply_comment: '回复了你的评论',
  like_story: '赞了你的故事',
  follow_you: '开始关注你',
  accept_invite: '接受加入',
  send_message: '发送了一条信息',
  invite_trip: '邀请你加入旅行',
  trip_upcoming: '将在三天后开始',
  logout: '登出',
  view_order: '查看订单',
  share_friend: '分享给好友',
  total_price: '总金额',
  buy_now: '立即购买',
  people_going_with_me: '同行人',
  add: '添加',
  add_people: '添加同行人',
  phone: '手机',
  phone_invalid: '手机号格式不对',
  payment: '支付',
  order_detail: '订单详情',
  please_open_wechat_payment: '请在微信内打开程序再支付',
  invite_friends_play: '邀请好友一起',
  what_included: '市集内容',
  ticket_details: '门票详情',
  paid_at: '支付时间',
  paid_for: '支付人数',
  order_id: '订单编号',
  see_more: '查看更多',
  see_less: '折叠',
  story_recommended: '你可能感兴趣',
  phone_not_valid: '手机号码格式不对',
  this_is_required: '必需项目',
  agree_with: '同意',
  payment_agreement: '支付服务协议',
  term_service: '用户服务协议',
  privacy_policy: '用户隐私政策',
  and: '和',
  related_plan: '相关旅游活动',
  initiate_new_event: '发起新活动',
  join_event: '加入活动',
  start_new_event: '发起新活动',
  enter_group_chat: '进入活动群',
  ongoing_status: '已截止',
  closed_status: '已结束',
  full_status: '已满员',
  duplicated_status: '有重复行程',
  duplicate_trip: '该日期内有重复行程，请重新选择',
  pay_for_your_friend: '帮好友付',
  share_to_invite: '或自己付完再邀请好友付',
  from: '从',
  cities: {
    beijing: '北京',
    hangzhou_jiachang: '杭州',
    hangzhou_yichang: '杭州 Yichang',
    guangzhou: '广州',
    shenzhen: '深圳',
    changsha: '长沙',
    wuhan: '武汉',
    chengdu: '成都',
    chongqing: '重庆',
    xian: '西安',
    nanjing: '南京',
  },
  screen_select_city: {
    go_to_bazaar: '去找摊主',
    find_coffee_bazaars_near_you: '寻找你身边的咖啡市集',
  },
  contact_support: '联系客服',
  got_questions: '遇到问题？点击这里',
  edit: '编辑',
  hint_download_poster: '长按下载图片',
  wechat: '微信登录',
  this_user_not_sign_up: '这位用户还没注册~',
  museum: '古村博物馆',
  invite: '邀请加入',
  to_my_village: 'ToMyVillage',
  tomyvillage_official: '远山边官方活动',
  age: '后',
  follow_us_wechat: '关注我们',
  type: '类型',
  trip_for: '群聊：',
  remove_accompany: '空位不足，请减少人数~',
  standard_version: '标准版',
  sport_version: '体育版',
  coming_soon: '近期上线，敬请期待',
  required: '必填',
  go_to_bottom: '去底部购买',
  organizer: '场地人员',
  no_permission: '您没有扫码权限',
  scan_successful: '门票入场成功',
  'invalid, ticket already used': '该门票已经扫过',
  onetime_ticket: '单日票',
};
