import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {deleteStoryUrl} from '../../endpoints';

export const deleteStoryRequest = createAsyncThunk(
  'story/deleteStory',
  async ({successCallback, failureCallback, storyId}, {rejectWithValue}) => {
    try {
      const response = await axios.request({
        method: 'DELETE',
        url: deleteStoryUrl(storyId),
      });
      successCallback?.(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback?.(err);
        return rejectWithValue(err.response.data);
      }
    }
  },
);
