const images = {
  WelcomeBackground: require('./WelcomeBackground.png'),
  Slider1: require('./Slider1.png'),
  Slider2: require('./Slider2.png'),
  Slider3: require('./Slider3.png'),
  ArrowLeft: require('./ArrowLeft.png'),
  ArrowUp: require('./ArrowUp.png'),
  ArrowDown: require('./ArrowDown.png'),
  ArrowRight: require('./ArrowRight.png'),
  Search: require('./Search.png'),
  People: require('./People.png'),
  Coffee: require('./Coffee.png'),
  Gallery: require('./Gallery.png'),
  Home: require('./Home.png'),
  Add: require('./Add.png'),
  PeopleCircle: require('./PeopleCircle.png'),
  Calendar: require('./Calendar.png'),
  CalendarWhite: require('./CalendarWhite.png'),
  Map: require('./Map.png'),
  RightBlack: require('./RightBlack.png'),
  Award: require('./Award.png'),
  Background: require('./background.png'),
  ProfileCard: require('./ProfileCard.png'),
  Hamburger: require('./Hamburger.png'),
  Circle: require('./Circle.png'),
  UserEdit: require('./UserEdit.png'),
  MockAchivement: require('./MockAchivement.png'),
  Divider: require('./Divider.png'),
  Journey: require('./Journey.png'),
  MockStory: require('./MockStory.png'),
  Location: require('./Location.png'),
  Share: require('./Share.png'),
  Like: require('./Like.png'),
  LikeGreen: require('./LikeGreen.png'),
  MockMap: require('./MockMap.png'),
  MoreArrow: require('./MoreArrow.png'),
  Send: require('./Send.png'),
  GroupPeople: require('./GroupPeople.png'),
  Clound: require('./Clound.png'),
  Rec: require('./Rec.png'),
  CalendarAnnouce: require('./CalendarAnnouce.png'),
  Book: require('./Book.png'),
  Timer: require('./Timer.png'),
  MagicStar: require('./MagicStar.png'),
  Scissor: require('./Scissor.png'),
  Building: require('./Building.png'),
  Mountain: require('./Mountain.png'),
  Bell: require('./Bell.png'),
  Close: require('./Close.png'),
  More: require('./More.png'),
  Bin: require('./Bin.png'),
  Pen: require('./Pen.png'),
  EditLine: require('./EditLine.png'),
  DollarCircle: require('./DollarCircle.png'),
  YuanCircle: require('./YuanCircle.png'),
  OrangeDot: require('./OrangeDot.png'),
  User: require('./User.png'),
  Summer: require('./Summer.png'),
  Camera: require('./Camera.png'),
  Archive: require('./Archive.png'),
  Briefcase: require('./Briefcase.png'),
  Money: require('./Money.png'),
  Success: require('./Success.png'),
  Tick: require('./Tick.png'),
  ArrowLeftWhite: require('./ArrowLeftWhite.png'),
  SearchWhite: require('./SearchWhite.png'),
  Failure: require('./Failure.png'),
  Chat: require('./Chat.png'),
  Notification: require('./Notification.png'),
  NotificationWhite: require('./NotificationWhite.png'),
  Trip: require('./Trip.png'),
  Code: require('./Code.png'),
  Check: require('./Check.png'),
  MockAvatar: require('./MockAvatar.png'),
  MarketActivityBackground: require('./MarketActivityBackground.png'),
  ContactSupport: require('./ContactSupport.jpg'),
  Wechat: require('./Wechat.png'),
  Note: require('./Note.png'),
  NoteWhite: require('./NoteWhite.png'),
  Person: require('./Person.png'),
  Pin: require('./Pin.png'),
  FollowUs: require('./FollowUs.jpg'),
  HomeBackground: require('./homeBackground.jpg'),
  Logo: require('./Logo.png'),
  Ticket: require('./Ticket.png'),
};
export default images;
