import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';

const MARGIN_RIGHT_LIST = 7.8;

const styles = StyleSheet.create({
  avatar: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    borderRadius: 100,
    backgroundColor: colors.neutral6,
    marginRight: calculateWidth(MARGIN_RIGHT_LIST),
  },
  buttonAdd: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    borderWidth: 1,
    borderColor: colors.neutral6,
    borderRadius: 100,
    marginRight: calculateWidth(MARGIN_RIGHT_LIST),
    justifyContent: 'center',
    alignItems: 'center',
  },
  check: {
    width: calculateWidth(10),
    height: calculateWidth(10),
  },
  success: {
    position: 'absolute',
    right: calculateWidth(10),
    top: calculateWidth(0),
  },
  item: {
    marginRight: calculateWidth(16),
  },
});

export default styles;
