import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {checkUserPhoneUrl} from 'src/api/endpoints';

export const checkUserPhoneRequest = createAsyncThunk(
  'users/checkUserPhone',
  async ({failureCallback, successCallback, data}, {rejectWithValue}) => {
    try {
      const response = await axios.request({
        method: 'POST',
        url: checkUserPhoneUrl,
        data,
      });
      successCallback && successCallback(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback && failureCallback(err);
        return rejectWithValue(err.response.data);
      }
    }
  },
);
