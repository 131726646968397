import {
  Text,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
  Image,
} from 'react-native';
import React from 'react';
import styles from './styles';

const Button = ({
  onClick,
  title,
  disabled,
  isLoading,
  buttonStyle = {},
  textStyle = {},
  icon = null,
}) => {
  const disableButton = disabled || isLoading;
  return (
    <TouchableOpacity
      disabled={disableButton}
      style={StyleSheet.flatten([
        disableButton ? styles.buttonDisable : styles.button,
        buttonStyle,
      ])}
      onPress={onClick}>
      {icon && <Image source={icon} style={styles.icon} />}
      {isLoading ? (
        <ActivityIndicator color={'#fff'} />
      ) : (
        <Text style={StyleSheet.flatten([styles.textButton, textStyle])}>
          {title}
        </Text>
      )}
    </TouchableOpacity>
  );
};

export default Button;
