import {View, Text, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import {useTranslations} from 'src/hooks';
import styles from './styles';
import {useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import dayjs from 'dayjs';
import {getUserGeneration, getUserPhoto} from 'src/utils/common';
import images from 'src/assets/images';
import {colors} from 'src/constants';
import {useMemo} from 'react';

const PublicTripItem = ({item}) => {
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const user = item?.created_by;
  const title = item.name;

  const userIndustry = useMemo(() => {
    if (user?.industry && user?.job) {
      return `${user?.industry}/${user?.job}`;
    }
    return user?.industry || user?.job || '';
  }, [user]);

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        navigation.navigate(routeNames.NonAuthFlow.TripDetail, {
          tripId: item.id,
        });
      }}>
      <View style={styles.invitation}>
        <Image source={{uri: item.background}} style={styles.image} />
        <Text style={styles.textVillage} numberOfLines={2}>
          {title}
        </Text>
        <View style={styles.village}>
          <Image source={images.Pin} style={styles.location} />
          <Text style={styles.textAge}>{item?.village?.name}</Text>
          <Text style={styles.textDate}>
            {dayjs(item.from_date).format('MM/DD')}
          </Text>
        </View>
      </View>
      <View style={styles.profile}>
        <View style={styles.userWrapper}>
          {getUserPhoto(user) ? (
            <Image source={{uri: getUserPhoto(user)}} style={styles.avatar} />
          ) : (
            <View style={styles.avatar} />
          )}
          <View style={styles.name}>
            <Text numberOfLines={1} style={styles.textName}>
              {`${user?.name} `}
              <Text style={{color: colors.gray}}>
                {user?.gender === 'male' ? '♂' : '♀'}
              </Text>
            </Text>
            <Text style={styles.textAge}>
              {user?.birthday
                ? `${getUserGeneration(user)}${i18n.t('age')}`
                : ''}
            </Text>
          </View>
        </View>
        <View style={styles.information}>
          <View style={styles.info}>
            <Text style={styles.textInfo}>{userIndustry}</Text>
            <Text style={styles.textInfo} numberOfLines={1}>
              {user?.college}
            </Text>
          </View>
          <View style={styles.invite}>
            <Image source={images.Person} style={styles.person} />
            <Text style={styles.inviteText}>{i18n.t('invite')}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default PublicTripItem;
