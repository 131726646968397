import {View, Text, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import styles from './styles';
import {useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import images from 'src/assets/images';

const PlanItem = ({item}) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        navigation.navigate(routeNames.NonAuthFlow.PlanDetail, {
          activityId: item.id,
        });
      }}>
      <View style={styles.invitation}>
        <Image source={{uri: item.background}} style={styles.image} />
        <Text style={styles.textTitle} numberOfLines={2}>
          {item.heading}
        </Text>
      </View>
      <View style={styles.userWrapper}>
        <Image source={images.Location} style={styles.avatar} />
        <Text numberOfLines={1} style={styles.textVillage}>
          {item.village?.name}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default PlanItem;
