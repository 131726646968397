import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  ScrollView,
  ImageBackground,
} from 'react-native';
import React, {useEffect} from 'react';
import styles from './styles';
import {HomeHeader, Member} from 'src/components';
import {useTranslations} from 'src/hooks';
import images from 'src/assets/images';
import {HOME_ITEM_TYPE} from 'src/constants';
import {useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import {useGetUserTrip} from 'src/api/user';
import {useSelector} from 'react-redux';
import {filter, isEmpty} from 'lodash';
import dayjs from 'dayjs';

const MyTrip = () => {
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const {runRequest: getUserTrip} = useGetUserTrip({});
  const userData = useSelector(state => state.user.data);
  const userTripData = useSelector(state => state.user.trip);

  useEffect(() => {
    getUserTrip(userData.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tripList = filter(userTripData, item => {
    return (
      item.type === HOME_ITEM_TYPE.TRIP ||
      item.type === HOME_ITEM_TYPE.BAZAAR_TRIP
    );
  });
  const invitationList = filter(userTripData, {
    type: HOME_ITEM_TYPE.INVITATION,
  });

  const renderTrip = trip => {
    if (trip.type === HOME_ITEM_TYPE.BAZAAR_TRIP) {
      return (
        <TouchableOpacity
          key={trip.id}
          style={styles.event}
          onPress={() => {
            navigation.navigate(routeNames.MainFlow.OrderDetail, {
              orderId: trip.orders_id,
              isFromAppNavigate: true,
            });
          }}>
          <Text style={styles.paid}>{`${i18n.t('paid_at')}: ${dayjs(
            trip.orders_created_at,
          ).format('YYYY-MM-DD HH:mm:ss')}`}</Text>
          <View style={styles.bazaar}>
            <View style={styles.container}>
              <Text numberOfLines={1} style={styles.eventTitle}>
                {trip.title}
              </Text>
              <Text numberOfLines={1} style={styles.eventDate}>
                {dayjs(trip.from_date).format('YYYY-MM-DD')}
              </Text>
            </View>
            <Image source={images.Code} style={styles.code} />
            <Image source={images.ArrowRight} style={styles.arrow} />
          </View>
        </TouchableOpacity>
      );
    }
    return (
      <TouchableOpacity
        key={trip.id}
        style={styles.event}
        onPress={() => {
          navigation.navigate(routeNames.NonAuthFlow.TripDetail, {
            tripId: trip.id,
          });
        }}>
        <View style={styles.eventHeader}>
          <Text numberOfLines={1} style={styles.eventTitle}>
            {trip.name}
          </Text>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate(routeNames.MainFlow.ChatScreen, {
                conversationId: trip?.conversation_id,
              });
            }}
            hitSlop={{bottom: 12, left: 12, right: 12, top: 12}}>
            <Image source={images.Chat} style={styles.chatIcon} />
          </TouchableOpacity>
        </View>
        <Member
          creatorId={trip?.created_by?.id}
          maximumMember={trip?.maximum_member}
          members={trip?.members}
          invitedList={trip?.invited_users}
          emptyComponent={
            <View style={styles.buttonAdd}>
              <Image
                style={styles.icon}
                source={images.People}
                resizeMode="cover"
              />
            </View>
          }
          containerStyle={styles.members}
          itemStyle={styles.avatar}
        />
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <HomeHeader withBackButton />
      <View style={styles.header}>
        <ImageBackground
          source={images.Slider1}
          style={styles.image}
          resizeMode="contain">
          <View style={styles.titleWrapper}>
            <Text style={styles.title}>{i18n.t('home.my_trip')}</Text>
          </View>
        </ImageBackground>
      </View>
      <ScrollView showsVerticalScrollIndicator={false} style={styles.detail}>
        {/* <View style={styles.calendar}>
          <Calendar
            markingType={'period'}
            markedDates={{
              '2022-03-15': {
                startingDay: true,
                selected: true,
                color: colors.primary,
              },
              '2022-03-16': {color: opacityColor(colors.primary, 0.2)},
              '2022-03-17': {color: opacityColor(colors.primary, 0.2)},
              '2022-03-18': {
                endingDay: true,
                selected: true,
                color: colors.primary,
              },
            }}
            firstDay={1}
            theme={{
              textSectionTitleColor: colors.neutral5,
              todayTextColor: colors.primary,
              dayTextColor: colors.neutral0,
              textDisabledColor: colors.neutral6,
              arrowColor: colors.primary,
              selectedDayBackgroundColor: colors.primary,
              disabledArrowColor: opacityColor(colors.primary, 0.2),
              monthTextColor: colors.neutral1,
              textDayFontFamily: 'SourceSansPro-Regular',
              textMonthFontFamily: 'SourceSansPro-SemiBold',
              textDayHeaderFontFamily: 'SourceSansPro-SemiBold',
              textDayFontWeight: '400',
              textMonthFontWeight: '600',
              textDayHeaderFontWeight: '600',
              textDayFontSize: 15,
              textMonthFontSize: 16,
              textDayHeaderFontSize: 16,
            }}
          />
        </View> */}
        <View style={styles.tripList}>
          {!isEmpty(tripList) && (
            <>
              <View style={styles.borderWrapper}>
                <View style={styles.border} />
                <View style={styles.moveOrText}>
                  <Text style={styles.textOr}>{i18n.t('home.confirmed')}</Text>
                </View>
              </View>
              {tripList.map(trip => {
                return renderTrip(trip);
              })}
            </>
          )}
          {!isEmpty(invitationList) && (
            <>
              <View style={styles.borderWrapper}>
                <View style={styles.border} />
                <View style={styles.moveOrText}>
                  <Text style={styles.textOr}>
                    {i18n.t('home.waiting_for_confirm')}
                  </Text>
                </View>
              </View>
              {invitationList.map(trip => {
                return (
                  <TouchableOpacity
                    key={trip.id}
                    style={styles.event}
                    onPress={() => {
                      navigation.navigate(
                        routeNames.NonAuthFlow.InvitationDetail,
                        {
                          invitationId: trip.id,
                        },
                      );
                    }}>
                    <View style={styles.eventHeader}>
                      <Text numberOfLines={1} style={styles.eventTitle}>
                        {trip.heading}
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          navigation.navigate(routeNames.MainFlow.ChatScreen, {
                            conversationId: trip?.conversation_id,
                          });
                        }}
                        hitSlop={{bottom: 12, left: 12, right: 12, top: 12}}>
                        <Image source={images.Chat} style={styles.chatIcon} />
                      </TouchableOpacity>
                    </View>
                    <Member
                      creatorId={trip?.trip_created_by}
                      maximumMember={trip?.maximum_member}
                      members={trip?.members}
                      invitedList={trip?.invited_users}
                      emptyComponent={
                        <View style={styles.buttonAdd}>
                          <Image
                            style={styles.icon}
                            source={images.People}
                            resizeMode="cover"
                          />
                        </View>
                      }
                      containerStyle={styles.members}
                      itemStyle={styles.avatar}
                    />
                  </TouchableOpacity>
                );
              })}
            </>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default MyTrip;
