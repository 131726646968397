import {createSlice} from '@reduxjs/toolkit';
import {logout} from 'src/api/auth';
import {
  createStoryRequest,
  dislikeStoryRequest,
  getStoryCommentsRequest,
  getStoryDetailRequest,
  likeStoryRequest,
  updateStoryRequest,
  createCommentRequest,
  replyCommentRequest,
  getAllStoryRequest,
} from 'src/api/story';

const initialState = {
  storyData: {},

  isLoadingCreateStory: false,
  errorCreateStory: false,

  isLoadingGetStoryDetail: false,
  errorGetStoryDetail: false,

  isLoadingLikeStory: false,
  errorLikeStory: false,

  isLoadingDislikeStory: false,
  errorDislikeStory: false,

  isLoadingUpdateStory: false,
  errorUpdateStory: false,

  isLoadingGetStoryComments: false,
  errorGetStoryComments: false,

  isLoadingCreateComment: false,
  errorCreateComment: false,

  isLoadingReplyComment: false,
  errorReplyComment: false,

  isLoadingGetAllStory: false,
};

export const storySlice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    saveStory: (state, action) => {
      state.storyData = action.payload;
    },
    resetStory: state => {
      state.storyData = {};
    },
  },
  extraReducers: builder => {
    builder.addCase(createStoryRequest.pending, state => {
      state.isLoadingCreateStory = true;
      state.errorCreateStory = false;
    });
    builder.addCase(createStoryRequest.rejected, state => {
      state.isLoadingCreateStory = false;
      state.errorCreateStory = true;
    });
    builder.addCase(createStoryRequest.fulfilled, state => {
      state.isLoadingCreateStory = false;
      state.errorCreateStory = false;
    });
    builder.addCase(getStoryDetailRequest.pending, state => {
      state.isLoadingGetStoryDetail = true;
      state.errorGetStoryDetail = false;
    });
    builder.addCase(getStoryDetailRequest.rejected, state => {
      state.isLoadingGetStoryDetail = false;
      state.errorGetStoryDetail = true;
    });
    builder.addCase(getStoryDetailRequest.fulfilled, state => {
      state.isLoadingGetStoryDetail = false;
      state.errorGetStoryDetail = false;
    });

    builder.addCase(getStoryCommentsRequest.pending, state => {
      state.isLoadingGetStoryComments = true;
      state.errorGetStoryComments = false;
    });
    builder.addCase(getStoryCommentsRequest.rejected, state => {
      state.isLoadingGetStoryComments = false;
      state.errorGetStoryComments = true;
    });
    builder.addCase(getStoryCommentsRequest.fulfilled, state => {
      state.isLoadingGetStoryComments = false;
      state.errorGetStoryComments = false;
    });

    builder.addCase(createCommentRequest.pending, state => {
      state.isLoadingCreateComment = true;
      state.errorCreateComment = false;
    });
    builder.addCase(createCommentRequest.rejected, state => {
      state.isLoadingCreateComment = false;
      state.errorCreateComment = true;
    });
    builder.addCase(createCommentRequest.fulfilled, state => {
      state.isLoadingCreateComment = false;
      state.errorCreateComment = false;
    });

    builder.addCase(replyCommentRequest.pending, state => {
      state.isLoadingReplyComment = true;
      state.errorReplyComment = false;
    });
    builder.addCase(replyCommentRequest.rejected, state => {
      state.isLoadingReplyComment = false;
      state.errorReplyComment = true;
    });
    builder.addCase(replyCommentRequest.fulfilled, state => {
      state.isLoadingReplyComment = false;
      state.errorReplyComment = false;
    });

    builder.addCase(likeStoryRequest.pending, state => {
      state.isLoadingLikeStory = true;
      state.errorLikeStory = false;
    });
    builder.addCase(likeStoryRequest.rejected, state => {
      state.isLoadingLikeStory = false;
      state.errorLikeStory = true;
    });
    builder.addCase(likeStoryRequest.fulfilled, state => {
      state.isLoadingLikeStory = false;
      state.errorLikeStory = false;
    });

    builder.addCase(dislikeStoryRequest.pending, state => {
      state.isLoadingDislikeStory = true;
      state.errorDislikeStory = false;
    });
    builder.addCase(dislikeStoryRequest.rejected, state => {
      state.isLoadingDislikeStory = false;
      state.errorDislikeStory = true;
    });
    builder.addCase(dislikeStoryRequest.fulfilled, state => {
      state.isLoadingDislikeStory = false;
      state.errorDislikeStory = false;
    });

    builder.addCase(updateStoryRequest.pending, state => {
      state.isLoadingUpdateStory = true;
      state.errorUpdateStory = false;
    });
    builder.addCase(updateStoryRequest.rejected, state => {
      state.isLoadingUpdateStory = false;
      state.errorUpdateStory = true;
    });
    builder.addCase(updateStoryRequest.fulfilled, state => {
      state.isLoadingUpdateStory = false;
      state.errorUpdateStory = false;
    });

    builder.addCase(getAllStoryRequest.pending, state => {
      state.isLoadingGetAllStory = true;
    });
    builder.addCase(getAllStoryRequest.rejected, state => {
      state.isLoadingGetAllStory = false;
    });
    builder.addCase(getAllStoryRequest.fulfilled, state => {
      state.isLoadingGetAllStory = false;
    });

    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

export const {saveStory, resetStory} = storySlice.actions;

const {reducer: usersReducer} = storySlice;

export default usersReducer;
