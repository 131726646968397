import React, {useLayoutEffect} from 'react';
import {useSelector} from 'react-redux';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {axiosSetAuthToken} from 'src/utils/axiosConfig';
import routeNames from './routeNames';
import TabBar from 'src/components/TabBar';
import {
  MainHome,
  ChatScreen,
  MyTrip,
  SearchScreen,
  MainProfile,
  FindFriend,
  Welcome,
  VerifyPhone,
  // MyWorkAndIndustry,
  // MyCollege,
  // MyLifestyle,
  // MyWish,
  // MyProfilePhoto,
  // PeopleLifestyle,
  // PeopleWish,
  // Bio,
  TripDetail,
  VillageDetail,
  UserDetail,
  FollowStatus,
  StoryDetail,
  // MainAdmin,
  AddStory,
  Notification,
  EditProfile,
  TripAdmin,
  MarketActivity,
  OrderConfirm,
  ShareActivity,
  OrderDetail,
  PlanDetail,
  ChooseCity,
} from 'src/screens';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const BottomTab = () => {
  return (
    <Tab.Navigator
      tabBar={props => <TabBar tabBarProps={props} />}
      screenOptions={{
        headerShown: false,
        gestureEnabled: true,
        title: '2myvillage',
      }}>
      <Tab.Screen name={routeNames.MainFlow.MainHome} component={MainHome} />
      <Tab.Screen
        name={routeNames.MainFlow.MainProfile}
        component={MainProfile}
      />
    </Tab.Navigator>
  );
};

const Navigation = () => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const token = useSelector(state => state.auth.token);
  useLayoutEffect(() => {
    if (token) {
      axiosSetAuthToken(token);
    }
  }, [token]);

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: true,
        title: '2myvillage',
      }}>
      {isLoggedIn ? (
        <>
          <Stack.Screen name={routeNames.BottomTab} component={BottomTab} />
          <Stack.Screen
            name={routeNames.MainFlow.ChatScreen}
            component={ChatScreen}
          />
          <Stack.Screen name={routeNames.MainFlow.MyTrip} component={MyTrip} />
          <Stack.Screen
            name={routeNames.MainFlow.SearchScreen}
            component={SearchScreen}
          />
          <Stack.Screen
            name={routeNames.MainFlow.FindFriend}
            component={FindFriend}
          />
          <Stack.Screen
            name={routeNames.MainFlow.MainAdmin}
            component={TripAdmin}
          />
          <Stack.Screen
            name={routeNames.MainFlow.AddStory}
            component={AddStory}
          />
          <Stack.Screen
            name={routeNames.MainFlow.Notification}
            component={Notification}
          />
          <Stack.Screen
            name={routeNames.MainFlow.EditProfile}
            component={EditProfile}
          />
          <Stack.Screen
            name={routeNames.MainFlow.OrderConfirm}
            component={OrderConfirm}
          />
          <Stack.Screen
            name={routeNames.MainFlow.ShareActivity}
            component={ShareActivity}
          />
          <Stack.Screen
            name={routeNames.MainFlow.OrderDetail}
            component={OrderDetail}
          />
        </>
      ) : (
        <>
          <Stack.Screen
            name={routeNames.AuthFlow.WelcomeScreen}
            component={Welcome}
          />
          <Stack.Screen
            name={routeNames.AuthFlow.VerifyPhone}
            component={VerifyPhone}
          />
          <Stack.Screen
            name={routeNames.AuthFlow.EditProfile}
            component={EditProfile}
          />
          {/* <Stack.Screen name={routeNames.AuthFlow.Bio} component={Bio} />
          <Stack.Screen
            name={routeNames.AuthFlow.MyWorkAndIndustryScreen}
            component={MyWorkAndIndustry}
          />
          <Stack.Screen
            name={routeNames.AuthFlow.MyCollegeScreen}
            component={MyCollege}
          />
          <Stack.Screen
            name={routeNames.AuthFlow.MyWishScreen}
            component={MyWish}
          />
          <Stack.Screen
            name={routeNames.AuthFlow.MyLifestyleScreen}
            component={MyLifestyle}
          />
          <Stack.Screen
            name={routeNames.AuthFlow.MyProfilePhoto}
            component={MyProfilePhoto}
          />
          <Stack.Screen
            name={routeNames.AuthFlow.RecommendFriend}
            component={FindFriend}
          />
          <Stack.Screen
            name={routeNames.AuthFlow.PeopleLifestyle}
            component={PeopleLifestyle}
          />
          <Stack.Screen
            name={routeNames.AuthFlow.PeopleWish}
            component={PeopleWish}
          /> */}
        </>
      )}
      <Stack.Screen
        name={routeNames.NonAuthFlow.TripDetail}
        component={TripDetail}
      />
      <Stack.Screen
        name={routeNames.NonAuthFlow.StoryDetail}
        component={StoryDetail}
      />
      <Stack.Screen
        name={routeNames.NonAuthFlow.VillageDetail}
        component={VillageDetail}
      />
      <Stack.Screen
        name={routeNames.NonAuthFlow.UserDetail}
        component={UserDetail}
      />
      <Stack.Screen
        name={routeNames.NonAuthFlow.FollowStatus}
        component={FollowStatus}
      />
      <Stack.Screen
        name={routeNames.NonAuthFlow.MarketActivity}
        component={MarketActivity}
      />
      <Stack.Screen
        name={routeNames.NonAuthFlow.PlanDetail}
        component={PlanDetail}
      />
      <Stack.Screen
        name={routeNames.NonAuthFlow.InvitationDetail}
        component={TripDetail}
      />
      <Stack.Screen
        name={routeNames.NonAuthFlow.ChooseCity}
        component={ChooseCity}
      />
    </Stack.Navigator>
  );
};

export default Navigation;
