import {
  Text,
  View,
  TouchableOpacity,
  Image,
  TextInput,
  FlatList,
} from 'react-native';
import React, {useState, useMemo} from 'react';
import styles from './styles';
import images from 'src/assets/images';
import {filter} from 'lodash';

const SearchDropdown = ({
  value,
  onChangeValue,
  data,
  placeholder,
  disableAdd,
  containerStyle,
}) => {
  const [openInput, setOpenInput] = useState(false);
  const [queryString, setQueryString] = useState('');

  const handleOpenInput = () => {
    setOpenInput(open => !open);
  };

  const onChangeSearch = searchValue => {
    setQueryString(searchValue);
  };

  const handleChooseItem = item => {
    setOpenInput(false);
    onChangeValue(item);
  };

  const renderItem = ({item}) => (
    <TouchableOpacity
      onPress={() => {
        handleChooseItem(item);
      }}>
      <Text style={styles.textSearchItem}>{item}</Text>
    </TouchableOpacity>
  );

  const searchData = useMemo(() => {
    return filter(data, t => t.search(queryString) !== -1);
  }, [queryString, data]);

  return (
    <View style={containerStyle}>
      <TouchableOpacity style={styles.inputSelect} onPress={handleOpenInput}>
        <Text style={styles.inputText}>{value}</Text>
        <Image
          source={openInput ? images.ArrowUp : images.ArrowDown}
          style={styles.inputIcon}
        />
      </TouchableOpacity>
      {openInput && (
        <View style={styles.recommendWrapper}>
          <View style={styles.searchWrapper}>
            <Image source={images.Search} style={styles.inputIcon} />
            <TextInput
              style={styles.inputSearch}
              placeholder={placeholder}
              value={queryString}
              onChangeText={onChangeSearch}
            />
          </View>
          <FlatList
            data={
              queryString && !searchData.includes(queryString) && !disableAdd
                ? [...searchData, `${queryString}`]
                : searchData
            }
            renderItem={renderItem}
            keyExtractor={item => item}
            showsVerticalScrollIndicator={false}
          />
        </View>
      )}
    </View>
  );
};

export default SearchDropdown;
