export const createStoryUrl = 'stories';
export const getStoryDetailUrl = storyId => `stories/${storyId}`;
export const likeStoryUrl = storyId => `users/likes/${storyId}`;
export const dislikeStoryUrl = storyId => `users/unlikes/${storyId}`;
export const updateStoryUrl = storyId => `stories/${storyId}`;
export const deleteStoryUrl = storyId => `stories/${storyId}`;
export const getStoryCommentsUrl = storyId => `stories/${storyId}/comments`;
export const createCommentUrl = storyId => `stories/${storyId}/comments`;
export const replyCommentUrl = (storyId, commentId) =>
  `stories/${storyId}/comments/replys/${commentId}`;
