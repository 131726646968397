import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getSuggestUserRequest} from '../requests';

export const useGetSuggestUser = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(
    state => state.metadata.isLoadingGetSuggestUser,
  );
  const [responseData, setResponseData] = useState([]);
  const dispatch = useDispatch();

  const runRequest = useCallback(() => {
    dispatch(
      getSuggestUserRequest({
        failureCallback: err => {
          failureCallback && failureCallback(err);
        },
        successCallback: data => {
          setResponseData(data);
          successCallback && successCallback(data);
        },
      }),
    );
  }, [failureCallback, successCallback, dispatch]);

  const returnObject = {
    isLoading,
    runRequest,
    responseData,
  };

  return returnObject;
};
