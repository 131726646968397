import {StyleSheet} from 'react-native';
import {opacityColor} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: opacityColor('#ffffff', 0.5),
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
