import {Image, StyleSheet} from 'react-native';
import React, {useLayoutEffect, useState} from 'react';
import {calculateWidth} from 'src/utils/dimensions';
import {colors} from 'src/constants';

const ImageRatio = ({source, style, imageWidth = 375, imageHeight}) => {
  const [imageCurrentHeight, setImageCurrentHeight] = useState(
    imageHeight || imageWidth,
  );
  const [imageCurrentWidth, setImageCurrentWidth] = useState(imageWidth);

  useLayoutEffect(() => {
    if (source.uri) {
      Image.getSize(source.uri, (loadedWidth, loadedHeight) => {
        const newImageCurrentHeight =
          (imageWidth * (loadedHeight || 0)) / (loadedWidth || 0);
        if (imageHeight && newImageCurrentHeight > imageHeight) {
          setImageCurrentHeight(imageHeight);
          setImageCurrentWidth(
            (imageHeight * (loadedWidth || 0)) / (loadedHeight || 0),
          );
        } else {
          setImageCurrentHeight(newImageCurrentHeight);
        }
      });
    }
  }, [source, imageWidth, imageHeight]);

  return (
    <Image
      source={source}
      resizeMode={'contain'}
      style={[
        styles.image,
        style,
        {
          width: calculateWidth(imageCurrentWidth),
          height: calculateWidth(imageCurrentHeight),
        },
      ]}
    />
  );
};

export default ImageRatio;

const styles = StyleSheet.create({
  loadingState: {
    backgroundColor: colors.neutral8,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {},
});
