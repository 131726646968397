import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {getInvitationDetailUrl} from '../../endpoints';

export const getInvitationDetail = createAsyncThunk(
  'invitation/detail',
  async (
    {successCallback, failureCallback, invitationId},
    {rejectWithValue},
  ) => {
    try {
      const response = await axios.request({
        method: 'GET',
        url: getInvitationDetailUrl(invitationId),
      });
      successCallback?.(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback?.(err);
        return rejectWithValue(err.response.data);
      }
    }
  },
);
