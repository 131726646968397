import React, {useState} from 'react';
import {View, TouchableOpacity, Text, Image, StyleSheet} from 'react-native';

import routeNames from 'src/navigation/routeNames';
import images from 'src/assets/images';

import colors from 'src/constants/colors';
import styles from './styles';
import {useTranslations} from 'src/hooks';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {calculateWidth} from 'src/utils/dimensions';

const TabBar = ({tabBarProps}) => {
  const {i18n} = useTranslations();
  const [active, setActive] = useState(0);
  const insets = useSafeAreaInsets();
  const config = {
    Main: {
      index: 0,
      mainScreen: routeNames.MainFlow.MainHome,
      showIcon: () => (
        <>
          <Image
            source={images.Home}
            resizeMode="contain"
            style={StyleSheet.flatten([
              styles.image,
              {tintColor: active === 0 ? colors.neutral1 : colors.neutral4},
            ])}
          />
          <Text
            style={StyleSheet.flatten([
              styles.text,
              {color: active === 0 ? colors.neutral1 : colors.neutral4},
            ])}>
            {i18n.t('tab_bar.home')}
          </Text>
        </>
      ),
      name: 'Home',
      main: {},
    },
    Add: {
      index: 1,
      mainScreen: routeNames.MainFlow.AddStory,
      showIcon: () => (
        <View style={styles.addWrapper}>
          <Image source={images.Add} resizeMode="contain" style={styles.add} />
        </View>
      ),
      name: '',
      main: {},
    },
    Profile: {
      index: 2,
      mainScreen: routeNames.MainFlow.MainProfile,
      showIcon: () => (
        <>
          <Image
            source={images.PeopleCircle}
            resizeMode="contain"
            style={StyleSheet.flatten([
              styles.image,
              {tintColor: active === 2 ? colors.neutral1 : colors.neutral4},
            ])}
          />
          <Text
            style={StyleSheet.flatten([
              styles.text,
              {color: active === 2 ? colors.neutral1 : colors.neutral4},
            ])}>
            {i18n.t('tab_bar.profile')}
          </Text>
        </>
      ),
      name: 'Profile',
      main: {},
    },
  };
  return (
    <View
      style={[
        styles.content,
        {
          height:
            calculateWidth(62) +
            (insets.bottom ? insets.bottom - calculateWidth(20) : 0),
        },
      ]}>
      {Object.entries(config).map(([key, options], index) => {
        return (
          <TouchableOpacity
            key={`${options.index}_${index}`}
            style={styles.menu}
            onPress={_event => {
              setActive(options.index);
              tabBarProps.navigation.navigate(options.mainScreen);
            }}>
            {options.showIcon()}
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default TabBar;
