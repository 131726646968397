import {View, Text, Modal, Image} from 'react-native';
import React from 'react';
import styles from './styles';
import {Button} from 'src/components';
import {useTranslations} from 'src/hooks';
import images from 'src/assets/images';

const CreateStorySuccess = ({show, onClose}) => {
  const {i18n} = useTranslations();

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={show}
      onRequestClose={onClose}>
      <View style={styles.container}>
        <View style={styles.popup}>
          <Text style={styles.headerAlign}>
            {i18n.t('addStory.congratulation')}
          </Text>
          <View style={styles.center}>
            <Image source={images.Success} style={styles.success} />
          </View>
          <Button
            title={i18n.t('addStory.goToHome')}
            buttonStyle={styles.bottomButton}
            onClick={onClose}
          />
        </View>
      </View>
    </Modal>
  );
};

export default CreateStorySuccess;
