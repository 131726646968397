import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(18, 24, 16, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    width: calculateWidth(375),
    alignSelf: 'center',
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    width: calculateWidth(300),
    height: calculateWidth(300),
    borderRadius: calculateWidth(20),
    backgroundColor: colors.neutral9,
  },
  buttonClose: {
    width: calculateWidth(36),
    height: calculateWidth(36),
    backgroundColor: colors.neutral8,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 12,
    top: calculateWidth(-18),
  },
  textClose: {
    fontSize: calculateWidth(25),
    marginBottom: calculateWidth(2),
    marginLeft: calculateWidth(3),
    color: colors.neutral1,
    transform: [{rotate: '45deg'}],
  },
  images: {
    borderRadius: calculateWidth(20),
    width: calculateWidth(300),
    height: calculateWidth(300),
  },
});
