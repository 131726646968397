import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  card: {
    borderRadius: calculateWidth(12),
    backgroundColor: '#fff',
    shadowColor: colors.neutral7,
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.5,
    shadowRadius: 12.35,

    elevation: 19,
    borderWidth: 1,
    borderColor: colors.neutral8,
  },
  image: {
    borderRadius: calculateWidth(12),
    height: calculateWidth(320),
    backgroundColor: colors.neutral7,
    borderWidth: 1,
    borderColor: colors.neutral8,
  },
  profile: {
    padding: calculateWidth(24),
    paddingTop: calculateWidth(15),
  },
  textName: {
    ...textFont(22, '400'),
    color: colors.neutral0,
  },
  textFollow: {
    ...textFont(14, '400'),
    color: colors.neutral4,
    marginTop: calculateWidth(4),
  },
  circle: {
    width: calculateWidth(8),
    height: calculateWidth(8),
  },
  tagWrapper: {
    marginTop: calculateWidth(16),
    marginBottom: calculateWidth(6),
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  textBio: {
    ...textFont(14, '400'),
    color: colors.neutral4,
  },
  imageWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: calculateWidth(16),
  },
  publishImage: {
    width: calculateWidth(54),
    height: calculateWidth(54),
    borderRadius: calculateWidth(5),
    justifyContent: 'center',
    alignItems: 'center',
  },
  storyWrapper: {
    paddingTop: calculateWidth(24),
  },
  loading: {paddingTop: calculateWidth(16)},

  // Story

  container: {
    width: calculateWidth(140),
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.neutral6,
    marginRight: calculateWidth(16),
    overflow: 'hidden',
    backgroundColor: colors.neutral9,
  },
  imageStory: {
    width: calculateWidth(140),
    height: calculateWidth(135),
    marginBottom: calculateWidth(16),
  },
  des: {
    marginHorizontal: calculateWidth(16),
    ...textFont(16, '400'),
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    margin: calculateWidth(16),
    marginTop: calculateWidth(12),
  },
  name: {
    marginLeft: 8,
    ...textFont(14, '600'),
    color: colors.neutral4,
  },
  avatar: {
    width: calculateWidth(24),
    height: calculateWidth(24),
    borderRadius: 100,
    backgroundColor: colors.neutral6,
  },
});
