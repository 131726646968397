import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {opacityColor, textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  button: {
    height: calculateWidth(48),
    backgroundColor: colors.primary,
    borderRadius: calculateWidth(8),
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonDisable: {
    height: calculateWidth(48),
    backgroundColor: opacityColor(colors.primary, 0.5),
    borderRadius: calculateWidth(8),
    justifyContent: 'center',
    alignItems: 'center',
  },
  textButton: {
    ...textFont(16, '700'),
    color: colors.neutral9,
  },
  icon: {
    position: 'absolute',
    top: calculateWidth(13),
    left: calculateWidth(10),
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
});

export default styles;
