import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {replyCommentRequest} from '../requests';

export const useReplyComment = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(state => state.story.isLoadingReplyComment);
  const [responseData, setResponseData] = useState(null);
  const dispatch = useDispatch();

  const runRequest = useCallback(
    (storyId, commentId, data) => {
      dispatch(
        replyCommentRequest({
          storyId,
          commentId,
          data,
          failureCallback: err => {
            failureCallback?.(err);
          },
          successCallback: response => {
            setResponseData(response);
            successCallback?.(response);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  return {
    isLoading,
    runRequest,
    responseData,
  };
};
