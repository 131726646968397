import {createSlice} from '@reduxjs/toolkit';
import {logout} from 'src/api/auth';
import {
  getNotificationRequest,
  readNotificationRequest,
} from 'src/api/notification';

const initialState = {
  notificationList: [],
  isLoadingGetNotification: false,
  errorGetNotification: false,

  isLoadingReadNotification: false,
  errorReadNotification: false,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getNotificationRequest.pending, state => {
      state.isLoadingGetNotification = true;
      state.errorGetNotification = false;
    });
    builder.addCase(getNotificationRequest.rejected, state => {
      state.isLoadingGetNotification = false;
      state.errorGetNotification = true;
    });
    builder.addCase(getNotificationRequest.fulfilled, (state, action) => {
      state.isLoadingGetNotification = false;
      state.errorGetNotification = false;
      state.notificationList = action.payload.data;
    });

    builder.addCase(readNotificationRequest.pending, state => {
      state.isLoadingReadNotification = true;
      state.errorReadNotification = false;
    });
    builder.addCase(readNotificationRequest.rejected, state => {
      state.isLoadingReadNotification = false;
      state.errorReadNotification = true;
    });
    builder.addCase(readNotificationRequest.fulfilled, state => {
      state.isLoadingReadNotification = false;
      state.errorReadNotification = false;
    });

    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

const {reducer: notificationReducer} = notificationSlice;

export default notificationReducer;
