import {StyleSheet} from 'react-native';

import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  flex: {flex: 1},
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: colors.neutral9,
  },
  content: {
    paddingHorizontal: calculateWidth(20),
    paddingBottom: calculateWidth(20),
    flex: 1,
  },
  textDescriptionWrapper: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: 'rgba(255, 166, 46, 0.5)',
    borderRadius: calculateWidth(8),
    padding: calculateWidth(12),
    marginBottom: calculateWidth(10),
  },
  textDescription: {
    ...textFont(14, '400'),
    color: colors.secondary1,
  },
  textTitle: {
    ...textFont(32, '600'),
    color: colors.neutral1,
  },
  textTitleDetail: {
    ...textFont(14, '400'),
    color: colors.neutral1,
    marginBottom: calculateWidth(20),
    marginTop: calculateWidth(5),
  },
  textTitleDetailTop: {
    ...textFont(14, '400'),
    color: colors.neutral1,
    marginBottom: calculateWidth(20),
    marginTop: calculateWidth(25),
  },
  textBottom: {
    ...textFont(15, '400'),
    color: colors.primary,
    textAlign: 'center',
    paddingBottom: calculateWidth(20),
    paddingHorizontal: calculateWidth(50),
  },
  gender: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: calculateWidth(24),
  },
  selectButton: {
    width: '47%',
    borderRadius: 8,
    color: 'red',
    height: calculateWidth(48),
  },
  select: {
    marginBottom: calculateWidth(24),
    paddingHorizontal: calculateWidth(20),
    height: calculateWidth(48),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: calculateWidth(8),
    borderWidth: 1,
    borderColor: colors.neutral6,
  },
  selectTitle: {
    ...textFont(16, '400'),
    color: colors.neutral1,
  },
  arrowDown: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
  nameInput: {
    marginBottom: calculateWidth(24),
    height: calculateWidth(48),
    borderWidth: 1,
    borderColor: colors.neutral6,
    backgroundColor: colors.neutral9,
    paddingHorizontal: calculateWidth(20),
    borderRadius: calculateWidth(8),
    color: colors.neutral1,
    ...textFont(16, '400'),
  },
});
