import {useNavigation} from '@react-navigation/native';
import React, {createContext, useEffect, useMemo, useReducer} from 'react';
import {View, Image, Text, TouchableOpacity} from 'react-native';
import images from 'src/assets/images';
import {useTranslations} from 'src/hooks';
import routeNames from 'src/navigation/routeNames';

import styles from './styles';

export const FollowFriendContext = createContext(null);

const defaults = {
  visibility: false,
};

const reducer = (state, action) => {
  const newState = {...state, ...action};
  return newState;
};

export const FollowFriendProvider = ({children, overrides}) => {
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const initialState = useMemo(
    () => ({...defaults, ...overrides}),
    [overrides],
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  const followFriend = useMemo(
    () => ({
      show(options) {
        const newState = {...initialState, ...options, visibility: true};
        dispatch(newState);
        setTimeout(() => {
          dispatch({visibility: false});
        }, 12000);
      },
      hide() {
        dispatch({visibility: false});
      },
    }),
    [initialState],
  );

  useEffect(() => {
    dispatch(initialState);
  }, [initialState]);

  return (
    <FollowFriendContext.Provider value={followFriend}>
      {children}
      {state.visibility && (
        <TouchableOpacity
          style={styles.container}
          onPress={() => {
            followFriend.hide();
            navigation.navigate(routeNames.MainFlow.FindFriend, {
              inviteLimit: 1000,
              isFromRecommend: true,
            });
          }}>
          <View style={styles.title}>
            <Image source={images.Circle} style={styles.circle} />
            <Text style={styles.textTitle}>{i18n.t('potential_teammate')}</Text>
          </View>
          <Text style={styles.textDescription}>
            {i18n.t('potential_teammate_description')}
            <Text style={styles.textDescriptionBold}>
              {i18n.t('check_their_profile')}
            </Text>
          </Text>
          <TouchableOpacity
            style={styles.closeButton}
            onPress={() => {
              dispatch({visibility: false});
            }}
            hitSlop={{bottom: 12, left: 12, right: 12, top: 12}}>
            <Text style={styles.close}>+</Text>
          </TouchableOpacity>
        </TouchableOpacity>
      )}
    </FollowFriendContext.Provider>
  );
};
