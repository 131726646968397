export const addActivityUrl = 'plans';

export const getListActivityUrl = 'plans';
export const getActivityDetailUrl = activityId => `plans/${activityId}`;
export const initTripFromActivityUrl = activityId =>
  `plans/${activityId}/initiative`;

export const getMarketActivityUrl = activityId => `bazaar-trips/${activityId}`;

export const getOrderDetailUrl = orderId => `payment/orders/${orderId}`;

export const getSharePosterUrl = 'poster/create-poster';

export const getSharePosterTripUrl = 'poster/create-poster-2';

export const scanTicketMarketUrl = orderId => `tickets/scan/${orderId}`;
