import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  wrapper: {
    top: 0,
    borderRadius: 0,
  },
  container: {
    width: calculateWidth(375),
    marginTop: calculateWidth(10),
    padding: calculateWidth(24),
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(4),
    borderWidth: 1,
    borderColor: colors.neutral8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,

    elevation: 12,
  },

  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  circle: {
    width: calculateWidth(12),
    height: calculateWidth(12),
    marginRight: calculateWidth(8),
  },
  textTitle: {...textFont(14, '600'), color: colors.primary},
  textDescription: {
    ...textFont(15, '400'),
    color: colors.neutral4,
    marginTop: calculateWidth(12),
  },
  textDescriptionBold: {
    color: colors.neutral1,
  },

  close: {
    fontSize: 30,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: 7,
    transform: [{rotate: '45deg'}],
  },
});
