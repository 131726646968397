import {Platform, Alert} from 'react-native';
import {useTranslations} from './useTranslation';

const useAlert = () => {
  const {i18n} = useTranslations();
  if (Platform.OS === 'web') {
    return {
      alert: (message, onClick) => {
        // eslint-disable-next-line no-alert
        window.alert(message);
        onClick?.();
      },
      confirm: (message, onConfirm, onCancel) => {
        // eslint-disable-next-line no-alert
        if (window.confirm(message)) {
          onConfirm?.();
        } else {
          onCancel?.();
        }
      },
    };
  } else {
    return {
      alert: (message, onClick) => {
        Alert.alert('', message, [{text: i18n.t('ok'), onPress: onClick}]);
      },
      confirm: (message, onConfirm = () => {}, onCancel = () => {}) => {
        Alert.alert('', message, [
          {
            text: i18n.t('cancel'),
            onPress: onCancel,
            style: 'cancel',
          },
          {text: 'OK', onPress: onConfirm},
        ]);
      },
    };
  }
};

export default useAlert;
