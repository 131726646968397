import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {opacityColor, textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.neutral9,
    justifyContent: 'space-between',
  },
  swiper: {
    backgroundColor: colors.neutral9,
    flex: 1,
  },
  flex1: {flex: 1},

  invitedText: {
    ...textFont(18, '600'),
    color: colors.neutral1,
  },

  invite: {
    padding: calculateWidth(10),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.neutral7,
  },
  flexRow: {
    flexDirection: 'row',
  },
  avatar: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    borderRadius: 100,
    backgroundColor: colors.neutral6,
    marginLeft: calculateWidth(8),
  },
  buttonAdd: {
    width: calculateWidth(40),
    height: calculateWidth(40),
    borderWidth: 1,
    borderColor: colors.neutral6,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: calculateWidth(8),
  },
  textAdd: {
    fontSize: calculateWidth(20),
    marginBottom: calculateWidth(3),
    color: colors.neutral5,
  },
  textInvite: {
    ...textFont(18, '600'),
    color: colors.neutral1,
  },

  // Card

  textMore: {
    ...textFont(14, '400'),
    color: colors.neutral4,
  },

  success: {
    width: calculateWidth(247),
    height: calculateWidth(217),
  },
  successWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textSuccess: {
    ...textFont(16, '600'),
    color: colors.secondary1,
    marginTop: calculateWidth(32),
  },
  title: {
    ...textFont(18, '600'),
    color: colors.neutral1,
    marginVertical: calculateWidth(16),
    textAlign: 'center',
  },
  notify: {
    position: 'absolute',
    top: calculateWidth(16),
    width: '90%',
    height: calculateWidth(32),
    backgroundColor: colors.primary,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: calculateWidth(8),
  },
  notifyText: {
    color: colors.neutral9,
    flex: 1,
    ...textFont(14, '400'),
    marginLeft: calculateWidth(12),
  },
  notifyClose: {
    color: colors.neutral9,
  },
  close: {
    backgroundColor: opacityColor(colors.neutral9, 0.4),
    width: calculateWidth(20),
    height: calculateWidth(20),
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: calculateWidth(6),
  },
});
export default styles;
