import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';

export default StyleSheet.create({
  input: {
    margin: 8,
  },
  wrapper: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: colors.primary,
  },
});
