import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  flex: {flex: 1},
  container: {flex: 1, width: '100%', height: '100%', backgroundColor: '#fff'},
  content: {
    paddingHorizontal: calculateWidth(20),
    paddingBottom: calculateWidth(20),
    flex: 1,
  },
  textDescriptionWrapper: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: 'rgba(255, 166, 46, 0.5)',
    borderRadius: calculateWidth(8),
    padding: calculateWidth(12),
    marginBottom: calculateWidth(10),
  },
  textDescription: {
    ...textFont(14, '400'),
    color: colors.secondary1,
  },

  textTitle: {
    ...textFont(32, '600'),
    color: colors.neutral1,
  },

  textTitleDetail: {
    ...textFont(14, '400'),
    color: colors.neutral1,
    marginTop: calculateWidth(5),
  },

  imagePickerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginHorizontal: -calculateWidth(20),
    marginTop: calculateWidth(16),
  },
  imagePicker: {
    width: calculateWidth(104),
    height: calculateWidth(114),
    backgroundColor: colors.neutral8,
    borderRadius: calculateWidth(12),
    overflow: 'hidden',
  },
  imageThumbnail: {
    width: calculateWidth(104),
    height: calculateWidth(114),
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: calculateWidth(20),
  },
  imagePickerIcon: {
    width: calculateWidth(23),
    height: calculateWidth(23),
  },
  textPicker: {
    ...textFont(15, '400'),
    color: colors.neutral4,
    marginTop: calculateWidth(5),
    textAlign: 'center',
  },
  textAddMore: {
    fontSize: calculateWidth(25),
    color: colors.neutral4,
  },
});
export default styles;
