import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  Platform,
  FlatList,
} from 'react-native';
import React, {useEffect} from 'react';
import styles from './styles';
import images from 'src/assets/images';
import LinearGradient from 'react-native-linear-gradient';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useAlert, useTranslations} from 'src/hooks';
import {checkDuplicate, formatPrice, isWeChat} from 'src/utils/common';
import {useDispatch, useSelector} from 'react-redux';
import routeNames from 'src/navigation/routeNames';
import {StackActions, useNavigation, useRoute} from '@react-navigation/native';
import {get, isEmpty, toNumber} from 'lodash';
import {useState} from 'react';
import {
  Button,
  DatePickerModal,
  ImageView,
  Loading,
  ModalFailure,
  ModalSuccess,
  ShareCode,
} from 'src/components';
import {
  useGetActivityDetail,
  useInitTripFromActivity,
} from 'src/api/activities';
import {addActivity} from 'src/store/slices/user/userSlice';
import dayjs from 'dayjs';
import {useGetUserTrip} from 'src/api/user';
import ModalInitActivities from '../deprecated/ActivityDetail/ModalInitActivities';
import ModalDisclaimer from '../deprecated/ActivityDetail/ModalDisclaimer';
import {API_URL, IS_STAGING} from 'src/utils/axiosConfig';
import {useDeleteTrip} from 'src/api/users';
import {textFont} from 'src/utils/styles';
import {colors, PAYMENT_AGREEMENT} from 'src/constants';

const INIT_START_DATE = dayjs()
  .add(dayjs().get('hour') >= 12 ? 2 : 1, 'day')
  .toDate();

const renderTourismActivity = (activity, host, index, onPress) => {
  return (
    <View style={styles.activity} key={index}>
      <TouchableOpacity onPress={onPress}>
        <Image source={{uri: activity.photo}} style={styles.activityImage} />
      </TouchableOpacity>
      <View style={styles.activityDetail}>
        <Text style={styles.activityTitle}>{activity.title}</Text>
        <Text style={styles.activityDescription}>{activity.description}</Text>
        {!!host && (
          <>
            <View style={styles.split} />
            <View style={styles.flexRow}>
              <Image
                source={{uri: host?.avatar ? host.avatar : ''}}
                style={styles.avatar}
              />
              <View style={styles.userWrapper}>
                <Text style={styles.textUserActivity}>{host?.name}</Text>
                <Text style={styles.textUserJob}>{host?.job_title}</Text>
              </View>
            </View>
          </>
        )}
      </View>
    </View>
  );
};
const VolunteerActivity = ({activity, host, onPress}) => {
  return (
    <View style={styles.activity}>
      <TouchableOpacity onPress={onPress}>
        <Image source={{uri: activity.photo}} style={styles.activityImage} />
      </TouchableOpacity>
      <View style={styles.activityDetail}>
        <Text style={styles.activityTitle}>{activity.title}</Text>
        <Text style={styles.activityDescription}>{activity.description}</Text>
        {!!host && (
          <>
            <View style={styles.split} />
            <View style={styles.flexRow}>
              <Image
                source={{uri: host?.avatar ? host.avatar : ''}}
                style={styles.avatar}
              />
              <View style={styles.userWrapper}>
                <Text style={styles.textUserActivity}>{host?.name}</Text>
                <Text style={styles.textUserJob}>{host?.job_title}</Text>
              </View>
            </View>
          </>
        )}
      </View>
    </View>
  );
};
const Schedule = ({schedule}) => {
  const {i18n} = useTranslations();
  return (
    <View style={styles.scheduleDay}>
      <View style={styles.schedule}>
        <View style={styles.scheduleIcon} />
        <Text style={styles.textSchedule}>{`${i18n.t('day')} ${
          schedule?.days
        }`}</Text>
      </View>
      {(schedule?.actions || []).map((action, scheduleIndex) => {
        return (
          <View key={scheduleIndex} style={styles.scheduleItem}>
            <View style={styles.schedulePoint} />
            <Text style={styles.textScheduleDetail}>{action}</Text>
          </View>
        );
      })}
    </View>
  );
};

const FORM_TYPE = {
  INIT: 1,
  DISCLAIMER: 2,
  SUCCESS: 3,
  FAILURE: 4,
  SUPPORT: 5,
};

const handlePayment = (
  activityId,
  tripId,
  accompanyCount,
  price,
  token,
  tierIndex,
) => {
  const params = new URLSearchParams({
    data: JSON.stringify({
      type: 'trip',
      tripId: tripId,
      price,
      quantity: accompanyCount,
      processor: ['wechatpay', 'jsapi'],
    }),
    redirects: JSON.stringify({
      success: `${API_URL}/trip/${tripId}/success/${accompanyCount}`,
      error: `${API_URL}/plan/${activityId}/${tripId}/fail/${accompanyCount}/${tierIndex}`,
    }),
    auth: token,
  });
  const payLink = '/api/payment/new-order?' + params;
  window.location.href = payLink;
};

const PlanDetail = () => {
  const route = useRoute();
  const activityId = route.params?.activityId;
  const tripId = route.params?.tripId;
  const paymentStatus = route.params?.paymentStatus;
  const accompanyCount = route.params?.accompanyCount;
  const tierIndex = route.params?.tierIndex;

  const insets = useSafeAreaInsets();
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const {alert} = useAlert();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const userData = useSelector(state => state.user.data);
  const token = useSelector(state => state.auth.token);
  const userTripData = useSelector(state => state.user.trip);

  const [viewImage, setViewImage] = useState([]);
  const [openingModalType, setOpeningModalType] = useState(
    paymentStatus === 'success'
      ? FORM_TYPE.SUCCESS
      : paymentStatus === 'fail'
      ? FORM_TYPE.FAILURE
      : null,
  );

  const [selectedTierIndex, setSelectedTierIndex] = useState(
    toNumber(tierIndex) || 0,
  );
  const [numberAccompany, setNumberAccompany] = useState(
    toNumber(accompanyCount) || 0,
  );

  const [startDate, setStartDate] = useState(INIT_START_DATE);
  const [openSelectDate, setOpenSelectDate] = useState(false);
  const [isPublicActivity, setIsPublicActivity] = useState(false);

  const handleShowNotFound = () => {
    alert(i18n.t('something_went_wrong'), () => {
      handleGoBack();
    });
  };
  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.dispatch(
        StackActions.replace(
          isLoggedIn ? routeNames.BottomTab : routeNames.AuthFlow.WelcomeScreen,
        ),
      );
    }
  };

  const {runRequest: getUserTrip} = useGetUserTrip({});

  const {runRequest: initTripFromActivity, isLoading: isLoadingInitTrip} =
    useInitTripFromActivity({
      successCallback: response => {
        handlePayment(
          activityId,
          response.data.id,
          numberAccompany,
          get(activityDetail, `prices.details[${selectedTierIndex}].price`, 0) *
            (1 + numberAccompany),
          token,
          selectedTierIndex,
        );
      },
      failureCallback: () => {
        alert(i18n.t('something_went_wrong'));
      },
    });

  const {
    runRequest: getActivityDetail,
    isLoading: isLoadingGetDetail,
    responseData,
  } = useGetActivityDetail({
    failureCallback: handleShowNotFound,
  });

  const {runRequest: deleteTrip, isLoading: loadingDelete} = useDeleteTrip({
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'));
    },
  });

  useEffect(() => {
    if (!activityId) {
      handleShowNotFound();
    } else {
      getActivityDetail(activityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData.id) {
      getUserTrip(userData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.id]);

  const activityDetail = responseData?.data?.plan || {};

  const handleCheckDuplicateTrip = params => {
    setStartDate(params.date);
    const tripStart = params.date;
    const tripEnd = dayjs(params.date)
      .add((activityDetail?.schedules || []).length, 'day')
      .toDate();
    if (checkDuplicate(userTripData, tripStart, tripEnd)) {
      alert(i18n.t('duplicate_trip'));
    } else {
      setOpenSelectDate(false);
      setOpeningModalType(FORM_TYPE.INIT);
    }
  };

  const handleCreateTrip = () => {
    setOpeningModalType(null);
    initTripFromActivity(activityDetail.id, {
      is_public: isPublicActivity,
      from_date: dayjs(startDate).utc().format('YYYY-MM-DD HH:mm:ss.SSS'),
      maximum_member: get(
        activityDetail,
        `prices.details[${selectedTierIndex}].personsCount`,
        0,
      ),
      trip_tier: selectedTierIndex,
    });
  };

  const handleBottomClick = () => {
    if (isLoggedIn) {
      if (Platform.OS === 'web' && isWeChat()) {
        setOpenSelectDate(true);
      } else {
        alert(i18n.t('please_open_wechat_payment'));
      }
    } else {
      dispatch(addActivity(activityId));
      navigation.navigate(routeNames.AuthFlow.WelcomeScreen);
    }
  };

  return (
    <>
      <View style={styles.modalContainer}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Image
            source={{uri: activityDetail?.background}}
            resizeMode="cover"
            style={styles.imageBackground}
          />
          <LinearGradient
            colors={['rgba(18, 24, 16, 0)', 'rgba(18, 24, 16, 0.5)', '#000']}
            locations={[0.05, 0.1, 0.2]}
            style={[styles.linearGradientMore, {paddingTop: insets.top}]}>
            <View style={styles.titleWrapperMore}>
              <Text style={styles.textTitle} numberOfLines={2}>
                {activityDetail?.heading}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate(routeNames.NonAuthFlow.VillageDetail, {
                    villageId: activityDetail.village_id,
                  });
                }}>
                <Text style={styles.textVillageMore}>
                  {activityDetail?.location}
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.border} />
            <Text style={styles.description}>
              {activityDetail?.introduction}
            </Text>
            <View style={styles.padding32}>
              <Text style={styles.header}>
                {i18n.t('trip_detail.tourism_activity')}
              </Text>
              {(activityDetail?.tourism_activities || []).map((i, index) => {
                const host = (activityDetail?.hosts || []).find(
                  item => item.id === i.host,
                );
                return renderTourismActivity(i, host, index, () => {
                  setViewImage([i.photo]);
                });
              })}
            </View>
            <View style={styles.padding32}>
              <Text style={styles.header}>
                {activityDetail?.village_id === 13
                  ? i18n.t('trip_detail.accommodation')
                  : i18n.t('trip_detail.volunteer_activity')}
              </Text>
              {(activityDetail?.volunteer_activities || []).map((i, index) => {
                const host = (activityDetail?.hosts || []).find(
                  item => item.id === i.host,
                );
                return (
                  <VolunteerActivity
                    activity={i}
                    host={host}
                    key={index}
                    onPress={() => {
                      setViewImage([i.photo]);
                    }}
                  />
                );
              })}
            </View>
            {(activityDetail?.schedules || []).length ? (
              <View style={styles.padding32}>
                <Text style={styles.header}>
                  {i18n.t('trip_detail.schedule')}
                </Text>
                {(activityDetail?.schedules || []).map((schedule, index) => {
                  return <Schedule schedule={schedule} key={index} />;
                })}
              </View>
            ) : null}
            <View style={styles.padding32}>
              <Text style={styles.header}>{i18n.t('trip_detail.hosts')}</Text>
              <View style={styles.hostList}>
                {(activityDetail?.hosts || []).map((host, index) => {
                  return (
                    <View style={styles.hostItem} key={index}>
                      <View style={styles.flexRow}>
                        <Image
                          source={{uri: host?.avatar ? host.avatar : ''}}
                          style={styles.avatar}
                        />
                        <View style={styles.userWrapper}>
                          <Text style={styles.textUserActivity}>
                            {host.name}
                          </Text>
                          <Text style={styles.textUserJob}>
                            {host.job_title}
                          </Text>
                        </View>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
            {!isEmpty(activityDetail?.accommodation) && (
              <View style={styles.padding32}>
                <Text style={styles.header}>
                  {i18n.t('invitation.accommodation')}
                </Text>
                <FlatList
                  data={activityDetail?.accommodation}
                  renderItem={({item}) => {
                    return (
                      <TouchableOpacity
                        style={styles.activityItem}
                        onPress={() => {
                          navigation.navigate(
                            routeNames.NonAuthFlow.StoryDetail,
                            {
                              storyId: item.cuid,
                            },
                          );
                        }}>
                        <Image
                          style={styles.activityImageItem}
                          source={{uri: item.background}}
                        />
                        <Text style={styles.activityNameItem} numberOfLines={2}>
                          {item.title}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                  keyExtractor={item => item.id}
                  horizontal
                  style={styles.storyWrapper}
                  contentContainerStyle={styles.storyContainer}
                />
              </View>
            )}
            <View style={styles.cost}>
              <Text style={styles.headerAlign}>
                {i18n.t('trip_detail.trip_cost')}
              </Text>
              {(activityDetail?.prices?.details || []).map((price, index) => {
                return (
                  <View style={styles.costItem} key={index}>
                    <Text style={styles.costTitle}>{`${
                      price.personsCount
                    } ${i18n.t('person')}`}</Text>
                    <Text style={styles.hostTitle}>{`¥${formatPrice(
                      price.price,
                    )}/${i18n.t('person')}`}</Text>
                  </View>
                );
              })}
              <View style={styles.split} />
              <Text style={styles.headerAlign}>
                {i18n.t('trip_detail.disclaimers')}
              </Text>
              <View>
                {(activityDetail?.disclaimers || []).map(
                  (disclaimer, index) => {
                    return (
                      <View key={index} style={styles.disclaimerItem}>
                        <View>
                          <Text style={styles.disclaimerTitle}>
                            {('00' + (index + 1)).slice(-2)}
                          </Text>
                        </View>
                        <Text style={styles.disclaimerDetail}>
                          {disclaimer}
                        </Text>
                      </View>
                    );
                  },
                )}
              </View>
            </View>
            <Text
              onPress={() => {
                setOpeningModalType(FORM_TYPE.SUPPORT);
              }}
              style={[
                textFont(16, '700'),
                {color: colors.primary},
                styles.wechatQR,
              ]}>
              {i18n.t('contact_support')}
            </Text>
          </LinearGradient>
        </ScrollView>

        <LinearGradient
          colors={['rgba(0, 0, 0, 0.01)', '#000']}
          locations={[0, 0.35]}
          style={[styles.bottomButtonMore, {paddingBottom: insets.bottom}]}>
          <View style={styles.bottomSection}>
            <Button
              disabled={isLoggedIn && !IS_STAGING}
              title={i18n.t(
                isLoggedIn
                  ? IS_STAGING
                    ? 'initiate_new_event'
                    : 'coming_soon'
                  : 'trip_detail.sign_up',
              )}
              onClick={handleBottomClick}
            />
          </View>
        </LinearGradient>
      </View>

      <TouchableOpacity
        onPress={handleGoBack}
        style={[styles.backButton, {top: insets.top + 30}]}>
        <Image source={images.ArrowLeftWhite} style={styles.backIcon} />
      </TouchableOpacity>
      <ImageView
        visible={!isEmpty(viewImage)}
        setVisible={() => {
          setViewImage([]);
        }}
        imageList={viewImage}
      />
      {(isLoadingInitTrip || isLoadingGetDetail || loadingDelete) && (
        <Loading />
      )}
      <DatePickerModal
        visible={openSelectDate}
        onDismiss={() => {
          setOpenSelectDate(false);
        }}
        date={startDate}
        onConfirm={handleCheckDuplicateTrip}
        validRange={{
          startDate: INIT_START_DATE,
          endDate: dayjs().add(1, 'year').toDate(),
        }}
        saveLabel={i18n.t('profile.bio.select')}
        label={i18n.t('trip_detail.trip_date')}
      />
      <ModalInitActivities
        activityData={activityDetail}
        show={openingModalType === FORM_TYPE.INIT}
        onClose={() => setOpeningModalType(null)}
        onNext={handleCreateTrip}
        tierIndex={selectedTierIndex}
        setTierIndex={setSelectedTierIndex}
        isPublicActivity={isPublicActivity}
        setIsPublicActivity={setIsPublicActivity}
        numberAccompany={numberAccompany}
        setNumberAccompany={setNumberAccompany}
        handleAgreement={() => setOpeningModalType(FORM_TYPE.DISCLAIMER)}
      />
      <ModalDisclaimer
        title={i18n.t('payment_agreement')}
        activityData={{disclaimers: [PAYMENT_AGREEMENT]}}
        show={openingModalType === FORM_TYPE.DISCLAIMER}
        onClose={() => setOpeningModalType(FORM_TYPE.INIT)}
        onNext={() => setOpeningModalType(FORM_TYPE.INIT)}
        isHideButton
      />
      <ModalSuccess
        title={activityDetail?.heading}
        show={openingModalType === FORM_TYPE.SUCCESS}
        onClose={() => {
          setOpeningModalType(null);
        }}
        onNext={() => {
          setOpeningModalType(null);
          navigation.navigate(routeNames.MainFlow.MyTrip);
        }}
        nextButtonTitle={'go_to_my_trip'}
      />
      <ModalFailure
        title={activityDetail?.heading}
        show={openingModalType === FORM_TYPE.FAILURE}
        onClose={() => {
          setOpeningModalType(null);
          deleteTrip(tripId);
        }}
        onNext={() => {
          setOpeningModalType(null);
          handlePayment(
            activityId,
            tripId,
            numberAccompany,
            get(
              activityDetail,
              `prices.details[${selectedTierIndex}].price`,
              0,
            ) *
              (1 + numberAccompany),
            token,
            selectedTierIndex,
          );
        }}
      />
      <ShareCode
        visible={openingModalType === FORM_TYPE.SUPPORT}
        onClose={() => {
          setOpeningModalType(null);
        }}
      />
    </>
  );
};

export default PlanDetail;
