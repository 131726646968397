import {createSlice} from '@reduxjs/toolkit';
import {
  loginRequest,
  logout,
  registerRequest,
  verifyPhoneRequest,
  registerPhoneRequest,
} from '../../../api/auth';
import {axiosSetAuthToken} from 'src/utils/axiosConfig';
import {isFillUserPhoneData} from 'src/utils/common';

const initialState = {
  isLoading: false,
  isVerifyPhoneLoading: false,
  error: false,
  isLoggedIn: false,
  token: null,
  // Is click on promotion link
  influencerPromotion: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    registerLogin: state => {
      state.isLoggedIn = true;
    },
    storeToken: (state, action) => {
      state.token = action.payload.token;
    },
    storeInfluencerPromotion: (state, action) => {
      state.influencerPromotion = action.payload.influencer;
    },
    removeInfluencerPromotion: state => {
      state.influencerPromotion = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(loginRequest.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(loginRequest.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
    builder.addCase(loginRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isLoggedIn = isFillUserPhoneData(action.payload.data);
      state.error = false;
      state.token = action.payload.data.token;
      axiosSetAuthToken(action.payload.data.token);
    });

    builder.addCase(registerRequest.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(registerRequest.rejected, state => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.error = true;
    });
    builder.addCase(registerRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isLoggedIn = isFillUserPhoneData(action.payload.data);
      state.error = false;
      state.token = action.payload.data.token;
      axiosSetAuthToken(action.payload.data.token);
    });
    builder.addCase(registerPhoneRequest.pending, state => {
      state.isVerifyPhoneLoading = true;
    });
    builder.addCase(registerPhoneRequest.rejected, state => {
      state.isVerifyPhoneLoading = false;
    });
    builder.addCase(registerPhoneRequest.fulfilled, (state, action) => {
      state.isVerifyPhoneLoading = false;
    });
    builder.addCase(verifyPhoneRequest.pending, state => {
      state.isVerifyPhoneLoading = true;
    });
    builder.addCase(verifyPhoneRequest.rejected, state => {
      state.isVerifyPhoneLoading = false;
    });
    builder.addCase(verifyPhoneRequest.fulfilled, (state, action) => {
      state.isLoggedIn = isFillUserPhoneData(action.payload.data);
      state.isVerifyPhoneLoading = false;
    });
    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

const {reducer: authReducer} = authSlice;

export const {
  registerLogin,
  storeToken,
  storeInfluencerPromotion,
  removeInfluencerPromotion,
} = authSlice.actions;

export default authReducer;
