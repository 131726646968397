import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  TextInput,
  Image,
  FlatList,
  ActivityIndicator,
  RefreshControl,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import styles from './styles';
import {HomeHeader} from 'src/components';
import {useDebounce, useTranslations} from 'src/hooks';
import images from 'src/assets/images';
import {colors} from 'src/constants';
import {useNavigation} from '@react-navigation/native';
import routeNames from 'src/navigation/routeNames';
import {useGetListActivity} from 'src/api/activities';
import {useGetVillageList} from 'src/api/village';
import {useSelector} from 'react-redux';

const SEARCH_TYPE = {
  VILLAGE: 1,
  ACTIVITY: 2,
};

const SearchScreen = () => {
  const [searchType, setSearchType] = useState(SEARCH_TYPE.VILLAGE);
  const [searchString, setSearchString] = useState('');
  const {i18n} = useTranslations();
  const navigation = useNavigation();

  const {runRequest: getListActivity, isLoading: isLoadingGetListActivity} =
    useGetListActivity({});

  const {runRequest: getListVillage, isLoading: isLoadingGetListVillage} =
    useGetVillageList({});

  const listActivity = useSelector(state => state.activities.listActivity);
  const listVillage = useSelector(state => state.village.listVillage);

  const searchDebounceString = useDebounce({value: searchString, delay: 500});

  useEffect(() => {
    onRefreshActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebounceString, searchType]);

  const onRefreshActivity = () => {
    if (searchType === SEARCH_TYPE.VILLAGE) {
      getListVillage({name: searchDebounceString?.toLowerCase()});
    } else {
      getListActivity({name: searchDebounceString?.toLowerCase()});
    }
  };

  const renderActivity = ({item}) => {
    return (
      <TouchableOpacity
        style={styles.activity}
        onPress={() => {
          navigation.navigate(routeNames.NonAuthFlow.PlanDetail, {
            activityId: item.id,
          });
        }}>
        <Image style={styles.activityImage} source={{uri: item.background}} />
        <Text style={styles.activityName} numberOfLines={2}>
          {item.heading}
        </Text>
      </TouchableOpacity>
    );
  };

  const renderVillage = ({item}) => {
    return (
      <TouchableOpacity
        style={styles.village}
        onPress={() => {
          navigation.navigate(routeNames.NonAuthFlow.VillageDetail, {
            villageId: item.id,
          });
        }}>
        <Image source={{uri: item.background}} style={styles.villageImage} />
        <Text style={styles.villageName} numberOfLines={1}>
          {item.name}
        </Text>
        <View style={styles.locationWrapper}>
          <Image source={images.Location} style={styles.location} />
          <Text style={styles.textCity} numberOfLines={1}>
            {item.location ? item.location : 'China'}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const isLoadingGetList = isLoadingGetListActivity || isLoadingGetListVillage;

  return (
    <SafeAreaView style={styles.container}>
      <HomeHeader withBackButton />
      <View style={styles.buttonWrapper}>
        <TouchableOpacity
          onPress={() => {
            setSearchString('');
            setSearchType(SEARCH_TYPE.VILLAGE);
          }}
          style={
            searchType === SEARCH_TYPE.VILLAGE
              ? styles.buttonSelected
              : styles.button
          }>
          <Text
            style={
              searchType === SEARCH_TYPE.VILLAGE
                ? styles.buttonTitleSelected
                : styles.buttonTitle
            }>
            {i18n.t('home.search.village')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setSearchString('');
            setSearchType(SEARCH_TYPE.ACTIVITY);
          }}
          style={
            searchType === SEARCH_TYPE.ACTIVITY
              ? styles.buttonSelected
              : styles.button
          }>
          <Text
            style={
              searchType === SEARCH_TYPE.ACTIVITY
                ? styles.buttonTitleSelected
                : styles.buttonTitle
            }>
            {i18n.t('home.search.activity')}
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.searchContent}>
        <View style={styles.searchWrapper}>
          <Image source={images.Search} style={styles.icon} />
          <TextInput
            style={styles.search}
            placeholder={i18n.t('home.search.search_village')}
            value={searchString}
            onChangeText={setSearchString}
          />
        </View>
        <TouchableOpacity
          style={styles.buttonClose}
          onPress={() => {
            setSearchString('');
          }}>
          {isLoadingGetList ? (
            <ActivityIndicator color={colors.neutral1} size="small" />
          ) : (
            <Text style={styles.textClose}>+</Text>
          )}
        </TouchableOpacity>
      </View>

      <FlatList
        refreshControl={
          <RefreshControl
            refreshing={isLoadingGetList}
            onRefresh={onRefreshActivity}
          />
        }
        style={styles.activityWrapper}
        horizontal={false}
        numColumns={2}
        showsVerticalScrollIndicator={false}
        data={searchType === SEARCH_TYPE.VILLAGE ? listVillage : listActivity}
        renderItem={
          searchType === SEARCH_TYPE.VILLAGE ? renderVillage : renderActivity
        }
        keyExtractor={item => item.id}
      />
    </SafeAreaView>
  );
};

export default SearchScreen;
