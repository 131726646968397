import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.neutral9,
    width: 148,
    height: 200,
    borderWidth: 1,
    borderColor: colors.neutral9,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: calculateWidth(16),
    marginBottom: calculateWidth(25),
    shadowColor: colors.primary,
    shadowOffset: {
      width: -5,
      height: 10,
    },
    shadowOpacity: 0.08,
    shadowRadius: 5,
    elevation: 5,
  },
  image: {
    width: 72,
    height: 72,
    marginBottom: calculateWidth(4),
  },
  date: {
    ...textFont(14, '400'),
    color: colors.neutral4,
    marginBottom: calculateWidth(12),
  },
  name: {
    ...textFont(16, '600'),
    marginBottom: calculateWidth(2),
  },
  des: {
    ...textFont(14, '400'),
  },
});
