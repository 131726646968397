import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  container: {flex: 1},
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  poster: {
    width: calculateWidth(350),
    height: calculateWidth(350),
    alignSelf: 'center',
    backgroundColor: colors.neutral7,
    borderRadius: 8,
  },
  button: {
    height: calculateWidth(70),
    marginTop: calculateWidth(16),
    width: calculateWidth(300),
  },
  title: {
    ...textFont(24, '600'),
    textAlign: 'center',
    marginBottom: calculateWidth(32),
  },
  hint: {
    ...textFont(12, '400'),
    color: colors.neutral4,
    textAlign: 'center',
  },
});
