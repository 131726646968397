import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: 'flex-end',
    height: '100%',
  },
  container: {
    height: '85%',
    backgroundColor: colors.neutral9,
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
    width: '100%',
  },
  closeContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  closeButton: {
    backgroundColor: colors.neutral9,
    borderRadius: 18,
    width: 36,
    height: 36,
    top: -18,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginRight: 16,
  },
  titleWrapper: {
    marginBottom: calculateWidth(32),
    marginHorizontal: calculateWidth(32),
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    ...textFont(22, '600'),
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
