import {StyleSheet} from 'react-native';

import {calculateWidth} from 'src/utils/dimensions';
import colors from 'src/constants/colors';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  content: {
    backgroundColor: colors.neutral9,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: calculateWidth(62),
    width: '100%',
    overflow: 'hidden',
    borderTopLeftRadius: calculateWidth(20),
    borderTopRightRadius: calculateWidth(20),
    borderWidth: 1,
    borderColor: colors.neutral8,
  },
  menu: {
    height: calculateWidth(62),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  image: {
    height: calculateWidth(22),
    width: calculateWidth(22),
    marginRight: calculateWidth(6),
  },
  add: {
    height: calculateWidth(41),
    width: calculateWidth(41),
    borderRadius: 100,
  },
  text: {
    textAlign: 'left',
    width: calculateWidth(45),
    ...textFont(14, '600'),
  },
  addWrapper: {
    height: calculateWidth(40),
    width: calculateWidth(40),
    backgroundColor: colors.neutral9,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.primary,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 1,
    shadowRadius: 30,

    elevation: 24,
  },
});
