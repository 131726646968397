import {View, Text, Modal, TouchableOpacity} from 'react-native';
import React from 'react';
import styles from './styles';
import {Button, Checkbox} from 'src/components';
import {useTranslations} from 'src/hooks';
import {get, isEmpty, max, min} from 'lodash';
import {colors} from 'src/constants';
import {formatPrice} from 'src/utils/common';
import {useState} from 'react';
import {textFont} from 'src/utils/styles';

const ModalInitActivities = ({
  show,
  onClose = () => {},
  onNext = () => {},
  activityData,
  isPublicActivity,
  setIsPublicActivity = () => {},
  tierIndex,
  setTierIndex = () => {},
  numberAccompany,
  setNumberAccompany = () => {},
  handleAgreement = () => {},
}) => {
  const {i18n} = useTranslations();
  const [checkedAgreement, setCheckedAgreement] = useState('unchecked');

  const checkOnePerson = index =>
    get(activityData, `prices.details[${index}].personsCount`, 0) === 1;

  const maximumPerson = get(
    activityData,
    `prices.details[${tierIndex}].personsCount`,
    1,
  );
  const paymentAmount =
    get(activityData, `prices.details[${tierIndex}].price`, 0) *
    (1 + numberAccompany);

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={show}
      onRequestClose={onClose}>
      <View style={styles.container}>
        <View style={styles.popup}>
          <Text style={styles.textHeader}>
            {i18n.t('activity_detail.inti_activity')}
          </Text>
          <Text style={styles.textDetail}>{activityData.heading}</Text>
          <View>
            {!isEmpty(activityData?.prices?.details) && (
              <>
                {activityData.prices.details.map((price, index) => {
                  return (
                    <TouchableOpacity
                      style={[
                        styles.money,
                        index === tierIndex && styles.moneySelected,
                      ]}
                      onPress={() => {
                        setTierIndex(index);
                        if (checkOnePerson(index)) {
                          setIsPublicActivity?.(false);
                          setNumberAccompany?.(0);
                        }
                      }}
                      key={index}>
                      <Text style={styles.textPerson}>{`${
                        price.personsCount
                      } ${i18n.t('person')}`}</Text>
                      <Text style={styles.textDetail}>{`¥${formatPrice(
                        price.price,
                      )}/${i18n.t('person')}`}</Text>
                    </TouchableOpacity>
                  );
                })}
              </>
            )}
          </View>
          {!checkOnePerson(tierIndex) && (
            <View>
              <View style={styles.publicRadio}>
                <View style={styles.people}>
                  <Text style={[textFont(15, '900'), {color: colors.neutral1}]}>
                    {i18n.t('pay_for_your_friend')}
                  </Text>
                  <Text style={[textFont(12, '900'), {color: colors.neutral1}]}>
                    {i18n.t('share_to_invite')}
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    setNumberAccompany(value => max([0, value - 1]));
                  }}
                  disabled={numberAccompany === 0}
                  style={[
                    styles.buttonCount,
                    numberAccompany === 0 && {borderColor: colors.neutral6},
                  ]}>
                  <Text
                    style={[
                      numberAccompany === 0 && {color: colors.neutral6},
                      styles.plusText,
                    ]}>
                    −
                  </Text>
                </TouchableOpacity>
                <Text style={styles.accompany}>{numberAccompany}</Text>
                <TouchableOpacity
                  onPress={() => {
                    setNumberAccompany(value =>
                      min([value + 1, maximumPerson - 1]),
                    );
                  }}
                  disabled={maximumPerson - 1 === numberAccompany}
                  style={[
                    styles.buttonCount,
                    maximumPerson - 1 === numberAccompany && {
                      borderColor: colors.neutral6,
                    },
                  ]}>
                  <Text
                    style={[
                      numberAccompany === maximumPerson - 1 && {
                        color: colors.neutral6,
                      },
                      styles.plusText,
                    ]}>
                    +
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.item}>
                <Checkbox
                  value="public"
                  color={colors.primary}
                  status={isPublicActivity ? 'checked' : 'unchecked'}
                  onPress={() => setIsPublicActivity(true)}
                />
                <Text style={styles.textRadio}>
                  {i18n.t('activity_detail.public_activity')}
                </Text>
              </View>
              <View style={styles.item}>
                <Checkbox
                  value="private"
                  color={colors.primary}
                  status={isPublicActivity ? 'unchecked' : 'checked'}
                  onPress={() => setIsPublicActivity(false)}
                />
                <Text style={styles.textRadio}>
                  {i18n.t('activity_detail.private_activity')}
                </Text>
              </View>
            </View>
          )}
          <View style={styles.confirm}>
            <Checkbox
              status={checkedAgreement}
              onPress={() => {
                setCheckedAgreement(
                  checkedAgreement === 'checked' ? 'unchecked' : 'checked',
                );
              }}
              color={colors.primary}
              uncheckedColor={colors.neutral7}
            />
            <Text style={styles.agreement}>
              {`${i18n.t('agree_with')}`}
              <Text
                style={[textFont(14, '900'), {color: colors.neutral1}]}
                onPress={handleAgreement}>
                {`《${i18n.t('payment_agreement')}》`}
              </Text>
            </Text>
          </View>
          <Button
            title={`${i18n.t('activity_detail.next')}     ¥${formatPrice(
              paymentAmount,
            )}`}
            buttonStyle={styles.bottomButton}
            onClick={onNext}
            disabled={checkedAgreement === 'unchecked'}
          />
          <TouchableOpacity style={styles.buttonClose} onPress={onClose}>
            <Text style={styles.textClose}>+</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ModalInitActivities;
