import {View, Text, ScrollView, Image, TouchableOpacity} from 'react-native';
import React from 'react';
import styles from './styles';
import images from 'src/assets/images';
import LinearGradient from 'react-native-linear-gradient';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import dayjs from 'dayjs';
import {useAlert, useTranslations} from 'src/hooks';
import {useSelector} from 'react-redux';
import routeNames from 'src/navigation/routeNames';
import {useNavigation, useRoute} from '@react-navigation/native';
import {get, isEmpty} from 'lodash';
import {useState} from 'react';
import {useEffect} from 'react';
import {Button, ImageView, Loading} from 'src/components';
import {useGetMarketActivity, useScanTicketMarket} from 'src/api/activities';
import {formatPrice} from 'src/utils/common';
import {useGetOrderDetail} from 'src/api/activities/hooks/useGetOrderDetail';
import {API_URL} from 'src/utils/axiosConfig';
import QRCode from 'react-qr-code';
import {calculateWidth} from 'src/utils/dimensions';
import {colors, USER_ROLE} from 'src/constants';

// const renderTourismActivity = (activity, host, index, onPress) => {
//   return (
//     <View style={styles.activity} key={index}>
//       <TouchableOpacity onPress={onPress}>
//         <Image source={{uri: activity.photo}} style={styles.activityImage} />
//       </TouchableOpacity>
//       <View style={styles.activityDetail}>
//         <Text style={styles.activityTitle}>{activity.title}</Text>
//         <Text style={styles.activityDescription}>{activity.description}</Text>
//         {!!host && (
//           <>
//             <View style={styles.split} />
//             <View style={styles.flexRow}>
//               <Image
//                 source={{uri: host?.avatar ? host.avatar : ''}}
//                 style={styles.avatar}
//               />
//               <View style={styles.userWrapper}>
//                 <Text style={styles.textUserActivity}>{host?.name}</Text>
//                 <Text style={styles.textUserJob}>{host?.job_title}</Text>
//               </View>
//             </View>
//           </>
//         )}
//       </View>
//     </View>
//   );
// };

const OrderDetail = () => {
  const userData = useSelector(state => state.user.data);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const route = useRoute();
  const orderId = route.params?.orderId;
  const isFromAppNavigate = route.params?.isFromAppNavigate;
  const {alert} = useAlert();
  const insets = useSafeAreaInsets();
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const [viewImage, setViewImage] = useState([]);

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate(routeNames.BottomTab);
    }
  };

  const {
    runRequest: getTripDetail,
    isLoading: isLoadingGetTripDetail,
    responseData,
  } = useGetMarketActivity({
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'), handleGoBack);
    },
  });

  const {
    runRequest: getOrderDetail,
    isLoading,
    responseData: orderResponse,
  } = useGetOrderDetail({
    successCallback: response => {
      getTripDetail(response.data.product_id);
    },
    failureCallback: () => {
      alert(i18n.t('no_permission'), handleGoBack);
    },
  });

  const {runRequest: scanTicketMarket, isLoading: isLoadingScan} =
    useScanTicketMarket({
      successCallback: () => {
        alert(i18n.t('scan_successful'));
      },
      failureCallback: error => {
        if (error?.response?.data?.message === 'invalid, ticket already used') {
          alert(i18n.t('invalid, ticket already used'));
        } else {
          alert(i18n.t('something_went_wrong'), handleGoBack);
        }
      },
    });

  const marketActivityDetail = responseData?.data;
  const orderData = orderResponse?.data;

  useEffect(() => {
    if (orderId) {
      getOrderDetail(orderId);
    } else {
      alert(i18n.t('something_went_wrong'), handleGoBack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      orderData?.ticket?.cuid &&
      userData.role === USER_ROLE.ORGANIZER &&
      !isFromAppNavigate
    ) {
      scanTicketMarket(orderData?.ticket?.cuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.ticket?.cuid, userData.role, isFromAppNavigate]);

  return (
    <>
      <View style={styles.modalContainer}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Image
            source={{uri: marketActivityDetail?.background}}
            resizeMode="cover"
            style={styles.imageBackground}
          />
          <LinearGradient
            colors={['rgba(18, 24, 16, 0)', 'rgba(18, 24, 16, 0.5)', '#000']}
            locations={[0.05, 0.1, 0.2]}
            style={[styles.linearGradientMore, {paddingTop: insets.top}]}>
            <View style={styles.dateContent}>
              <View style={styles.dateWrapperMore}>
                <View style={styles.flexRow}>
                  <Image
                    source={images.CalendarWhite}
                    style={styles.calendar}
                  />
                  <Text style={styles.textDate}>
                    {dayjs(marketActivityDetail?.from_date).format(
                      'YYYY-MM-DD',
                    )}
                  </Text>
                  <Text style={styles.textDate}>{' ~ '}</Text>
                  <Text style={styles.textDate}>
                    {dayjs(marketActivityDetail?.to_date).format('YYYY-MM-DD')}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.titleWrapperMore}>
              <Text style={styles.textTitle} numberOfLines={2}>
                {marketActivityDetail?.title}
              </Text>
              <View style={styles.location}>
                <Image source={images.Location} style={styles.iconLocation} />
                <Text style={styles.textVillageMore}>
                  {marketActivityDetail?.location}
                </Text>
              </View>
            </View>
            <View style={styles.border} />
            <View style={styles.cost}>
              <Text style={styles.headerAlign}>{i18n.t('ticket_details')}</Text>
              <View style={styles.costItem}>
                <Text style={styles.costTitle}>{i18n.t('paid_at')}</Text>
                <Text style={styles.hostTitle}>
                  {dayjs(orderData?.created_at).format('YYYY-MM-DD HH:mm')}
                </Text>
              </View>
              <View style={styles.costItem}>
                <Text style={styles.costTitle}>{i18n.t('paid_for')}</Text>
                <Text style={styles.hostTitle}>{`${
                  1 + (get(orderData, 'persons', [])?.length || 0)
                } ${i18n.t('person')}`}</Text>
              </View>
              <View style={styles.costItem}>
                <Text style={styles.costTitle}>{i18n.t('total_price')}</Text>
                <Text style={styles.hostTitle}>{`¥${formatPrice(
                  orderData?.amount,
                )}`}</Text>
              </View>
              <View style={styles.costItem}>
                <Text style={styles.costTitle}>{i18n.t('order_id')}</Text>
                <Text style={styles.hostTitle}>{orderId}</Text>
              </View>
              <View style={styles.code}>
                <QRCode
                  value={`${API_URL}/orderDetail/${orderId}`}
                  size={calculateWidth(180)}
                  bgColor={colors.neutral4}
                  fgColor={colors.neutral9}
                />
              </View>
            </View>
            {/* <View style={styles.padding32}>
              <Text style={styles.header}>{i18n.t('what_included')}</Text>
              {(marketActivityDetail?.tourism_activities || []).map(
                (i, index) => {
                  const host = (marketActivityDetail?.hosts || []).find(
                    item => item.id === i.host,
                  );
                  return renderTourismActivity(i, host, index, () => {
                    setViewImage([i.photo]);
                  });
                },
              )}
            </View> */}
            <View
              style={[styles.bottomButtonMore, {paddingBottom: insets.bottom}]}>
              <Button
                title={i18n.t('share_friend')}
                buttonStyle={styles.shareButton}
                disabled={!marketActivityDetail?.cuid}
                onClick={() => {
                  navigation.navigate(routeNames.MainFlow.ShareActivity, {
                    data: JSON.stringify({
                      title: marketActivityDetail?.title,
                      dateFromTo: `${dayjs(
                        marketActivityDetail?.from_date,
                      ).format('DD/MM')} - ${dayjs(
                        marketActivityDetail?.to_date,
                      ).format('DD/MM')}`,
                      year: dayjs(marketActivityDetail?.from_date).format(
                        'YYYY',
                      ),
                      url: `${API_URL}/marketActivity/${
                        marketActivityDetail?.cuid
                      }${
                        userData?.referral_code
                          ? `?influencer=${userData.referral_code}`
                          : ''
                      }`,
                    }),
                  });
                }}
              />
            </View>
          </LinearGradient>
          <TouchableOpacity
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack();
              } else {
                navigation.navigate(
                  isLoggedIn
                    ? routeNames.BottomTab
                    : routeNames.AuthFlow.WelcomeScreen,
                );
              }
            }}
            style={[styles.backButton, {top: insets.top + 30}]}>
            <Image source={images.ArrowLeftWhite} style={styles.backIcon} />
          </TouchableOpacity>
        </ScrollView>
      </View>
      {(isLoading || isLoadingGetTripDetail || isLoadingScan) && <Loading />}
      <ImageView
        visible={!isEmpty(viewImage)}
        setVisible={() => {
          setViewImage([]);
        }}
        imageList={viewImage}
      />
    </>
  );
};

export default OrderDetail;
