import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {uploadBase64Url} from '../../endpoints';

export const upload = createAsyncThunk(
  'upload',
  async ({params, successCallback, failureCallback}, {rejectWithValue}) => {
    const data = {
      imageBase64: params.file,
      tags: params.tags,
    };

    try {
      const response = await axios.request({
        method: 'POST',
        url: uploadBase64Url,
        data,
      });
      successCallback?.(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback?.(err);
        return rejectWithValue(err.response.data.errors);
      }
    }
  },
);
