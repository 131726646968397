import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateHeight, calculateWidth} from 'src/utils/dimensions';
import {opacityColor} from 'src/utils/styles';

export default StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: calculateWidth(375),
    height: calculateHeight(812),
    backgroundColor: opacityColor(colors.neutral1, 0.5),
  },
  menu: {
    position: 'absolute',
    right: 0,
    backgroundColor: colors.neutral9,
    padding: calculateWidth(8),
    borderRadius: calculateWidth(6),
    borderWidth: 1,
    borderColor: colors.neutral6,
  },
  item: {
    paddingVertical: calculateWidth(4),
  },
});
