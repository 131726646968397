import {createSlice} from '@reduxjs/toolkit';
import {logout} from 'src/api/auth';
import {getWechatConfigRequest} from 'src/api/wechat';

const initialState = {
  isLoadingGetConfig: false,
  errorGetConfig: false,
  config: {},
};

export const wechatSlice = createSlice({
  name: 'wechat',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getWechatConfigRequest.pending, state => {
      state.isLoadingGetConfig = true;
      state.errorGetConfig = false;
      state.config = {};
    });
    builder.addCase(getWechatConfigRequest.rejected, state => {
      state.isLoadingGetConfig = false;
      state.errorGetConfig = true;
    });
    builder.addCase(getWechatConfigRequest.fulfilled, (state, action) => {
      state.isLoadingGetConfig = false;
      state.errorGetConfig = false;
      state.config = action.payload.data;
    });

    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

const {reducer: wechatReducer} = wechatSlice;

export default wechatReducer;
