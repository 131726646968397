import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Modal,
  FlatList,
  Pressable,
  TouchableWithoutFeedback,
} from 'react-native';
import React from 'react';
import styles from './styles';
import images from 'src/assets/images';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useTranslations} from 'src/hooks';
import {useSelector} from 'react-redux';
import routeNames from 'src/navigation/routeNames';
import {StackActions, useNavigation} from '@react-navigation/native';
import {useState} from 'react';
import {Button} from 'src/components';
import {colors} from 'src/constants';

const listCity = [
  'hangzhou_jiachang',
  'guangzhou',
  'shenzhen',
  'changsha',
  'wuhan',
  'xian',
];

const listEventType = ['standard_version', 'sport_version'];

const MODAL_TYPE = {
  CITY: 1,
  TYPE: 2,
};

export const ProductionActivityID = {
  beijing: 'cl7t2p91e0000oww09acfh3ue',
  hangzhou_jiachang: 'cl7t2qojk0001oww03cz7ctm4',
  // hangzhou_yichang: 'cl7wrld7o0000b8w0g9rm6tkt',
  guangzhou: 'cl7t2qojk0002oww0fm0vgrxv',
  shenzhen: 'cl7t2qojl0003oww0cgt337lh',
  // shenzhen_sport: 'cl7t2qojl0004oww0dspobe95',
  changsha: 'cl7t2qojl0005oww08ztu8g8x',
  wuhan: 'cl7t2qojl0006oww00drl4np8',
  chengdu: 'cl7t2qojl0007oww0devk3qtm',
  chongqing: 'cl7t2qojl0008oww05j7t23o9',
  xian: 'cl7t2qojl0009oww0ca923zxs',
  nanjing: 'cl7t2qojl000aoww062wkbt32',
  hangzhou_onetime: 'cl8pczk960002o4w0gw571y9m',
  changsha_onetime: 'cl8pcrop50001o4w09d8v81s4',
  xian_onetime: 'cl8pdd6j00003o4w07n912lve',
};

const ChooseCity = () => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const insets = useSafeAreaInsets();
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const [showModalType, setShowModalType] = useState(false);
  const [selectedCity, setSelectedCity] = useState(listCity[0]);
  // Does not use for now!
  // eslint-disable-next-line no-unused-vars
  const [selectedEventType, setSelectedEventType] = useState(listEventType[0]);

  const activityId = ProductionActivityID;

  const renderItem = ({item}) => (
    <Pressable
      onPress={() => {
        setSelectedCity(item);
      }}>
      <View style={selectedCity === item ? styles.itemSelected : styles.item}>
        <Text style={styles.title}>
          {i18n.t(showModalType === MODAL_TYPE.CITY ? `cities.${item}` : item)}
        </Text>
      </View>
    </Pressable>
  );
  return (
    <View style={styles.modalContainer}>
      <Image
        source={images.MarketActivityBackground}
        resizeMode="cover"
        style={styles.imageBackground}
      />
      <View
        style={[
          styles.bottomButtonMoreChooseCity,
          {paddingBottom: insets.bottom},
        ]}>
        <Button
          title={i18n.t(`cities.${selectedCity}`)}
          icon={images.Location}
          buttonStyle={styles.findButton}
          textStyle={[{color: colors.black}]}
          onClick={() => {
            setSelectedCity(selectedCity);
            setShowModalType(MODAL_TYPE.CITY);
          }}
        />
        {/* {selectedCity === listCity[3] && (
            <Button
              icon={images.Ticket}
              title={i18n.t(selectedEventType)}
              buttonStyle={styles.locationButton}
              textStyle={[{color: colors.black}]}
              onClick={() => {
                setTempSelected(selectedEventType);
                setShowModalType(MODAL_TYPE.TYPE);
              }}
            />
          )} */}

        <Button
          title={i18n.t('screen_select_city.go_to_bazaar')}
          buttonStyle={styles.shareButton}
          onClick={() => {
            navigation.navigate(routeNames.NonAuthFlow.MarketActivity, {
              activityId: activityId[selectedCity],
              isFromAppNavigate: true,
            });
          }}
        />
      </View>
      <TouchableOpacity
        onPress={() => {
          if (navigation.canGoBack()) {
            navigation.goBack();
          } else {
            navigation.dispatch(
              StackActions.replace(
                isLoggedIn
                  ? routeNames.BottomTab
                  : routeNames.AuthFlow.WelcomeScreen,
              ),
            );
          }
        }}
        style={[styles.backButton, {top: insets.top + 30}]}>
        <Image source={images.ArrowLeftWhite} style={styles.backIcon} />
      </TouchableOpacity>
      <Modal
        animationType="slide"
        transparent={true}
        visible={showModalType}
        onRequestClose={() => setShowModalType(false)}>
        <TouchableWithoutFeedback onPress={() => setShowModalType(false)}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={styles.modalControl}>
                <Pressable
                  onPress={() => {
                    setShowModalType(false);
                  }}>
                  <Text style={styles.modalTextCancel}>{i18n.t('cancel')}</Text>
                </Pressable>
                <Pressable
                  onPress={() => {
                    setShowModalType(false);
                  }}>
                  <Text style={styles.modalTextOk}>{i18n.t('ok')}</Text>
                </Pressable>
              </View>
              <FlatList
                data={
                  showModalType === MODAL_TYPE.CITY ? listCity : listEventType
                }
                renderItem={renderItem}
                keyExtractor={item => item}
                style={styles.modal}
                showsVerticalScrollIndicator={false}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
};

export default ChooseCity;
