import {createSlice} from '@reduxjs/toolkit';
import {logout} from 'src/api/auth';
import {
  getConversationDetailRequest,
  getMessages,
} from '../../../api/conversation';

const initialState = {
  error: false,
  isLoadingGetDetail: false,
  isLoadingGetMessages: false,
  data: {},
};

export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getConversationDetailRequest.pending, state => {
      state.isLoadingGetDetail = true;
      state.error = false;
    });
    builder.addCase(getConversationDetailRequest.rejected, state => {
      state.isLoadingGetDetail = false;
      state.error = true;
    });
    builder.addCase(getConversationDetailRequest.fulfilled, (state, action) => {
      state.isLoadingGetDetail = false;
      state.error = false;
      state.data = action.payload.data;
    });
    builder.addCase(getMessages.pending, state => {
      state.isLoadingGetMessages = true;
      state.error = false;
    });
    builder.addCase(getMessages.rejected, state => {
      state.isLoadingGetMessages = false;
      state.error = true;
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      state.isLoadingGetMessages = false;
      state.error = false;
    });

    builder.addCase(logout.fulfilled, state => {
      Object.entries(initialState).map(([key, value]) => {
        state[key] = value;
      });
    });
  },
});

const {reducer: conversationReducer} = conversationSlice;

export default conversationReducer;
