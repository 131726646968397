import {View, Text, ScrollView} from 'react-native';
import React, {useState, useMemo, useRef, useEffect} from 'react';
import styles, {ITEM_HEIGHT} from './styles';

const WheelPicker = ({options, selectedIndex, onChange}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const extendedOption = useMemo(
    () => ['-4', '-3', ...options, '-2', '-1'],
    [options],
  );
  const scrollViewRef = useRef();

  useEffect(() => {
    if (activeIndex !== selectedIndex) {
      setActiveIndex(selectedIndex);
      scrollViewRef.current?.scrollTo({
        x: 0,
        y: selectedIndex * ITEM_HEIGHT,
        animated: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  return (
    <View style={styles.wrapper}>
      <ScrollView
        ref={scrollViewRef}
        style={styles.container}
        showsVerticalScrollIndicator={false}
        pagingEnabled
        overScrollMode="never"
        scrollEventThrottle={0}
        onScroll={event => {
          const index = Math.round(
            event.nativeEvent.contentOffset.y / ITEM_HEIGHT,
          );
          if (selectedIndex !== index) {
            setActiveIndex(index);
            onChange(index);
          }
        }}>
        {extendedOption.map((item, index) => {
          return (
            <View style={styles.item} key={item}>
              <Text
                style={
                  index === selectedIndex + 2
                    ? styles.itemFocus
                    : styles.itemNormal
                }>
                {Number(item) < 0 ? '' : item}
              </Text>
            </View>
          );
        })}
      </ScrollView>
      <View style={styles.borderTop} />
      <View style={styles.borderBottom} />
    </View>
  );
};

export default WheelPicker;
