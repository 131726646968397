import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {opacityColor, textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  flexContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  imageBackground: {
    width: '100%',
    height: '100%',
  },
  buttonWrapperBorder: {
    marginBottom: calculateWidth(48),
    backgroundColor: 'rgba(251, 255, 251, 0.5)',
    alignSelf: 'center',
    width: calculateWidth(311),
    borderRadius: calculateWidth(12),
    padding: calculateWidth(12),
  },
  buttonWrapper: {
    backgroundColor: '#FCFFFC',
    alignSelf: 'center',
    borderRadius: calculateWidth(8),
    alignItems: 'center',
    paddingTop: calculateWidth(24),
  },

  buttonSignIn: {
    width: calculateWidth(239),
    height: calculateWidth(48),
    backgroundColor: '#fff',
    margin: calculateWidth(24),
    marginTop: 0,
    borderRadius: calculateWidth(8),
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#89FE6D',
    shadowOffset: {
      width: 0,
      height: 16,
    },
    shadowOpacity: 0.58,
    shadowRadius: 64,

    elevation: 24,
  },
  buttonSignUp: {
    width: calculateWidth(239),
    height: calculateWidth(48),
    backgroundColor: colors.primary,
    margin: calculateWidth(24),
    marginTop: 0,
    borderRadius: calculateWidth(8),
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#89FE6D',
    shadowOffset: {
      width: 0,
      height: 16,
    },
    shadowOpacity: 0.58,
    shadowRadius: 64,

    elevation: 24,
  },

  textSignIn: {
    ...textFont(16, '700'),
    color: colors.neutral1,
  },

  textSignUp: {
    ...textFont(16, '700'),
    color: '#fff',
  },

  dotActiveStyle: {
    backgroundColor: colors.secondary1,
    width: calculateWidth(24),
    height: calculateWidth(4),
    borderRadius: calculateWidth(4),
    marginHorizontal: calculateWidth(4),
  },

  dotsNormalStyle: {
    backgroundColor: colors.neutral9,
    width: calculateWidth(12),
    height: calculateWidth(4),
    borderRadius: calculateWidth(4),
    marginHorizontal: calculateWidth(4),
  },

  textSlide1Title: {
    ...textFont(48, '600'),
    color: '#fff',
  },
  textSlide1Description: {
    ...textFont(64, '600'),
    color: colors.primary,
  },
  imageSlide1: {
    width: calculateWidth(322.71),
    height: calculateWidth(59),
  },

  slide2Wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageSlide2: {
    width: calculateWidth(104),
    height: calculateWidth(128),
  },
  textSlide2Title: {
    ...textFont(48, '600'),
    color: '#fff',
    marginLeft: calculateWidth(20),
  },
  textSlide2Description: {
    ...textFont(18, '400'),
    color: '#fff',
    marginTop: calculateWidth(18),
  },
  slide1: {
    flex: 1,
    paddingLeft: calculateWidth(32),
    paddingTop: calculateWidth(50),
  },
  slide2: {
    flex: 1,
    paddingLeft: calculateWidth(32),
    paddingTop: calculateWidth(60),
  },
  slide3: {
    flex: 1,
    paddingLeft: calculateWidth(32),
    paddingTop: calculateWidth(60),
  },

  input: {
    width: calculateWidth(239),
    height: calculateWidth(40),
    paddingHorizontal: calculateWidth(10),
    borderWidth: 1,
    borderRadius: calculateWidth(8),
    borderColor: colors.neutral4,
    color: colors.neutral1,
    ...textFont(16, '400'),
  },

  textError: {
    ...textFont(14, '400'),
    color: colors.error,
    paddingTop: calculateWidth(2),
    height: calculateWidth(24),
  },
  confirm: {
    marginBottom: calculateWidth(8),
    marginHorizontal: calculateWidth(40),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabled: {
    backgroundColor: opacityColor(colors.primary, 0.5),
  },
});

export default styles;
