import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(18, 24, 16, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    width: calculateWidth(375),
    alignSelf: 'center',
  },
  popup: {
    width: calculateWidth(335),
    height: calculateWidth(510),
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(24),
    padding: calculateWidth(36),
  },
  center: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  headerAlign: {
    ...textFont(32, '600'),
    color: colors.neutral1,
    textAlign: 'center',
    marginBottom: calculateWidth(12),
  },
  success: {
    width: calculateWidth(247),
    height: calculateWidth(233),
  },

  bottomButton: {
    marginTop: calculateWidth(32),
  },
});

export default styles;
