import {StyleSheet} from 'react-native';

import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%',
  },
  container: {
    height: '100%',
    backgroundColor: colors.neutral9,
    width: '100%',
  },
  back: {
    width: 24,
    height: 24,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: calculateWidth(16),
    paddingVertical: calculateWidth(24),
    borderBottomWidth: 1,
    borderBottomColor: colors.neutral7,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  preview: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: colors.primary,
    borderRadius: 8,
  },
  text: {
    color: colors.neutral9,
    ...textFont(15, '600'),
  },
  create: {
    ...textFont(22, '600'),
    marginLeft: calculateWidth(16),
  },
  imagePicker: {
    width: '100%',
    height: 448,
    backgroundColor: colors.neutral8,
    marginBottom: calculateWidth(32),
  },
  thumbnailStyle: {
    width: null,
    paddingHorizontal: 16,
    marginVertical: 8,
    borderRadius: 48,
  },
  imageThumbnail: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: calculateWidth(20),
  },
  imagePickerIcon: {
    width: calculateWidth(23),
    height: calculateWidth(23),
  },
  textPicker: {
    ...textFont(15, '400'),
    color: colors.neutral4,
    marginTop: calculateWidth(5),
    textAlign: 'center',
  },
  upload: {
    ...textFont(14, '400'),
    color: colors.neutral4,
  },
  thumbIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  thumbGallery: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  wrapper: {
    marginHorizontal: calculateWidth(24),
  },
  name: {
    ...textFont(32, '600'),
  },
  bottom: {
    marginBottom: calculateWidth(14),
  },
  intro: {
    ...textFont(18, '600'),
  },
  add: {
    marginHorizontal: calculateWidth(16),
  },
});
