import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  Platform,
  Linking,
} from 'react-native';
import React from 'react';
import styles from './styles';
import images from 'src/assets/images';
import LinearGradient from 'react-native-linear-gradient';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import dayjs from 'dayjs';
import {useAlert, useTranslations} from 'src/hooks';
import {useDispatch, useSelector} from 'react-redux';
import routeNames from 'src/navigation/routeNames';
import {StackActions, useNavigation, useRoute} from '@react-navigation/native';
import {find, isEmpty} from 'lodash';
import {useState} from 'react';
import {useEffect} from 'react';
import {Button, ImageView, Loading, Checkbox, ImageRatio} from 'src/components';
import {useGetMarketActivity} from 'src/api/activities';
import {addMarketActivity} from 'src/store/slices/user/userSlice';
import {formatPrice} from 'src/utils/common';
import {textFont} from 'src/utils/styles';
import {colors} from 'src/constants';
import {API_URL} from 'src/utils/axiosConfig';
import {ProductionActivityID} from '../ChooseCity/ChooseCity';
import {useTrackEvent} from 'src/api/event';
import ModalDisclaimer from '../deprecated/ActivityDetail/ModalDisclaimer';
import {calculateWidth} from 'src/utils/dimensions';
import {useRef} from 'react';
import {
  storeInfluencerPromotion,
  removeInfluencerPromotion,
} from 'src/store/slices/auth/authSlice';

const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
  const paddingToBottom = calculateWidth(100);
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
};

const OneTimeConfig = [
  {
    city: ProductionActivityID.hangzhou_jiachang,
    onetime: ProductionActivityID.hangzhou_onetime,
    cityPrice: 12800,
    onetimePrice: 5800,
  },
  {
    city: ProductionActivityID.changsha,
    onetime: ProductionActivityID.changsha_onetime,
    cityPrice: 19800,
    onetimePrice: 9800,
  },
  {
    city: ProductionActivityID.xian,
    onetime: ProductionActivityID.xian_onetime,
    cityPrice: 12800,
    onetimePrice: 6800,
  },
];

const MarketActivity = () => {
  const userData = useSelector(state => state.user.data);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const route = useRoute();
  const activityId = route.params?.activityId;
  const isFromAppNavigate = route.params?.isFromAppNavigate;
  const {alert} = useAlert();
  const insets = useSafeAreaInsets();
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const scrollRef = useRef();
  const [viewImage, setViewImage] = useState([]);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [checkedAgreement, setCheckedAgreement] = useState('unchecked');
  const [showGoToBottom, setShowGoToBottom] = useState(true);
  const dispatch = useDispatch();
  const handleGoBack = () => {
    dispatch(removeInfluencerPromotion());
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.dispatch(
        StackActions.replace(
          isLoggedIn ? routeNames.BottomTab : routeNames.AuthFlow.WelcomeScreen,
        ),
      );
    }
  };
  const {
    runRequest: getMarketActivity,
    isLoading: isLoadingGetTrip,
    responseData,
  } = useGetMarketActivity({
    failureCallback: () => {
      alert(i18n.t('something_went_wrong'), handleGoBack);
    },
  });

  const {runRequest: trackEvent} = useTrackEvent({});

  const cityHasOnetimePayment = OneTimeConfig.find(
    i => i.city === activityId || i.onetime === activityId,
  );

  useEffect(() => {
    getMarketActivity(activityId);
    if (Platform.OS === 'web') {
      const urlParams = new URLSearchParams(window.location.search);
      const influencer = urlParams.get('influencer');
      if (influencer) {
        dispatch(storeInfluencerPromotion({influencer}));
        trackEvent({
          event_type: 'page_view',
          event_name: 'bazaar-trip',
          influencer,
          shared_object_id: activityId,
          ...(userData.id && {viewer: userData.id}),
        });
      } else {
        if (isFromAppNavigate) {
          dispatch(removeInfluencerPromotion());
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);

  const marketActivityDetail = responseData?.data;

  const isJoined = find(
    marketActivityDetail?.members,
    member => member.id === userData.id,
  );

  return (
    <>
      <View style={styles.modalContainer}>
        <ScrollView
          ref={scrollRef}
          showsVerticalScrollIndicator={false}
          onScroll={({nativeEvent}) => {
            if (isCloseToBottom(nativeEvent)) {
              showGoToBottom && setShowGoToBottom(false);
            } else {
              !showGoToBottom && setShowGoToBottom(true);
            }
          }}
          scrollEventThrottle={400}>
          <Image
            source={{uri: marketActivityDetail?.background}}
            resizeMode="cover"
            style={styles.imageBackground}
          />
          <LinearGradient
            colors={['rgba(18, 24, 16, 0)', 'rgba(18, 24, 16, 0.5)', '#000']}
            locations={[0, 0.1, 0.5]}
            style={styles.linearGradientMore}
          />
          <View style={[styles.content, {paddingTop: insets.top}]}>
            <View style={styles.dateContent}>
              <View style={styles.dateWrapperMore}>
                <View style={styles.flexRow}>
                  <Image
                    source={images.CalendarWhite}
                    style={styles.calendar}
                  />
                  <Text style={styles.textDate}>
                    {dayjs(marketActivityDetail?.from_date).format(
                      'YYYY-MM-DD',
                    )}
                  </Text>
                  <Text style={styles.textDate}> ~ </Text>
                  <Text style={styles.textDate}>
                    {dayjs(marketActivityDetail?.to_date).format('YYYY-MM-DD')}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.titleWrapperMore}>
              <Text style={styles.textTitle} numberOfLines={2}>
                {marketActivityDetail?.title}
              </Text>
              <View style={styles.location}>
                <Image source={images.Location} style={styles.iconLocation} />
                <Text style={styles.textVillageMore}>
                  {marketActivityDetail?.location}
                </Text>
              </View>
            </View>
            <View style={styles.border} />
            <Text style={styles.description}>
              {marketActivityDetail?.introduction}
            </Text>
            <View style={styles.contentImage}>
              {(marketActivityDetail?.content_images || []).map((i, index) => {
                return (
                  <ImageRatio source={{uri: i}} key={index} imageWidth={375} />
                );
              })}
            </View>
            {/* <View style={styles.padding32}>
              <Text style={styles.header}>{i18n.t('trip_detail.hosts')}</Text>
              <View style={styles.hostList}>
                {(marketActivityDetail?.hosts || []).map((host, index) => {
                  return (
                    <View style={styles.hostItem} key={index}>
                      <View style={styles.flexRow}>
                        <Image
                          source={{uri: host?.avatar ? host.avatar : ''}}
                          style={styles.avatar}
                        />
                        <View style={styles.userWrapper}>
                          <Text style={styles.textUserActivity}>
                            {host.name}
                          </Text>
                          <Text style={styles.textUserJob}>
                            {host.job_title}
                          </Text>
                        </View>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View> */}
            <View style={styles.cost}>
              <Text style={styles.headerAlign}>
                {i18n.t('trip_detail.trip_cost')}
              </Text>
              {cityHasOnetimePayment ? (
                <>
                  <TouchableOpacity
                    style={[
                      styles.costItem,
                      activityId === cityHasOnetimePayment.city &&
                        styles.costSelected,
                    ]}
                    onPress={() => {
                      navigation.navigate(
                        routeNames.NonAuthFlow.MarketActivity,
                        {
                          activityId: cityHasOnetimePayment.city,
                          isFromAppNavigate,
                        },
                      );
                    }}>
                    <Text style={styles.costTitle}>
                      {i18n.t('standard_version')}
                    </Text>
                    <Text style={styles.hostTitle}>{`¥${formatPrice(
                      cityHasOnetimePayment.cityPrice,
                    )}/${i18n.t('person')}`}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[
                      styles.costItem,
                      activityId === cityHasOnetimePayment.onetime &&
                        styles.costSelected,
                    ]}
                    onPress={() => {
                      navigation.navigate(
                        routeNames.NonAuthFlow.MarketActivity,
                        {
                          activityId: cityHasOnetimePayment.onetime,
                          isFromAppNavigate,
                        },
                      );
                    }}>
                    <Text style={styles.costTitle}>
                      {i18n.t('onetime_ticket')}
                    </Text>
                    <Text style={styles.hostTitle}>{`¥${formatPrice(
                      cityHasOnetimePayment.onetimePrice,
                    )}/${i18n.t('person')}`}</Text>
                  </TouchableOpacity>
                </>
              ) : (
                <View style={styles.costItem}>
                  <Text style={styles.costTitle}>{`1 ${i18n.t(
                    'person',
                  )}`}</Text>
                  <Text style={styles.hostTitle}>{`¥${formatPrice(
                    marketActivityDetail?.prices?.price,
                  )}/${i18n.t('person')}`}</Text>
                </View>
              )}
              <View style={styles.split} />
              <Text style={styles.headerAlign}>
                {i18n.t('payment_agreement')}
              </Text>
              <View>
                {(marketActivityDetail?.disclaimers || []).map(
                  (disclaimer, index) => {
                    return (
                      <View key={index} style={styles.disclaimerItem}>
                        <View>
                          <Text style={styles.disclaimerTitle}>
                            {('00' + (index + 1)).slice(-2)}
                          </Text>
                        </View>
                        <Text style={styles.disclaimerDetail}>
                          {disclaimer}
                        </Text>
                      </View>
                    );
                  },
                )}
              </View>
            </View>
            <View
              style={[styles.bottomButtonMore, {paddingBottom: insets.bottom}]}>
              {!isJoined && (
                <View style={styles.confirm}>
                  <Checkbox
                    status={checkedAgreement}
                    onPress={() => {
                      setCheckedAgreement(
                        checkedAgreement === 'checked'
                          ? 'unchecked'
                          : 'checked',
                      );
                    }}
                    color={colors.primary}
                    uncheckedColor={colors.neutral7}
                  />
                  <Text style={styles.agreement}>
                    {`${i18n.t('agree_with')} `}
                    <Text
                      onPress={() => {
                        setShowDisclaimer(true);
                      }}
                      style={[textFont(14, '900'), {color: colors.neutral9}]}>
                      {`《${i18n.t('payment_agreement')}》`}
                    </Text>
                  </Text>
                </View>
              )}
              <Button
                disabled={!isJoined && checkedAgreement === 'unchecked'}
                title={i18n.t(
                  isLoggedIn
                    ? isJoined
                      ? 'view_order'
                      : 'buy_now'
                    : 'trip_detail.sign_up',
                )}
                onClick={() => {
                  if (isLoggedIn) {
                    if (isJoined) {
                      navigation.navigate(routeNames.MainFlow.OrderDetail, {
                        orderId: marketActivityDetail?.order?.id,
                        isFromAppNavigate: true,
                      });
                    } else {
                      navigation.navigate(routeNames.MainFlow.OrderConfirm, {
                        activityId,
                      });
                    }
                  } else {
                    dispatch(addMarketActivity(activityId));
                    navigation.dispatch(
                      StackActions.replace(routeNames.AuthFlow.WelcomeScreen),
                    );
                  }
                }}
              />
              {isJoined && (
                <Button
                  title={i18n.t('share_friend')}
                  buttonStyle={styles.shareButton}
                  disabled={!marketActivityDetail?.cuid}
                  onClick={() => {
                    navigation.navigate(routeNames.MainFlow.ShareActivity, {
                      data: JSON.stringify({
                        title: marketActivityDetail?.title,
                        dateFromTo: `${dayjs(
                          marketActivityDetail?.from_date,
                        ).format('DD/MM')} - ${dayjs(
                          marketActivityDetail?.to_date,
                        ).format('DD/MM')}`,
                        year: dayjs(marketActivityDetail?.from_date).format(
                          'YYYY',
                        ),
                        url: `${API_URL}/marketActivity/${
                          marketActivityDetail.cuid
                        }${
                          userData?.referral_code
                            ? `?influencer=${userData.referral_code}`
                            : ''
                        }`,
                      }),
                    });
                  }}
                />
              )}
              <Text
                onPress={() => {
                  Linking.openURL('/support.html');
                }}
                style={[
                  textFont(16, '700'),
                  {color: colors.primary},
                  styles.supportLink,
                ]}>
                {i18n.t('got_questions')}
              </Text>
            </View>
          </View>
          <TouchableOpacity
            onPress={handleGoBack}
            style={[styles.backButton, {top: insets.top + 30}]}>
            <Image source={images.ArrowLeftWhite} style={styles.backIcon} />
          </TouchableOpacity>
        </ScrollView>
        {showGoToBottom && (
          <Button
            title={i18n.t('go_to_bottom')}
            onClick={() => {
              setShowGoToBottom(false);
              scrollRef.current?.scrollToEnd({animated: true});
            }}
            buttonStyle={styles.scrollToEnd}
          />
        )}
      </View>
      {isLoadingGetTrip && <Loading />}
      <ImageView
        visible={!isEmpty(viewImage)}
        setVisible={() => {
          setViewImage([]);
        }}
        imageList={viewImage}
      />
      <ModalDisclaimer
        title={i18n.t('payment_agreement')}
        activityData={{
          disclaimers: marketActivityDetail?.payment_agreement || [],
        }}
        show={showDisclaimer}
        onClose={() => setShowDisclaimer(false)}
        onNext={() => setShowDisclaimer(false)}
        isHideButton
      />
    </>
  );
};

export default MarketActivity;
