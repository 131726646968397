import {Text, TouchableOpacity, FlatList, Image, View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import React, {useEffect} from 'react';
import {Header} from 'src/components';
import {useTranslations} from 'src/hooks';

import styles from './styles';
import dayjs from 'dayjs';
import {useGetNotification, useReadNotification} from 'src/api/notification';
import {useSelector} from 'react-redux';
import routeNames from 'src/navigation/routeNames';
import images from 'src/assets/images';

const Notification = () => {
  const {i18n} = useTranslations();
  const navigation = useNavigation();
  const {runRequest: getNotification} = useGetNotification({});
  const {runRequest: readNotification} = useReadNotification({
    successCallback: () => {
      getNotification();
    },
  });
  const notificationList = useSelector(
    state => state.notification.notificationList,
  );

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItem = ({item}) => {
    const handlePress = () => {
      if (!item.mark_as_read) {
        readNotification({id: item.id});
      }
      switch (item.noti_type) {
        case 'like':
        case 'comment':
        case 'reply':
          navigation.navigate(routeNames.NonAuthFlow.StoryDetail, {
            storyId: item.data.story_cuid,
            commentId:
              item.noti_type === 'like'
                ? undefined
                : item.data.comment_id_reply_to || item.data.comment_id,
          });
          break;
        case 'accept_invite':
          navigation.navigate(routeNames.NonAuthFlow.TripDetail, {
            tripId: item.data.trip_id,
          });
          break;
        case 'invite':
          navigation.navigate(routeNames.NonAuthFlow.InvitationDetail, {
            invitationId: item.data.invitation_id,
          });
          break;
        case 'follow':
          navigation.navigate(routeNames.NonAuthFlow.UserDetail, {
            userId: item.data.follower_id,
          });
          break;

        case 'chat':
          navigation.navigate(routeNames.MainFlow.ChatScreen, {
            conversationId: item.data.conversation_id,
          });
          break;
        case 'trip_coming':
          navigation.navigate(routeNames.NonAuthFlow.TripDetail, {
            tripId: item.data.trip_id,
          });
          break;
        default:
          break;
      }
    };
    const getActionText = notification => {
      switch (notification.noti_type) {
        case 'like':
        case 'comment':
        case 'reply':
          return (
            <Text style={styles.textTitleObject}>
              {`${notification.data.username} `}
              <Text style={styles.textTitle}>
                {i18n.t(
                  notification.noti_type === 'like'
                    ? 'like_story'
                    : notification.noti_type === 'reply'
                    ? 'reply_comment'
                    : 'comment_story',
                )}
              </Text>
              <Text>{` ${notification.data.story_title}`}</Text>
            </Text>
          );
        case 'follow':
          return (
            <Text style={styles.textTitleObject}>
              {`${notification.data.username} `}
              <Text style={styles.textTitle}>{i18n.t('follow_you')}</Text>
            </Text>
          );
        case 'invite':
          return (
            <Text style={styles.textTitleObject}>
              {`${notification.data.username} `}
              <Text style={styles.textTitle}>{i18n.t('invite_trip')}</Text>
              <Text>{` ${notification.data.trip_name}`}</Text>
            </Text>
          );

        case 'accept_invite':
          return (
            <Text style={styles.textTitleObject}>
              {`${notification.data.username} `}
              <Text style={styles.textTitle}>{i18n.t('accept_invite')}</Text>
              <Text>{` ${notification.data.trip_name}`}</Text>
            </Text>
          );
        case 'chat':
          return (
            <Text style={styles.textTitleObject}>
              {`${notification.data.username} `}
              <Text style={styles.textTitle}>{i18n.t('send_message')}</Text>
            </Text>
          );
        case 'trip_coming':
          return (
            <Text style={styles.textTitleObject}>
              {`${notification.data.trip_name} `}
              <Text style={styles.textTitle}>{i18n.t('trip_upcoming')}</Text>
            </Text>
          );
        default:
          break;
      }
    };
    const avatar =
      item.noti_type === 'trip_coming' ? images.Trip : {uri: item.data.avatar};
    return (
      <TouchableOpacity
        style={[styles.notification, !item.mark_as_read && styles.unread]}
        onPress={handlePress}>
        <Image source={avatar} style={styles.avatar} />
        <View style={styles.message}>
          {getActionText(item)}
          <Text style={styles.textTime}>
            {dayjs(item.created_at).toNow(true)}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <>
      <Header
        withBackButton
        onPressBackButton={navigation.goBack}
        title={i18n.t('notification')}
        rightContent={() => (
          <TouchableOpacity
            style={styles.leftIconWrapper}
            onPress={() => {
              readNotification({id: 'all'});
            }}>
            <Text style={styles.skip}>{i18n.t('empty')}</Text>
          </TouchableOpacity>
        )}
      />
      <FlatList
        data={notificationList}
        renderItem={renderItem}
        keyExtractor={item => item.id}
      />
    </>
  );
};

export default Notification;
