import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(18, 24, 16, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    width: calculateWidth(375),
    alignSelf: 'center',
  },
  popup: {
    width: calculateWidth(335),
    backgroundColor: colors.neutral9,
    borderRadius: calculateWidth(24),
    padding: calculateWidth(36),
  },
  buttonClose: {
    width: calculateWidth(36),
    height: calculateWidth(36),
    backgroundColor: colors.neutral8,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 12,
    top: calculateWidth(-18),
  },
  textClose: {
    fontSize: calculateWidth(25),
    marginBottom: calculateWidth(2),
    marginLeft: calculateWidth(3),
    color: colors.neutral1,
    transform: [{rotate: '45deg'}],
  },

  headerAlign: {
    ...textFont(32, '600'),
    color: colors.neutral1,
    textAlign: 'center',
    marginBottom: calculateWidth(12),
  },
  textDetail: {
    ...textFont(16, '400'),
    color: colors.neutral1,
    textAlign: 'center',
  },
  success: {
    width: calculateWidth(247),
    height: calculateWidth(217),
  },
  adWapper: {
    borderStyle: 'dashed',
    borderColor: colors.orange,
    borderWidth: 1,
    borderRadius: 80,
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    marginTop: calculateWidth(-30),
    backgroundColor: colors.neutral9,
  },
  award: {
    width: calculateWidth(20),
    height: calculateWidth(20),
    marginBottom: 6,
  },
  ad: {
    ...textFont(14, '400'),
    color: colors.neutral4,
  },

  bottomButton: {
    marginTop: calculateWidth(32),
  },
  primaryButton: {
    height: calculateWidth(50),
    borderWidth: calculateWidth(2),
    borderColor: colors.primary,
    borderRadius: calculateWidth(8),
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: calculateWidth(16),
  },
  code: {
    margin: calculateWidth(-36),
    borderRadius: calculateWidth(24),
    width: calculateWidth(335),
    height: calculateWidth(335),
  },
});

export default styles;
