import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {readNotificationRequest} from '../requests';

export const useReadNotification = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(
    state => state.invitation.isLoadingReadNotification,
  );
  const [responseData, setResponseData] = useState(null);
  const dispatch = useDispatch();

  const runRequest = useCallback(
    data => {
      dispatch(
        readNotificationRequest({
          data,
          failureCallback: err => {
            failureCallback?.(err);
          },
          successCallback: response => {
            setResponseData(response);
            successCallback?.(response);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  return {
    isLoading,
    runRequest,
    responseData,
  };
};
