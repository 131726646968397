const colors = {
  primary: '#3CB220',
  neutral0: '#121810',
  neutral1: '#282E27',
  neutral2: '#5d5c5a',
  neutral4: '#667063',
  neutral5: '#93A18F',
  neutral6: '#DEE3DC',
  neutral7: '#E7EDE6',
  neutral8: '#F0F2F0',
  neutral9: '#FCFFFC',
  neutral10: '#D6F0D0',
  neutral11: '#F5F7F5',
  secondary1: '#FFA62E',
  gray: '#dae3de',
  black: '#000000',
  error: '#FE5459',
  orange: '#FED395',
};

export default colors;
