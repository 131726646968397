import {
  View,
  Text,
  SafeAreaView,
  TextInput,
  Image,
  TouchableOpacity,
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform,
  FlatList,
} from 'react-native';
import React from 'react';
import {HomeHeader} from 'src/components';
import reverse from 'lodash/reverse';
import styles from './styles';
import images from 'src/assets/images';
import ChatItem from './components/ChatItem';
import {io} from 'socket.io-client';
import {useSelector} from 'react-redux';
import {useNavigation, useRoute} from '@react-navigation/native';
import {useGetConversationDetail} from 'src/api/conversation';
import {useGetConversationMessages} from 'src/api/conversation/hooks/useGetMessages';
import {useAlert, useTranslations} from 'src/hooks';
import {API_URL} from 'src/utils/axiosConfig';

const ChatScreen = () => {
  const [message, setMessage] = React.useState('');
  const [chatMessages, setChatMessages] = React.useState([]);
  const [loadingConnectSocket, setLoadingConnectSocket] = React.useState(true);
  const route = useRoute();
  const userData = useSelector(state => state.user.data);
  const conversationId = route.params?.conversationId;
  const {i18n} = useTranslations();
  const {alert} = useAlert();
  const navigation = useNavigation();

  const chatDetail = useSelector(state => state.conversation.data);

  const {runRequest: getConversationDetail, isLoading: loadingGetDetail} =
    useGetConversationDetail({
      failureCallback: () => {
        alert(i18n.t('something_went_wrong'), () => {
          navigation.goBack();
        });
      },
    });
  const {runRequest: getConversationMessages, isLoading: loadingGetMessages} =
    useGetConversationMessages({
      successCallback: response => {
        setChatMessages(reverse(response.data));
      },
    });

  React.useEffect(() => {
    if (conversationId) {
      getConversationDetail(conversationId);
      getConversationMessages(conversationId);
      setChatMessages([]);
    } else {
      alert(i18n.t('something_went_wrong'), () => {
        navigation.goBack();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  const socket = React.useMemo(
    () =>
      io(API_URL, {
        transports: ['websocket'],
      }),
    [],
  );

  React.useEffect(() => {
    socket.on('connect', () => {
      setLoadingConnectSocket(false);
      console.log('connect', Platform.OS, socket.id);
      socket.emit(
        'join-conversation',
        JSON.stringify({
          conversationId,
          username: userData.username,
          userId: userData.id,
        }),
      );
    });

    socket.on('message', data => {
      console.log('MESSAGE', Platform.OS, data);
      setChatMessages(chatData => {
        return [data, ...chatData];
      });
    });

    socket.on('user-joined', data => {
      console.log('USER JOINED', Platform.OS, data);
      getConversationDetail(conversationId);
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, userData, conversationId]);

  const renderItem = ({item}) => <ChatItem item={item} />;

  const handlePressSend = () => {
    if (message) {
      console.log('setnd');
      const messageObject = {
        conversationId,
        content: message,
        sendingSocketId: socket.id,
        userId: userData.id,
      };
      socket.emit('message', JSON.stringify(messageObject));
      const newMessage = {
        content: message,
        conversation_id: 1,
        sender: userData.id,
      };
      setChatMessages(chatData => {
        return [newMessage, ...chatData];
      });
      setMessage('');
    }
  };
  const isLoading =
    loadingGetMessages || loadingConnectSocket || loadingGetDetail;
  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView
        style={styles.container}
        behavior={'padding'}
        enabled={Platform.OS === 'ios'}>
        <View style={styles.container}>
          <HomeHeader withBackButton={true} />
          {isLoading ? (
            <ActivityIndicator />
          ) : (
            <Text style={styles.title}>
              {`${i18n.t('trip_for')} ${chatDetail?.title}`}
              {/* <Text style={styles.textDate}>2022/02/20</Text> */}
            </Text>
          )}
          <View style={styles.chatContent}>
            <FlatList
              data={chatMessages}
              renderItem={renderItem}
              inverted={true}
              keyExtractor={(item, index) => index}
            />
          </View>
          <View style={styles.inputWrapper}>
            <TextInput
              style={styles.input}
              value={message}
              onChangeText={setMessage}
              onSubmitEditing={handlePressSend}
            />
            <TouchableOpacity
              disabled={loadingConnectSocket}
              style={styles.buttonSend}
              onPress={handlePressSend}>
              <Image source={images.Send} style={styles.send} />
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default ChatScreen;
