import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  container: {
    width: calculateWidth(148),
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.neutral6,
    marginBottom: calculateWidth(16),
    overflow: 'hidden',
    backgroundColor: colors.neutral9,
  },
  image: {
    width: calculateWidth(148),
    height: calculateWidth(135),
    marginBottom: calculateWidth(16),
  },
  des: {
    marginHorizontal: calculateWidth(16),
    ...textFont(16, '400'),
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    margin: calculateWidth(16),
    marginTop: calculateWidth(12),
  },
  name: {
    marginLeft: 8,
    ...textFont(14, '600'),
    color: colors.neutral4,
  },
  avatar: {
    width: calculateWidth(24),
    height: calculateWidth(24),
    borderRadius: 100,
    backgroundColor: colors.neutral6,
  },

  homeWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: calculateWidth(32),
    marginBottom: calculateWidth(32),
  },
});
