import React from 'react';
import {TouchableOpacity, Text, Platform, ScrollView} from 'react-native';
import {map, clone} from 'lodash';

const RNKeyboardScrollView = Platform.select({
  native: () => require('react-native-keyboard-aware-scroll-view'),
  default: () => ({KeyboardAwareScrollView: ScrollView}),
})();

import {AdminTextInput} from 'src/components';

import styles from './styles';

const EditPreparing = ({preparing, setPreparing}) => {
  const onChangePreapring = (index, t) => {
    const newArr = clone(preparing);
    newArr[index] = t;
    setPreparing(newArr);
  };
  return (
    <RNKeyboardScrollView.KeyboardAwareScrollView style={styles.editContainer}>
      {map(preparing, (p, index) => (
        <AdminTextInput
          viewStyle={styles.prepareViewStyle}
          textStyle={styles.prepareTexstyle}
          value={p}
          onChangeText={t => onChangePreapring(index, t)}
          key={index}
        />
      ))}
      <TouchableOpacity
        style={styles.addMoreWrapper}
        onPress={() => {
          const newArr = clone(preparing);
          newArr.push('');
          setPreparing(newArr);
        }}>
        <Text style={styles.addMore}>+ Add more</Text>
      </TouchableOpacity>
    </RNKeyboardScrollView.KeyboardAwareScrollView>
  );
};

export default EditPreparing;
