import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  inputSelect: {
    borderWidth: 1,
    flexDirection: 'row',
    height: calculateWidth(48),
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: calculateWidth(20),
    borderRadius: calculateWidth(8),
    borderColor: colors.neutral6,
  },
  inputIcon: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
  inputText: {
    ...textFont(16, '400'),
    color: colors.neutral1,
  },

  recommendWrapper: {
    zIndex: 2,

    height: calculateWidth(200),
    width: '100%',

    paddingHorizontal: calculateWidth(20),
    paddingVertical: calculateWidth(10),

    borderRadius: calculateWidth(8),
    backgroundColor: '#fff',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  searchWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 3,
  },
  inputSearch: {
    flex: 1,
    paddingVertical: calculateWidth(5),
    marginLeft: calculateWidth(7),
    ...textFont(16, '400'),
    color: colors.neutral1,
  },

  textSearchItem: {
    ...textFont(16, '400'),
    color: colors.neutral1,
    paddingVertical: calculateWidth(10),
  },
});

export default styles;
