import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {getSuggestUserTripUrl} from 'src/api/endpoints';

export const getSuggestUserTripRequest = createAsyncThunk(
  'metadata/getSuggestUserTrip',
  async ({failureCallback, successCallback, tripId}, {rejectWithValue}) => {
    try {
      const response = await axios.request({
        method: 'GET',
        url: getSuggestUserTripUrl(tripId),
      });
      successCallback && successCallback(response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      } else {
        failureCallback && failureCallback(err);
        return rejectWithValue(err.response.data);
      }
    }
  },
);
