import {View, Text, Modal, TouchableOpacity} from 'react-native';
import React, {useEffect, useMemo, useState} from 'react';
import {max, min} from 'lodash';
import dayjs from 'dayjs';

import WheelPicker from '../WheelPicker';
import Button from '../Button';

import styles from './styles';

const DatePickerModal = ({
  visible,
  onDismiss,
  date,
  onConfirm,
  validRange,
  saveLabel,
  label,
}) => {
  const [selectedYearIndex, setSelectedYearIndex] = useState(0);
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);

  const yearOptions = useMemo(() => {
    const startYear = validRange.startDate?.getFullYear() || 1800;
    const endYear =
      validRange.endDate?.getFullYear() || new Date().getFullYear() + 10;
    return [
      ...Array.from({length: endYear - startYear}, (_, i) => i + startYear),
      endYear,
    ];
  }, [validRange]);

  const monthOptions = useMemo(() => {
    const currentDate = new Date();
    const startDate = validRange.startDate || new Date(1800, 0);
    const endDate =
      validRange.endDate || new Date(currentDate.getFullYear() + 10, 11);
    const startYearSelected = new Date(yearOptions[selectedYearIndex], 0);

    const minMonth = max([startDate, startYearSelected]).getMonth();
    const maxMonth = min([
      endDate,
      dayjs(startYearSelected).endOf('year').toDate(),
    ]).getMonth();
    const result = [
      ...Array.from({length: maxMonth - minMonth}, (_, i) => i + 1 + minMonth),
      maxMonth + 1,
    ];
    return result;
  }, [validRange, selectedYearIndex, yearOptions]);

  const dateOptions = useMemo(() => {
    const currentDate = new Date();
    const startDate = validRange.startDate || new Date(1800, 0, 1);
    const endDate =
      validRange.endDate ||
      new Date(
        currentDate.getFullYear() + 10,
        monthOptions[selectedMonthIndex] - 1,
        1,
      );

    const startMonthSelected = new Date(
      yearOptions[selectedYearIndex],
      monthOptions[selectedMonthIndex] - 1,
      1,
    );

    const minDate = max([startDate, startMonthSelected]).getDate();
    const maxDate = min([
      endDate,
      dayjs(startMonthSelected).endOf('month').toDate(),
    ]).getDate();
    return [
      ...Array.from({length: maxDate - minDate}, (_, i) => i + minDate),
      maxDate,
    ];
  }, [
    validRange,
    selectedMonthIndex,
    monthOptions,
    selectedYearIndex,
    yearOptions,
  ]);

  const dateString = date && dayjs(date).isValid() ? date.toISOString() : '';
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const formatDate = new Date(dateString);
      const currentYearIndex =
        yearOptions.findIndex(i => i === formatDate.getFullYear()) > -1
          ? yearOptions.findIndex(i => i === formatDate.getFullYear())
          : yearOptions.findIndex(i => i === new Date().getFullYear()) > -1
          ? yearOptions.findIndex(i => i === new Date().getFullYear())
          : 0;

      if (currentYearIndex !== selectedYearIndex) {
        setSelectedYearIndex(currentYearIndex);
      }
      const currentMonthIndex = max([
        monthOptions.findIndex(i => i === formatDate.getMonth() + 1),
        0,
      ]);
      if (currentMonthIndex !== selectedMonthIndex) {
        setSelectedMonthIndex(currentMonthIndex);
      }
      const currentDateIndex = max([
        dateOptions.findIndex(i => i === formatDate.getDate()),
        0,
      ]);
      if (currentDateIndex !== selectedDateIndex) {
        setSelectedDateIndex(currentDateIndex);
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateString, visible]);

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onDismiss}>
      <View style={styles.container}>
        <TouchableOpacity onPress={onDismiss} style={styles.flex1} />
        <View style={styles.content}>
          <Text style={styles.label}>{label}</Text>
          <View style={styles.row}>
            <WheelPicker
              selectedIndex={selectedYearIndex}
              options={yearOptions}
              onChange={index => setSelectedYearIndex(index)}
            />
            <WheelPicker
              selectedIndex={selectedMonthIndex}
              options={monthOptions}
              onChange={index => setSelectedMonthIndex(index)}
            />
            <WheelPicker
              selectedIndex={selectedDateIndex}
              options={dateOptions}
              onChange={index => setSelectedDateIndex(index)}
            />
          </View>
          <Button
            title={saveLabel}
            onClick={() => {
              onConfirm({
                date: new Date(
                  yearOptions[selectedYearIndex],
                  monthOptions[selectedMonthIndex] - 1,
                  dateOptions[selectedDateIndex],
                ),
              });
            }}
            disabled={
              !dayjs(
                `${yearOptions[selectedYearIndex]}/${monthOptions[selectedMonthIndex]}/${dateOptions[selectedDateIndex]}`,
                'YYYY/M/D',
              ).isValid()
            }
            buttonStyle={styles.button}
          />
        </View>
      </View>
    </Modal>
  );
};

export default DatePickerModal;
