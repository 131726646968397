import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {initTripFromActivityRequest} from '../requests';

export const useInitTripFromActivity = ({failureCallback, successCallback}) => {
  const isLoading = useSelector(state => state.activities.isLoadingInitTrip);
  const [responseData, setResponseData] = useState(null);
  const dispatch = useDispatch();
  const runRequest = useCallback(
    (activityId, data) => {
      dispatch(
        initTripFromActivityRequest({
          activityId,
          data,
          failureCallback: err => {
            failureCallback?.(err);
          },
          successCallback: response => {
            setResponseData(response);
            successCallback?.(response);
          },
        }),
      );
    },
    [failureCallback, successCallback, dispatch],
  );

  return {
    isLoading,
    runRequest,
    responseData,
  };
};
