import dayjs from 'dayjs';
import React from 'react';
import {View, Text, Image} from 'react-native';

import styles from './styles';

const Achievement = ({achievement}) => {
  return (
    <View style={styles.container}>
      <Image source={{uri: achievement.iconUrl}} style={styles.image} />
      <Text style={styles.date}>
        {dayjs(achievement.collectedAt).format('MMM DD, YYYY')}
      </Text>
      <Text style={styles.name}>{achievement.title}</Text>
      <Text style={styles.des}>{achievement.content}</Text>
    </View>
  );
};

export default Achievement;
