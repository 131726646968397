export const userMetaDataUrl = 'metadata';
export const updateUserProfileUrl = 'users';
export const userListUrl = 'users';
export const getUserDetailUrl = userId => `users/${userId}`;
export const getUserStoryUrl = userId => `users/${userId}/stories`;
export const getUserTripUrl = userId => `users/${userId}/trips`;
export const followUserUrl = userId => `users/follow/${userId}`;
export const unFollowUserUrl = userId => `users/unfollow/${userId}`;
export const getUserFollowerUrl = userId => `users/${userId}/follower`;
export const getUserFollowingUrl = userId => `users/${userId}/following`;
export const userDislikeUrl = userId => `users/not-interested/${userId}`;
export const getSuggestUserUrl = 'users/user-suggestions';
export const checkUserPhoneUrl = 'users/check-phones';
