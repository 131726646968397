import {TouchableOpacity, Text} from 'react-native';
import React from 'react';
import styles from './styles';
import {colors} from 'src/constants';
import {textFont} from 'src/utils/styles';

const Checkbox = ({status, onPress, color, uncheckedColor}) => {
  const isSelected = status === 'checked';
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.container,
        {
          borderColor: isSelected ? color : uncheckedColor,
          backgroundColor: isSelected ? color : colors.neutral9,
        },
      ]}>
      {isSelected && (
        <Text style={[textFont(12, '400'), {color: colors.neutral10}]}>✓</Text>
      )}
    </TouchableOpacity>
  );
};

export default Checkbox;
