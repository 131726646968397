import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';

const styles = StyleSheet.create({
  container: {
    width: calculateWidth(375),
    alignSelf: 'center',
    flex: 1,
    backgroundColor: colors.neutral0,
  },
  imageWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
});

export default styles;
