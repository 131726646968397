export const USER_MODE = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export const INVITATION_STATUS = {
  OPEN: 'open',
  EXPIRED: 'expired',
  ACCEPTED: 'accepted',
};

export const HOME_ITEM_TYPE = {
  INVITATION: 'INVITATION',
  STORY: 'STORY',
  TRIP: 'TRIP',
  PLAN: 'PLAN',
  BAZAAR_TRIP: 'BAZAAR_TRIP',
};

export const HEADER_HEIGHT = 52;

export const MAX_IMAGE_WIDTH = 1024;
export const MAX_IMAGE_HEIGHT = 1024;
export const IMAGE_COMPRESS_QUALITY = 0.75;

export const USER_PHOTO_KEY = {
  PORTRAIT: 'portrait',
  TRAVEL: 'travel',
  HOBBY: 'hobby',
  MORE1: 'more1',
  MORE2: 'more2',
  MORE3: 'more3',
};

export const USER_DATING = ['wishDate', 'wishFriend', 'wishNetwork'];

export const USER_ROLE = {
  ORGANIZER: 'organizer',
  EDITOR: 'editor',
  ADMIN: 'admin',
};

export const PAYMENT_AGREEMENT = 'test payment';

export const TERM_OF_SERVICE = 'test TOS';

export const PRIVACY = 'test privacy';
