import {
  Text,
  View,
  TouchableOpacity,
  Image,
  TextInput,
  FlatList,
} from 'react-native';
import React, {useState, useMemo, useEffect} from 'react';
import styles from './styles';
import images from 'src/assets/images';
import {filter} from 'lodash';
import {useGetVillageList} from 'src/api/village';
import {useSelector} from 'react-redux';

const SearchVillage = ({value, onChangeValue, placeholder, containerStyle}) => {
  const [openInput, setOpenInput] = useState(false);
  const [queryString, setQueryString] = useState('');

  const {runRequest: getVillageList} = useGetVillageList({});

  const villageList = useSelector(state => state.village.listVillage);

  useEffect(() => {
    getVillageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenInput = () => {
    setOpenInput(open => !open);
  };

  const onChangeSearch = searchValue => {
    setQueryString(searchValue);
  };

  const handleChooseItem = item => {
    setOpenInput(false);
    onChangeValue(item);
  };

  const renderItem = ({item}) => (
    <TouchableOpacity
      onPress={() => {
        handleChooseItem(item);
      }}>
      <Text style={styles.textSearchItem}>{item.name}</Text>
    </TouchableOpacity>
  );

  const searchData = useMemo(() => {
    return filter(villageList, t => t.name.search(queryString) !== -1);
  }, [queryString, villageList]);

  return (
    <View style={containerStyle}>
      <TouchableOpacity style={styles.inputSelect} onPress={handleOpenInput}>
        <Text style={styles.inputText}>{value.name}</Text>
        <Image
          source={openInput ? images.ArrowUp : images.ArrowDown}
          style={styles.inputIcon}
        />
      </TouchableOpacity>
      {openInput && (
        <View style={styles.recommendWrapper}>
          <View style={styles.searchWrapper}>
            <Image source={images.Search} style={styles.inputIcon} />
            <TextInput
              style={styles.inputSearch}
              placeholder={placeholder}
              value={queryString}
              onChangeText={onChangeSearch}
            />
          </View>
          <FlatList
            data={searchData}
            renderItem={renderItem}
            keyExtractor={item => item.id}
            showsVerticalScrollIndicator={false}
          />
        </View>
      )}
    </View>
  );
};

export default SearchVillage;
