import {first, split} from 'lodash';
import {calculateWidth} from './dimensions';

export const opacityColor = (color, opacity) => {
  const postFix = first(split((opacity * 255).toString(16), '.'));
  return `${color}${postFix}`;
};

export const textFont = (fontSize, fontWeight) => {
  return {
    fontWeight: fontWeight,
    fontSize: calculateWidth(fontSize),
  };
};
