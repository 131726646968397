import {get} from 'lodash';
import {useMemo} from 'react';

import {en, zh} from 'src/translations';

export const useTranslations = () => {
  const t = stringToTranslate => {
    return (
      get(zh, stringToTranslate) ||
      get(en, stringToTranslate) ||
      '! missing translation !'
    );
  };

  const tf = functionName => {
    if (get(en, functionName)) {
      return get(en, functionName);
    } else {
      return () => '! missing translation !';
    }
  };

  const returnObject = useMemo(() => {
    return {
      i18n: {
        t,
        tf,
      },
    };
  }, []);

  return returnObject;
};

export default useTranslations;
