import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {filter, includes, isEmpty, isNaN, reduce, some, toNumber} from 'lodash';
import {get} from 'lodash';
import {HOME_ITEM_TYPE, USER_DATING, USER_PHOTO_KEY} from 'src/constants';
import routeNames from 'src/navigation/routeNames';
dayjs.extend(relativeTime);

export const getUserPhoto = user => {
  // return (
  //   user?.photos?.find(i => i.includes('users/portrait')) || user.avatar || ''
  // );
  return get(user, `photos.${USER_PHOTO_KEY.PORTRAIT}`, '');
};
export const getUserPhotoKey = (user, key) => {
  // return user?.photos?.find(i => i.includes(`users/${key}`)) || '';
  return get(user, `photos.${key}`, '');
};

export const getUserAge = user => {
  return (
    new Date().getFullYear() -
    (user?.birthday
      ? dayjs(user.birthday, 'YYYY-MM-DD').year()
      : user?.age || 0)
  );
};

export const getUserGeneration = user => {
  const userBirthdayYear = user?.birthday
    ? dayjs(user.birthday, 'YYYY-MM-DD').year()
    : 1990;
  return userBirthdayYear > 1999
    ? '00'
    : Math.floor((userBirthdayYear % 100) / 10) + '0';
};

export const isWeChat = () => {
  return /MicroMessenger/i.test(window.navigator.userAgent);
  // window?.location?.href.includes(API_URL)
};

export const getNextNavigateOnboarding = (userData = {}) => {
  if (userData.user_type === 'wechat' && !userData.is_phone_verified) {
    return routeNames.AuthFlow.VerifyPhone;
  }
  if (!userData.birthday || !userData.gender) {
    return routeNames.AuthFlow.EditProfile;
  }
  // if (!userData.gender || !userData.name || !userData.birthday) {
  //   return routeNames.AuthFlow.Bio;
  // }
  // if (!userData.job || !userData.industry) {
  //   return routeNames.AuthFlow.MyWorkAndIndustryScreen;
  // }
  // if (!userData.college) {
  //   return routeNames.AuthFlow.MyCollegeScreen;
  // }
  // if (
  //   isEmpty(USER_DATING.filter(i => includes(userData?.dating_standard, i)))
  // ) {
  //   return routeNames.AuthFlow.MyWishScreen;
  // }
  // if (!userData.bio) {
  //   return routeNames.AuthFlow.MyLifestyleScreen;
  // }
  // if (!getUserPhoto(userData)) {
  //   return routeNames.AuthFlow.MyProfilePhoto;
  // }
  return routeNames.AuthFlow.WelcomeScreen;
};

export const isFillUserPhoneData = (userData = {}) => {
  return (
    !(userData.user_type === 'wechat' && !userData.is_phone_verified) &&
    userData.birthday &&
    userData.gender
  );
};

export const isFulfillUserData = (userData = {}) => {
  return !!(
    !(userData.user_type === 'wechat' && !userData.is_phone_verified) &&
    userData.gender &&
    userData.name &&
    userData.birthday &&
    userData.job &&
    userData.industry &&
    userData.college &&
    !isEmpty(USER_DATING.filter(i => includes(userData?.dating_standard, i))) &&
    userData.bio &&
    getUserPhoto(userData)
  );
};

export const formatPrice = price => {
  return isNaN(parseFloat(price))
    ? 0
    : parseFloat(price / 100)
        .toFixed(2)
        .replace('.00', '');
};

export const getTripMemberCount = members => {
  return reduce(
    members,
    (count, member) => count + toNumber(member?.accompany_persons) + 1,
    0,
  );
};

export const checkDuplicate = (userTripData, tripStart, tripEnd) => {
  return some(
    filter(userTripData, item => {
      return (
        item.type === HOME_ITEM_TYPE.TRIP ||
        item.type === HOME_ITEM_TYPE.BAZAAR_TRIP
      );
    }),
    item => {
      if (item.type === HOME_ITEM_TYPE.TRIP) {
        const start = new Date(item.from_date);
        const end = new Date(item.to_date);
        return (
          (start <= tripStart && tripStart <= end) ||
          (start <= tripEnd && tripEnd <= end) ||
          (tripStart <= start && end <= tripEnd)
        );
      } else {
        const tripDate = new Date(item.from_date);
        return tripStart <= tripDate <= tripEnd;
      }
    },
  );
};
