import {StyleSheet, Platform} from 'react-native';
import {colors} from 'src/constants';
import {calculateHeight, calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  modalContainer: {
    width: calculateWidth(375),
    height: '100%',
    alignSelf: 'center',
    backgroundColor: '#000',
  },
  imageBackground: {
    width: calculateWidth(375),
    height: calculateHeight(812),
  },
  content: {
    marginTop: -calculateHeight(812),
  },
  textDate: {
    ...textFont(13, '600'),
    color: colors.neutral9,
  },
  calendar: {
    width: calculateWidth(16),
    height: calculateWidth(16),
    marginRight: calculateWidth(8),
  },
  dateWrapper: {
    flexDirection: 'row',
    backgroundColor: colors.primary,
    alignSelf: 'center',
    paddingVertical: calculateWidth(8),
    paddingHorizontal: calculateWidth(12),
    borderRadius: calculateWidth(6),
    marginTop: Platform.OS === 'ios' ? 0 : 20,
  },
  flexRow: {
    flexDirection: 'row',
  },

  avatar: {
    width: calculateWidth(28),
    height: calculateWidth(28),
    borderRadius: 100,
    marginRight: calculateWidth(2),
    backgroundColor: colors.neutral6,
  },
  textTitle: {
    ...textFont(38, '600'),
    color: colors.neutral9,
  },
  linearGradientMore: {
    marginTop: -calculateHeight(812),
    height: calculateHeight(812),
  },
  dateContent: {
    marginLeft: calculateWidth(32),
    marginTop: calculateHeight(150),
    alignItems: 'baseline',
  },
  dateWrapperMore: {
    flexDirection: 'row',
    backgroundColor: colors.primary,
    paddingVertical: calculateWidth(8),
    paddingHorizontal: calculateWidth(12),
    borderRadius: calculateWidth(6),
  },
  titleWrapperMore: {
    paddingHorizontal: calculateWidth(32),
    marginTop: calculateWidth(24),
  },
  avatarWrapperMore: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: calculateWidth(16),
  },
  textInviteBy: {
    ...textFont(15, '600'),
    color: colors.neutral9,
  },
  avatarMore: {
    width: calculateWidth(28),
    height: calculateWidth(28),
    borderRadius: 100,
    marginHorizontal: calculateWidth(8),
    backgroundColor: colors.neutral8,
  },
  textVillageMore: {
    ...textFont(15, '600'),
    color: colors.neutral9,
  },
  border: {
    borderWidth: 0.5,
    borderColor: colors.neutral5,
    marginHorizontal: calculateWidth(32),
  },
  description: {
    ...textFont(16, '400'),
    color: colors.neutral5,
    paddingHorizontal: calculateWidth(32),
    marginTop: calculateWidth(24),
  },
  padding32: {
    padding: calculateWidth(32),
  },
  header: {
    ...textFont(32, '600'),
    color: colors.neutral9,
  },
  activityImage: {
    width: calculateWidth(82),
    height: calculateWidth(82),
    borderRadius: calculateWidth(12),
    backgroundColor: colors.neutral5,
  },
  activity: {
    marginTop: calculateWidth(24),
    flexDirection: 'row',
  },
  activityTitle: {
    ...textFont(18, '600'),
    color: colors.neutral9,
  },
  activityDescription: {
    flex: 1,
    ...textFont(15, '400'),
    color: colors.neutral5,
    marginTop: calculateWidth(4),
  },
  activityDetail: {
    flex: 1,
    marginLeft: calculateWidth(32),
  },
  split: {
    borderWidth: 0.5,
    borderColor: colors.neutral5,
    marginVertical: calculateWidth(12),
  },
  userWrapper: {
    marginLeft: calculateWidth(10),
  },
  textUserActivity: {
    ...textFont(14, '600'),
    color: colors.neutral6,
  },
  textUserJob: {
    color: colors.neutral5,
    ...textFont(14, '400'),
    textTransform: 'capitalize',
  },

  hostTitle: {
    ...textFont(16, '400'),
    color: colors.neutral9,
  },

  cost: {
    margin: calculateWidth(32),
    padding: calculateWidth(32),
    backgroundColor: 'rgba(40, 46, 39, 0.7)',
    borderRadius: calculateWidth(12),
  },
  costItem: {
    backgroundColor: colors.neutral1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: calculateWidth(20),
    borderRadius: calculateWidth(8),
    marginTop: calculateWidth(8),
  },
  costSelected: {
    borderWidth: 1,
    borderColor: colors.neutral5,
  },
  headerAlign: {
    ...textFont(32, '600'),
    color: colors.neutral9,
    textAlign: 'center',
    marginBottom: calculateWidth(12),
  },
  costTitle: {
    ...textFont(16, '400'),
    color: colors.neutral5,
  },

  disclaimerItem: {
    flexDirection: 'row',
    marginTop: calculateWidth(16),
  },
  disclaimerTitle: {
    ...textFont(13, '700'),
    color: colors.neutral5,
    borderWidth: 1,
    borderColor: colors.neutral5,
    paddingVertical: calculateWidth(4),
    paddingHorizontal: calculateWidth(12),
    borderRadius: calculateWidth(14),
  },
  disclaimerDetail: {
    ...textFont(16, '400'),
    color: colors.neutral9,
    marginLeft: calculateWidth(16),
    marginTop: calculateWidth(2),
    flex: 1,
  },
  bottomButtonMore: {
    marginHorizontal: calculateWidth(32),
    marginBottom: Platform.OS === 'ios' ? 0 : calculateWidth(32),
    width: calculateWidth(310),
    bottom: calculateHeight(10),
  },
  bottomButtonMoreChooseCity: {
    marginHorizontal: calculateWidth(32),
    marginBottom: Platform.OS === 'ios' ? 0 : calculateWidth(32),
    position: 'absolute',
    width: calculateWidth(310),
    bottom: calculateHeight(10),
  },
  viewTextTip: {
    position: 'absolute',
    top: calculateHeight(305),
    width: calculateWidth(375),
  },
  textTip: {
    ...textFont(24, '400'),
    textAlign: 'center',
  },
  hostList: {
    // flexWrap: 'wrap',
    // flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  hostItem: {
    backgroundColor: colors.neutral1,
    borderRadius: calculateWidth(8),
    padding: calculateWidth(16),
    marginTop: calculateWidth(16),
  },

  backIcon: {
    width: calculateWidth(20),
    height: calculateWidth(20),
  },
  backButton: {
    position: 'absolute',
    left: calculateWidth(22),
    width: calculateWidth(40),
    height: calculateWidth(40),
    justifyContent: 'center',
    alignItems: 'center',
  },
  shareButton: {
    marginTop: calculateWidth(16),
  },
  findButton: {
    backgroundColor: '#fff',
  },
  iconLocation: {
    width: calculateWidth(20),
    height: calculateWidth(20),
    marginRight: calculateWidth(4),
  },
  location: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: calculateWidth(8),
    marginBottom: calculateWidth(32),
  },

  agreement: {
    ...textFont(14, '400'),
    color: colors.neutral6,
  },
  confirm: {
    flexDirection: 'row',
    marginBottom: calculateWidth(12),
    alignItems: 'center',
    justifyContent: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'end',
    alignItems: 'center',
  },
  modalView: {
    width: calculateWidth(375),
    height: calculateHeight(300),
    backgroundColor: 'white',
    // paddingLeft: calculateWidth(35),
    // paddingRight: calculateWidth(35),
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTextCancel: {
    ...textFont(20, '400'),
  },
  modalTextOk: {
    ...textFont(20, '400'),
    color: '#60a445',
  },
  modalControl: {
    display: 'flex',
    justifyContent: 'space-between',
    width: calculateWidth(375),
    flexDirection: 'row',
    padding: calculateWidth(5),
    paddingLeft: calculateWidth(15),
    paddingRight: calculateWidth(15),
    borderBottomWidth: '1px',
    borderBottomColor: '#dbdada',
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  item: {
    backgroundColor: '#ffffff',
    paddingBottom: calculateWidth(1),
    margin: 0,
    borderRadius: 5,
    // marginVertical: 8,
    // marginHorizontal: 16,
    textAlign: 'center',
  },
  itemSelected: {
    backgroundColor: '#f4f4f4',
    paddingBottom: calculateWidth(3),
    margin: 0,
    borderRadius: 5,
    // marginVertical: 8,
    // marginHorizontal: 16,
    textAlign: 'center',
  },
  title: {
    ...textFont(20, '400'),
  },
  container: {
    flex: 1,
    marginTop: 0,
  },
  contentImage: {
    marginTop: calculateWidth(32),
  },
  supportLink: {
    marginTop: calculateWidth(16),
    textAlign: 'center',
  },
  scrollToEnd: {
    position: 'absolute',
    bottom: 0,
    width: calculateWidth(311),
    marginHorizontal: calculateWidth(32),
    marginBottom: Platform.OS === 'ios' ? 0 : calculateWidth(32),
  },
});

export default styles;
