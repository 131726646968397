import React, {useEffect} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import {StackActions, useNavigation} from '@react-navigation/native';

import images from 'src/assets/images';

import styles from './styles';
import routeNames from 'src/navigation/routeNames';
import {useTranslations} from 'src/hooks';
import {useSelector} from 'react-redux';
import {filter} from 'lodash';
import {useGetNotification} from 'src/api/notification';
import {textFont} from 'src/utils/styles';
import {colors} from 'src/constants';

const Header = ({
  title,
  withBackButton,
  onPressBackButton,
  withRightButton = true,
  containerStyle,
  isDarkHeader = false,
  withDoneButton,
  onPressDoneButton,
  withSearchButton,
}) => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const navigation = useNavigation();
  const {i18n} = useTranslations();
  const notificationList = useSelector(
    state => state.notification.notificationList,
  );
  const {runRequest: getNotification} = useGetNotification({});
  const newNotificationList = filter(notificationList, ['mark_as_read', false]);

  useEffect(() => {
    getNotification();
    const timeOut = setInterval(getNotification, 20000);
    return () => {
      clearInterval(timeOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={[styles.container, containerStyle]}>
      {withBackButton ? (
        <TouchableOpacity
          style={styles.leftIconWrapper}
          onPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.dispatch(
                StackActions.replace(
                  isLoggedIn
                    ? routeNames.BottomTab
                    : routeNames.AuthFlow.WelcomeScreen,
                ),
              );
            }
            onPressBackButton?.();
          }}>
          <Image
            source={isDarkHeader ? images.ArrowLeftWhite : images.ArrowLeft}
            style={styles.leftIcon}
            resizeMode="contain"
          />
          <Text style={isDarkHeader ? styles.textTitleDark : styles.textTitle}>
            {i18n.t('back')}
          </Text>
        </TouchableOpacity>
      ) : (
        <View style={styles.leftIconWrapper}>
          {withSearchButton && (
            <TouchableOpacity
              style={styles.museum}
              onPress={() => {
                navigation.navigate(routeNames.MainFlow.SearchScreen);
              }}>
              <Text style={[textFont(12, '400'), {color: colors.neutral5}]}>
                {i18n.t('museum')}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      )}
      <Text style={styles.textTitle}>{title}</Text>
      <View style={styles.rightIconWrapper}>
        {withRightButton && isLoggedIn && (
          <>
            {/* <TouchableOpacity
              style={styles.leftIconWrapper}
              onPress={() => {
                navigation.navigate(routeNames.MainFlow.SearchScreen);
              }}>
              <Image
                source={isDarkHeader ? images.SearchWhite : images.Search}
                style={styles.leftIcon}
                resizeMode="contain"
              />
            </TouchableOpacity> */}
            <TouchableOpacity
              style={styles.leftIconWrapper}
              onPress={() => {
                navigation.navigate(routeNames.MainFlow.Notification);
              }}>
              <Image
                source={
                  isDarkHeader ? images.NotificationWhite : images.Notification
                }
                style={styles.leftIcon}
                resizeMode="contain"
              />
              {newNotificationList.length > 0 && (
                <View style={styles.notification}>
                  <Text style={styles.textNotification}>
                    {newNotificationList.length}
                  </Text>
                </View>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.leftIconWrapper}
              onPress={() => {
                navigation.navigate(routeNames.MainFlow.MyTrip);
              }}>
              <Image
                source={isDarkHeader ? images.NoteWhite : images.Note}
                style={styles.leftIcon}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </>
        )}
        {withDoneButton && (
          <TouchableOpacity
            style={styles.leftIconWrapper}
            onPress={onPressDoneButton}>
            <Text style={styles.textTitle}>{i18n.t('done')}</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default Header;
