import {StyleSheet} from 'react-native';
import {colors} from 'src/constants';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

export default StyleSheet.create({
  modalContainer: {
    width: calculateWidth(375),
    flex: 1,
    alignSelf: 'center',
    backgroundColor: 'rgba(18, 24, 16, 0.4)',
  },
  container: {
    flex: 1,
    backgroundColor: colors.neutral9,
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
  },
  closeContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  closeButton: {
    backgroundColor: colors.neutral9,
    borderRadius: 18,
    width: 36,
    height: 36,
    top: -18,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginRight: 16,
  },
  closeIcon: {
    width: calculateWidth(12),
    height: calculateWidth(12),
  },

  title: {
    textAlign: 'center',
    ...textFont(22, '600'),
    color: colors.neutral1,
    marginBottom: calculateWidth(16),
  },
  user: {
    margin: calculateWidth(16),
  },
  bottomButton: {
    flex: 1,
    marginLeft: calculateWidth(8),
  },
  edit: {
    flex: 1,
    height: calculateWidth(48),
    borderColor: colors.primary,
    borderWidth: 2,
    borderRadius: calculateWidth(8),
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: calculateWidth(8),
  },
  flexRow: {
    flexDirection: 'row',
    marginTop: calculateWidth(32),
  },
});
