import {StyleSheet} from 'react-native';
import colors from 'src/constants/colors';
import {calculateWidth} from 'src/utils/dimensions';
import {textFont} from 'src/utils/styles';

const styles = StyleSheet.create({
  button: {
    height: calculateWidth(48),
    backgroundColor: colors.primary,
    borderRadius: calculateWidth(8),
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonDisable: {
    height: calculateWidth(48),
    backgroundColor: colors.neutral8,
    borderRadius: calculateWidth(8),
    justifyContent: 'center',
    alignItems: 'center',
  },
  textButton: {
    ...textFont(16, '400'),
    color: colors.neutral9,
  },
  deActive: {
    ...textFont(16, '400'),
    color: colors.neutral4,
  },
});

export default styles;
